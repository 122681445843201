// Third party libraries
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
// Local State Functions
import {
  removeCalculation,
  removeCalculationHard,
  cloneCalculation,
  restoreCalculation,
} from '../../clientStore/calculationSlice/calculation-async-thunk';
import {
  getCalculationRemovingStatus,
  getCalculationCloningStatus,
  getCalculationHardRemovingStatus,
  setCalculationSavingStatus,
  setCalculationLoadingStatus,
  setCalculationRemovingStatus,
  setCalculationHardRemovingStatus,
  setCalculationRestoringStatus,
  setCalculationCloningStatus,
  getCalculationRestoringStatus,
  getCalculation,

} from '../../clientStore/calculationSlice/calculation-slice';
// Styles
import styles from './CalculationTableBody.module.css';
import deleteIcon from './../../img/delete.png';
import restoreIcon from './../../img/restore.png';
import copyIcon from './../../img/copy.png';
import { Icon } from '../Icon';
import { IconNames } from '../../util/const';
import { ChildCalcsMain } from '../child-calcs-main/Child-calcs-main';
import { setGroupCalculationsLoadingStatus } from '../../clientStore/calculationsSlice/calculations-slice';
import { CalculationTableBodyItem } from '../calculation-table-body-item/Calculation-table-body-item';

export const CalculationTableBody = ({ tableData, setFilterChanged, isCalculationPage }) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const removedStatus = useSelector(getCalculationRemovingStatus);
  const hardRemovedStatus = useSelector(getCalculationHardRemovingStatus);
  const restoredStatus = useSelector(getCalculationRestoringStatus)
  const clonedStatus = useSelector(getCalculationCloningStatus);
  const hardRemovedCalculation = useSelector(getCalculation);

  const [expandedItems, setExpandedItems] = useState([]);
  const handleExpandToggle = (itemId) => {
    setExpandedItems((prevExpandedItems) => {
      if (prevExpandedItems.includes(itemId)) {
        dispatch(setGroupCalculationsLoadingStatus({ status: 'successed' }));
        return prevExpandedItems.filter((id) => id !== itemId);
      } else {
        dispatch(setGroupCalculationsLoadingStatus({ status: 'idle' }));
        return [...prevExpandedItems, itemId];
      }
    });
  }


  useEffect(() => {
    if (removedStatus === 'successed') {
      dispatch(setCalculationSavingStatus({ status: 'idle' }));
      dispatch(setCalculationLoadingStatus({ status: 'idle' }));
      dispatch(setCalculationRemovingStatus({ status: 'idle' }));
      dispatch(setCalculationHardRemovingStatus({ status: 'idle' }));
      dispatch(setCalculationRestoringStatus({ status: 'idle' }));
      dispatch(setCalculationCloningStatus({ status: 'idle' }));
      try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      }
    }

    if (hardRemovedStatus === 'successed') {
      dispatch(setCalculationSavingStatus({ status: 'idle' }));
      dispatch(setCalculationLoadingStatus({ status: 'idle' }));
      dispatch(setCalculationRemovingStatus({ status: 'idle' }));
      dispatch(setCalculationHardRemovingStatus({ status: 'idle' }));
      dispatch(setCalculationRestoringStatus({ status: 'idle' }));
      dispatch(setCalculationCloningStatus({ status: 'idle' }));

      try {
        if (isCalculationPage) {
          history.push(`/calculations/${hardRemovedCalculation.mainCalcId}`)
        }
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      }
    }

    if (clonedStatus === 'successed') {
      dispatch(setCalculationSavingStatus({ status: 'idle' }));
      dispatch(setCalculationLoadingStatus({ status: 'idle' }));
      dispatch(setCalculationRemovingStatus({ status: 'idle' }));
      dispatch(setCalculationHardRemovingStatus({ status: 'idle' }));
      dispatch(setCalculationRestoringStatus({ status: 'idle' }));
      dispatch(setCalculationCloningStatus({ status: 'idle' }));
      try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      }
    }

    if (restoredStatus === 'successed') {
      dispatch(setCalculationSavingStatus({ status: 'idle' }));
      dispatch(setCalculationLoadingStatus({ status: 'idle' }));
      dispatch(setCalculationRemovingStatus({ status: 'idle' }));
      dispatch(setCalculationHardRemovingStatus({ status: 'idle' }));
      dispatch(setCalculationRestoringStatus({ status: 'idle' }));
      dispatch(setCalculationCloningStatus({ status: 'idle' }));
      try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      }
    }
  }, [removedStatus, hardRemovedStatus, clonedStatus, restoredStatus, history, dispatch]);

  return (
    <ul className={styles.list}>
      {tableData.map((data, index) => {
        const isExpanded = expandedItems.includes(data._id);
        const isFirstItem = index === 0
        return (
          <div key={`tbi${index}`}>
            <CalculationTableBodyItem
              data={data}
              handleExpandToggle={handleExpandToggle}
              isExpanded={isExpanded}
              isCalculationPage={isCalculationPage}
              isFirstItem={isFirstItem}
            />
            {isExpanded && <div /* className={styles.mainCont} */><ChildCalcsMain mainCalcId={data._id} isExpanded /></div>}
          </div>
        );
      })}
    </ul>
  );
};
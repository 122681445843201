// Third party libraries
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom';
// Components
import { DepartmentEdit } from '../Components/department-edit/Department-edit';
import { DepartmentForm } from '../Components/department-form/Department-form';
import { DepartmentsMain } from '../Components/departments-main/Departments-main';
import { Footer } from '../Components/footer/Footer';
import { Header } from '../Components/header/Header';
import { PageHeader } from '../Components/page-header/Page-header';
import { PageMenu } from '../Components/page-menu/Page-menu';
// Functions for work with state
import { getUserRole, checkAuth } from '../clientStore/authSlice/auth-slice';
import {
  getDepartments,
  setDepartmentsLoadingStatus,
} from '../clientStore/departmentsSlice/departments-slice';
//Utils functions
import { IconNames } from '../util/const';
import { PrivateRoute } from '../util/private-route';
import { getSectionNames } from '../util/utils';
import { useLocation } from 'react-router-dom';
import { CuFtDepartmentForm } from '../Components/cuft-department-form/CuFt-department-form';


export const Departments = ({ userName }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const userRole = useSelector(getUserRole);
  const SectionNames = getSectionNames(userRole);
  const location = useLocation();

  const { type } = location.state || { type: 'hourly' };


  const settingsHeaderBtn = [
    /*   {
        name: 'Change password'
      } */
  ];

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setDepartmentsLoadingStatus({ status: 'idle' }));
  }
  )

  return (
    <div className='container'>
      <Header
        isLoginBtnShow={false}
      />
      <main className='main'>
        <PageHeader
          titleIcon={IconNames.USER}
          title={userName}
          btns={settingsHeaderBtn}
          menuItems={SectionNames}
        />
        {/*  <PageMenu
       
        /> */}

        <Switch>
          <Route exact path={path}>
            <DepartmentsMain type={type} />
          </Route>
          <PrivateRoute path={`${path}/add`}>
            {type === 'cuft' ? (
              <CuFtDepartmentForm formType={'new'} type={type} />
            ) : (
              <DepartmentForm formType={'new'} type={type} />
            )}
          </PrivateRoute>
          <PrivateRoute path={`${path}/:departmentId`}>
            <DepartmentEdit />
          </PrivateRoute>
        </Switch>
      </main>
      {/* <Footer /> */}
    </div>
  );
};
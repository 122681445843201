import { createSlice } from '@reduxjs/toolkit';
import {
  loadOutOfState,
  saveOutOfState,
  updateOutOfState,
  removeOutOfState,
} from './outofstate-async-thunk';


const initialState = {
  savingStatus: 'idle',
  loadingStatus: 'idle',
  outofstate: {},
  countOfOutOfStates: 0,
  errors: null,
};

const outofstateSlice = createSlice({
  name: 'outofstate',
  initialState,
  reducers: {
    setOutOfStateSavingStatus (state, action) {
      const { status } = action.payload;
      state.savingStatus = status;
    },
    setOutOfStateLoadingStatus (state, action) {
      const { status } = action.payload;
      state.loadingStatus = status;
    },
    setOutOfStateRemovingStatus(state, action) {
      const { status } = action.payload;
      state.removingStatus = status;
    },
  },
  extraReducers: {
    // saveOutOfState extra reducer
    [saveOutOfState.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [saveOutOfState.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [saveOutOfState.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.outofstate = action.payload.data;
    },

    // updateDepartmen extra reducer
    [updateOutOfState.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [updateOutOfState.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [updateOutOfState.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.outofstate = action.payload.data;
    },

    // loadOutOfState extra reducer
    [loadOutOfState.pending]: (state, action) => {
      state.loadingStatus = 'loading';
    },
    [loadOutOfState.rejected]: (state, action) => {
      state.loadingStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadOutOfState.fulfilled]: (state, action) => {
      state.loadingStatus = 'successed';
      state.outofstate = action.payload.data;
    },

    //removeOutOfState extra reducer
    [removeOutOfState.pending]: (state, action) => {
      state.removingStatus = 'loading';
    },
    [removeOutOfState.rejected]: (state, action) => {
      state.removingStatus = 'failed';
      state.errors = action.error.message;
    },
    [removeOutOfState.fulfilled]: (state, action) => {
      state.removingStatus = 'successed';
      state.outofstate = action.payload.data;
    },
  },
});

export const { setOutOfStateSavingStatus, setOutOfStateLoadingStatus, setOutOfStateRemovingStatus } = outofstateSlice.actions;

export default outofstateSlice.reducer;

export const getOutOfStateSavingStatus = (state) => state.outofstate.savingStatus;

export const getOutOfStateLoadingStatus = (state) => state.outofstate.loadingStatus;

export const getOutOfStateRemovingStatus = (state) => state.outofstate.removingStatus;

export const getOutOfState = (state) => state.outofstate.outofstate;

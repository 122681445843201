// Third party libraries
import PropTypes from 'prop-types';
// Styles
import styles from './LongDistanceMilesRangeInput.module.css';

export const LongDistanceMilesRangeInput = (props) => {
  const {
    values,
    changeValues,
    index,
    thirdTitle,
  } = props;
  const keys = Object.keys(values);
  return (
    <div className={styles.distanceContainer}>

      <label
        className={styles.inputContainer}
        htmlFor={keys[0]}
      >
        <p className={styles.inputName}>Miles range</p>
        <input
          id={keys[0]}
          className={styles.doubleInput}
          type='number'
          name={keys[0]}
          value={values[keys[0]]}
          onChange={(evt) => changeValues(evt)}
          onWheel={(e) => e.target.blur()}
        />
        <span>-</span>
        <input
          id={keys[1]}
          className={styles.doubleInput}
          type='number'
          name={keys[1]}
          value={values[keys[1]]}
          onChange={(evt) => changeValues(evt)}
          onWheel={(e) => e.target.blur()}
        />
        <span className={styles.eofmiles}>miles</span>
        <input
          id={keys[2]}
          className={styles.doubleInputSecond}
          type='number'
          name={keys[2]}
          value={values[keys[2]]}
          onChange={(evt) => changeValues(evt)}
          onWheel={(e) => e.target.blur()}
        />
        <p className={styles.inputNameFinish}>{thirdTitle}</p>
      </label>
    </div>);
};

LongDistanceMilesRangeInput.propTypes = {
  values: PropTypes.object.isRequired,
  changeValues: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  thirdTitle: PropTypes.string.isRequired,
};

// Third party libraries
import PropTypes from 'prop-types';
// Styles
import styles from './PackingLabel.module.css';

export const PackingLabel = (props) => {
  const {
    inputId,
    name,
    title,
    placeholder,
    value,
    valueCuFt,
    changeValue,
    changeValueCuFt,
    valueType,
    isFocused,
    isLong,
  } = props;

  return (
    <>
      <label
        className={styles.inputContainer}
        htmlFor={inputId + "CuFt"}
      >
        <p className={styles.inputName}>{title}</p>
        <input
          id={inputId + "CuFt"}
          className={styles.input}
          type='number'
          placeholder=""
          name={name + "CuFt"}
          value={valueCuFt}
          onChange={changeValueCuFt}
          /* autoFocus={isFocused} */
          required
          onWheel={(e) => e.target.blur()}
        />
      </label>

      <label
        className={styles.inputContainerSecond}
        htmlFor={inputId}
      >
        <p className={styles.inputNameSecond}> - amount</p>
        <span className={styles.inputValueType}>$</span>
        <input
          id={inputId}
          className={styles.inputSecond}
          type='number'
          placeholder={"ex 160"}
          name={name}
          value={value}
          onChange={changeValue}
          /* autoFocus={isFocused} */
          required
          onWheel={(e) => e.target.blur()}
        />
      </label>
    </>
  );
};

// Variable type settings
PackingLabel.propTypes = {
  inputId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  changeValue: PropTypes.func.isRequired,
  valueCuFt: PropTypes.string,
  changeValueCuFt: PropTypes.func.isRequired,
  valueType: PropTypes.string,
  isFocused: PropTypes.bool,
  isLong: PropTypes.bool,
};

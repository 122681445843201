// Third party libraries
import PropTypes from 'prop-types';
// Components
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentTitle } from '../department-title/Department-title';
import { Icon } from '../Icon';
// Utils
import { IconNames } from '../../util/const';
import {
  getProcessTemplatesLoading,
  getTemplates,
} from '../../clientStore/templatesSlice/templates-slice';
import { loadTemplates } from '../../clientStore/templatesSlice/templates-async-thunk';
import { generateRandomString } from '../../util/utils';

// Styles
import styles from './MiscCheckMarks.module.css';
import { useEffect, useState } from 'react';
import { removeByIndex, replaceSpaces } from '../../util/utils';
import { useDispatch, useSelector } from 'react-redux';
import CustomMchsPopup from '../custom-mchs-popup/Custom-mchs-popup';

export const MiscCheckMarks = (props) => {
  const {
    miscCheckMarks,
    changeMiscCheckMarks,
    clearLastTarget,
    companyMiscCheckMarks,
    setCompanyMiscCheckMarks,
  } = props;
  const dispatch = useDispatch();
  const templates = useSelector(getTemplates);
  const loadingProcess = useSelector(getProcessTemplatesLoading);

  const [templateTags, setTemplateTags] = useState([])
  const [isMchsPopupOpen, setIsMchsPopupOpen] = useState(false);

  useEffect(() => {
    if (loadingProcess === 'idle') {
      dispatch(loadTemplates());
    }
  }, [dispatch, loadingProcess])


  const addCheckMark = (index) => {
    var uId = generateRandomString(10);
    const newCharge = {
      [`id${index}`]: 'id' + index,
      [`checkMarkName${index}`]: '',
      [`uId${index}`]: uId,
    }
    setCompanyMiscCheckMarks(current => [...current, { ['id' + index]: '', ['uId' + index]: uId }])
    changeMiscCheckMarks(`miscCheckMarks.miscCheckMarks`, 'push', newCharge);
  }


  const removeCheckMark = () => {
    if (window.confirm('This action will remove checkMarks from all departments in your company. Are you sure?')) {
      let newmiscCheckMarks = companyMiscCheckMarks.slice(0, -1)
      setCompanyMiscCheckMarks(newmiscCheckMarks)
      changeMiscCheckMarks(`miscCheckMarks.miscCheckMarks.${miscCheckMarks.miscCheckMarks.length - 1}`, 'del');
    }
  }

  const removeCheckMarkByIndex = (index) => {
    if (window.confirm('This action will remove checkMarks from all departments in your company. Are you sure?')) {
      let templateTagsLoc = [];
      if (templates && templates.length) {
        templates.forEach(template => {
          const templateObj = {
            name: template.templateName,
            tags: []
          };
          if (template.tags && template.tags.length) {
            template.tags.forEach(tag => {
              if (tag.conditions && tag.conditions.length) {
                tag.conditions.forEach((condition, conditionIndex) => {
                  if ('mChId_' + miscCheckMarks.miscCheckMarks[index][`uId${index}`] == condition[`selectedItemObj${conditionIndex}`].value) {
                    templateObj.tags.push(tag.tagName);
                  }
                });
              }
            });
          }
          if (templateObj.tags.length) {
            templateTagsLoc.push({ ...templateObj, tags: templateObj.tags.map(item => `{${item}}`).join(', ') });
          }
        });
      }
      if (templateTagsLoc.length) {
        setTemplateTags(templateTagsLoc);
        setIsMchsPopupOpen(true);
      } else {
        let newCompanyMiscCheckMarks = removeByIndex([...companyMiscCheckMarks], index);
        let newMiscCheckMarks = removeByIndex([...miscCheckMarks.miscCheckMarks], index)
        setCompanyMiscCheckMarks(newCompanyMiscCheckMarks)
        changeMiscCheckMarks(`miscCheckMarks.miscCheckMarks`, 'set', newMiscCheckMarks);
      }
    }
  }

  const setChargeValue = (evt, index) => {
    changeMiscCheckMarks(`miscCheckMarks.miscCheckMarks.${index}.${evt.target.name}`, 'set', evt.target.value);
  }

  useEffect(() => {
    let newCheckMarks = []
    if (companyMiscCheckMarks) {
      if (!companyMiscCheckMarks.length) {
        companyMiscCheckMarks.map((checkMarkId, chMIdIndex) => {
          const newCharge = {
            [`id${chMIdIndex}`]: 'id' + chMIdIndex,
            [`checkMarkName${chMIdIndex}`]: checkMarkId[`id${chMIdIndex}`],
            [`uId${chMIdIndex}`]: checkMarkId[`uId${chMIdIndex}`],
          }
          newCheckMarks.push((newCharge))
        })
      }
      else {
        companyMiscCheckMarks.map((checkMarkId, chMIdIndex) => {
          if (miscCheckMarks.miscCheckMarks[chMIdIndex]) {
            const newCharge = {
              [`id${chMIdIndex}`]: 'id' + chMIdIndex,
              [`checkMarkName${chMIdIndex}`]: miscCheckMarks.miscCheckMarks[chMIdIndex][`checkMarkName${chMIdIndex}`] ? miscCheckMarks.miscCheckMarks[chMIdIndex][`checkMarkName${chMIdIndex}`] : checkMarkId[`id${chMIdIndex}`],
              [`uId${chMIdIndex}`]: miscCheckMarks.miscCheckMarks[chMIdIndex][`uId${chMIdIndex}`] ? miscCheckMarks.miscCheckMarks[chMIdIndex][`uId${chMIdIndex}`] : checkMarkId[`uId${chMIdIndex}`],
            }
            newCheckMarks.push(({ ...newCharge }))
          }
        })
      }
      changeMiscCheckMarks(`miscCheckMarks.miscCheckMarks`, 'set', [...newCheckMarks]);
    }
  }, [companyMiscCheckMarks]);




  return (
    <section className={styles.contaner}
      onFocus={clearLastTarget}>
      <CustomMchsPopup isOpen={isMchsPopupOpen} templateTags={templateTags} hideCustomDialog={setIsMchsPopupOpen} />
      <DepartmentTitle
        title={'MISC. CHECK MARKS'}
      />
      {
        companyMiscCheckMarks && companyMiscCheckMarks.map((checkMarkId, chMIdIndex) => {
          const checkMark = miscCheckMarks.miscCheckMarks[chMIdIndex]
          return (
            <div className={styles.container} key={chMIdIndex + "eoChM"}>
              <div className={styles.shuttleContainer}>
                <label
                  className={styles.inputContainer}
                  htmlFor={`checkMark_${chMIdIndex + 1}_name`}
                >
                  <p className={styles.inputName}>Check mark name</p>
                  <input
                    id={`checkMark_${chMIdIndex + 1}_name`}
                    className={styles.input}
                    type='text'
                    placeholder="Name of the checkMark"
                    name={`checkMarkName${chMIdIndex}`}
                    value={(checkMark) ? checkMark[`checkMarkName${chMIdIndex}`] : ""}
                    onChange={(evt) => setChargeValue(evt, chMIdIndex)}
                    required
                  />
                  <button
                    className={styles.removeExtraBtnList}
                    onClick={
                      (evt) => {
                        evt.preventDefault();
                        removeCheckMarkByIndex(chMIdIndex);
                      }
                    }
                  >
                    <Icon name={IconNames.MINUS} />
                  </button>
                </label>

              </div>
            </div>
          )

        })
      }

      <div className={styles.buttonContainer}>
        <button
          className={styles.extraBtn}
          onClick={
            (evt) => {
              evt.preventDefault();
              addCheckMark(miscCheckMarks.miscCheckMarks.length);
            }
          }
        >
          Add check mark
          <Icon name={IconNames.PLUS} />
        </button>
        {/*    <button
          className={styles.removeExtraBtn}
          onClick={
            (evt) => {
              evt.preventDefault();
              removeCheckMark();
            }
          }
        >
          Remove check mark
          <Icon name={IconNames.MINUS} />
        </button> */}
      </div>
    </section>
  );
}

MiscCheckMarks.propTypes = {
  miscCheckMarks: PropTypes.object.isRequired,
  changeMiscCheckMarks: PropTypes.func.isRequired,
  clearLastTarget: PropTypes.func.isRequired,
}

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// Util functions
import { onChangeFormValue, fetchedData } from '../../util/utils';
import { HttpCode, Role } from '../../util/const';

// Styles
import styles from './DeleteCalculationsButton.module.css';

const DeleteCalculationsButton = ({ companyId }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [password, setPassword] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();

  const startConfirmation = () => {
    setIsConfirming(true);
  };



  const handleDeleteClick = async (evt) => {
    evt.preventDefault();
    // Perform the deletion of company calculations here
    setIsDeleting(true);
    const deletedCalculations = await fetchedData('calculation/removeCalculations', 'DELETE', { companyId, password });
    setIsDeleting(false);
    if (deletedCalculations.status === HttpCode.OK) {
      alert('Company calculations have been deleted!');

      history.go(0);
    } else {
      alert(deletedCalculations.data.message)
    }
  };

  if (isConfirming) {
    return (
      <div className={styles.form}>
        <div className={styles.title}>Confirm Deletion!</div>
        <div >
          <input
            type="password"
            className={styles.field}
            placeholder="Enter Password to Confirm"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <button className={styles.btnSubmit} onClick={handleDeleteClick}>
            Confirm Deletion
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="form">
      <button className={styles.btnSubmit} onClick={startConfirmation}>
        Delete All Company Calculations
      </button>
    </div>
  );
};

export default DeleteCalculationsButton;
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const loadOutOfStates = createAsyncThunk(
  'outofstateList/getOutOfStates',
  async (params) => await fetchedData('outofstate/outofstates', 'POST',{params})
);

export const getCountOfOutOfStates = createAsyncThunk(
  'outofstateList/getCount',
  async () => await fetchedData('outofstate/count', 'GET')
);

// Third party libraries
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
// Components
import { PackingLabel } from '../packing-label/Packing-label';
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentLabelsec } from '../department-labelsec/Department-labelsec';
import { CrateLabel } from '../crate-label/Crate-label';
import { DepartmentTitle } from "../department-title/Department-title";
import { SectionTitle } from '../section-title/Section-title';
import { Icon } from '../Icon';
// Utils
import { IconNames } from '../../util/const';
// Styles
import styles from './Packing.module.css';
import { useEffect } from 'react';
import { PacSupLabel } from '../pacsup-label/PacSup-label';
import { PacSuppLabel } from '../pacsupp-label/PacSupp-label';
import { generateRandomString, removeByIndex } from '../../util/utils';


export const Packing = (props) => {
  const {
    packing,
    changePackingValue,
    setLastTarget,
    lastTarget,
    clearLastTarget,
    setCursorPosition,
    cursorPosition,
    setCompanyProcedures,
    companyProcedures
  } = props;

  const addCrating = (index) => {
    var uId = generateRandomString(10);
    const newCrate = {
      [`id${index}`]: 'id' + index,
      [`crateName${index}`]: '',
      [`crateCost${index}`]: '',
      [`timeToExecuteCrate${index}`]: '',
      [`uId${index}`]: uId,
    }
    setCompanyProcedures(current => [...current, { ['id' + index]: '', ['uId' + index]: uId  }])
    changePackingValue(`packing.cratings`, 'push', newCrate);
  }

  const removeCrating = () => {
    if (window.confirm('This action will remove crates/procedures from all departments in your company. Are you sure?')) {
      let companyProceduresNewIds = companyProcedures.slice(0, -1)
      setCompanyProcedures(companyProceduresNewIds);
      changePackingValue(`packing.cratings.${packing.cratings.length - 1}`, 'del');
    }
  }

  const removeCratingByIndex = (index) => {
    if (window.confirm('This action will remove crates/procedures from all departments in your company. Are you sure?')) {
      let companyProceduresNewIds = removeByIndex([...companyProcedures], index)
      let newProcedures = removeByIndex([...packing.cratings], index)
      setCompanyProcedures(companyProceduresNewIds);
      changePackingValue(`packing.cratings`, 'set', newProcedures);
    }
  }


  useEffect(() => {
    let newCharges = []
    if (companyProcedures) {
      if (!packing.cratings.length) {
        companyProcedures.map((crateId, crateIndex) => {
          const newCrate = {
            [`id${crateIndex}`]: 'id' + crateIndex,
            [`crateName${crateIndex}`]: crateId[`id${crateIndex}`],
            [`crateCost${crateIndex}`]: '',
            [`timeToExecuteCrate${crateIndex}`]: '',
            [`uId${crateIndex}`]: crateId[`uId${crateIndex}`],
          }
          newCharges.push(newCrate)
        })
      }
      else {
        companyProcedures.map((crateId, crateIndex) => {
          if (packing.cratings[crateIndex]) {
            const newCharge = {
              [`id${crateIndex}`]: 'id' + crateIndex,
              [`crateName${crateIndex}`]: packing.cratings[crateIndex][`crateName${crateIndex}`] ? packing.cratings[crateIndex][`crateName${crateIndex}`] : crateId[`id${crateIndex}`],
              [`crateCost${crateIndex}`]: packing.cratings[crateIndex][`crateCost${crateIndex}`],
              [`timeToExecuteCrate${crateIndex}`]: packing.cratings[crateIndex][`timeToExecuteCrate${crateIndex}`],
              [`uId${crateIndex}`]:  packing.cratings[crateIndex][`uId${crateIndex}`] ? packing.cratings[crateIndex][`uId${crateIndex}`] : crateId[`uId${crateIndex}`],
            }
            newCharges.push((newCharge))
          }
        })
      }
      changePackingValue(`packing.cratings`, 'set', [...newCharges]);
    }
  }, [companyProcedures]);

  return (
    <section>
      <div className={styles.packingContainer}>
        <DepartmentTitle
          title={' Additional procedures & Crating '}
        />
        {
          companyProcedures && companyProcedures.map((procedureId, prIdIndex) => {
            const crating = packing.cratings[prIdIndex]
            return (
              <div className={styles.distanceContainer} key={nanoid(5)}>
                <CrateLabel
                  inputId={`crateName${prIdIndex}`}
                  name={`crateName${prIdIndex}`}
                  title={'Procedure name'}
                  placeholder={'Name'}
                  value={(crating) ? crating[`crateName${prIdIndex}`] : ""}
                  isFocused={lastTarget === `crateName${prIdIndex}`}
                  changeValue={(evt) => {
                    setLastTarget(evt.target.name);
                    setCursorPosition(evt.target.selectionStart)
                    changePackingValue(`packing.cratings.${prIdIndex}.${evt.target.name}`, 'set', evt.target.value)
                  }
                  }
                  cursorPosition={cursorPosition}
                />
                <CrateLabel
                  inputId={`crateCost${prIdIndex}`}
                  name={`crateCost${prIdIndex}`}
                  title={'Cost'}
                  placeholder={'ex: 33'}
                  value={(crating) ? crating[`crateCost${prIdIndex}`] : ""}
                  isFocused={lastTarget === `crateCost${prIdIndex}`}
                  changeValue={(evt) => {
                    setLastTarget(evt.target.name);
                    setCursorPosition(evt.target.selectionStart)
                    changePackingValue(`packing.cratings.${prIdIndex}.${evt.target.name}`, 'set', evt.target.value)
                  }
                  }
                  cursorPosition={cursorPosition}
                  valueType={'$'}
                  pattern="[0-9]*"
                />
                <CrateLabel
                  style={{ marginRight: "0" }}
                  inputId={`timeToExecuteCrate${prIdIndex}`}
                  name={`timeToExecuteCrate${prIdIndex}`}
                  title={'Time to execute for 2 movers'}
                  placeholder={'ex: 3'}
                  value={(crating) ? crating[`timeToExecuteCrate${prIdIndex}`] : ""}
                  isFocused={lastTarget === `timeToExecuteCrate${prIdIndex}`}
                  changeValue={(evt) => {
                    setLastTarget(evt.target.name);
                    setCursorPosition(evt.target.selectionStart)
                    changePackingValue(`packing.cratings.${prIdIndex}.${evt.target.name}`, 'set', evt.target.value)
                  }}
                  valueType={'hrs'}
                  pattern="[0-9]*"
                  cursorPosition={cursorPosition}
                />
                <button
                  className={styles.removeExtraBtnList}
                  onClick={
                    (evt) => {
                      evt.preventDefault();
                      removeCratingByIndex(prIdIndex);
                    }
                  }
                >
                  <Icon name={IconNames.MINUS} />
                </button>
              </div>
            )
          })}

        <div className={styles.buttonContainer} style={{ left: "10px", position: "relative" }}>
          <button
            className={styles.extraBtn}
            onClick={
              (evt) => {
                evt.preventDefault();
                addCrating(packing.cratings.length);
              }
            }
          >
            Add procedure
            <Icon name={IconNames.PLUS} />
          </button>
        </div>
      </div>
      <DepartmentTitle title={'Packing kits'} />
      <div className={styles.packingContainersec} onFocus={clearLastTarget}>
        <PackingLabel
          inputId={'firstBedroom'}
          name={'firstBedroom'}
          title={'1 Bedroom Packing kit - cu ft'}
          placeholder={'ex: 199'}
          value={packing.packingKits.firstBedroom}
          valueCuFt={packing.packingKits.firstCuFt}
          changeValue={(evt) => changePackingValue(`packing.packingKits.firstBedroom`, 'set', evt.target.value)}
          changeValueCuFt={(evt) => changePackingValue(`packing.packingKits.firstCuFt`, 'set', evt.target.value)}
          valueType={'$'}
        />
        <PackingLabel
          inputId={'secondBedroom'}
          name={'secondBedroom'}
          title={'2 Bedroom Packing kit - cu ft'}
          placeholder={'ex: 299'}
          value={packing.packingKits.secondBedroom}
          valueCuFt={packing.packingKits.secondCuFt}
          changeValue={(evt) => changePackingValue(`packing.packingKits.secondBedroom`, 'set', evt.target.value)}
          changeValueCuFt={(evt) => changePackingValue(`packing.packingKits.secondCuFt`, 'set', evt.target.value)}
          valueType={'$'}
        />
        <PackingLabel
          inputId={'thirdBedroom'}
          name={'thirdBedroom'}
          title={'3 Bedroom Packing kit - cu ft'}
          placeholder={'ex: 399'}
          value={packing.packingKits.thirdBedroom}
          valueCuFt={packing.packingKits.thirdCuFt}
          changeValue={(evt) => changePackingValue(`packing.packingKits.thirdBedroom`, 'set', evt.target.value)}
          changeValueCuFt={(evt) => changePackingValue(`packing.packingKits.thirdCuFt`, 'set', evt.target.value)}
          valueType={'$'}
        />
        <PackingLabel
          inputId={'fourthBedroom'}
          name={'fourthBedroom'}
          title={'4 Bedroom Packing kit - cu ft'}
          placeholder={'ex: 499'}
          value={packing.packingKits.fourthBedroom}
          valueCuFt={packing.packingKits.fourthCuFt}
          changeValue={(evt) => changePackingValue(`packing.packingKits.fourthBedroom`, 'set', evt.target.value)}
          changeValueCuFt={(evt) => changePackingValue(`packing.packingKits.fourthCuFt`, 'set', evt.target.value)}
          valueType={'$'}
        />
        <PackingLabel
          inputId={'fifthBedroom'}
          name={'fifthBedroom'}
          title={'5 Bedroom Packing kit - cu ft'}
          placeholder={'ex: 599'}
          value={packing.packingKits.fifthBedroom}
          valueCuFt={packing.packingKits.fifthCuFt}
          changeValue={(evt) => changePackingValue(`packing.packingKits.fifthBedroom`, 'set', evt.target.value)}
          changeValueCuFt={(evt) => changePackingValue(`packing.packingKits.fifthCuFt`, 'set', evt.target.value)}
          valueType={'$'}
        />
        <PackingLabel
          inputId={'sixthBedroom'}
          name={'sixthBedroom'}
          title={'6 Bedroom Packing kit - cu ft'}
          placeholder={'ex: 699'}
          value={packing.packingKits.sixthBedroom}
          valueCuFt={packing.packingKits.sixthCuFt}
          changeValue={(evt) => changePackingValue(`packing.packingKits.sixthBedroom`, 'set', evt.target.value)}
          changeValueCuFt={(evt) => changePackingValue(`packing.packingKits.sixthCuFt`, 'set', evt.target.value)}
          valueType={'$'}
        />
        <PackingLabel
          inputId={'seventhBedroom'}
          name={'seventhBedroom'}
          title={'7 Bedroom Packing kit - cu ft'}
          placeholder={'ex: 799'}
          value={packing.packingKits.seventhBedroom}
          valueCuFt={packing.packingKits.seventhCuFt}
          changeValue={(evt) => changePackingValue(`packing.packingKits.seventhBedroom`, 'set', evt.target.value)}
          changeValueCuFt={(evt) => changePackingValue(`packing.packingKits.seventhCuFt`, 'set', evt.target.value)}
          valueType={'$'}
        />
        <PackingLabel
          inputId={'eighthBedroom'}
          name={'eighthBedroom'}
          title={'8 Bedroom Packing kit - cu ft'}
          placeholder={'ex: 899'}
          value={packing.packingKits.eighthBedroom}
          valueCuFt={packing.packingKits.eighthCuFt}
          changeValue={(evt) => changePackingValue(`packing.packingKits.eighthBedroom`, 'set', evt.target.value)}
          changeValueCuFt={(evt) => changePackingValue(`packing.packingKits.eighthCuFt`, 'set', evt.target.value)}
          valueType={'$'}
        />
        <PackingLabel
          inputId={'ninthBedroom'}
          name={'ninethBedroom'}
          title={'9 Bedroom Packing kit - cu ft'}
          placeholder={'ex: 999'}
          value={packing.packingKits.ninethBedroom}
          valueCuFt={packing.packingKits.ninethCuFt}
          changeValue={(evt) => changePackingValue(`packing.packingKits.ninethBedroom`, 'set', evt.target.value)}
          changeValueCuFt={(evt) => changePackingValue(`packing.packingKits.ninethCuFt`, 'set', evt.target.value)}
          valueType={'$'}
        />
        <PackingLabel
          inputId={'tenthBedroom'}
          name={'tenthBedroom'}
          title={'10 Bedroom Packing kit - cu ft'}
          placeholder={'ex: 1099'}
          value={packing.packingKits.tenthBedroom}
          valueCuFt={packing.packingKits.tenthCuFt}
          changeValue={(evt) => changePackingValue(`packing.packingKits.tenthBedroom`, 'set', evt.target.value)}
          changeValueCuFt={(evt) => changePackingValue(`packing.packingKits.tenthCuFt`, 'set', evt.target.value)}
          valueType={'$'}
        />
        <PackingLabel
          inputId={'kitchen'}
          name={'kitchen'}
          title={'Kitchen Packing kit - cu ft'}
          placeholder={'ex: 120'}
          value={packing.packingKits.kitchen}
          valueCuFt={packing.packingKits.kitchenCuFt}
          changeValue={(evt) => changePackingValue(`packing.packingKits.kitchen`, 'set', evt.target.value)}
          changeValueCuFt={(evt) => changePackingValue(`packing.packingKits.kitchenCuFt`, 'set', evt.target.value)}
          valueType={'$'}
        />
        <div className={styles.fixKitchen}></div>
      </div>
      <div className={styles.packingWrapper}
        style={{ paddingLeft: "20px" }}
      >
        <DepartmentLabelsec
          style={{ marginRight: "50px" }}
          inputId={'salesTax'}
          name={'salesTax'}
          title={'Sales Tax on Packing'}
          placeholder={'ex: 9'}
          value={packing.packingKits.salesTax}
          changeValue={(evt) => changePackingValue(`packing.packingKits.salesTax`, 'set', evt.target.value)}
          valueType={'%'}
          inputType='number'
        />
      </div>
      <DepartmentTitle
        title={'Packing Supplies'}
      />
      <div className={styles.packingContainersecond} style={{ marginBottom: "15px" }} onFocus={clearLastTarget}>
        <PacSupLabel

          inputId={'smallBox'}
          name={'smallBox'}
          title={'Small Box'}
          placeholder={'ex: 3'}
          value={packing.supplies.smallBox}
          changeValue={(evt) => changePackingValue(`packing.supplies.smallBox`, 'set', evt.target.value)}
          valueType={'$'}
          inputType='number'
        />
        <PacSupLabel
          inputId={'mediumBox'}
          name={'mediumBox'}
          title={'Medium Box'}
          placeholder={'ex: 4'}
          value={packing.supplies.mediumBox}
          changeValue={(evt) => changePackingValue(`packing.supplies.mediumBox`, 'set', evt.target.value)}
          valueType={'$'}
          inputType='number'
        />
        <PacSupLabel
          inputId={'largeBox'}
          name={'largeBox'}
          title={'Large Box'}
          placeholder={'ex: 6'}
          value={packing.supplies.largeBox}
          changeValue={(evt) => changePackingValue(`packing.supplies.largeBox`, 'set', evt.target.value)}
          valueType={'$'}
          inputType='number'
        />
      </div>
      <div className={styles.packingContainersecond} onFocus={clearLastTarget}>
        <PacSuppLabel
          className={styles.inputcontanr}
          inputId={'bubbleWrap'}
          name={'bubbleWrap'}
          title={'Roll of Bubble wrap'}
          placeholder={'ex: 20'}
          value={packing.supplies.bubbleWrap}
          changeValue={(evt) => changePackingValue(`packing.supplies.bubbleWrap`, 'set', evt.target.value)}
          valueType={'$'}
          inputType='number'
        />
        <PacSuppLabel
          inputId={'packingPapper'}
          name={'packingPapper'}
          title={'Roll of Packing papper'}
          placeholder={'ex: 20'}
          value={packing.supplies.packingPapper}
          changeValue={(evt) => changePackingValue(`packing.supplies.packingPapper`, 'set', evt.target.value)}
          valueType={'$'}
          inputType='number'
        />
      </div>



    </section >
  );
}

Packing.propTypes = {
  packing: PropTypes.object.isRequired,
  changePackingValue: PropTypes.func.isRequired,
  lastTarget: PropTypes.string,
  setLastTarget: PropTypes.func.isRequired,
  clearLastTarget: PropTypes.func.isRequired,
}

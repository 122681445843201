// Components
import { Table } from '../table/Table';
import { LongDistanceTableBody } from '../longdistance-table-body/LongDistance-table-body';
import { TableHeader } from '../table-header/Table-header';

export const LongDistanceList = ({ longdistances, filters, setFilters }) => {
  const headerTitles = {
    directionName: 'LongDistance Name',
    authorId: 'Author',
    thirdColumn: "",
    createdAt: 'Created Date',
    updatedAt: 'Updated Date',
  };

  return (
    <Table>
      <TableHeader headerTitles={headerTitles} filters={filters} setFilters={setFilters} />
      <LongDistanceTableBody tableData={longdistances} />
    </Table>
  );
};
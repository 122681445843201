import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const saveTemplate = createAsyncThunk(
  'template/save',
  async (newTemplateData) => await fetchedData('template/template', 'POST', { ...newTemplateData })
);

export const updateTemplate = createAsyncThunk(
  'template/update',
  async (updateTemplate) => await fetchedData('template/update', 'POST', updateTemplate)
);

export const loadTemplate = createAsyncThunk(
  'template/getTemplate',
  async (id) => await fetchedData('template/getTemplate', 'POST', { id })
);

export const loadTemplateByType = createAsyncThunk(
  'template/getTemplateByType',
  async (data) => await fetchedData('template/getTemplateByType', 'POST', data )
);

export const removeTemplate = createAsyncThunk(
  'template/remove',
  async (id) => await fetchedData('template/remove', 'POST', { id })
);

// Third party libraries
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom';
// Components
import { CompanyEdit } from '../Components/company-edit/Company-edit';
import { CompanyForm } from '../Components/company-form/Company-form';
import { CompaniesMain } from '../Components/companies-main/Companies-main';
import { Footer } from '../Components/footer/Footer';
import { Header } from '../Components/header/Header';
import { PageHeader } from '../Components/page-header/Page-header';
import { PageMenu } from '../Components/page-menu/Page-menu';
// Functions for work with state
import { getUserRole, checkAuth } from '../clientStore/authSlice/auth-slice';
//Utils functions
import { IconNames } from '../util/const';
import { PrivateRoute } from '../util/private-route';
import { getSectionNames } from '../util/utils';
import { RoleRoute } from '../util/role-route';


export const Companies = ({ userName }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const userRole = useSelector(getUserRole);
  const SectionNames = getSectionNames(userRole);

  const settingsHeaderBtn = [
   /*  {
      name: 'Change password'
    } */
  ];

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, [dispatch]);

  return (
    <div className='container'>
      <Header
        isLoginBtnShow={false}
      />
      <main className='main'>
        <PageHeader
          titleIcon={IconNames.USER}
          title={userName}
          menuItems={SectionNames}
        />

        <Switch>
          <RoleRoute exact path={path}>
            <CompaniesMain />
          </RoleRoute>
          <RoleRoute path={`${path}/add`}>
            <CompanyForm formType={'new'} />
          </RoleRoute>
          <RoleRoute path={`${path}/:companyId`}>
            <CompanyEdit />
          </RoleRoute>
        </Switch>
      </main>
      {/* <Footer /> */}
    </div>
  );
};
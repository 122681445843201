// Components
import { Table } from '../table/Table';
import { TemplateTableBody } from '../template-table-body/Template-table-body';
import { TableHeader } from '../table-header/Table-header';

export const TemplateList = ({ templates, filters, setFilters }) => {
  const headerTitles = {
    templateName: 'Template Name',
    authorId: 'Author',
    createdAt: 'Created Date',
    updatedAt: 'Updated Date',
  };

  return (
    <Table>
      <TableHeader headerTitles={headerTitles} filters={filters} setFilters={setFilters} />
      <TemplateTableBody tableData={templates} />
    </Table>
  );
};
import { createSlice } from '@reduxjs/toolkit';
import {
  loadTemplate,
  loadTemplateByType,
  saveTemplate,
  updateTemplate,
  removeTemplate,
} from './template-async-thunk';


const initialState = {
  savingStatus: 'idle',
  loadingStatus: 'idle',
  template: {},
  countOfTemplates: 0,
  errors: null,
};

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setTemplateSavingStatus(state, action) {
      const { status } = action.payload;
      state.savingStatus = status;
    },
    setTemplateLoadingStatus(state, action) {
      const { status } = action.payload;
      state.loadingStatus = status;
    },
    setTemplateRemovingStatus(state, action) {
      const { status } = action.payload;
      state.removingStatus = status;
    },
  },
  extraReducers: {
    // saveTemplate extra reducer
    [saveTemplate.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [saveTemplate.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [saveTemplate.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.template = action.payload.data;
    },

    // updateDepartmen extra reducer
    [updateTemplate.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [updateTemplate.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [updateTemplate.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.template = action.payload.data;
    },

    // loadTemplate extra reducer
    [loadTemplate.pending]: (state, action) => {
      state.loadingStatus = 'loading';
    },
    [loadTemplate.rejected]: (state, action) => {
      state.loadingStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadTemplate.fulfilled]: (state, action) => {
      state.loadingStatus = 'successed';
      state.template = action.payload.data;
    },

    // loadTemplatebyType extra reducer
    [loadTemplateByType.pending]: (state, action) => {
      state.loadingStatus = 'loading';
    },
    [loadTemplateByType.rejected]: (state, action) => {
      state.loadingStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadTemplateByType.fulfilled]: (state, action) => {
      state.loadingStatus = 'successed';
      state.template = action.payload.data;
    },

    //removeTemplate extra reducer
    [removeTemplate.pending]: (state, action) => {
      state.removingStatus = 'loading';
    },
    [removeTemplate.rejected]: (state, action) => {
      state.removingStatus = 'failed';
      state.errors = action.error.message;
    },
    [removeTemplate.fulfilled]: (state, action) => {
      state.removingStatus = 'successed';
      state.template = action.payload.data;
    },
  },
});

export const { setTemplateSavingStatus, setTemplateLoadingStatus, setTemplateRemovingStatus } = templateSlice.actions;

export default templateSlice.reducer;

export const getTemplateSavingStatus = (state) => state.template.savingStatus;

export const getTemplateLoadingStatus = (state) => state.template.loadingStatus;

export const getTemplateRemovingStatus = (state) => state.template.removingStatus;

export const getTemplate = (state) => state.template.template;

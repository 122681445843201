// Third party libraries
import PropTypes from 'prop-types';
// Components
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentTitle } from '../department-title/Department-title';
import { DepartmentRadiobuttons } from '../department-radiobuttons/Department-radiobuttons';
// Styles
import styles from './ChargePerMile.module.css';

export const ChargePerMile = (props) => {
  const {
    chargePerMile = { apply: false },
    changeChargePerMileValue,
    clearLastTarget,
  } = props;
  const onIsActiveChange = (evt) => {
    //setRatesValue({ target: { value: evt.target.checked, name: evt.target.name } }, index)

  }
  return (
    <section
      onFocus={clearLastTarget}
    >

      <div style={{ display: 'flex' }}> <DepartmentTitle title={'Charge per mile'} />
        <input type='checkbox'
          checked={chargePerMile.isActiveChargePerMile}
          name='isActiveChargePerMile'
          onChange={(evt) => changeChargePerMileValue(`doubleDrive.isActiveChargePerMile`, 'set', evt.target.checked)}
          defaultValue={false}
        />
      </div>
      <div className={styles.chargesPerMileContainer} style={{ display: 'block' }}>
        <div style={{ marginBottom: '10px' }}>
          Charge per mile(Moving Miles)
          <input
            id='ddmiles'
            className={styles.chargesPerMileInput}
            type='number'
            onWheel={(e) => e.target.blur()}
            placeholder='ex. 2'
            name='chargePerMile'
            value={chargePerMile.chargePerMile}
            onChange={(evt) => changeChargePerMileValue(`doubleDrive.chargePerMile`, 'set', evt.target.value)}
            disabled={!chargePerMile.isActiveChargePerMile}
          />
        </div>
        <div style={{ display: 'block' }}>
          Charge per mile(Service Miles)
          <input
            id='sdmiles'
            className={styles.chargesPerMileInput}
            type='number'
            onWheel={(e) => e.target.blur()}
            placeholder='ex. 2'
            name='serviceMiles'
            value={chargePerMile.serviceMiles}
            onChange={(evt) => changeChargePerMileValue(`doubleDrive.serviceMiles`, 'set', evt.target.value)}
            disabled={!chargePerMile.isActiveChargePerMile}
          />
        </div>
      </div>

    </section>
  );
};

ChargePerMile.propTypes = {
  doubleDrive: PropTypes.object.isRequired,
  changeChargePerMileValue: PropTypes.func.isRequired,
  clearLastTarget: PropTypes.func.isRequired,
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const loadCompanies = createAsyncThunk(
  'companyList/getCompanies',
  async (params) => await fetchedData('company/companies', 'POST', { params })
);

export const getCountOfCompanies = createAsyncThunk(
  'companyList/getCount',
  async () => await fetchedData('company/count', 'GET')
);

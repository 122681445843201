//Third party libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Components
import { DepartmentForm } from '../department-form/Department-form';
import { Loading } from '../loading/Loading';
// Functions for work with store
import {
  getDepartment,
  getDepartmentLoadingStatus,
} from '../../clientStore/departmentSlice/department-slice';
import { loadDepartment } from '../../clientStore/departmentSlice/department-async-thunk';
import { CuFtDepartmentForm } from '../cuft-department-form/CuFt-department-form';

export const DepartmentEdit = () => {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(getDepartmentLoadingStatus);
  const department = JSON.parse(JSON.stringify(useSelector(getDepartment)));
  const params = useParams();

  const id = params.departmentId;

  useEffect(() => {
    if (loadingStatus === 'idle') {
      dispatch(loadDepartment(id));
    }
  }, [dispatch, id, loadingStatus]);
  /* <DepartmentForm departmentData={ department } formType={'edit'} departmentId={id} /> */
  return (
    <React.Fragment>
      {loadingStatus !== 'successed' && <Loading />}
      {loadingStatus === 'successed' && (department.type === 'cuft' ? (
        <CuFtDepartmentForm departmentData={department} formType={'edit'} departmentId={id} />
      ) : (
        <DepartmentForm departmentData={department} formType={'edit'} departmentId={id} />
      ))}
    </React.Fragment>
  );
}
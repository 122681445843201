import { createSlice } from '@reduxjs/toolkit';
import { loadLongDistances, getCountOfLongDistances } from './longdistances-async-thunk';

const initialState = {
  status: 'idle',
  longdistances: [],
  count: 0,
  errors: null,
};

const longdistanceListSlice = createSlice({
  name: 'longdistanceList',
  initialState,
  reducers: {
    setLongDistancesLoadingStatus (state, action) {
      const { status } = action.payload;
      state.status = status;
    },
  },
  extraReducers: {
    // getLongDistances extra reducer
    [loadLongDistances.pending]: (state, action) => {
      state.status = 'loading';
    },
    [loadLongDistances.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [loadLongDistances.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.longdistances = action.payload.data;
    },

    // getCountOfLongDistances extra reducer
    [getCountOfLongDistances.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getCountOfLongDistances.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [getCountOfLongDistances.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.count = action.payload.data;
    },
  },
});

export const { setLongDistancesLoadingStatus } = longdistanceListSlice.actions;

export default longdistanceListSlice.reducer;

export const getProcessLongDistancesLoading = (state) => state.longdistanceList.status;
export const getLongDistances = (state) => state.longdistanceList.longdistances;
export const getLongDistancesCount = (state) => state.longdistanceList.count;

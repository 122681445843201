import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const saveAction = createAsyncThunk(
  'department/save',
  async (newActionData) => await fetchedData('department/department', 'POST', { ...newActionData })
);

export const updateAction = createAsyncThunk(
  'department/update',
  async (updateAction) => await fetchedData('department/update', 'POST', updateAction)
);

export const loadAction = createAsyncThunk(
  'department/getAction',
  async (id) => await fetchedData('department/getAction', 'POST', { id })
);

export const removeAction = createAsyncThunk(
  'department/remove',
  async (id) => await fetchedData('department/remove', 'POST', { id })
);

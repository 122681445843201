export const calculationDataModel = {
    calculationNumber: '',
    isDeleted: false,
    hasSubCalcs: false,
    isSubCalc: false,
    mainCalcId: '',
    calculationType: 'oneDayPacking',
    department: undefined,
    unpDepartment: undefined,
    outOfState: undefined,
    longDistance: undefined,
    departmentId: undefined,
    unpDepartmentId: undefined,
    outOfStateId: undefined,
    longDistanceId: undefined,
    calculationNote: '',
    selectedMiscCheckMarks: [],
    template: undefined,
    commonValues: {
        packing: 'No',
        baseLoadingHours: 0,
        baseUnloadingHours: 0,
        addTimeFirstDay: 0,
        addTimeSecondDay: 0,
        addTimeThirdDay: 0,
        addTimeUnp: 0,
        removeTimeFirstDay: 0,
        removeTimeSecondDay: 0,
        removeTimeThirdDay: 0,
        removeTimeUnp: 0,
        cubicFt: '',
        miles: '',
        roundTripMiles: '',
        driveTime: '',
        smallBoxes: '',
        mediumBoxes: '',
        fragileBoxes: '',
        shuttle: false,
        liftGate: false,
        hardFloorCovers: false,
        filmFloorCovers: false,
        numOfExtraHeavyItem: 0,
        packingKitOverride: '',
        cratesCosts: [],
        unpacking: 'No', // 'Same Day', 'Separate Day'
        extraStops: [],
        procedures: [],
        moversToUnPackSeparate: 2,
        roundTripServiceMiles: 0,
        roundTripServiceMilesUnp: 0,
        shuttleOnUnpacking: false,
        quantityUnpacking: 1,
        unpackingRate: '',
        isNoPackingConfirmed: false,
        selectedAdditionalServices: [],
        additionalServicesPlaceType: '',
        partialUnPacking: false,
        fragileBoxesPartialUnpacking: 0,
        smallBoxesPartialUnpacking: 0,
        mediumBoxesPartialUnpacking: 0,
    },
    oneDayPacking: {
        rate: '',
        moversToLoad: 0,
        addMovers: 0,
        addTrucks: 0,
        removeMovers: 0,
        moversToDrivesAndUnload: 0,
        removeTrucks: 0,
        noTwoTrips: false,
        selectedChargesNamesDay1: [],
        truckFeeSeparate: 0,
        additionalAmount: 0,
        firstDayDate: null,
        quantityDay1: 1,
        twoTripsManuallyInserted: false,
    },
    twoDaysPacking: {
        firstDayRate: '',
        secondDayRate: '',
        cubicFtToMoveDay1: 0,
        moversToLoad: 0,
        moversToPack: 2,
        addMovers: 0,
        addTrucksDay1: 0,
        addTrucksDay2: 0,
        removeMovers: 0,
        moversToDrivesAndUnload: 0,
        removeTrucks: 0,
        noTwoTrips: false,
        shuttleOnDayOne: false,
        quantityDay1: 1,
        quantityDay2: 1,
        proceduresOnDay1: false,
        proceduresOnDay2: true,
        selectedChargesNamesDay1: [],
        selectedChargesNamesDay2: [],
        truckFee: 0,
        truckFeeSeparate: 0,
        additionalAmount: 0,
        firstDayDate: null,
        secondDayDate: null,
        twoTripsManuallyInserted: false,
    },
    twoDays150Miles: {
        firstDayRate: '',
        secondDayRate: '',
        shuttleOnDay1: false,
        shuttleOnDay2: false,
        moversDay1: 2,
        moversDay2: 2,
        milesDay1: 0,
        milesDay2: 0,
        driveTimeDay1: 0,
        driveTimeDay2: 0,
        selectedChargesNamesDay1: [],
        selectedChargesNamesDay2: [],
        truckFeeSeparate: 0,
        additionalAmount: 0,
        firstDayDate: null,
        secondDayDate: null,
        proceduresOnDay1: true,
        proceduresOnDay2: false,
        addTrucksDay1: 0,
        addTrucksDay2: 0,
        removeTrucksDay1: 0,
        removeTrucksDay1: 0,
        quantityDay1: 1,
        quantityDay2: 1,
    },
    longOneDayPacking: {
        firstDayRate: '',
        shuttleOnDay1: false,
        shuttleOnDay2: false,
        addMoversDay1: 0,
        addMoversDay2: 0,
        removeMoversDay1: 0,
        removeMoversDay2: 0,
        drivingTimeAdjustment: '',
        selectedChargesNamesDay1: [],
        selectedChargesNamesDay2: [],
        numberOfRoomsDay1: 1,
        numberOfRoomsToPack: 1,
        adjustRoomsToPack: false,
        truckFeeSeparate: 0,
        additionalAmount: 0,
        firstDayDate: null,
        secondDayDate: null,
        quantityDay1: 1,
        quantityDay2: 1,
    },
    longTwoDaysPacking: {
        firstDayRate: '',
        secondDayRate: '',
        drivingTimeAdjustment: '',
        shuttleOnDay1: false,
        shuttleOnDay2: false,
        shuttleOnDay3: false,
        proceduresOnDay1: false,
        proceduresOnDay2: true,
        moversToPack: 2,
        selectedChargesNamesDay1: [],
        selectedChargesNamesDay2: [],
        selectedChargesNamesDay3: [],
        addMoversDay2: 0,
        addMoversDay3: 0,
        removeMoversDay2: 0,
        removeMoversDay3: 0,
        truckFee: 0,
        numberOfRoomsDay2: 1,
        numberOfRoomsToPack: 1,
        adjustRoomsToPack: false,
        truckFeeSeparate: 0,
        additionalAmount: 0,
        firstDayDate: null,
        secondDayDate: null,
        thirdDayDate: null,
        addTrucks: 0,
        quantityDay1: 1,
        quantityDay2: 1,
        quantityDay3: 1,
    },
    oos1day: {
        factor: '',
        firstDayRate: '',
        selectedChargesNamesDay1: [],
        selectedChargesNamesDay2: [],
        shuttleOnDay1: false,
        shuttleOnDay2: false,
        addMoversDay1: 0,
        addMoversDay2: 0,
        removeMoversDay1: 0,
        removeMoversDay2: 0,
        truckFeeSeparate: 0,
        additionalAmount: 0,
        firstDayDate: null,
        secondDayDate: null,
        quantityDay1: 1,
        quantityDay2: 1,
    },
    oos2days: {
        factor: '',
        firstDayRate: '',
        secondDayRate: '',
        shuttleOnDay1: false,
        shuttleOnDay2: false,
        shuttleOnDay3: false,
        proceduresOnDay1: false,
        proceduresOnDay2: true,
        moversToPack: 2,
        selectedChargesNamesDay1: [],
        selectedChargesNamesDay2: [],
        selectedChargesNamesDay3: [],
        addMoversDay2: 0,
        addMoversDay3: 0,
        removeMoversDay2: 0,
        removeMoversDay3: 0,
        truckFee: 0,
        numberOfRoomsDay2: 1,
        truckFeeSeparate: 0,
        additionalAmount: 0,
        firstDayDate: null,
        secondDayDate: null,
        thirdDayDate: null,
        addTrucks: 0,
        quantityDay1: 1,
        quantityDay2: 1,
        quantityDay3: 1,
    },
    inventory: {
        rooms: [
            /* {
                roomName: '',
                furnitureGoes: [{
                    name: '',
                    cuft: 0,
                    qty: 1,
                    stopIndex: 0
                }],
                furnitureStays: [],
                boxes: [{ fragile: 0, small: 0, medium: 0, WB: 0 }],//extraStops.length
            } */
        ],
        extraStops: [
            {
                name: '',
                color: '',
            }
        ],
        cuFtOption: { include: false, remove: false },
    },
    customTagsValues: {
        oneDayPacking: {},/* {key: customValue} */
        twoDaysPacking: {},
        twoDays150Miles: {},
        longOneDayPacking: {},
        longTwoDaysPacking: {},
        oos1day: {},
        oos2days: {},
    }
}

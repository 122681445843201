import { createSlice } from '@reduxjs/toolkit';
import {
 
  loadUnpDepartment,
 
} from './unp-department-async-thunk';


const initialState = {
  unpLoadingStatus: 'idle',
  unpDepartment: {},
  errors: null,
};

const unpDepartmentSlice = createSlice({
  name: 'unpDepartment',
  initialState,
  reducers: {
    setUnpDepartmentLoadingStatus(state, action) {
      const { status } = action.payload;
      state.unpLoadingStatus = status;
    },
  },
  extraReducers: {
 

    // loadDepartment extra reducer
    [loadUnpDepartment.pending]: (state, action) => {
      state.unpLoadingStatus = 'loading';
    },
    [loadUnpDepartment.rejected]: (state, action) => {
      state.unpLoadingStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadUnpDepartment.fulfilled]: (state, action) => {
      state.unpLoadingStatus = 'successed';
      state.unpDepartment = action.payload.data;
    },

  },
});

export const { setUnpDepartmentLoadingStatus } = unpDepartmentSlice.actions;

export default unpDepartmentSlice.reducer;

export const getUnpDepartmentLoadingStatus = (state) => state.unpDepartment.unpLoadingStatus;

export const getUnpDepartment = (state) => state.unpDepartment.unpDepartment;

import React, { useEffect } from 'react';
import socketio from 'socket.io-client';
import { SOCKET_URL } from '../util/const';

export const SocketContext = React.createContext();
let socket;

const configureSocket = () => {
  socket = socketio.connect(SOCKET_URL, {
    reconnection: true,            // Enable reconnection
    reconnectionAttempts: 5,      // Number of reconnection attempts
    reconnectionDelay: 1000,       // Delay between reconnection attempts in milliseconds
  });

  socket.on('connect', () => {
    console.log('Connected to the server');
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from the server');
  });

  socket.on('reconnect', (attemptNumber) => {
    console.log(`Reconnected to the server (attempt ${attemptNumber})`);
  });

  // Add other event listeners and custom socket.io logic here

  return socket;
};

export const getSocket = () => {
  if (!socket) {
    configureSocket();
  }
  return socket;
};

export const disconnectSocket = () => {
    if (socket && socket.connected) {
      socket.disconnect();
      console.log('Manually disconnected from the server');
    }
  };
  
  export const connectSocket = () => {
    if (socket && !socket.connected) {
      socket.connect();
      console.log('Manually reconnected to the server');
    }
  };

export const SocketProvider = ({ children }) => {
  useEffect(() => {
    configureSocket();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
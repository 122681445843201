// Third party libraries
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components
import { Loading } from '../Components/loading/Loading';
// Functions for work with local state
import {
  getAuthenticatedStatus,
  getProcessAuthStatus,
  getUserRole,
} from "../clientStore/authSlice/auth-slice";
// Utils
import { Role } from '../util/const';

export const RoleRoute = ({ children, ...rest }) => {
  const isAuth = useSelector(getAuthenticatedStatus);
  const authStatus = useSelector(getProcessAuthStatus);
  const role = useSelector(getUserRole);

  if (isAuth) {
    switch (role) {
      case Role.SUPERUSER:
        return (
          <Route
            {...rest}
            render={() => children}
          />
        )
        break
      case Role.ADMIN:
        return (
          <Route
            {...rest}
            render={({ location }) => {
              return (location.pathname !== "/companies") ? children
                :
                <Redirect
                  to={{
                    pathname: '/calculations',
                    state: { from: location }
                  }}
                />
            }
            }
          />
        )
        break

      case Role.COMPANYUSER:
        return (
          <Route
            {...rest}
            render={({ location }) => {
              return (location.pathname !== "/companies") ? children
                :
                <Redirect
                  to={{
                    pathname: '/calculations',
                    state: { from: location }
                  }}
                />
            }
            }
          />
        )
        break

      default:
        <Route
          {...rest}
          render={({ location }) => {
            return (
              <Redirect
                to={{
                  pathname: '/calculations',
                  state: { from: location }
                }}
              />)
          }}
        />
        break;
    }
  }
  else if (!isAuth || authStatus === 'failed') {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        }
      />
    )
  }
  else {
    return (
      <Route
        {...rest}
        render={() => <Loading />}
      />
    )
  }
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return (
          <Redirect
            to={{
              pathname: '/calculations',
              state: { from: location }
            }}
          />)
      }}
    />
  )
}
import { createSlice } from '@reduxjs/toolkit';
import {
  loadAction,
  saveAction,
  updateAction,
  removeAction,
} from './action-async-thunk';


const initialState = {
  savingStatus: 'idle',
  loadingStatus: 'idle',
  action: {},
  countOfActions: 0,
  errors: null,
};

const actionSlice = createSlice({
  name: 'action',
  initialState,
  reducers: {
    setActionSavingStatus(state, action) {
      const { status } = action.payload;
      state.savingStatus = status;
    },
    setActionLoadingStatus(state, action) {
      const { status } = action.payload;
      state.loadingStatus = status;
    },
    setActionRemovingStatus(state, action) {
      const { status } = action.payload;
      state.removingStatus = status;
    },
  },
  extraReducers: {
    // saveAction extra reducer
    [saveAction.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [saveAction.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [saveAction.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.action = action.payload.data;
    },

    // updateDepartmen extra reducer
    [updateAction.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [updateAction.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [updateAction.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.action = action.payload.data;
    },

    // loadAction extra reducer
    [loadAction.pending]: (state, action) => {
      state.loadingStatus = 'loading';
    },
    [loadAction.rejected]: (state, action) => {
      state.loadingStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadAction.fulfilled]: (state, action) => {
      state.loadingStatus = 'successed';
      state.action = action.payload.data;
    },

    //removeAction extra reducer
    [removeAction.pending]: (state, action) => {
      state.removingStatus = 'loading';
    },
    [removeAction.rejected]: (state, action) => {
      state.removingStatus = 'failed';
      state.errors = action.error.message;
    },
    [removeAction.fulfilled]: (state, action) => {
      state.removingStatus = 'successed';
      state.action = action.payload.data;
    },
  },
});

export const { setActionSavingStatus, setActionLoadingStatus, setActionRemovingStatus } = actionSlice.actions;

export default actionSlice.reducer;

export const getActionSavingStatus = (state) => state.action.savingStatus;

export const getActionLoadingStatus = (state) => state.action.loadingStatus;

export const getActionRemovingStatus = (state) => state.action.removingStatus;

export const getAction = (state) => state.action.action;

// Components
import { Table } from '../table/Table';
import { OutOfStateTableBody } from '../outofstate-table-body/OutOfState-table-body';
import { TableHeader } from '../table-header/Table-header';

export const OutOfStateList = ({ outofstates, filters, setFilters }) => {
  const headerTitles = {
    directionName: 'OutOfState Name',
    authorId: 'Author',
    thirdColumn: "",
    createdAd: 'Created Date',
    updatedAt: 'Updated Date',
  };

  return (
    <Table>
      <TableHeader headerTitles={headerTitles} filters={filters} setFilters={setFilters} />
      <OutOfStateTableBody tableData={outofstates} />
    </Table>
  );
};
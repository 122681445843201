// Third party libraries
import { TemplateTagItem } from '../template-tag-item/Template-tag-item';
import { useState, useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';
import templateCalcIcon from '../../img/calc.png';
import * as immutable from 'object-path-immutable';
// Components
import { Icon } from '../Icon';
// Utils
import { IconNames, Role } from '../../util/const';
import { fetchedData, hasRole, replaceTagsByValues } from '../../util/utils';

// Styles
import styles from './TemplateTagsColumn.module.css';
import { removeByIndex } from '../../util/utils';
import { CalculationCommonValues } from '../calculation-common-values/Calculation-common-values';
import { convertFromRaw, EditorState, convertToRaw, Modifier, SelectionState } from 'draft-js';
import TagRemovePopup from '../tag-remove-popup/TagRemove-popup';
import { Loading } from '../loading/Loading';
import { getUserRole } from '../../clientStore/authSlice/auth-slice';
import { useSelector } from 'react-redux';

export const TemplateTagsColumn = ({ changeTag, setHighLiteItem, templateForm,
  company, users, department, departments, setTemplateFormValue, setUpdateTempState,
  tagsResultsTexts, saveBtnEvent, setRemoveTag, calculationPopUpShown, setCalculationPopUpShown,
  setRates, resetLastTarget, lastTarget, setLastTarget, onCalcFormValueChange, options }) => {
  const template = templateForm;
  const tags = template.tags;
  const emptyRef = useRef(null);
  const clonnedRef = useRef(null);
  const [clonnedName, setClonnedName] = useState('!clo!');
  const calculationForm = templateForm.templateCalcObj;
  const templateDepType = template.type;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [calculationNames, setCalculationNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tagIndexToRemove, setTagIndexToRemove] = useState(null);
  const priceOptionAllowedItems = ['aSIdAmount', 'heavyFee',];
  //condition[`selectedItemObj${conditionIndex}`].value.split('_')[0] === 'aSIdAmount'

  const [cursorPosition, setCursorPosition] = useState();


  const renderProceduresOptions = () => {
    try {
      let proceduresOptions = [];
      if (calculationForm.department) {
        proceduresOptions = calculationForm.department.packing.cratings.map((crating, index) => {
          return <option key={nanoid(6)}>{crating[`crateName${index}`]}</option>
        })
      }
      return proceduresOptions;
    } catch (err) {
      console.log(err)
    }
  }



  const clearCursorPosition = () => {
    if (cursorPosition) {
      setCursorPosition(undefined);
    }
  }

  const addTag = (index) => {
    /*     window.scrollTo({ top: 0, behavior: 'smooth' }); */

    const newTag = {
      [`tagName`]: '',
      [`elseText`]: '',
      [`elseTextType`]: 'input', //input, result, none, tag
      [`elseSelectedTag`]: 'none',
      [`conditions`]: [
        {
          operator0: '=',
          selectedItemObj0: { value: '', label: '' },
          selectedItemType0: '',
          selectOptions0: [],
          resultingText0: '',
          conditionAmountValue0: '',
          conditionSelectValue0: '',
          conditionValueType0: 'input',//input, none
          resultTextType0: 'input', //input, result, none, tag
          selectedTag0: 'none',
        }]
    }
    changeTag(`tags`, 'push', newTag);
  }

  const cloneTag = (evt, tagIndex) => {
    evt.preventDefault();
    const cloneableTagName = tags[tagIndex].tagName;
    let clonnedTagName = cloneableTagName;
    let candidates = [];
    tags.forEach(tag => {
      if (tag.tagName.includes(cloneableTagName)) {
        candidates.push(tag.tagName);
      }
    });

    if (candidates && candidates.length > 0) {
      let maxCloneNumber = 0;
      let copyAvailable = false
      candidates.forEach(tagName => {
        if (tagName.includes(cloneableTagName) && ((tagName.length - cloneableTagName.length) === 4)) {
          try {
            if (parseInt(tagName[tagName.length - 2]) > maxCloneNumber) {
              maxCloneNumber = parseInt(tagName[tagName.length - 2])
              //ete 10+ kam 10-ic avel voroshel maxCloneNumber-i simvolneri qanak@ dran gumarel 2 u nerqevi 3@ poxarinel ed tiv+2 ov
              clonnedTagName = tagName.slice(0, tagName.length - 3) + '_' + (maxCloneNumber + 1) + '_';
              copyAvailable = true;
            }
          }
          catch (err) {
            return err
          }
        }
        else {
          if (!copyAvailable) {
            clonnedTagName = cloneableTagName + ' _1_';
          }
        }
      });
    }
    else {
      clonnedTagName = cloneableTagName + ' _1_';
    }
    let newTag = { ...tags[tagIndex] }
    newTag.tagName = clonnedTagName;
    setClonnedName(clonnedTagName)
    let newTags = [...tags];
    newTags.push(newTag);
    newTags = newTags.sort((a, b) => a.tagName.localeCompare(b.tagName));
    changeTag('tags', 'set', newTags);
    // changeTag(`tags`, 'push', newTag);
  }


  const renameTag = (tagIndex, localTagName, previousTagName) => {
    try {
      let textToInsert = '|{' + localTagName + '}';
      let removableStr = '|{' + previousTagName + '}';
      let newTemplateform = JSON.parse(JSON.stringify(template));

      // Create an EditorState from the existing content
      const editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(template.templateEditorState))
      );

      // Get the current ContentState
      const contentState = editorState.getCurrentContent();

      let newContentState = contentState;
      let contentModified = false;

      contentState.getBlockMap().forEach((block) => {
        const text = block.getText();
        let startIndex = text.indexOf(removableStr);
        let offset = 0;

        while (startIndex !== -1) {
          const endIndex = startIndex + removableStr.length;
          const blockKey = block.getKey();
          const selection = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: startIndex - offset,
            focusOffset: endIndex - offset,
          });

          // Remove the text
          newContentState = Modifier.removeRange(newContentState, selection, 'backward');
          contentModified = true;

          // Insert text at the same position, accounting for replacement
          const insertionSelection = selection.merge({
            anchorOffset: startIndex - offset,
            focusOffset: startIndex - offset,
          });

          newContentState = Modifier.insertText(newContentState, insertionSelection, textToInsert);

          // Update the offset to account for replacement
          offset += (removableStr.length - textToInsert.length);
          startIndex = text.indexOf(removableStr, endIndex);
        }
      });

      if (contentModified) {
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          'remove-range'
        );
        newTemplateform.templateEditorState = JSON.stringify(
          convertToRaw(newEditorState.getCurrentContent())
        );
      }

      newTemplateform.tags.forEach((tag) => {
        if (tag.tagName === previousTagName) {
          tag.tagName = localTagName;
        }
        tag.conditions.forEach((condition, conditionIndex) => {
          if (condition[`selectedTag${conditionIndex}`] !== '' && condition[`selectedTag${conditionIndex}`] === `\|{${previousTagName}}`) {
            condition[`selectedTag${conditionIndex}`] = `\|{${localTagName}}`
          }
        });
        if (tag.elseSelectedTag !== '' && tag.elseSelectedTag === `\|{${previousTagName}}`) {
          tag.elseSelectedTag = `\|{${localTagName}}`;
        }
        setUpdateTempState(true)
      });

      newTemplateform.tags = newTemplateform.tags.sort((a, b) => a.tagName.localeCompare(b.tagName));
      setTemplateFormValue({
        ...newTemplateform
      });

    } catch (err) {
      console.log(err);
    }
  }

  const deleteTagByIndex = (tagIndex) => {

    let newTags = [...tags];
    let previousTagName = newTags[tagIndex]['tagName'];
    let removableStr = '|{' + previousTagName + '}';
    newTags.splice(tagIndex, 1);
    changeTag('tags', 'set', newTags);

    try {
      let newTemplateform = JSON.parse(JSON.stringify(template));
      const editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(template.templateEditorState))
      );
      const contentState = editorState.getCurrentContent();
      let newContentState = contentState;
      let contentModified = false;
      contentState.getBlockMap().forEach((block) => {
        const text = block.getText();
        let startIndex = text.indexOf(removableStr);
        let offset = 0;
        while (startIndex !== -1) {
          const endIndex = startIndex + removableStr.length;
          const blockKey = block.getKey();
          const selection = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: startIndex - offset,
            focusOffset: endIndex - offset,
          });
          newContentState = Modifier.removeRange(newContentState, selection, 'backward');
          contentModified = true;
          offset += removableStr.length;
          startIndex = text.indexOf(removableStr, endIndex);
        }
      });
      if (contentModified) {
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          'remove-range'
        );
        newTemplateform.templateEditorState = JSON.stringify(
          convertToRaw(newEditorState.getCurrentContent())
        );
      }
      newTemplateform.tags = newTags.sort((a, b) => a.tagName.localeCompare(b.tagName));
      setUpdateTempState(true);
      setTemplateFormValue({ ...newTemplateform });
      setRemoveTag(true);
    } catch (err) {
      console.log(err);
    }
  };


  const removeTag = (evt, tagIndex) => {
    evt.preventDefault();
    setTagIndexToRemove(tagIndex);
    const currentRemovableTagCondition = tags[tagIndex].conditions[0].selectedItemObj0.value.split('_')[0];
    const removableTagName = tags[tagIndex].tagName;
    if (currentRemovableTagCondition === 'mChId'
      || currentRemovableTagCondition === 'aSIdName'
      || currentRemovableTagCondition === 'aSIdAmount') {
      const params = { removableTagName, templateDepType };
      setIsLoading(true);
      fetchedData('template/getRemovableTagCalculations', 'POST', params)
        .then(response => {
          setIsLoading(false);
          if (response.status === 200) {
            if (response.data && response.data.tagNameExistsIn.length) {
              if (response.data.tagNameExistsIn.length > 1) {
                setCalculationNames(response.data.tagNameExistsIn);
                setIsPopupOpen(true);
              } else if (response.data.tagNameExistsIn.length == 1) {
                deleteTagByIndex(tagIndex);
              }
            }

          }

        })
        .catch(error => {
          setIsLoading(false);
          console.error('Error:', error);
        });


    } else {
      deleteTagByIndex(tagIndex);
    }
  };

  const removeCondition = (evt, tagIndex, conditionIndex) => {
    evt.preventDefault();
    let newTag = { ...tags[tagIndex] }
    newTag.conditions = removeByIndex([...newTag.conditions], conditionIndex)
    changeTag(`tags.${tagIndex}`, 'set', newTag);
  }

  const setTagsValues = (evt, index) => {
    changeTag(`tags.${evt.target.name}`, 'set', evt.target.value);
  }

  const tagNames = tags.map((tag, index) => {
    return tag[`tagName`]
  });

  const onChangeDepartmentSelect = async (evt) => {
    try {
      evt.preventDefault();
    } catch (err) { }
    try {
      if (evt.target.value === 'None') {
        /*  setCalculationFormValue(immutable.set(calculationForm, 'oneDayPacking.rate', 'none'));
         setCalculationFormValue(immutable.set(calculationForm, 'twoDaysPacking.firstDayRate', 'none'));
         setCalculationFormValue(immutable.set(calculationForm, 'twoDaysPacking.secondDayRate', 'none')); */
        setRates([]);
        onCalcFormValueChange('oneDayPacking.rate', 'set', 'none');
        onCalcFormValueChange('department', 'del');
        //setCalculationFormValue({ ...calculationForm, department: undefined });
        return;
      }
      const selectedDepartment = await fetchedData('department/getDepartment', 'POST', { id: evt.target.value });
      if (selectedDepartment.status === 200) {
        onCalcFormValueChange('department', 'set', { ...selectedDepartment.data });
        /*  onCalcFormValueChange('departmentId', 'set', { ...selectedDepartment.data._id }); */
        //setCalculationFormValue(immutable.set(calculationForm, 'department', { ...selectedDepartment.data }));
        setRates(selectedDepartment.data.rates.ratesList);
      }
    } catch (err) { }
  }

  useEffect(() => {
    try {
      onChangeDepartmentSelect({ target: { value: templateForm.templateCalcObj.department._id } })
    } catch (err) {
      console.log(err)
    }
  }, []);

  const handleRemoveTag = (removeFromEverywhere) => {
    deleteTagByIndex(tagIndexToRemove);
    if (removeFromEverywhere) {
      const params = { tagName: tags[tagIndexToRemove].tagName, templateDepType };
      fetchedData('template/removeTagFromTemplatesByName', 'POST', params)
        .then(response => {
          if (response.status === 200) {
            console.log('Tag removed from templates:', response.data.removedFromTemplatesNames)
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
    setIsPopupOpen(false);
    setTagIndexToRemove(null);
  };

  const hideDialog = () => {
    setIsPopupOpen(false);
  };

  const currentUserRole = useSelector(getUserRole);


  return (
    <div className={styles.tagsContainer}>
      <TagRemovePopup
        isOpen={isPopupOpen}
        calculationNames={calculationNames}
        handleRemoveTag={handleRemoveTag}
        hideDialog={hideDialog} />


      <div className={styles.fixedContainer}>
        {isLoading ? <Loading /> : <>
          <div className={styles.calculatorIcon}>
            <img src={templateCalcIcon} width="30" height="30" alt='restoreIcon' onClick={evt => { evt.preventDefault(); setCalculationPopUpShown(!calculationPopUpShown); resetLastTarget() }} />
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.extraBtn}
              onClick={
                (evt) => {
                  evt.preventDefault();
                  addTag(tags.length);
                  resetLastTarget();
                }
              }
            >
              <Icon name={IconNames.PLUS} />
              Add tag
            </button>
          </div>
        </>}
      </div>

      <div className={(calculationPopUpShown) ? styles.showSideBar : styles.hideSideBar}>

        <button className={styles.sideBarButton} onClick={evt => { evt.preventDefault(); setCalculationPopUpShown(!calculationPopUpShown); resetLastTarget() }} >
          <img src={templateCalcIcon} width="30" height="30" alt='restoreIcon' />
        </button>
        <div className={styles.overlay}>
          <fieldset className={styles.fieldset}>
            <label className={styles.label}>
              Calculation number</label>
            <input
              className={styles.input}
              name={'calculationNumber'}
              value={(calculationForm) ? calculationForm.calculationNumber : ''}
              onChange={
                (evt) => {
                  onCalcFormValueChange(evt.target.name, 'set', evt.target.value)
                }
              }
              onFocus={resetLastTarget}
              placeholder={'000000000000'}
            />

            <label className={styles.label}> Department</label>
            <select
              className={styles.select}
              value={calculationForm && calculationForm.department !== undefined ? calculationForm.department._id : 'None'}
              onChange={onChangeDepartmentSelect}
              onFocus={resetLastTarget}
            >
              <option value={undefined} key='none'>None</option>
              {
                departments && departments.map((department) => {
                  return (
                    <option key={nanoid(departments.length)} value={department._id}>{department.departmentName}</option>
                  );
                })
              }
            </select>

          </fieldset>
          {(calculationForm && calculationForm.commonValues) ?
            <CalculationCommonValues
              commonValues={calculationForm.commonValues}
              onFormChange={onCalcFormValueChange}
              lastTarget={lastTarget}
              setLastTarget={setLastTarget}
              resetLastTarget={resetLastTarget}
              clearCursorPosition={clearCursorPosition}
              renderProceduresOptions={renderProceduresOptions}
              department={calculationForm.department}
              calculationType={calculationForm.calculationType}
              setCursorPosition={setCursorPosition}
              cursorPosition={cursorPosition}
              hasRole={hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
            />
            : ''}
        </div>
      </div>

      <div style={{ padding: '5px' }}>
        {(tags.length > 0) ? tags.map((tag, index) => {
          return (
            <TemplateTagItem
              tag={tag}
              priceOptionAllowedItems={priceOptionAllowedItems}
              tagsLength={tags.length}
              tagIndex={index}
              setTagsValues={setTagsValues}
              key={'tcc' + index}
              tagNames={tagNames}
              changeTag={changeTag}
              setHighLiteItem={setHighLiteItem}
              template={template}
              company={company}
              users={users}
              departments={departments}
              department={department}
              tags={tags}
              removeTag={removeTag}
              removeCondition={removeCondition}
              cloneTag={cloneTag}
              renameTag={renameTag}
              resultText={(tagsResultsTexts[tag.tagName]) ? tagsResultsTexts[tag.tagName] : ''}
              saveBtnEvent={saveBtnEvent}
              options={options}
              emptyRef={emptyRef}
              clonnedRef={clonnedRef}
              clonnedName={clonnedName}
              resetLastTarget={resetLastTarget}
              templateDepType={templateDepType}
            />
          )
        }) : ''}
      </div>

      {/*      <div className={styles.buttonContainer}>
        <button
          className={styles.extraBtn}
          onClick={
            (evt) => {
              evt.preventDefault();
              addTag(tags.length);
            }
          }
        >
          <Icon name={IconNames.PLUS} />
          Add tag
        </button>
      </div> */}

    </div>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const saveOutOfState = createAsyncThunk(
  'outofstate/save',
  async (newOutOfStateData) => await fetchedData('outofstate/outofstate', 'POST', { ...newOutOfStateData })
);

export const updateOutOfState = createAsyncThunk(
  'outofstate/update',
  async (updateOutOfState) => await fetchedData('outofstate/update', 'POST', updateOutOfState)
);

export const loadOutOfState = createAsyncThunk(
  'outofstate/getOutOfState',
  async (id) => await fetchedData('outofstate/getOutOfState', 'POST', { id })
);

export const removeOutOfState = createAsyncThunk(
  'outofstate/remove',
  async (id) => await fetchedData('outofstate/remove', 'POST', { id })
);

import React, { useState, useContext, useCallback, useEffect } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'

// Styles
import styles from "./CalculationOOSLongTwoDayParams.module.css";
//Components
import { CalculationSeparateDayLongView } from '../calculation-separate-day-long-view/Calculation-separate-day-long-view';
import { generateUnpackingLongText, getChargesResText, getCheckMarkLabelById, getHigherNumberOfHours, getTagsValues, getTotalTimeRange, replaceOptionsTagsByValues, replaceTagsByValues } from '../../util/utils';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import CopyIcon from '../results-copy-icon/Results-copy-icon';
import { ExtraOptionsResults } from '../extra-options-results/Extra-options-results';
import { setTotalFragileBoxes } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { useDispatch } from 'react-redux';
import TruckloadsView from '../truckloads/TruckloadsView';


export const CalculationOOSLongTwoDayParams = ({
  commonValues,
  department,
  oos2days,
  outOfState,
  unpDepartment,
  selectedMiscCheckMarks,
  checkboxValueChange,
  editorState,
  tags,
  setPreviewText, setOptionsPreviewText,
  popUpShown,
  calculationData,
  setShowGenerateEmail, furniture, roomsAreas, setTagsValuesDefault, customTagsValues, showCustomTags
}) => {
  const dispatch = useDispatch();
  const [Total_cubes, setTotal_cubes] = useState(0)
  const [Total_small_boxes, setTotal_small_boxes] = useState(0)
  const [Total_medium_boxes, setTotal_medium_boxes] = useState(0)
  const [Total_fragile_boxes, setTotal_fragile_boxes] = useState(0)
  const [Total_box_cubes, setTotal_box_cubes] = useState(0)
  const [Truckloads, setTruckloads] = useState(0)
  const [Stop_CN_Truckload, setStop_CN_Truckload] = useState(0)
  const [Stop_1_Truckload, setStop_1_Truckload] = useState(0)
  const [Heavy_fee, setHeavy_fee] = useState(0)
  const [Packing_kit_Show, setPacking_kit_Show] = useState(0)
  const [Packing_kit_total_amount_with_taxes, setPacking_kit_total_amount_with_taxes] = useState(0)
  const [Truck_fee_LONG_Day1, setTruck_fee_LONG_Day1] = useState(0)
  const [Movers_LONG2DAYS_Unloading, setMovers_LONG2DAYS_Unloading] = useState(0)
  const [Movers_To_Pack_OOS_Day1, setMovers_To_Pack_OOS_Day1] = useState(0)
  const [Rate_card_OOS_Day1, setRate_card_OOS_Day1] = useState(0)
  const [Packing_Time_OOS_Day1, setPacking_Time_OOS_Day1] = useState(0)
  const [Wrapping_time_OOS_Day1, setWrapping_time_OOS_Day1] = useState(0)
  const [Total_Hours_OOS2DAYS_Loading, setTotal_Hours_OOS2DAYS_Loading] = useState(0)
  const [Total_Hours_OOS_Day1, setTotal_Hours_OOS_Day1] = useState(0)
  const [Lower_Number_of_Hours_OOS_Day1, setLower_Number_of_Hours_OOS_Day1] = useState(0)
  const [Higher_Number_of_Packing_Hours_OOS_Day1, setHigher_Number_of_Packing_Hours_OOS_Day1] = useState(0)
  const [Higher_Number_of_Hours_OOS_Day1, setHigher_Number_of_Hours_OOS_Day1] = useState(0)
  const [Total_Card_Packing_OOS_Day1, setTotal_Card_Packing_OOS_Day1] = useState(0)
  const [Total_Card_Packing_OOS_Day1_Rounded, setTotal_Card_Packing_OOS_Day1_Rounded] = useState(0)
  const [Movers_OOS2DAYS_Loading, setMovers_OOS2DAYS_Loading] = useState(0)
  const [Drivetime_OOSOne_Day, setDrivetime_OOSOne_Day] = useState(0)
  const [Total_Hours_OOS2DAYS_Unloading, setTotal_Hours_OOS2DAYS_Unloading] = useState(0)
  const [Total_Card_OOS2DAYS, setTotal_Card_OOS2DAYS] = useState(0)
  const [Total_Amount_MilesFactor_OOS2DAYS, setTotal_Amount_MilesFactor_OOS2DAYS] = useState(0)
  const [Trucks_OOS2DAYS, setTrucks_OOS2DAYS] = useState(0)
  const [Total_Hours_OOS2DAYS_Loading_Lower_number, setTotal_Hours_OOS2DAYS_Loading_Lower_number] = useState(0)
  const [Total_Hours_OOS2DAYS_Unloading_Lower_number, setTotal_Hours_OOS2DAYS_Unloading_Lower_number] = useState(0)
  const [Total_Cash_OOS2DAYS, setTotal_Cash_OOS2DAYS] = useState(0)
  const [Rate_card_Unpacking_Same_Day_OOS_2Days, setRate_card_Unpacking_Same_Day_OOS_2Days] = useState(0);
  const [Unpacking_hours_same_day_OOS2DAYS, setUnpacking_hours_same_day_OOS2DAYS] = useState(0);
  const [Total_Card_Unpacking_Same_Day_OOS_2Days, setTotal_Card_Unpacking_Same_Day_OOS_2Days] = useState(0);
  const [Trucks_Unpacking_Separate_Day, setTrucks_Unpacking_Separate_Day] = useState(0)
  const [Movers_Unpacking_Separate_Day, setMovers_Unpacking_Separate_Day] = useState(0)
  const [Unpacking_Separate_Day_Lower_hours, setUnpacking_Separate_Day_Lower_hours] = useState(0)
  const [Unpacking_Separate_Day_Higher_hours, setUnpacking_Separate_Day_Higher_hours] = useState(0)
  const [Rate_cash_Unpacking_Separate_Day, setRate_cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_card_Unpacking_Separate_Day, setRate_card_Unpacking_Separate_Day] = useState(0)
  const [Total_Card_Unpacking_Separate_Day_Long_and_OOS, setTotal_Card_Unpacking_Separate_Day_Long_and_OOS] = useState(0)
  const [Truck_fee_Unpacking_Separate_Day, setTruck_fee_Unpacking_Separate_Day] = useState(0)
  const [Combined_mileage, setCombined_mileage] = useState(0)
  const [Trucks_To_Pack_OOS_Day1, setTrucks_To_Pack_OOS_Day1] = useState(0)
  const [Time_to_pack, setTime_to_pack] = useState(0);
  const [Total_cubes_Within_Building, setTotal_cubes_Within_Building] = useState(0)
  const [Truckloads_to_move, setTruckloads_to_move] = useState(0)
  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.emit('getFormulasValues', { calculationType: 'oos2days', commonValues: commonValues, oos2days: oos2days, department: department, unpDepartment: unpDepartment, outOfState: outOfState })
    socket.on('Total_cubes', (data) => setTotal_cubes(data))
    socket.on('Total_small_boxes', (data) => setTotal_small_boxes(data))
    socket.on('Total_medium_boxes', (data) => setTotal_medium_boxes(data))
    socket.on('Total_fragile_boxes', (data) => { setTotal_fragile_boxes(data); dispatch(setTotalFragileBoxes(data)); })
    socket.on('Total_box_cubes', (data) => setTotal_box_cubes(data))
    socket.on('Truckloads', (data) => setTruckloads(data))
    socket.on('Stop_CN_Truckload', (data) => setStop_CN_Truckload(data))
    socket.on('Stop_1_Truckload', (data) => setStop_1_Truckload(data))
    socket.on('Heavy_fee', (data) => setHeavy_fee(data))
    socket.on('Packing_kit_Show', (data) => setPacking_kit_Show(data))
    socket.on('Packing_kit_total_amount_with_taxes', (data) => setPacking_kit_total_amount_with_taxes(data))
    socket.on('Truck_fee_LONG_Day1', (data) => setTruck_fee_LONG_Day1(data))
    socket.on('Movers_LONG2DAYS_Unloading', (data) => setMovers_LONG2DAYS_Unloading(data))
    socket.on('Movers_To_Pack_OOS_Day1', (data) => setMovers_To_Pack_OOS_Day1(data))
    socket.on('Rate_card_OOS_Day1', (data) => setRate_card_OOS_Day1(data))
    socket.on('Packing_Time_OOS_Day1', (data) => setPacking_Time_OOS_Day1(data))
    socket.on('Wrapping_time_OOS_Day1', (data) => setWrapping_time_OOS_Day1(data))
    socket.on('Total_Hours_OOS2DAYS_Loading', (data) => setTotal_Hours_OOS2DAYS_Loading(data))
    socket.on('Total_Hours_OOS_Day1', (data) => setTotal_Hours_OOS_Day1(data))
    socket.on('Lower_Number_of_Hours_OOS_Day1', (data) => setLower_Number_of_Hours_OOS_Day1(data))
    socket.on('Higher_Number_of_Packing_Hours_OOS_Day1', (data) => setHigher_Number_of_Packing_Hours_OOS_Day1(data))
    socket.on('Higher_Number_of_Hours_OOS_Day1', (data) => setHigher_Number_of_Hours_OOS_Day1(data))
    socket.on('Total_Card_Packing_OOS_Day1', (data) => setTotal_Card_Packing_OOS_Day1(data))
    socket.on('Total_Card_Packing_OOS_Day1_Rounded', (data) => setTotal_Card_Packing_OOS_Day1_Rounded(data))
    socket.on('Movers_OOS2DAYS_Loading', (data) => setMovers_OOS2DAYS_Loading(data))
    socket.on('Drivetime_OOSOne_Day', (data) => setDrivetime_OOSOne_Day(data))
    socket.on('Total_Hours_OOS2DAYS_Unloading', (data) => setTotal_Hours_OOS2DAYS_Unloading(data))
    socket.on('Total_Card_OOS2DAYS', (data) => setTotal_Card_OOS2DAYS(data))
    socket.on('Total_Amount_MilesFactor_OOS2DAYS', (data) => setTotal_Amount_MilesFactor_OOS2DAYS(data))
    socket.on('Trucks_OOS2DAYS', (data) => setTrucks_OOS2DAYS(data))
    socket.on('Total_Hours_OOS2DAYS_Loading_Lower_number', (data) => setTotal_Hours_OOS2DAYS_Loading_Lower_number(data))
    socket.on('Total_Hours_OOS2DAYS_Unloading_Lower_number', (data) => setTotal_Hours_OOS2DAYS_Unloading_Lower_number(data))
    socket.on('Total_Cash_OOS2DAYS', (data) => setTotal_Cash_OOS2DAYS(data))

    socket.on('Rate_card_Unpacking_Same_Day_OOS_2Days', (data) => setRate_card_Unpacking_Same_Day_OOS_2Days(data));
    socket.on('Unpacking_hours_same_day_OOS2DAYS', (data) => setUnpacking_hours_same_day_OOS2DAYS(data));
    socket.on('Total_Card_Unpacking_Same_Day_OOS_2Days', (data) => setTotal_Card_Unpacking_Same_Day_OOS_2Days(data));

    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data))
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data))
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data))
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data))
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data))
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data))
    socket.on('Total_Card_Unpacking_Separate_Day_Long_and_OOS', (data) => setTotal_Card_Unpacking_Separate_Day_Long_and_OOS(data))
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data))
    socket.on('Combined_mileage', (data) => setCombined_mileage(data));
    socket.on('Trucks_To_Pack_OOS_Day1', (data) => setTrucks_To_Pack_OOS_Day1(data));
    socket.on('Time_to_pack', (data) => setTime_to_pack(data));
    socket.on('Total_cubes_Within_Building', (data) => setTotal_cubes_Within_Building(data));
    socket.on('Truckloads_to_move', (data) => setTruckloads_to_move(data));


  }, [commonValues, oos2days, department, outOfState])
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  useEffect(() => {
    if (editorState) {
      let templateText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      let tagValues = getTagsValues({
        calculation: calculationData, tags,
        calculatedParams: {
          Total_cubes: Total_cubes,
          Total_small_boxes: Total_small_boxes,
          Total_medium_boxes: Total_medium_boxes,
          Total_fragile_boxes: Total_fragile_boxes,
          Total_box_cubes: Total_box_cubes,
          Truckloads: Truckloads,
          Truckloads_to_move: Truckloads_to_move,
          Total_cubes_Within_Building: Total_cubes_Within_Building,
          Stop_CN_Truckload: Stop_CN_Truckload,
          Stop_1_Truckload: Stop_1_Truckload,
          Heavy_fee: Heavy_fee,
          Packing_kit_Show: Packing_kit_Show,
          Packing_kit_total_amount_with_taxes: Packing_kit_total_amount_with_taxes,
          Truck_fee_LONG_Day1: Truck_fee_LONG_Day1,
          Movers_LONG2DAYS_Unloading: Movers_LONG2DAYS_Unloading,
          Movers_To_Pack_OOS_Day1: Movers_To_Pack_OOS_Day1,
          Rate_card_OOS_Day1: Rate_card_OOS_Day1,
          Packing_Time_OOS_Day1: Packing_Time_OOS_Day1,
          Wrapping_time_OOS_Day1: Wrapping_time_OOS_Day1,
          Total_Hours_OOS2DAYS_Loading: Total_Hours_OOS2DAYS_Loading,
          Total_Hours_OOS_Day1: Total_Hours_OOS_Day1,
          Lower_Number_of_Hours_OOS_Day1: Lower_Number_of_Hours_OOS_Day1,
          Higher_Number_of_Packing_Hours_OOS_Day1: Higher_Number_of_Packing_Hours_OOS_Day1,
          Higher_Number_of_Hours_OOS_Day1: Higher_Number_of_Hours_OOS_Day1,
          Total_Card_Packing_OOS_Day1: Total_Card_Packing_OOS_Day1,
          Total_Card_Packing_OOS_Day1_Rounded: Total_Card_Packing_OOS_Day1_Rounded,
          Movers_OOS2DAYS_Loading: Movers_OOS2DAYS_Loading,
          Drivetime_OOSOne_Day: Drivetime_OOSOne_Day,
          Total_Hours_OOS2DAYS_Unloading: Total_Hours_OOS2DAYS_Unloading,
          Total_Card_OOS2DAYS: Total_Card_OOS2DAYS,
          Total_Amount_MilesFactor_OOS2DAYS: Total_Amount_MilesFactor_OOS2DAYS,
          Trucks_OOS2DAYS: Trucks_OOS2DAYS,
          Total_Hours_OOS2DAYS_Loading_Lower_number: Total_Hours_OOS2DAYS_Loading_Lower_number,
          Total_Hours_OOS2DAYS_Unloading_Lower_number: Total_Hours_OOS2DAYS_Unloading_Lower_number,
          Total_Cash_OOS2DAYS: Total_Cash_OOS2DAYS,
          Rate_card_Unpacking_Same_Day_OOS_2Days: Rate_card_Unpacking_Same_Day_OOS_2Days,
          Unpacking_hours_same_day_OOS2DAYS: Unpacking_hours_same_day_OOS2DAYS,
          Total_Card_Unpacking_Same_Day_OOS_2Days: Total_Card_Unpacking_Same_Day_OOS_2Days,
          Trucks_Unpacking_Separate_Day: Trucks_Unpacking_Separate_Day,
          Movers_Unpacking_Separate_Day: Movers_Unpacking_Separate_Day,
          Unpacking_Separate_Day_Lower_hours: Unpacking_Separate_Day_Lower_hours,
          Unpacking_Separate_Day_Higher_hours: Unpacking_Separate_Day_Higher_hours,
          Rate_cash_Unpacking_Separate_Day: Rate_cash_Unpacking_Separate_Day,
          Rate_card_Unpacking_Separate_Day: Rate_card_Unpacking_Separate_Day,
          Total_Card_Unpacking_Separate_Day_Long_and_OOS: Total_Card_Unpacking_Separate_Day_Long_and_OOS,
          Truck_fee_Unpacking_Separate_Day: Truck_fee_Unpacking_Separate_Day,
          Combined_mileage: Combined_mileage,
          Trucks_To_Pack_OOS_Day1: Trucks_To_Pack_OOS_Day1,
          Truckloads_to_move: Truckloads_to_move,
        }, furniture, roomsAreas
      })
      const tagValuesCopy = JSON.parse(JSON.stringify(tagValues));
      if (typeof setTagsValuesDefault === 'function') {
        setTagsValuesDefault(prevState => ({
          ...prevState,
          [calculationData.calculationType]: tagValuesCopy
        }));
      }
      if (customTagsValues && Object.keys(customTagsValues).length > 0) {
        for (const key in customTagsValues) {
          tagValues[`|{${key}}`] = customTagsValues[key];
        }
      }
      setPreviewText(replaceTagsByValues(templateText, tagValues))
      setOptionsPreviewText(replaceOptionsTagsByValues(templateText, tagValues))
    }
  }, [popUpShown, tags, /* calculationData,  */showCustomTags]);

  const handleResCopy = (evt) => {
    evt.preventDefault();

    let chargesText = getChargesResText(department, oos2days.selectedChargesNamesDay1, Trucks_To_Pack_OOS_Day1);

    let chargesTextDay2 = getChargesResText(department, oos2days.selectedChargesNamesDay2, Trucks_OOS2DAYS);

    let chargesTextDay3 = getChargesResText(department, oos2days.selectedChargesNamesDay3, Trucks_OOS2DAYS);

    let unpackingText = generateUnpackingLongText(commonValues, oos2days, unpDepartment, Truck_fee_Unpacking_Separate_Day, Movers_Unpacking_Separate_Day, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours, Rate_cash_Unpacking_Separate_Day, Rate_card_Unpacking_Separate_Day, Total_Card_Unpacking_Separate_Day_Long_and_OOS, Trucks_Unpacking_Separate_Day, Rate_card_Unpacking_Same_Day_OOS_2Days, Unpacking_hours_same_day_OOS2DAYS, Total_Card_Unpacking_Same_Day_OOS_2Days);

    let proceduresText = '';
    let procedureAdded = false;
    commonValues.procedures.forEach((procedure, pkIndex) => {
      if (procedure.procedureType !== '' && procedure.procedureType !== 'None' && oos2days.proceduresOnDay1) {
        if (!procedureAdded) {
          proceduresText += 'Procedures:\n';
          procedureAdded = true;
        }
        proceduresText += '• ' + procedure.procedureType + ' x ' + procedure.numberOfProcedures + ':';
        if (Object.keys(department).length !== 0) {
          department.packing.cratings.forEach((crating, index) => {
            if (procedure.procedureType === crating[`crateName${index}`]) {
              proceduresText += ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
            }
          });
        }
        proceduresText += '\n';
      }
    });

    let proceduresTextDay2 = '';
    let procedureAddedDay2 = false;
    commonValues.procedures.forEach((procedure, pkIndex) => {
      if (procedure.procedureType !== '' && procedure.procedureType !== 'None' && oos2days.proceduresOnDay2) {
        if (!procedureAddedDay2) {
          proceduresTextDay2 += 'Procedures:\n';
          procedureAddedDay2 = true;
        }
        proceduresTextDay2 += '• ' + procedure.procedureType + ' x ' + procedure.numberOfProcedures + ':';
        if (Object.keys(department).length !== 0) {
          department.packing.cratings.forEach((crating, index) => {
            if (procedure.procedureType === crating[`crateName${index}`]) {
              proceduresTextDay2 += ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
            }
          });
        }
        proceduresTextDay2 += '\n';
      }
    });




    const invoiceText = `Total Cubic ft: ${parseFloat(Total_cubes).toFixed(2)} cu ft.
    ${(Total_cubes_Within_Building > 0) ? `Cubic ft within building:${parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
    Cubic ft to move: ${parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.` : ''}            
Truckloads: ${Truckloads}
${(Truckloads > 1 && (Math.floor(Number(Truckloads))) - Number(Truckloads) < 0) ? Math.floor(Truckloads) + ' truck(s) + ' + Math.floor(Number((Number(Truckloads) - Math.floor(Truckloads)).toFixed(2) * 100)) + "%" : ''}
Total miles: ${parseFloat(Combined_mileage).toFixed(1)}
${(commonValues.extraStops.filter((extraStop, index) =>
      extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
    ).length > 0) ? `There will be ${commonValues.extraStops.filter((extraStop, index) =>
      extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
    ).length} additional stops` : ''}
Day 1 - Packing

Movers: ${Movers_To_Pack_OOS_Day1}

Trucks: ${Trucks_To_Pack_OOS_Day1}${(commonValues.shuttle && oos2days.shuttleOnDay1) ? "+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${oos2days.quantityDay1})` : '') : ''}

Total Time Range:${getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_OOS_Day1, Higher_Number_of_Hours_OOS_Day1)}

${(commonValues.packing !== "No") ?
        (commonValues.packingKitOverride > 0) ? `Packing Kit:${'$' + Packing_kit_Show} {commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]` : `Packing Kit:${'$' + Packing_kit_Show} ${(commonValues.packing === "Yes") ? "Full" : commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]` : ""}
${(commonValues?.unpacking === 'Same Day') ? `UNPACKING ${(commonValues.partialUnPacking) ? "PARTIAL" : ''}` : ''}

${(commonValues.packing !== "No") ? `
Packing Supplies On The Go:
• Small box: $${(Object.keys(department).length !== 0) ? department.packing.supplies.smallBox : ''}
• Medium box: $${(Object.keys(department).length !== 0) ? department.packing.supplies.mediumBox : ''}
• Large box: $${(Object.keys(department).length !== 0) ? department.packing.supplies.largeBox : ''}
• Roll of packing paper: $${(Object.keys(department).length !== 0) ? department.packing.supplies.packingPapper : ''}
• Roll of bubble wrap: $${(Object.keys(department).length !== 0) ? department.packing.supplies.bubbleWrap : ''}
`: ''}

${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && oos2days.selectedChargesNamesDay1.length > 0) ? `
Extra options:` : ''}
${chargesText}

${((commonValues.procedures.length > 0) && (commonValues.procedures[0].procedureType !== "None" && commonValues.procedures[0].procedureType !== "" && oos2days.proceduresOnDay1)) ? 'Procedures:' : ''}
${proceduresText}

DAY 2 - LOADING

Movers: ${Movers_OOS2DAYS_Loading}

Trucks: ${Trucks_OOS2DAYS}${(commonValues.shuttle && oos2days.shuttleOnDay2) ? "+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${oos2days.quantityDay2})` : '') : ''}

Total time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Total_Hours_OOS2DAYS_Loading_Lower_number, Total_Hours_OOS2DAYS_Loading)}

${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && oos2days.selectedChargesNamesDay2.length > 0) ? `
Extra options:` : ''}
${chargesTextDay2}

${(Heavy_fee > 0) ?
        `Extra Heavy Items Charge: $${Heavy_fee}`
        : ''}

${(commonValues.procedures.length > 0 && oos2days.proceduresOnDay2) ? 'Procedures:' : ''}
${proceduresTextDay2}

${commonValues.liftGate ? ' | LIFTGATE' : ''}
${commonValues.hardFloorCovers ? ' | HARDWOOD FLOOR COVERS' : ''}
${commonValues.filmFloorCovers ? ' | CARPET FLOOR COVERS' : ''}
${selectedMiscCheckMarks.map(checkMark => ` | ${getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks).toUpperCase()}`).join('')}

DRIVING

Arrival Time: ${(!Drivetime_OOSOne_Day || Drivetime_OOSOne_Day === 0) ? "Not specified" : Drivetime_OOSOne_Day.toString() + ' days'}

DAY 3 - UNLOADING

Movers: ${Movers_LONG2DAYS_Unloading}

Trucks: ${Trucks_OOS2DAYS}${(commonValues.shuttle && oos2days.shuttleOnDay3) ? "+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${oos2days.quantityDay3})` : '') : ''}

${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && oos2days.selectedChargesNamesDay3.length > 0) ? `
Extra options:` : ''}
${chargesTextDay3}

Total time Range: ${getTotalTimeRange(department.minimumHours, Total_Hours_OOS2DAYS_Unloading_Lower_number, Total_Hours_OOS2DAYS_Unloading)}

${(oos2days.additionalAmount) ? `Additional amount:$${oos2days.additionalAmount}` : ''}

FLAT RATE: $${(outOfState.calcMethod !== 'deduct') ?
        Total_Card_OOS2DAYS
        :
        (Total_Cash_OOS2DAYS
          + ' cash / $').toString() +
        Total_Card_OOS2DAYS + " card"
      }

${(outOfState.calcMethod !== 'deduct') ? `FLAT RATE with ${outOfState.cashPaymentDiscount}% cash discount:$${Total_Cash_OOS2DAYS}` : ""}

${unpackingText}`;




    // Create a textarea element to hold the text
    const textArea = document.createElement("textarea");
    textArea.value = invoiceText.replace(/\n{2,}/g, '\n\n') + '\n';;

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textArea);

  }

  if (typeof setShowGenerateEmail === 'function') {
    if (department && Object.keys(department).length > 0 && oos2days.firstDayRate && oos2days.factor && Trucks_OOS2DAYS > 0 && outOfState
      && Object.keys(outOfState).length > 0 && Movers_To_Pack_OOS_Day1 > 0 && Movers_OOS2DAYS_Loading > 0 && Movers_LONG2DAYS_Unloading > 0) {
      setShowGenerateEmail(true)
    } else {
      setShowGenerateEmail(false)
    }
  }

  return (
    <ul className={styles.paramsSection}>
      <button {...getToggleProps()} className={styles.btnSubmit} style={{ zIndex: '0' }}>
        <i className={isExpanded ? styles.up : styles.down}></i>
      </button>
      <section {...getCollapseProps()}>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Small boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_small_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Medium boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_medium_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Fragile boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_fragile_boxes}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Boxes cubic ft:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_box_cubes.toFixed(2) + " cu ft."}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Time to pack for 1 mover:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Time_to_pack).toFixed(2) + " h"}
          </p>
        </li>
        <li className={styles.paramsItem} >
          <p className={styles.paramsName}>Truckloads from Stop 1:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_1_Truckload).toFixed(3)}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>
            Truckloads from Additional stops:
          </p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_CN_Truckload).toFixed(3)}
          </p>
        </li>
        <h4>DAY - 1</h4>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Movers to Pack:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Movers_To_Pack_OOS_Day1}
          </p>
        </li>


        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Rate:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>$
            {Rate_card_OOS_Day1 + '/h + $' + Truck_fee_LONG_Day1 + " truck(s) fee"}
          </p>
        </li>


        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Packing Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Packing_Time_OOS_Day1).toFixed(3)}{' h'}
          </p>
        </li>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Wrapping Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Wrapping_time_OOS_Day1).toFixed(3)}{' h'}
          </p>
        </li>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_Hours_OOS_Day1}{' hours'}
          </p>
        </li>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Time Range:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_OOS_Day1, Higher_Number_of_Hours_OOS_Day1)}
          </p>
        </li>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Only packing time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Higher_Number_of_Packing_Hours_OOS_Day1}{' h'}
          </p>
        </li>


        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Day 1:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>$
            {Total_Card_Packing_OOS_Day1}
          </p>
        </li>



        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total Day 1 Rounded:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>$
            {Total_Card_Packing_OOS_Day1_Rounded}
          </p>
        </li>



        <h4>DAY - 2 (Packing & Loading)</h4>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Loading Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {/* {Loading_hours_OOS2DAYS} */}
            {Total_Hours_OOS2DAYS_Loading}{' h'}

          </p>
        </li>

        <h4>DAY - 3 (Unloading)</h4>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Unloading Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Total_Hours_OOS2DAYS_Unloading).toFixed(3)}{' h'}
          </p>
        </li>

        {(commonValues?.unpacking === 'Same Day') ?
          <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Unpacking Rate:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>$
                {Rate_card_Unpacking_Same_Day_OOS_2Days}/h
              </p>
            </li>

            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Unpacking Time:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {Math.ceil(Unpacking_hours_same_day_OOS2DAYS)}{' h'}
              </p>
            </li>

            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Total for Unpacking:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>$
                {Total_Card_Unpacking_Same_Day_OOS_2Days}
              </p>
            </li>
          </>
          : ''
        }
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total days 2 & 3:</p>
          <div className={styles.roundedOrNot}>
            <span className={styles.paramsDecor}></span>
          </div>
          <p className={styles.paramsValue}>$
            {Total_Amount_MilesFactor_OOS2DAYS}
          </p>
        </li>
      </section>
      {(department && Object.keys(department).length > 0 && oos2days.firstDayRate && oos2days.factor && Trucks_OOS2DAYS > 0 && outOfState
        && Object.keys(outOfState).length > 0 && Movers_To_Pack_OOS_Day1 > 0 && Movers_OOS2DAYS_Loading > 0 && Movers_LONG2DAYS_Unloading > 0) ?
        <div>
          <h2 className={styles.titleCenter}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Results  <CopyIcon onClick={handleResCopy} style={{ marginLeft: '5px' }} /></div>{(Total_box_cubes > 0 && commonValues.packing === 'No') ? <>
            <span style={{ color: 'red' }}> - No Packing
            </span>
            <label className={styles.commonLabeling} style={{ marginLeft: '5px' }}>
              Confirm
              <input
                className={styles.mycheckbox}
                type='checkbox'
                name={'isNoPackingConfirmed'}
                checked={commonValues.isNoPackingConfirmed}
                value={commonValues.isNoPackingConfirmed}
                onChange={checkboxValueChange}
              />
            </label>
          </> : ''}</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Cubic ft:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Total_cubes.toFixed(2)} cu ft.
            </p>
          </li>
          {(Total_cubes_Within_Building > 0) ? <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft within building:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft to move:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.
              </p>
            </li>
          </> : ''}
          <TruckloadsView Truckloads={Truckloads} Total_cubes_Within_Building={Total_cubes_Within_Building} Truckloads_to_move={Truckloads_to_move} />
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total miles:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>{parseFloat(Combined_mileage).toFixed(1)}</p>
          </li>
          {
            (commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ?
              <li className={styles.paramsItem}><div>
                There will be {commonValues.extraStops.filter((extraStop, index) =>
                  extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
                ).length} additional stops</div>
              </li>
              : ''
          }
          <h2 className={styles.titleCenter}>Day 1 - Packing</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Movers_To_Pack_OOS_Day1}
            </p>
          </li>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Trucks_To_Pack_OOS_Day1}
              {(commonValues.shuttle && oos2days.shuttleOnDay1) && ("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${oos2days.quantityDay1})` : ''))}</p>
          </li>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_OOS_Day1, Higher_Number_of_Hours_OOS_Day1)}
            </p>
          </li>

          {(commonValues.packing !== "No") ?
            (commonValues.packingKitOverride > 0) ?
              <li className={styles.paramsItem} style={{ color: "red" }}>
                <p className={styles.paramsName}>Packing Kit:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  {'$' + Packing_kit_Show} {commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]
                </p>
              </li>
              : <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Packing Kit:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  {'$' + Packing_kit_Show} {(commonValues.packing === "Yes") ? "Full" : commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]
                </p>
              </li>
            : ""}
          {(commonValues?.unpacking === 'Same Day') &&
            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
              <span style={{ whiteSpace: 'nowrap' }}>UNPACKING {commonValues.partialUnPacking && "PARTIAL"}</span>
            </div>
          }
          {(commonValues.packing !== "No") ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Packing Supplies On The Go:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
              </p>
              <ul>
                <li>
                  Small box: ${(Object.keys(department).length !== 0) && department.packing.supplies.smallBox}
                </li>
                <li>
                  Medium box: ${(Object.keys(department).length !== 0) && department.packing.supplies.mediumBox}
                </li>
                <li>
                  Large box: ${(Object.keys(department).length !== 0) && department.packing.supplies.largeBox}
                </li>
                <li>
                  Roll of packing paper: ${(Object.keys(department).length !== 0) && department.packing.supplies.packingPapper}
                </li>
                <li>
                  Roll of bubble wrap: ${(Object.keys(department).length !== 0) && department.packing.supplies.bubbleWrap}
                </li>
              </ul>
            </li>
            : ""}

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={oos2days.selectedChargesNamesDay1}
            trucksCount={Trucks_To_Pack_OOS_Day1}
          />

          {(commonValues.procedures.length > 0 && commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '') && oos2days.proceduresOnDay1) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Procedures:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>

              </p>
              <ul>
                {
                  commonValues.procedures.map((procedure) => {
                    if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
                      return <li>{procedure.procedureType + " x " + procedure.numberOfProcedures}:{
                        (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                          if (procedure.procedureType === crating[`crateName${index}`]) {
                            return ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
                          }
                          else {
                            return ''
                          }
                        })
                      }</li>
                    }
                    else { return '' }
                  })
                }
              </ul>
            </li>
            :
            ''}

          <h2 className={styles.titleCenter}>Day 2 - Loading</h2>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Movers_OOS2DAYS_Loading}
            </p>
          </li>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Trucks_OOS2DAYS}
              {(commonValues.shuttle && oos2days.shuttleOnDay2) && ("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${oos2days.quantityDay2})` : ''))}</p>
          </li>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {getTotalTimeRange((department) ? department.minimumHours : 0, Total_Hours_OOS2DAYS_Loading_Lower_number, Total_Hours_OOS2DAYS_Loading)}
            </p>
          </li>

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={oos2days.selectedChargesNamesDay2}
            trucksCount={Trucks_OOS2DAYS}
          />

          {(Heavy_fee > 0) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Extra Heavy Items Charge:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>{'$' + Heavy_fee}</p>
            </li>
            : ""}

          {(commonValues.procedures.length > 0 && commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '') && oos2days.proceduresOnDay2) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Procedures:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
              </p>
              <ul>
                {
                  commonValues.procedures.map((procedure) => {
                    if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
                      return <li>{procedure.procedureType + " x " + procedure.numberOfProcedures}:{
                        (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                          if (procedure.procedureType === crating[`crateName${index}`]) {
                            return ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
                          }
                          else {
                            return ''
                          }
                        })
                      }</li>
                    }
                    else { return '' }
                  })
                }
              </ul>
            </li>
            :
            ''}

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.liftGate && ' | LIFTGATE'}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.hardFloorCovers && " | HARDWOOD FLOOR COVERS"}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.filmFloorCovers && " | CARPET FLOOR COVERS "}</span>
            {selectedMiscCheckMarks.map(checkMark => <span style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
              {' '} | {getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks)}
            </span>)}
          </div>


          <h2 className={styles.titleCenter}>Driving</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Arrival Time:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {(!Drivetime_OOSOne_Day || Drivetime_OOSOne_Day === 0) ? "Not specified" :
                Drivetime_OOSOne_Day.toString() + ' days'}
            </p>
          </li>

          <h2 className={styles.titleCenter}>Day 3 - Unloading</h2>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Movers_LONG2DAYS_Unloading}
            </p>
          </li>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Trucks_OOS2DAYS}
              {(commonValues.shuttle && oos2days.shuttleOnDay3) && ("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${oos2days.quantityDay3})` : ''))}</p>
          </li>

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={oos2days.selectedChargesNamesDay3}
            trucksCount={Trucks_OOS2DAYS}
          />

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {getTotalTimeRange(department.minimumHours, Total_Hours_OOS2DAYS_Unloading_Lower_number, Total_Hours_OOS2DAYS_Unloading)}
            </p>
          </li>

          {(oos2days.additionalAmount) ? (
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Additional amount:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                ${oos2days.additionalAmount}
              </p>
            </li>) : ''
          }

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>FLAT RATE:</p>
            <div className={styles.roundedOrNot}>
              <span className={styles.paramsDecor}></span>
            </div>
            <p className={styles.paramsValue}>$
              {(outOfState.calcMethod !== 'deduct') ?
                Total_Card_OOS2DAYS
                :
                (Total_Cash_OOS2DAYS
                  + ' cash / $').toString() +
                Total_Card_OOS2DAYS + " card"
              }

            </p>
          </li>


          {(outOfState.calcMethod !== 'deduct') ?
            <div style={{ paddingLeft: '30px' }}>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>FLAT RATE with {outOfState.cashPaymentDiscount}% cash discount:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>$
                  {
                    Total_Cash_OOS2DAYS
                  }
                </p>
              </li>
            </div>
            : ""}


          <CalculationSeparateDayLongView commonValues={commonValues} anyCalculationType={oos2days} unpDepartment={unpDepartment} isOOS={true}
            Trucks_Unpacking_Separate_Day={Trucks_Unpacking_Separate_Day}
            Movers_Unpacking_Separate_Day={Movers_Unpacking_Separate_Day}
            Unpacking_Separate_Day_Lower_hours={Unpacking_Separate_Day_Lower_hours}
            Unpacking_Separate_Day_Higher_hours={Unpacking_Separate_Day_Higher_hours}
            Rate_cash_Unpacking_Separate_Day={Rate_cash_Unpacking_Separate_Day}
            Rate_card_Unpacking_Separate_Day={Rate_card_Unpacking_Separate_Day}
            Total_Card_Unpacking_Separate_Day_Long_and_OOS={Total_Card_Unpacking_Separate_Day_Long_and_OOS}
            Truck_fee_Unpacking_Separate_Day={Truck_fee_Unpacking_Separate_Day}
          />
        </div>
        : ""}
    </ul>
  );
};

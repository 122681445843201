// Components
import { ActionTableBody } from '../action-table-body/Action-table-body';
import { ActionsTable } from '../actions-table/Actions-table';
import { ActionsTableHeader } from '../actions-table-header/Actions-table-header';

export const ActionList = ({ actions, filters, setFilters }) => {
  const headerTitles = {
    actionName: 'Note',
    authorId: 'User',
    thirdColumn: "Date & Time",
  };

  return (
    <ActionsTable>
      <ActionsTableHeader headerTitles={headerTitles} filters={filters} setFilters={setFilters} />
      <ActionTableBody tableData={actions} />
    </ActionsTable>
  );
};
import React from 'react';
import { Estimates } from '../estimates/Estimates';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { PageHeader } from '../page-header/Page-header';
import { IconNames } from '../../util/const';
import { Promo } from '../promo/Promo';
import styles from './ErrorBoundary.module.css';
import { Icon } from '../Icon';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div className='container'>

                    <Header
                        isLoginBtnShow={false}
                    />
                    <main className='main'>
                        <section className={styles.container}>
                            <Icon name={IconNames.ERRORTRUCK} className={styles.centerIcon} />
                        </section>
                        <div className={styles.titleContainer}>
                            <h1 className={styles.errorNumber}>...404...</h1>
                            <h1 className={styles.errorTitle}>Ooops!</h1>
                            <h1 className={styles.errorTitle}>Page Not Found</h1>
                        </div>
                    </main>
                    {/* <Footer /> */}
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;
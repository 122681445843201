// Components
import { Icon } from '../Icon';
// Util functions
import { IconNames } from '../../util/const';
// Styles
import styles from './Filter.module.css';
import { useState } from 'react';

export const Filter = ({ searchName, setFilters, filters, selectOptions, filterField }) => {
  return (
    <div className={styles.container}>
      <label>
        <span className='visually-hidden'>Input {searchName} </span>
        <select
          className={styles.select}
          value={filters.filter.value}
          onChange={(evt) => setFilters("filter", 'set', { 'field': filterField, 'value': evt.target.value })}
        >
          <option value=''>Filter by {searchName}</option>
          {selectOptions}
        </select>
        {/*  <input
          className={styles.input}
          name='searchText'
          id='search'
          placeholder={searchName}
          onChange={(evt) => setSearchText(evt.target.value)}
        /> */}
      </label>
      {/*       <button className={styles.submitBtn} onClick={() => {
        setFilters("search", 'set', { 'field': 'login', 'searchText': searchText });
      }}>
        <Icon name={IconNames.SEARCH} />
      </button> */}
    </div>
  );
};
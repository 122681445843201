// Third party libraries
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom';
// Components
import { LongDistanceEdit } from '../Components/longdistance-edit/LongDistance-edit';
import { LongDistanceForm } from '../Components/longdistance-form/LongDistance-form';
import { LongDistancesMain } from '../Components/longdistances-main/LongDistances-main';
import { Footer } from '../Components/footer/Footer';
import { Header } from '../Components/header/Header';
import { PageHeader } from '../Components/page-header/Page-header';
import { PageMenu } from '../Components/page-menu/Page-menu';
// Functions for work with state
import { getUserRole, checkAuth } from '../clientStore/authSlice/auth-slice';
//Utils functions
import { IconNames } from '../util/const';
import { PrivateRoute } from '../util/private-route';
import { getSectionNames } from '../util/utils';


export const LongDistances = ({ userName }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const userRole = useSelector(getUserRole);
  const SectionNames = getSectionNames(userRole);

  const settingsHeaderBtn = [
/*     {
      name: 'Change password'
    } */
  ];

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, [dispatch]);

  return (
    <div className='container'>
      <Header
        isLoginBtnShow={false}
      />
      <main className='main'>
        <PageHeader
          titleIcon={IconNames.USER}
          title={userName}
          btns={settingsHeaderBtn}
          menuItems={SectionNames}
        />
       {/*  <PageMenu
          menuItems={SectionNames}
        /> */}

        <Switch>
          <Route exact path={path}>
            <LongDistancesMain />
          </Route>
          <PrivateRoute path={`${path}/add`}>
            <LongDistanceForm formType={'new'} />
          </PrivateRoute>
          <PrivateRoute path={`${path}/:longdistanceId`}>
            <LongDistanceEdit />
          </PrivateRoute>
        </Switch>
      </main>
      {/* <Footer /> */}
    </div>
  );
};
// Third party libraries
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useState } from 'react';
// Components
import { DepartmentRadiobuttons } from '../department-radiobuttons/Department-radiobuttons';
import { DepartmentRadiobutons } from '../department-radiobutons/Department-radiobutons';
import { RateTypeRadiobuttons } from '../rateType-radiobuttons/RateType-radiobuttons';
import { DepartmentTitle } from '../department-title/Department-title';
import { Icon } from '../Icon';
import { RateFieldset } from '../rate-fieldset/Rate-fieldset';
import { MinHoursLabel } from '../min-hours-label/Min-hours-label';
// Utils
import { IconNames } from '../../util/const';
//Styles
import styles from './DepartmentRates.module.css';


export const DepartmentRates = (props) => {
  const {
    rates,
    setDepartmentFormValue,
    lastTarget,
    setLastTarget,
    setCursorPosition,
    cursorPosition,
    minimumHours
  } = props;

  const [isSameRate, setSameRate] = useState(false);
  const [isSameTruck, setSameTruck] = useState(false);
  const [isSameDiscount, setSameDiscount] = useState(false);

  const addRateBtnClick = (index, typeRate, isSameRate, isSameTruck, isSameDiscount) => {
    const newRate = typeRate === 'deduct' ? {
      [`longDistanceExtraType${index}`]: 'perJob',
      [`rateName${index}`]: '',
      [`cardPayment${index}`]: isSameRate ? rates.ratesList[0].cardPayment0 : '',
      [`deductPayment${index}`]: isSameDiscount ? rates.ratesList[0].deductPayment0 : '',
      [`extraMover${index}`]: isSameRate ? rates.ratesList[0].extraMover0 : '',
      [`extraTruck${index}`]: isSameTruck ? rates.ratesList[0].extraTruck0 : '',
      [`customRatesList${index}`]: [{
        customMovers0: '2',
        customTrucks0: '1',
        customHourlyRate0: '',
      }],
    } :
      {
        [`longDistanceExtraType${index}`]: 'perJob',
        [`rateName${index}`]: '',
        [`hourlyRate${index}`]: isSameRate ? rates.ratesList[0].hourlyRate0 : '',
        [`cashDiscount${index}`]: isSameDiscount ? rates.ratesList[0].cashDiscount0 : '',
        [`extraMover${index}`]: isSameRate ? rates.ratesList[0].extraMover0 : '',
        [`extraTruck${index}`]: isSameTruck ? rates.ratesList[0].extraTruck0 : '',
        [`customRatesList${index}`]: [{
          customMovers0: '2',
          customTrucks0: '1',
          customHourlyRate0: '',
        }],
      };
    setDepartmentFormValue('rates.ratesList', 'push', newRate);
  };

  const removeRateBtnClick = (index) => {
    setDepartmentFormValue(`rates.ratesList.${rates.ratesList.length - 1}`, 'del') //{rates.ratesList.splice(index, 1)}
  }

  const onChangeCalcMethod = (evt) => {
    const typeValue = evt.target.value === 'true' ? 'discount' : 'deduct';
    setDepartmentFormValue('rates.calcMethod', 'set', typeValue);
    if (lastTarget) {
      setLastTarget(undefined);
    }
  };

  const onChangeRateType = (evt) => {
    const typeValue = evt.target.value === 'true' ? 'calculated' : 'custom';
    setDepartmentFormValue('rates.rateType', 'set', typeValue);
    if (lastTarget) {
      setLastTarget(undefined);
    }
  };

  const setRatesValue = (evt, index) => {
    setLastTarget(evt.target.name);
    setCursorPosition(evt.target.selectionStart)
    setDepartmentFormValue(`rates.ratesList.${index}.${evt.target.name}`, 'set', evt.target.value);
  };

  const copyCustomRateBtnClick = (evt, rateIndex, increaseCustomRatesBy) => {
    evt.preventDefault();
    if (lastTarget) {
      setLastTarget(undefined);
    }

    let newCustomRatesList = rates.ratesList[rateIndex][`customRatesList${rateIndex}`].map((customRate, index) => {
      let currentCustomRate = customRate[`customHourlyRate${index}`];
      if (currentCustomRate) {
        return { ...customRate, [`customHourlyRate${index}`]: Number(currentCustomRate) + Number(increaseCustomRatesBy) }
      }
      else {
        return { ...customRate, [`customHourlyRate${index}`]: 0 }
      }
    })

    let newRateIndex = rateIndex + 1;
    let newRate = {
      [`rateName${newRateIndex}`]: '',
      [`isArchived${newRateIndex}`]: false,
      [`cardPayment${newRateIndex}`]: '',
      [`deductPayment${newRateIndex}`]: '',
      [`extraMover${newRateIndex}`]: '',
      [`extraTruck${newRateIndex}`]: '',
      [`cashDiscount${newRateIndex}`]: '',
      [`hourlyRate${newRateIndex}`]: "",
      [`customRatesList${newRateIndex}`]: newCustomRatesList,
    }
    setDepartmentFormValue('rates.ratesList', 'push', newRate);
  }

  return (
    <section>
      <div className={styles.titleContainer}>
        <h3 className={styles.title1}>Rates</h3>
        <div style={{ display: 'flex' }}>
          <RateTypeRadiobuttons
            name={'rateType'}
            isChecked={rates.rateType === 'calculated'}
            onChangeValue={onChangeRateType}
            firstValue={'Calculated rates'}
            secondValue={'Custom rates'}
          />
          <div className={styles.vl}></div>
          <MinHoursLabel
            inputId={'minimumHours'}
            name={'minimumHours'}
            placeholder={'ex. 3'}
            value={minimumHours}
            title={'Minimum hours'}
            changeValue={(evt) => setDepartmentFormValue(`minimumHours`, 'set', evt.target.value)}
            inputType='number'
            required
          />
        </div>
      </div>
      <DepartmentRadiobutons
        title={'Calculation method'}
        style={{ marginLeft: "0" }}
        name={'calcMethod'}
        isChecked={rates.calcMethod === 'discount'}
        onChangeValue={onChangeCalcMethod}
        firstValue={'cash discount % from total'}
        secondValue={'deduct $/hr for cash payment'}
      />
      {
        rates.ratesList.map((rate, index) => {
          return <RateFieldset
            key={nanoid(10)}
            rate={rate}
            rateType={rates.rateType}
            calcMethod={rates.calcMethod}
            index={index}
            copyCustomRateBtnClick={copyCustomRateBtnClick}
            isSameRate={isSameRate}
            isSameTruck={isSameTruck}
            isSameDiscount={isSameDiscount}
            setSameRate={setSameRate}
            setSameTruck={setSameTruck}
            setSameDiscount={setSameDiscount}
            setRatesValue={setRatesValue}
            lastTarget={lastTarget}
            isLastItem={(index === rates.ratesList.length - 1) ? true : false}
            removeRateBtnClick={removeRateBtnClick}
            setDepartmentFormValue={setDepartmentFormValue}
            setLastTarget={setLastTarget}
            cursorPosition={cursorPosition}
            setCursorPosition={setCursorPosition}
          />
        })
      }

      {rates.ratesList.length > 1 &&
        <button
          className={styles.removeRateBtn}
          onClick={(evt) => {
            evt.preventDefault();
            removeRateBtnClick()
          }}
        >
          <Icon name={IconNames.MINUS} />
          Remove rate
        </button>
      }
      <br />
      <button
        className={styles.addRateBtn}
        onClick={
          (evt) => {
            evt.preventDefault();
            addRateBtnClick(rates.ratesList.length, rates.calcMethod, isSameRate, isSameTruck, isSameDiscount);
          }
        }
      >
        <Icon name={IconNames.PLUS} />
        Add rate
      </button>


    </section>
  );
};

DepartmentRates.propTypes = {
  rates: PropTypes.object.isRequired,
  setDepartmentFormValue: PropTypes.func.isRequired,
  lastTarget: PropTypes.string,
  setLastTarget: PropTypes.func.isRequired,
  setCursorPosition: PropTypes.func,
  cursorPosition: PropTypes.number
};

//Third party libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Components
import { CalculationForm } from '../calculation-form/Calculation-form';
import { Loading } from '../loading/Loading';

// Functions for work with store
import {
  getCalculation,
  getCalculationLoadingStatus,
  setCalculationLoadingStatus,
} from '../../clientStore/calculationSlice/calculation-slice';
import { loadCalculation } from '../../clientStore/calculationSlice/calculation-async-thunk';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom';

export const CalculationEdit = () => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const loadingStatus = useSelector(getCalculationLoadingStatus);
  const calculation = useSelector(getCalculation);
  const params = useParams();

  const id = params.calculationId;

  useEffect(() => {
    dispatch(setCalculationLoadingStatus({ status: 'idle' }));
  }, [url]);

  useEffect(() => {
    if (loadingStatus === 'idle') {
      dispatch(loadCalculation(id));
    }
  }, [dispatch, id, loadingStatus, url]);

  return (
    <React.Fragment>
      {loadingStatus !== 'successed' && <Loading />}
      {loadingStatus === 'successed' && <CalculationForm calculationData={calculation} formType={'edit'} calculationId={id} />}
    </React.Fragment>
  );
}
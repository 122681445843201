import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const loadTemplates = createAsyncThunk(
  'templateList/getTemplates',
  async (params) => await fetchedData('template/templates', 'POST',{params})
);

export const getCountOfTemplates = createAsyncThunk(
  'templateList/getCount',
  async () => await fetchedData('template/count', 'GET')
);

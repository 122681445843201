import { useDispatch, useSelector } from 'react-redux';
import {
  cloneToSubCalculation,
} from '../../clientStore/calculationSlice/calculation-async-thunk';
import { useHistory } from 'react-router-dom';

import styles from './BtnCloneToCalcGroup.module.css';
import { getCalculation, getCalculationSubCalcCloningStatus, setCalculationLoadingStatus, setCalculationRemovingStatus, setCalculationSavingStatus, setCalculationSubCalcCloningStatus } from '../../clientStore/calculationSlice/calculation-slice';
import { useEffect } from 'react';
import { setCalculationsLoadingStatus } from '../../clientStore/calculationsSlice/calculations-slice';

export const BtnCloneToCalcGroup = ({ mainCalcId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const subCalcClonningStatus = useSelector(getCalculationSubCalcCloningStatus);
  const savedCalculation = useSelector(getCalculation);

  useEffect(() => {
    if (subCalcClonningStatus === 'successed') {
      dispatch(setCalculationSavingStatus({ status: 'idle' }));
      dispatch(setCalculationLoadingStatus({ status: 'idle' }));
      dispatch(setCalculationsLoadingStatus({ status: 'idle' }));
      dispatch(setCalculationRemovingStatus({ status: 'idle' }));
      dispatch(setCalculationSubCalcCloningStatus({ status: 'idle' }));
      history.replace('/calculations/' + savedCalculation._id);
    }
  }, [dispatch, savedCalculation, subCalcClonningStatus]);

  const handleClick = (evt) => {
    evt.preventDefault();
    dispatch(cloneToSubCalculation(mainCalcId))
    //history.go(0)
  };


  return (
    <button onClick={handleClick} className={styles.btn}>Add group calculation</button>
  );
}
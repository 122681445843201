import { createSlice } from '@reduxjs/toolkit';
import { loadActions, getCountOfActions } from './actions-async-thunk';

const initialState = {
  status: 'idle',
  actions: [],
  count: 0,
  errors: null,
};

const actionListSlice = createSlice({
  name: 'actionList',
  initialState,
  reducers: {
    setActionsLoadingStatus(state, action) {
      const { status } = action.payload;
      state.status = status;
    },
  },
  extraReducers: {
    // getActions extra reducer
    [loadActions.pending]: (state, action) => {
      state.status = 'loading';
    },
    [loadActions.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [loadActions.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.actions = action.payload.data;
    },

    // getCountOfActions extra reducer
    [getCountOfActions.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getCountOfActions.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [getCountOfActions.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.count = action.payload.data;
    },
  },
});

export const { setActionsLoadingStatus } = actionListSlice.actions;

export default actionListSlice.reducer;

export const getProcessActionsLoading = (state) => state.actionList.status;
export const getActions = (state) => state.actionList.actions;
export const getActionsCount = (state) => state.actionList.count;

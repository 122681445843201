// Components
import { Table } from '../table/Table';
import { CalculationTableBody } from '../calculation-table-body/Calculation-table-body';
import { TableHeader } from '../table-header/Table-header';


export const CalculationList = ({ calculations, filters, setFilters, setFilterChanged, isChildList, isCalculationPage }) => {
  const headerTitles = {
    calculationNumber: 'N of calculation',
    departmentId: 'Department',
    thirdColumn: "",
    userId: 'User',
    createdAt: 'Created Date',
  };
  return (
    <Table>
      {!isChildList && <TableHeader headerTitles={headerTitles} filters={filters} setFilters={setFilters} />}
      <CalculationTableBody tableData={calculations} setFilterChanged={setFilterChanged} isCalculationPage={isCalculationPage}/>
    </Table>
  );
};
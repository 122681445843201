// Third party libraries
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
// Local State Functions
import {
  removeDepartment,
} from '../../clientStore/departmentSlice/department-async-thunk';
import {
  getDepartmentRemovingStatus,
  setDepartmentSavingStatus,
  setDepartmentLoadingStatus,
  setDepartmentRemovingStatus,
} from '../../clientStore/departmentSlice/department-slice';
// Styles
import styles from './DepartmentTableBody.module.css';
import deleteIcon from './../../img/delete.png';


export const DepartmentTableBody = ({ tableData }) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const removedStatus = useSelector(getDepartmentRemovingStatus);

  useEffect(() => {
    if (removedStatus === 'successed') {
      dispatch(setDepartmentSavingStatus({ status: 'idle' }));
      dispatch(setDepartmentLoadingStatus({ status: 'idle' }));
      dispatch(setDepartmentRemovingStatus({ status: 'idle' }));
      history.go(0)
    }
  }, [removedStatus, history, dispatch]);

  return (
    <ul className={styles.list}>
      {tableData.map((data) => {
        return (
          <li className={styles.listItem} key={data._id} className={styles.link}>
            <Link to={`${url}/${data._id}`} className={styles.txt}><span >{data.departmentName}</span></Link>
            <Link to={`${url}/${data._id}`} className={styles.txt}> <span className={styles.txt}>{data.authorId.login}</span></Link>
            <span className={styles.txt} onClick={() => dispatch(removeDepartment(data._id))}> <img src={deleteIcon} alt="Delete icon" className={styles.icon} /></span>
            <Link to={`${url}/${data._id}`} className={styles.txt}> <span className={styles.txt}>{new Date(data.createdAt).toDateString()}</span></Link>
            <Link to={`${url}/${data._id}`} className={styles.txt}> <span className={styles.txt}>{new Date(data.updatedAt).toDateString()}</span></Link>
          </li>
        );
      })}
    </ul>
  );
};
// Third party libraries
import PropTypes from 'prop-types';
// Styles
import styles from './LongDistanceSingleMilesRangeInput.module.css';

export const LongDistanceSingleMilesRangeInput = (props) => {
  const {
    values,
    changeValues,
  } = props;

  return (

    <label
      className={styles.inputContainer}
      htmlFor={`rangeStartRD`}
    >
      <span className={styles.eofmiles}>1 bedroom flat rate without packing</span>
      <span className={styles.inputValueType}>$</span>
      <input
        id='amountRD'
        className={styles.doubleInputSecond}
        type='number'
        placeholder='ex 1600'
        name='amountRD'
        value={values.amountRD}
        onChange={(evt) => changeValues(evt)}
        onWheel={(e) => e.target.blur()}
      />
    </label>
  );
};

LongDistanceSingleMilesRangeInput.propTypes = {
  values: PropTypes.object.isRequired,
  changeValues: PropTypes.func.isRequired,
};

export const outofstateDataModel = {
  directionName: '',
  version: 0,
  authorId: '',
  companyId: '',
  calcMethod: 'discount',//discount, deduct
  cashPaymentDiscount: '',
  cashPaymentDeduct: '',
  parameters: [{
    pName0: '',
    pAmount0: '',
  }],
  extraAmounts: [{
    amountName0: '',
    extraAmount0: '',
    type0: 'perJob',//perJob, perTruck
  }],
  driveTimes: [{
    rangeStart0: '',
    rangeEnd0: '',
    days0: '',
  }],
  extraMultNumOfTrucks: true,
  extraPerTruckAmount: '',
};

// Third party libraries
import PropTypes from 'prop-types';
// Components
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentTitle } from '../department-title/Department-title';
import { Icon } from '../Icon';
// Utils
import { IconNames } from '../../util/const';
// Styles
import styles from './ExtraOptions.module.css';
import { useEffect, useState } from 'react';
import { generateRandomString, removeByIndex } from '../../util/utils';

export const ExtraOptions = (props) => {
  const {
    extraOptions,
    changeExtraOptions,
    clearLastTarget,
    companyCharges,
    setCompanyCharges,
  } = props;

  const setHeavyItem = (evt) => {
    changeExtraOptions('extraOptions.heavyItem', 'set', evt.target.value);
  };

  const setShuttle = (evt) => {
    changeExtraOptions(`extraOptions.shuttle.${evt.target.name}`, 'set', evt.target.value);
  };
  const setShuttleCheckPoint = (evt) => {
    changeExtraOptions(`extraOptions.shuttle.${evt.target.name}`, 'set', evt.target.checked);
  }

  const addCharge = (index) => {
    var uId = generateRandomString(10);
    const newCharge = {
      [`id${index}`]: 'id' + index,
      [`chargeName${index}`]: '',
      [`amount${index}`]: '',
      [`isCountable${index}`]: false,
      [`period${index}`]: 'hourly',
      [`perTruck${index}`]: false,
      [`uId${index}`]: uId,
    }
    setCompanyCharges(current => [...current, { ['id' + index]: '', ['uId' + index]: uId }])
    changeExtraOptions(`extraOptions.charges`, 'push', newCharge);
  }


  const removeCharge = () => {
    if (window.confirm('This action will remove charges from all departments in your company. Are you sure?')) {
      let newcompanyCharges = companyCharges.slice(0, -1)
      setCompanyCharges(newcompanyCharges)
      changeExtraOptions(`extraOptions.charges.${extraOptions.charges.length - 1}`, 'del');
    }
  }

  const removeChargeByIndex = (index) => {
    if (window.confirm('This action will remove charges from all departments in your company. Are you sure?')) {
      let newcompanyCharges = removeByIndex([...companyCharges], index)
      let newCharges = removeByIndex([...extraOptions.charges], index)
      setCompanyCharges(newcompanyCharges)
      changeExtraOptions(`extraOptions.charges`, 'set', newCharges);
    }
  }

  const setChargeValue = (evt, index) => {
    changeExtraOptions(`extraOptions.charges.${index}.${evt.target.name}`, 'set', evt.target.value);
  }

  const setChargeCheckPoint = (evt, index) => {
    changeExtraOptions(`extraOptions.charges.${index}.${evt.target.name}`, 'set', evt.target.checked);
  }

  useEffect(() => {
    let newCharges = []
    if (companyCharges) {
      if (!extraOptions.charges.length) {
        companyCharges.map((chargeId, chIdIndex) => {
          const newCharge = {
            [`id${chIdIndex}`]: 'id' + chIdIndex,
            [`chargeName${chIdIndex}`]: chargeId[`id${chIdIndex}`],
            [`amount${chIdIndex}`]: '',
            [`period${chIdIndex}`]: 'hourly',
            [`perTruck${chIdIndex}`]: false,
            [`isCountable${chIdIndex}`]: false,
            [`uId${chIdIndex}`]: chargeId[`uId${chIdIndex}`],
          }
          newCharges.push((newCharge))
        })
      }
      else {
        companyCharges.map((chargeId, chIdIndex) => {
          if (extraOptions.charges[chIdIndex]) {
            const newCharge = {
              [`id${chIdIndex}`]: 'id' + chIdIndex,
              [`chargeName${chIdIndex}`]: extraOptions.charges[chIdIndex][`chargeName${chIdIndex}`] ? extraOptions.charges[chIdIndex][`chargeName${chIdIndex}`] : chargeId[`id${chIdIndex}`],
              [`amount${chIdIndex}`]: extraOptions.charges[chIdIndex][`amount${chIdIndex}`],
              [`period${chIdIndex}`]: extraOptions.charges[chIdIndex][`period${chIdIndex}`],
              [`perTruck${chIdIndex}`]: extraOptions.charges[chIdIndex][`perTruck${chIdIndex}`],
              [`isCountable${chIdIndex}`]: extraOptions.charges[chIdIndex][`isCountable${chIdIndex}`],
              [`uId${chIdIndex}`]: extraOptions.charges[chIdIndex][`uId${chIdIndex}`] ? extraOptions.charges[chIdIndex][`uId${chIdIndex}`] : chargeId[`uId${chIdIndex}`],
            }
            newCharges.push(({ ...newCharge }))
          }
        })
      }

      changeExtraOptions(`extraOptions.charges`, 'set', [...newCharges]);
    }
  }, [companyCharges]);


  return (
    <section className={styles.contaner}
      onFocus={clearLastTarget}>
      <DepartmentTitle
        title={'Extra options'}
      />
      <div className={styles.container}>
        <DepartmentLabel
          inputId={'heavyItem'}
          placeholder={'ex. 160'}
          title={'Heavy item charge'}
          name={'heavyItem'}
          value={extraOptions.heavyItem}
          changeValue={(evt) => setHeavyItem(evt)}
          valueType={'$'}
          inputType='number'
        />
        <hr className={styles.hLine} />
        <div className={styles.shuttleContainer} >
          <DepartmentLabel
            inputId={'shuttle'}
            placeholder={'ex. 20'}
            title={'Shuttle charge'}
            name={'charge'}
            value={extraOptions.shuttle.charge}
            changeValue={(evt) => setShuttle(evt)}
            valueType={'$'}
            inputType='number'
          />
          <label className={styles.optionsContainer}>
            <p className={styles.radiobtnContainer}>
              <input
                style={{ marginLeft: "10px" }}
                type='radio'
                value='hourly'
                name='period'
                checked={extraOptions.shuttle.period === 'hourly'}
                onChange={(evt) => setShuttle(evt)}
              />
              Hourly<br />
              <input
                type='radio'
                value='daily'
                name='period'
                checked={extraOptions.shuttle.period === 'daily'}
                onChange={(evt) => setShuttle(evt)}
              />
              Daily
              <br />
              <input
                type='checkbox'
                name='perTruck'
                checked={extraOptions.shuttle.perTruck}
                onChange={(evt) => setShuttleCheckPoint(evt)}
                defaultValue={false}
              />
              Per truck
              <br />
              <input
                type='checkbox'
                name='isCountable'
                checked={extraOptions.shuttle.isCountable}
                onChange={(evt) => setShuttleCheckPoint(evt)}
                defaultValue={false}
              />
              Quantity
            </p>
          </label>
        </div>
        <hr className={styles.shutLine} />
      </div>
      {
        companyCharges && companyCharges.map((chargeId, chIdIndex) => {
          const charge = extraOptions.charges[chIdIndex]
          return (
            <div className={styles.container} key={chIdIndex + "eo"}>
              <div className={styles.shuttleContainer}>
                <label
                  className={styles.inputContainer}
                  htmlFor={`charge_${chIdIndex + 1}_name`}
                >
                  <p className={styles.inputName}>Charge name</p>
                  <input
                    id={`charge_${chIdIndex + 1}_name`}
                    className={styles.input}
                    type='text'
                    placeholder="Name of the charge"
                    name={`chargeName${chIdIndex}`}
                    value={(charge) ? charge[`chargeName${chIdIndex}`] : ""}
                    onChange={(evt) => setChargeValue(evt, chIdIndex)}
                    required
                  />

                </label>
                <label
                  className={styles.inputContainerSecond}
                  htmlFor={`charge_${chIdIndex + 1}_amount`}
                >
                  <p className={styles.inputNameSecond}>amount</p>
                  <span className={styles.inputValueType}>$</span>
                  <input
                    id={`charge_${chIdIndex + 1}_amount`}
                    className={styles.inputSecond}
                    type='number'
                    onWheel={(e) => e.target.blur()}
                    placeholder={"ex 160"}
                    name={`amount${chIdIndex}`}
                    value={(charge) ? charge[`amount${chIdIndex}`] : ""}
                    onChange={(evt) => setChargeValue(evt, chIdIndex)}
                    required
                  />
                </label>
                <label className={styles.optionsContainer}>
                  <p className={styles.radiobtnContainer}>
                    <input
                      type='radio'
                      value='hourly'
                      name={`period${chIdIndex}`}
                      checked={(charge) ? charge[`period${chIdIndex}`] === 'hourly' : true}
                      onChange={(evt) => setChargeValue(evt, chIdIndex)}
                    />
                    Hourly<br />
                    <input
                      type='radio'
                      value='daily'
                      name={`period${chIdIndex}`}
                      checked={(charge) ? charge[`period${chIdIndex}`] === 'daily' : false}
                      onChange={(evt) => setChargeValue(evt, chIdIndex)}
                    />
                    Daily
                    <br />
                    <input
                      type='checkbox'
                      name={`perTruck${chIdIndex}`}
                      checked={(charge) ? charge[`perTruck${chIdIndex}`] : false}
                      onChange={(evt) => setChargeCheckPoint(evt, chIdIndex)}
                      defaultValue={false}
                    />
                    Per truck
                    <br />
                    <input
                      type='checkbox'
                      name={`isCountable${chIdIndex}`}
                      checked={(charge) ? charge[`isCountable${chIdIndex}`] : false}
                      onChange={(evt) => setChargeCheckPoint(evt, chIdIndex)}
                      defaultValue={false}
                    />
                    Quantity
                    <button
                      onClick={
                        (evt) => {
                          evt.preventDefault();
                          removeChargeByIndex(chIdIndex);
                        }
                      }
                      className={styles.removeExtraBtnList}><Icon name={IconNames.MINUS} /></button>
                  </p>
                </label>
              </div>
            </div>
          )

        })
      }

      <div className={styles.buttonContainer}>
        <button
          className={styles.extraBtn}
          onClick={
            (evt) => {
              evt.preventDefault();
              addCharge(extraOptions.charges.length);
            }
          }
        >
          Add charge
          <Icon name={IconNames.PLUS} />
        </button>
        {/*      <button
          className={styles.removeExtraBtn}
          onClick={
            (evt) => {
              evt.preventDefault();
              removeCharge();
            }
          }
        >
          Remove charge
          <Icon name={IconNames.MINUS} />
        </button> */}
      </div>
    </section>
  );
}

ExtraOptions.propTypes = {
  extraOptions: PropTypes.object.isRequired,
  changeExtraOptions: PropTypes.func.isRequired,
  clearLastTarget: PropTypes.func.isRequired,
  companyCharges: PropTypes.array,
}

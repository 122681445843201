// Third party libraries
import React from 'react';
import { nanoid } from 'nanoid';
// Components
import { CalculationCommonLabel } from '../calculation-common-label/Calculation-common-label';
// Util
import { parseName } from '../../util/utils';
// Styles
import styles from './CalculationProcedure.module.css';

export const CalculationProcedure = ({ procedure, groupInputChange, lastTarget, index, renderProceduresOptions }) => {
  /*  const extraKey = Object.keys(extraStop); */

  return (
    <fieldset className={styles.container}>
      <legend className={styles.title}>Procedure #{index + 1}</legend>
      <div>
        <label className={styles.commonLabel}>
          <span>Type</span>
          <select
            className={styles.commonInput}
            name={`procedureType`}
            value={procedure[`procedureType`]}
            onChange={(evt) => groupInputChange(evt, index)}
          >
            <option value={undefined}>None</option>
            {renderProceduresOptions()}
          </select>
        </label>

        <label className={styles.commonLabel}>
          <span>Number of procedures</span>
          <input
            type='number'
            step="1"
            onWheel={(e) => e.target.blur()}
            name={`numberOfProcedures`}
            className={styles.commonInput}
            value={procedure[`numberOfProcedures`]}
            onChange={(evt) => groupInputChange(evt, index)}
            autoFocus={(`numberOfProcedures`+index)===lastTarget}
          />
        </label>
      </div>
    </fieldset>
  );
}

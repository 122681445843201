export const longdistanceDataModel = {
  directionName: '',
  version: 0,
  authorId: '',
  companyId: '',
  directionType: 'expences',//expences, roomDistance
  calcMethod: 'discount',//discount, deduct
  cashPaymentDiscount: '',
  cashPaymentDeduct: '',
  rangeStartRD: '',
  rangeEndRD: '',
  amountRD: '',
  flatRateRD: '',
  packingRD: '',
  extraPackingRD: '',
  extraTruckDriverRD: '',
  extraAmountsRD: [{
    amountNameRD0: '',
    extraAmountRD0: '',
    typeRD0: 'perJob',//perJob, perTruck
  }],
  arrivalTimeDaysRD: '',
  driveTimesRD: [{
    rangeStartRD0: '',
    rangeEndRD0: '',
    milesRD0: '',
  }],
  salaryExpenses: {
    foreman: '',
    helperAtLoading: '',
    helperAtDriving: '',
    helperAtUnloading: '',
  },
  fuelExpenses: {
    fuelPerGallon: '',
    milesPerGallon: '',
    wrappingMaterials: '',
    oosLogBook: '',
    hotel: '',
    extraAmounts: [{
      amountName0: '',
      extraAmount0: '',
      type0: 'perJob',//perJob, perTruck
    }],
  },
  driveTimes: [{
    rangeStart0: '',
    rangeEnd0: '',
    hours0: '',
  }],
  roundToFull: true,
};

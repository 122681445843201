// Third party libraries
import { useState } from 'react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
// Components
import { DepartmentRadiobuttons } from '../department-radiobuttons/Department-radiobuttons';
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentLabelsec } from '../department-labelsec/Department-labelsec';
import { DepartmentHourlyRates } from '../department-hourly-rates/Department-hourly-rates';
import { Icon } from '../Icon';
import { IconNames } from '../../util/const';
// Styles
import styles from './RateFieldset.module.css';
import { NumberInputEC } from '../number-input-ec/Number-input-ec';

export const RateFieldset = (props) => {
  const {
    rate,
    calcMethod,
    rateType,
    index,
    isSameRate,
    isSameTruck,
    isSameDiscount,
    setSameRate,
    setSameTruck,
    setSameDiscount,
    setRatesValue,
    lastTarget,
    setDepartmentFormValue,
    setLastTarget,
    copyCustomRateBtnClick,
    cursorPosition,
    setCursorPosition,
  } = props;

  const onChangeSameRate = (evt) => {
    switch (evt.target.value) {
      case 'true':
        setSameRate(true);
        break;
      case 'false':
        setSameRate(false);
        break;
      default:
        break
    }
  };

  const onChangeSameTruck = (evt) => {
    switch (evt.target.value) {
      case 'true':
        setSameTruck(true);
        break;
      case 'false':
        setSameTruck(false);
        break;
      default:
        break
    }
  };

  const onChangeSameDiscount = (evt) => {
    switch (evt.target.value) {
      case 'true':
        setSameDiscount(true);
        break;
      case 'false':
        setSameDiscount(false);
        break;
      default:
        break
    }
  };

  const onIsArchivedChange = (evt, index) => {
    setRatesValue({ target: { value: evt.target.checked, name: evt.target.name } }, index)
  }

  const [increaseCustomRatesBy, setIncreaseRates] = useState(0);


  return (
    <fieldset
      key={nanoid(10)}
      className={styles.rateGroup}
    >
      <legend className={styles.rateName}>Rate #{index + 1}</legend>
      <div className={styles.flexDiv}>
        <DepartmentLabelsec
          inputId={`rate_${index + 1}_name`}
          index={index}
          name={`rateName${index}`}
          title={'Name'}
          placeholder={'Name of the rate'}
          value={rate[`rateName${index}`]}
          changeValue={(evt) => {
            setRatesValue(evt, index)
          }}
          isFocused={lastTarget === `rateName${index}`}
          cursorPosition={cursorPosition}
        />
        <div>
          <input type='checkbox' checked={rate[`isArchived${index}`]} name={`isArchived${index}`} onChange={(evt) => onIsArchivedChange(evt, index)} /> Archive rate
        </div>
      </div>
      <div>
        <section className={styles.rateSection}>
          {(rateType === "calculated") ?
            (calcMethod === 'discount') ?
              <DepartmentLabel
                inputId={`${index + 1}_hourly_rate`}
                name={`hourlyRate${index}`}
                title={'Hourly rate for 2 movers'}
                placeholder={'ex. 99'}
                value={rate[`hourlyRate${index}`]}
                changeValue={(evt) => setRatesValue(evt, index)}
                valueType={'$'}
                isFocused={lastTarget === `hourlyRate${index}`}
                /* inputType='number' */
                pattern="[0-9]*"
                cursorPosition={cursorPosition}
              />
              :
              <DepartmentLabel
                inputId={`${index + 1}_hourly_rate`}
                name={`hourlyRate${index}`}
                title={'Hourly rate for 2 movers CARD'}
                placeholder={'ex. 99'}
                value={rate[`hourlyRate${index}`]}
                changeValue={(evt) => setRatesValue(evt, index)}
                valueType={'$'}
                isFocused={lastTarget === `hourlyRate${index}`}
                pattern="[0-9]*"
                cursorPosition={cursorPosition}
              /*        inputType='number' */
              />
            :
            <>
              <DepartmentHourlyRates
                customRatesList={rate[`customRatesList${index}`]}
                changeValue={(evt) => setRatesValue(evt, index)}
                index={index}
                setDepartmentFormValue={setDepartmentFormValue}
                setLastTarget={setLastTarget}
                lastTarget={lastTarget}
                cursorPosition={cursorPosition}
                setCursorPosition={setCursorPosition}
              />

              <label
                className={styles.inputContainer}
              >
                <button onClick={evt => copyCustomRateBtnClick(evt, index, increaseCustomRatesBy)} className={styles.andincButton}>Copy rate and increase custom rates by</button>
                <input
                  type='number'
                  onWheel={(e) => e.target.blur()}
                  className={styles.input}
                  onChange={evt => {
                    setLastTarget(evt.target.name)
                    setIncreaseRates(evt.target.value)
                  }}
                  value={increaseCustomRatesBy}
                  name={`increaseCustomRatesBy${index}`}
                  autoFocus={lastTarget === `increaseCustomRatesBy${index}`}

                />
              </label>
            </>

          }
        </section>
        <section className={styles.rateSection}>
          <DepartmentLabel
            inputId={`${index + 1}_extra_mover`}
            index={index}
            name={`extraMover${index}`}
            title={'Hourly rate per extra mover'}
            placeholder={'ex. 40'}
            value={rate[`extraMover${index}`]}
            changeValue={(evt) => setRatesValue(evt, index)}
            valueType={'$'}
            isFocused={lastTarget === `extraMover${index}`}
            pattern="[0-9]*"
            cursorPosition={cursorPosition}
          />
          {
            index === 0 &&
            <DepartmentRadiobuttons
              title='Same for all the rates bellow'
              name='samePaymentForAll'
              isChecked={isSameRate}
              onChangeValue={onChangeSameRate}
              firstValue='Yes'
              secondValue='No'
            />
          }

        </section>
        <section className={styles.rateSection}>
          <DepartmentLabel
            inputId={`${index + 1}_extra_truck`}
            index={index}
            name={`extraTruck${index}`}
            title={'Hourly rate per extra truck'}
            placeholder={'ex. 50'}
            value={rate[`extraTruck${index}`]}
            changeValue={(evt) => setRatesValue(evt, index)}
            valueType={'$'}
            isFocused={lastTarget === `extraTruck${index}`}
            pattern="[0-9]*"
            cursorPosition={cursorPosition}
          />

          {
            index === 0 &&
            <DepartmentRadiobuttons
              title='Same for all the rates bellow'
              name='sameTruckForAll'
              isChecked={isSameTruck}
              onChangeValue={onChangeSameTruck}
              firstValue='Yes'
              secondValue='No'
            />
          }
          {
            index !== 0 &&
            <div className={styles.inputContainer}></div>
          }
        </section>
        <section className={styles.rateSection}>

          {
            calcMethod === 'discount' &&
            <DepartmentLabel
              inputId={`${index + 1}_cash_discount`}
              index={index}
              name={`cashDiscount${index}`}
              title={'Discount for CASH PAYMENT (%)'}
              placeholder={'ex. 10'}
              value={rate[`cashDiscount${index}`]}
              changeValue={(evt) => setRatesValue(evt, index)}
              isFocused={lastTarget === `cashDiscount${index}`}
              pattern="[0-9]*"
              cursorPosition={cursorPosition}
            />
          }
          {
            calcMethod === 'deduct' &&
            <DepartmentLabel
              inputId={`${index + 1}_card_payment`}
              index={index}
              name={`deductPayment${index}`}
              title={'Deduct hourly amount for CASH'}
              placeholder={'ex. 10'}
              value={rate[`deductPayment${index}`]}
              changeValue={(evt) => setRatesValue(evt, index)}
              valueType={'$'}
              isFocused={lastTarget === `deductPayment${index}`}
              pattern="[0-9]*"
              cursorPosition={cursorPosition}
            />
          }

          {
            index === 0 &&
            <DepartmentRadiobuttons
              title='Same for all the rates bellow'
              name='sameDiscountForAll'
              isChecked={isSameDiscount}
              onChangeValue={onChangeSameDiscount}
              firstValue='Yes'
              secondValue='No'
            />
          }
          {
            index !== 0 &&
            <div className={styles.inputContainer}></div>
          }
        </section>
        <section className={styles.rateSection}>
          <DepartmentLabel
            inputId={`${index + 1}_long_distance_extra`}
            index={index}
            name={`longDistanceExtra${index}`}
            title={'Long distance extra'}
            placeholder={'ex. 50'}
            value={rate[`longDistanceExtra${index}`]}
            changeValue={(evt) => setRatesValue(evt, index)}
            valueType={'$'}
            isFocused={lastTarget === `longDistanceExtra${index}`}
            pattern="[0-9]*"
            cursorPosition={cursorPosition}
          />
          <label className={styles.optionsContainer}>
            <p className={styles.radiobtnContainer}>
              <input
                style={{ marginLeft: "35px" }}
                type='radio'
                value='perJob'
                name={`longDistanceExtraType${index}`}
                checked={rate[`longDistanceExtraType${index}`] === 'perJob'}
                onChange={(evt) => setRatesValue(evt, index)}
              />
              Per Job
              <input
                type='radio'
                value='perTruck'
                name={`longDistanceExtraType${index}`}
                checked={rate[`longDistanceExtraType${index}`] === 'perTruck'}
                onChange={(evt) => setRatesValue(evt, index)}
              />
              Per Truck
            </p>
          </label>
        </section>
      </div>
    </fieldset>
  );
};

RateFieldset.propTypes = {
  rate: PropTypes.object.isRequired,
  calcMethod: PropTypes.string.isRequired,
  rateType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isSameRate: PropTypes.bool.isRequired,
  isSameTruck: PropTypes.bool.isRequired,
  setSameRate: PropTypes.func.isRequired,
  setSameTruck: PropTypes.func.isRequired,
  setRatesValue: PropTypes.func.isRequired,
  lastTarget: PropTypes.string,
  setDepartmentFormValue: PropTypes.func.isRequired,
  setLastTarget: PropTypes.func.isRequired,
  copyCustomRateBtnClick: PropTypes.func.isRequired,
  setCursorPosition: PropTypes.func.isRequired,
};

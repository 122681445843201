// Components
import { Table } from '../table/Table';
import { UserTableBody } from '../user-table-body/User-table-body';
import { TableHeader } from '../table-header/Table-header';

export const UsersList = ({ users, setFilters, filters }) => {
  const headerTitles = {
    firstColumn: '№',
    login: 'Name',
    role: 'Role',
    createdAt: 'Created Date',
    companyId: 'Company',
    disabled: 'Disabled'
  };

  return (
    <Table>
      <TableHeader headerTitles={headerTitles} setFilters={setFilters} filters={filters} />
      <UserTableBody tableData={users} />
    </Table>
  );
};
export const departmentDataModel = {
  departmentName: '',
  version: 0,
  authorId: '',
  companyId: '',
  type: 'hourly',//'hourly', 'cuft'
  minimumHours: 0,
  minimumCuFt: 0,
  rates: {
    rateType: 'calculated',//calculated, custom
    calcMethod: 'discount',//discount, deduct, 
    ratesList: [
      {
        rateName0: '',
        isArchived0: false,
        hourlyRate0: '',
        cashDiscount0: '',
        deductPayment0: '',
        extraMover0: '',
        extraTruck0: '',
        longDistanceExtra0: '',
        longDistanceExtraType0: 'perJob',//perJob, perTruck
        deductAmount0: '',
        extraAmount0: '',
        extraAmountType0: 'perJob',//perJob, perTruck
        pricesPerCuFt0: [{
          cubicFtRange0: '',
          operator0: '===',// '===', '<', '>' '<=', '>='
          pricePerCuFt0: '',
        }
        ],
        customRatesList0: [
          {
            customMovers0: '2',
            customTrucks0: '1',
            customHourlyRate0: '',
          },
        ],
      }
    ],
  },

  additionalServices: {
    placeTypes: [{ placeTypeName: '' }],
    services:
      [{
        serviceName: '',
        serviceType: 'cuft',// cuft, factor
        pricesPerCuFt: [{
          cubicFtRange0: '',
          factorName0: '',
          operator0: '===',// '===', '<', '>' '<=', '>='
          pricePerCuFt0: '',
        }],
      }
      ],
  },
  truckFee: {
    calcMethod: '',//none, simple, different
    truckReservation: '',
    truckDestionation: {
      fee: '',
      miles: '',
    },
    tfdfCombined: false,
    diffTruckReservations: [{
      fee: '',
      miles: '',
    }]
  },
  doubleDrive: {
    apply: false,
    miles: '',
    chargePerMile: '',
    serviceMiles: '',
    isActiveChargePerMile: false,

  },
  extraOptions: {
    heavyItem: '',
    shuttle: {
      charge: '',
      period: 'hourly',//daily
      perTruck: false,
      isCountable: false,
    },
    charges: [
      /*       {
              id0: 'id0',
              chargeName0: '',
              amount0: '',
              isCountable0: false,
              period0: 'hourly',//daily
              perTruck0: false,
            } */
    ]
  },
  miscCheckMarks: {
    miscCheckMarks: [
      /*  {
         id0: 'id0',
         checkMarkName0: '',
        } */
    ]
  },
  packingCharge: {
    fullPacking: '',
    kitchenPacking: '',
    partialPacking: '',
  },
  unPackingCharge: {
    fullUnPacking: '',
    kitchenUnPacking: '',
    partialUnPacking: '',
  },
  packing: {
    packingKits: {
      enabled: false,
      firstBedroom: '',
      firstCuFt: '',
      secondBedroom: '',
      secondCuFt: '',
      thirdBedroom: '',
      thirdCuFt: '',
      fourthBedroom: '',
      fourthCuFt: '',
      fifthBedroom: '',
      fifthCuFt: '',
      sixthBedroom: '',
      sixthCuFt: '',
      seventhBedroom: '',
      seventhCuFt: '',
      eighthBedroom: '',
      eighthCuFt: '',
      ninethBedroom: '',
      ninethCuFt: '',
      tenthBedroom: '',
      tenthCuFt: '',
      kitchen: '',
      kitchenCuFt: '',
      salesTax: '',
    },
    supplies: {
      smallBox: '',
      mediumBox: '',
      largeBox: '',
      packingPapper: '',
      bubbleWrap: '',
      calculationMethod: 'suppliesPrices',// suppliesPrices, perBox
      perOnePackedBox: '',
    },
    cratings: [/* {
      id0: 'id0',
      crateName0: '',
      crateCost0: '',
      timeToExecuteCrate0: '', 
    }*/],
  },

};

// Third party libraries
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
// Components
import { PackingLabel } from '../packing-label/Packing-label';
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentLabelsec } from '../department-labelsec/Department-labelsec';
import { CrateLabel } from '../crate-label/Crate-label';
import { DepartmentTitle } from "../department-title/Department-title";
import { SectionTitle } from '../section-title/Section-title';
import { Icon } from '../Icon';
// Utils
import { IconNames } from '../../util/const';
// Styles
import styles from './PackingCuFt.module.css';
import { useEffect } from 'react';
import { PacSupLabel } from '../pacsup-label/PacSup-label';
import { PacSuppLabel } from '../pacsupp-label/PacSupp-label';
import { generateRandomString, removeByIndex } from '../../util/utils';
import { DepartmentRadiobutons } from '../department-radiobutons/Department-radiobutons';


export const PackingCuFt = (props) => {
  const {
    packing,
    packingCharge,
    unPackingCharge,
    changePackingValue,
    setLastTarget,
    lastTarget,
    clearLastTarget,
    setCursorPosition,
    cursorPosition,
    setCompanyProcedures,
    companyProcedures
  } = props;

  const addCrating = (index) => {
    var uId = generateRandomString(10);
    const newCrate = {
      [`id${index}`]: 'id' + index,
      [`crateName${index}`]: '',
      [`crateCost${index}`]: '',
      [`timeToExecuteCrate${index}`]: '',
      [`uId${index}`]: uId,
    }
    setCompanyProcedures(current => [...current, { ['id' + index]: '', ['uId' + index]: uId }])
    changePackingValue(`packing.cratings`, 'push', newCrate);
  }

  const removeCrating = () => {
    if (window.confirm('This action will remove crates/procedures from all departments in your company. Are you sure?')) {
      let companyProceduresNewIds = companyProcedures.slice(0, -1)
      setCompanyProcedures(companyProceduresNewIds);
      changePackingValue(`packing.cratings.${packing.cratings.length - 1}`, 'del');
    }
  }

  const removeCratingByIndex = (index) => {
    if (window.confirm('This action will remove crates/procedures from all departments in your company. Are you sure?')) {
      let companyProceduresNewIds = removeByIndex([...companyProcedures], index)
      let newProcedures = removeByIndex([...packing.cratings], index)
      setCompanyProcedures(companyProceduresNewIds);
      changePackingValue(`packing.cratings`, 'set', newProcedures);
    }
  }


  useEffect(() => {
    let newCharges = []
    if (companyProcedures) {
      if (!packing.cratings.length) {
        companyProcedures.map((crateId, crateIndex) => {
          const newCrate = {
            [`id${crateIndex}`]: 'id' + crateIndex,
            [`crateName${crateIndex}`]: crateId[`id${crateIndex}`],
            [`crateCost${crateIndex}`]: '',
            [`timeToExecuteCrate${crateIndex}`]: '',
            [`uId${crateIndex}`]: crateId[`uId${crateIndex}`],
          }
          newCharges.push(newCrate)
        })
      }
      else {
        companyProcedures.map((crateId, crateIndex) => {
          if (packing.cratings[crateIndex]) {
            const newCharge = {
              [`id${crateIndex}`]: 'id' + crateIndex,
              [`crateName${crateIndex}`]: packing.cratings[crateIndex][`crateName${crateIndex}`] ? packing.cratings[crateIndex][`crateName${crateIndex}`] : crateId[`id${crateIndex}`],
              [`crateCost${crateIndex}`]: packing.cratings[crateIndex][`crateCost${crateIndex}`],
              [`timeToExecuteCrate${crateIndex}`]: packing.cratings[crateIndex][`timeToExecuteCrate${crateIndex}`],
              [`uId${crateIndex}`]: packing.cratings[crateIndex][`uId${crateIndex}`] ? packing.cratings[crateIndex][`uId${crateIndex}`] : crateId[`uId${crateIndex}`],
            }
            newCharges.push((newCharge))
          }
        })
      }
      changePackingValue(`packing.cratings`, 'set', [...newCharges]);
    }
  }, [companyProcedures]);

  return (
    <section>
      <div className={styles.packingContainer}>
        <DepartmentTitle
          title={' Additional procedures & Crating '}
        />
        {
          companyProcedures && companyProcedures.map((procedureId, prIdIndex) => {
            const crating = packing.cratings[prIdIndex]
            return (
              <div className={styles.distanceContainer} key={nanoid(5)}>
                <CrateLabel
                  inputId={`crateName${prIdIndex}`}
                  name={`crateName${prIdIndex}`}
                  title={'Procedure name'}
                  placeholder={'Name'}
                  value={(crating) ? crating[`crateName${prIdIndex}`] : ""}
                  isFocused={lastTarget === `crateName${prIdIndex}`}
                  changeValue={(evt) => {
                    setLastTarget(evt.target.name);
                    setCursorPosition(evt.target.selectionStart)
                    changePackingValue(`packing.cratings.${prIdIndex}.${evt.target.name}`, 'set', evt.target.value)
                  }
                  }
                  cursorPosition={cursorPosition}
                />
                <CrateLabel
                  inputId={`crateCost${prIdIndex}`}
                  name={`crateCost${prIdIndex}`}
                  title={'Cost'}
                  placeholder={'ex: 33'}
                  value={(crating) ? crating[`crateCost${prIdIndex}`] : ""}
                  isFocused={lastTarget === `crateCost${prIdIndex}`}
                  changeValue={(evt) => {
                    setLastTarget(evt.target.name);
                    setCursorPosition(evt.target.selectionStart)
                    changePackingValue(`packing.cratings.${prIdIndex}.${evt.target.name}`, 'set', evt.target.value)
                  }
                  }
                  cursorPosition={cursorPosition}
                  valueType={'$'}
                  pattern="[0-9]*"
                />
                <CrateLabel
                  style={{ marginRight: "0" }}
                  inputId={`timeToExecuteCrate${prIdIndex}`}
                  name={`timeToExecuteCrate${prIdIndex}`}
                  title={'Time to execute for 2 movers'}
                  placeholder={'ex: 3'}
                  value={(crating) ? crating[`timeToExecuteCrate${prIdIndex}`] : ""}
                  isFocused={lastTarget === `timeToExecuteCrate${prIdIndex}`}
                  changeValue={(evt) => {
                    setLastTarget(evt.target.name);
                    setCursorPosition(evt.target.selectionStart)
                    changePackingValue(`packing.cratings.${prIdIndex}.${evt.target.name}`, 'set', evt.target.value)
                  }}
                  valueType={'hrs'}
                  pattern="[0-9]*"
                  cursorPosition={cursorPosition}
                />
                <button
                  className={styles.removeExtraBtnList}
                  onClick={
                    (evt) => {
                      evt.preventDefault();
                      removeCratingByIndex(prIdIndex);
                    }
                  }
                >
                  <Icon name={IconNames.MINUS} />
                </button>
              </div>
            )
          })}

        <div className={styles.buttonContainer} style={{ left: "10px", position: "relative" }}>
          <button
            className={styles.extraBtn}
            onClick={
              (evt) => {
                evt.preventDefault();
                addCrating(packing.cratings.length);
              }
            }
          >
            Add procedure
            <Icon name={IconNames.PLUS} />
          </button>
        </div>
      </div>



      <DepartmentTitle title={'Packing charge'} />
      <div style={{ display: 'flex' }}>
        <div>
          <div style={{ display: 'flex' }}>
            <DepartmentLabel
              inputId={'fullPacking'}
              name={'fullPacking'}
              valueType={'$'}
              placeholder={'ex. 39'}
              value={packingCharge.fullPacking}
              title={'Full packing'}
              changeValue={(evt) => changePackingValue(`packingCharge.${evt.target.name}`, 'set', evt.target.value)}
              inputType='number'
              required
            />/cu ft
          </div>
          <div style={{ display: 'flex' }}>
            <DepartmentLabel
              inputId={'kitchenPacking'}
              name={'kitchenPacking'}
              valueType={'$'}
              placeholder={'ex. 39'}
              value={packingCharge.kitchenPacking}
              title={'Kitchen packing'}
              changeValue={(evt) => changePackingValue(`packingCharge.${evt.target.name}`, 'set', evt.target.value)}
              inputType='number'
              required
            />/cu ft
          </div>
          <div style={{ display: 'flex' }}>
            <DepartmentLabel
              inputId={'partialPacking'}
              name={'partialPacking'}
              valueType={'$'}
              placeholder={'ex. 39'}
              value={packingCharge.partialPacking}
              title={'Partial packing'}
              changeValue={(evt) => changePackingValue(`packingCharge.${evt.target.name}`, 'set', evt.target.value)}
              inputType='number'
              required
            />/cu ft
          </div>
        </div>
      </div>
      <br />
      <br />

      {/* check unpacking same day */}
      <DepartmentTitle title={'UnPacking charge'} />
      <div style={{ display: 'flex' }}>
        <div>



          <div style={{ display: 'flex' }}>
            <DepartmentLabel
              inputId={'fullUnPacking'}
              name={'fullUnPacking'}
              valueType={'$'}
              placeholder={'ex. 39'}
              value={unPackingCharge.fullUnPacking}
              title={'Full unpacking'}
              changeValue={(evt) => changePackingValue(`unPackingCharge.${evt.target.name}`, 'set', evt.target.value)}
              inputType='number'
              required
            />/cu ft
          </div>
          <div style={{ display: 'flex' }}>
            <DepartmentLabel
              inputId={'kitchenUnPacking'}
              name={'kitchenUnPacking'}
              valueType={'$'}
              placeholder={'ex. 39'}
              value={unPackingCharge.kitchenUnPacking}
              title={'Kitchen unpacking'}
              changeValue={(evt) => changePackingValue(`unPackingCharge.${evt.target.name}`, 'set', evt.target.value)}
              inputType='number'
              required
            />/cu ft
          </div>
          <div style={{ display: 'flex' }}>
            <DepartmentLabel
              inputId={'partialUnPacking'}
              name={'partialUnPacking'}
              valueType={'$'}
              placeholder={'ex. 39'}
              value={unPackingCharge.partialUnPacking}
              title={'Partial unpacking '}
              changeValue={(evt) => changePackingValue(`unPackingCharge.${evt.target.name}`, 'set', evt.target.value)}
              inputType='number'
              required
            />/cu ft
          </div>



        </div>
      </div>

      <div style={{ display: "flex" }}>

        <DepartmentTitle title={'On-the-go packing'} />
        <div style={{ marginTop: "15px" }}>
          <DepartmentRadiobutons
            /* title={'Calculation method'} */
            style={{ marginLeft: "0" }}
            name={'enabled'}
            isChecked={packing.packingKits.enabled}
            onChangeValue={(evt) => changePackingValue(`packing.packingKits.enabled`, 'set', evt.target.value === 'true')}
            firstValue={'Yes'}
            secondValue={'No'}
          />
        </div>
      </div>

      <div style={{ marginBottom: '10px' }}>
        <label
          htmlFor='calculationMethod'
          className={styles.fieldName}
        >
          Calculation method
        </label>
        <select
          id='calculationMethod'
          className={styles.feeTypeSelect}
          name='calculationMethod'
          value={packing.supplies.calculationMethod}
          onChange={(evt) => changePackingValue(`packing.supplies.calculationMethod`, 'set', evt.target.value)}
        >
          <option
            value='suppliesPrices'
          >Supplies prices</option>
          <option
            value='perBox'
          >
            $ Per Box
          </option>
        </select>
      </div>

      {/* Supplies prices */}
      {packing.supplies.calculationMethod === 'suppliesPrices' && <>
        <div className={styles.packingContainersecond} style={{ marginBottom: "15px" }} onFocus={clearLastTarget}>
          <PacSupLabel
            inputId={'smallBox'}
            name={'smallBox'}
            title={'Small Box'}
            placeholder={'ex: 3'}
            value={packing.supplies.smallBox}
            changeValue={(evt) => changePackingValue(`packing.supplies.smallBox`, 'set', evt.target.value)}
            valueType={'$'}
            inputType='number'
          />
          <PacSupLabel
            inputId={'mediumBox'}
            name={'mediumBox'}
            title={'Medium Box'}
            placeholder={'ex: 4'}
            value={packing.supplies.mediumBox}
            changeValue={(evt) => changePackingValue(`packing.supplies.mediumBox`, 'set', evt.target.value)}
            valueType={'$'}
            inputType='number'
          />
          <PacSupLabel
            inputId={'largeBox'}
            name={'largeBox'}
            title={'Large Box'}
            placeholder={'ex: 6'}
            value={packing.supplies.largeBox}
            changeValue={(evt) => changePackingValue(`packing.supplies.largeBox`, 'set', evt.target.value)}
            valueType={'$'}
            inputType='number'
          />
        </div>
        <div className={styles.packingContainersecond} onFocus={clearLastTarget}>
          <PacSuppLabel
            className={styles.inputcontanr}
            inputId={'bubbleWrap'}
            name={'bubbleWrap'}
            title={'Roll of Bubble wrap'}
            placeholder={'ex: 20'}
            value={packing.supplies.bubbleWrap}
            changeValue={(evt) => changePackingValue(`packing.supplies.bubbleWrap`, 'set', evt.target.value)}
            valueType={'$'}
            inputType='number'
          />
          <PacSuppLabel
            inputId={'packingPapper'}
            name={'packingPapper'}
            title={'Roll of Packing papper'}
            placeholder={'ex: 20'}
            value={packing.supplies.packingPapper}
            changeValue={(evt) => changePackingValue(`packing.supplies.packingPapper`, 'set', evt.target.value)}
            valueType={'$'}
            inputType='number'
          />
        </div>
        {/* EOF  Supplies prices*/}
      </>}
      {/*    $ Per Box */}
      {packing.supplies.calculationMethod === 'perBox' && <>
        <div className={styles.packingContainersecond} style={{ marginBottom: "15px" }} onFocus={clearLastTarget}>
          <PacSupLabel
            inputId={'perOnePackedBox'}
            name={'perOnePackedBox'}
            title={'Per one packed box'}
            placeholder={'ex: 3'}
            value={packing.supplies.perOnePackedBox}
            changeValue={(evt) => changePackingValue(`packing.supplies.perOnePackedBox`, 'set', evt.target.value)}
            valueType={'$'}
            inputType='number'
          />
        </div>
      </>}
      {/* EOF    $ Per Box */}


    </section >
  );
}

PackingCuFt.propTypes = {
  packing: PropTypes.object.isRequired,
  changePackingValue: PropTypes.func.isRequired,
  lastTarget: PropTypes.string,
  setLastTarget: PropTypes.func.isRequired,
  clearLastTarget: PropTypes.func.isRequired,
}

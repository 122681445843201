import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

const initialState = {
  TWO_Trips_Or_OK: '',
  TWO_Trips_Or_OK_Day2: '',
  Total_small_boxes: 0,
  Total_medium_boxes: 0,
  Total_fragile_boxes: 0,
};

const calculatedParamsSlice = createSlice({
  name: 'calculatedParams',
  initialState,
  reducers: {
    setTWOTripsOrOK: (state, action) => {
      state.TWO_Trips_Or_OK = action.payload;
    },
    setTWOTripsOrOK_Day2: (state, action) => {
      state.TWO_Trips_Or_OK_Day2 = action.payload;
    },
    setTotalSmallBoxes: (state, action) => {
      state.Total_small_boxes = action.payload;
    },
    setTotalMediumBoxes: (state, action) => {
      state.Total_medium_boxes = action.payload;
    },
    setTotalFragileBoxes: (state, action) => {
      state.Total_fragile_boxes = action.payload;
    },

  },
});

export const { setTWOTripsOrOK, setTWOTripsOrOK_Day2, setTotalSmallBoxes, setTotalMediumBoxes, setTotalFragileBoxes } = calculatedParamsSlice.actions;

export default calculatedParamsSlice.reducer;
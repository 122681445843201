import React from 'react';
import styles from './ResultsCopyIcon.module.css'; // Import your CSS file with the styles
import copyIcon from './../../img/copy.png'

function CopyIcon({ onClick }) {
  return (
    <div className={styles.copy_icon} onClick={onClick}>
      <img src={copyIcon} alt="Copy icon" className={styles.icon} style={{ marginRight: "20px" }}  />
    </div>
  );
}

export default CopyIcon;
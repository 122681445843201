import styles from './BtnApplyNewRate.module.css';

export const BtnApplyNewRate = ({ isActive, action, name }) => {
  return (
    <button
      type='submit'
      className={styles.btnSubmit}
      disabled={!isActive}
      onClick={(evt) => window.confirm("Are you sure to apply new rates?") && action(evt)}
    >
      {name}
    </button>
  );
}
// Third party libraries
import { nanoid } from 'nanoid';
import useCollapse from 'react-collapsed';
import { getTruckFeeMin } from '../../util/utils';
import * as immutable from 'object-path-immutable';
import clockIcon from './../../img/clock.png';
// Styles
import styles from './CalculationTabTwoDays150Values.module.css';
import { ExtraOptionsValues } from '../extra-options-values/Extra-options-values';
import { MiscChechMarksValues } from '../misc-checkmarks-values/Misc-checkmarks-values';
import { AdditionalServicesValues } from '../additional-services-values/Additional-services-values';
import { useEffect, useState } from 'react';
import AdjustTime from '../adjust-time/Adjust-time';

export const CalculationTabTwoDays150Values = ({ rates, formData, setCalculationFormValue, formChange, resetLastTarget, sepCheckboxValueChange, lastTarget, setLastTarget, checkMarkCheckboxValueChange, additionalServicesCheckboxValueChange, disabled }) => {
  const onValueChange = (evt) => {
    resetLastTarget();
    formChange(`twoDays150Miles.${evt.target.name}`, 'set', evt.target.value)
  }
  const isCuFtDepartment = formData.department?.type === 'cuft';
  const [showInputs, setShowInputs] = useState(false);
  const [showInputsSecond, setShowInputsSecond] = useState(false);
  const [showInputsUnpacking, setShowInputsUnpacking] = useState(false);

  const checkboxValueChange = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        return formChange(`twoDays150Miles.${evt.target.name}`, 'set', false);
      case 'false':
        return formChange(`twoDays150Miles.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };

  useEffect(() => {
    if (isCuFtDepartment) {
      formChange(`commonValues.unpackingRate`, 'set', formData.twoDays150Miles.firstDayRate);
    }
  }, []);


  const chargeCheckboxValueChangeDayOne = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.twoDays150Miles.selectedChargesNamesDay1];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`twoDays150Miles.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`twoDays150Miles.selectedChargesNamesDay1`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };

  const chargeCheckboxValueChangeDayTwo = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.twoDays150Miles.selectedChargesNamesDay2];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`twoDays150Miles.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`twoDays150Miles.selectedChargesNamesDay2`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };

  const chargeQuantityChangeDayOne = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.twoDays150Miles.selectedChargesNamesDay1));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`twoDays150Miles.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
    }
  }

  const chargeQuantityChangeDayTwo = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.twoDays150Miles.selectedChargesNamesDay2));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`twoDays150Miles.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
    }
  }




  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  return (
    <section className={styles.container}>
      <div className={styles.groupInput}>
        <label className={styles.commonLabel} >
          1st date:
          <input
            className={styles.dateInput}
            type='date'
            name={'firstDayDate'}
            value={formData.twoDays150Miles.firstDayDate}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
        <label className={styles.commonLabel} style={{ marginLeft: "20px" }}>
          2nd date:
          <input
            className={styles.dateInput}
            type='date'
            name={'secondDayDate'}
            value={formData.twoDays150Miles.secondDayDate}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
      </div>
      <div className={styles.groupInput} >
        <label style={{ marginTop: "5px" }}>
          Additional amount:
          <input
            onWheel={(e) => e.target.blur()}
            style={{ width: "60px" }}
            type='number'
            name={'additionalAmount'}
            value={(formData.twoDays150Miles) ? formData.twoDays150Miles.additionalAmount : ''}
            placeholder={'ex: 2'}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
      </div>

      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", width: '630px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 1 (Loading→Parking)</span>
            <img src={clockIcon} onClick={() => setShowInputs(!showInputs)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputs && (
            <fieldset className={styles.groupInput} style={{ marginLeft: '20px' }}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeFirstDay'}
                  value={formData.commonValues.addTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeFirstDay'}
                  value={formData.commonValues.removeTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          <div style={{ display: 'flex' }}>
            {rates.length === 0 && <p className={styles.warning}>Select Department!</p>}
            {rates.length > 0 &&
              <label style={{ marginBottom: "10px" }}>
                Rate:
                <select
                  className={styles.select}
                  value={formData.twoDays150Miles ? formData.twoDays150Miles.firstDayRate : 'none'}
                  onChange={(evt) => {
                    resetLastTarget();
                    /* formChange('twoDays150Miles.firstDayRate', 'set', evt.target.value); */
                    const newValue = evt.target.value;
                    const newState = immutable.set(formData, 'twoDays150Miles.firstDayRate', newValue);
                    setCalculationFormValue(newState);
                    if (isCuFtDepartment) {
                      const secondDayRatePath = 'twoDays150Miles.secondDayRate';
                      const secondDayRateState = immutable.set(newState, secondDayRatePath, newValue);
                      setCalculationFormValue(secondDayRateState);
                      const unpackingRatePath = 'commonValues.unpackingRate';
                      const unpackingRateState = immutable.set(secondDayRateState, unpackingRatePath, newValue)
                      setCalculationFormValue(unpackingRateState)
                    }
                  }}
                  disabled={disabled}
                >
                  <option value={''}>None</option>
                  {
                    rates.map((rate, index) => {
                      return (
                        <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                      );
                    })
                  }
                </select>
              </label>
            }

            {formData.commonValues?.shuttle && <div style={{ display: 'flex' }} className={styles.groupInput}>
              <label className={styles.shuttleOnDayOne}>
                Shuttle on Day 1
                <input
                  type='checkbox'
                  name={'shuttleOnDay1'}
                  value={(formData.twoDays150Miles) ? formData.twoDays150Miles.shuttleOnDay1 : false}
                  checked={(formData.twoDays150Miles) ? formData.twoDays150Miles.shuttleOnDay1 : false}
                  onChange={checkboxValueChange}
                  disabled={disabled}
                />
              </label>
              {(formData.department.extraOptions?.shuttle.isCountable && formData.twoDays150Miles.shuttleOnDay1) &&
                <label className={styles.inputContainer}>
                  Qty:
                  <input
                    type='number'
                    name={'quantityDay1'}
                    value={(formData.twoDays150Miles.quantityDay1)}
                    placeholder={'ex: 2'}
                    onChange={onValueChange}
                    onWheel={(e) => e.target.blur()}
                    disabled={disabled}
                  />
                </label>
              }
            </div>
            }
          </div>


          <fieldset className={styles.groupInput}>
            <div style={{ display: "flex", marginTop: '10px' }}>
              <label className={styles.commonLabel} >
                <span>Movers:</span>
                <select
                  className={styles.commonInput}
                  name={'moversDay1'}
                  value={(formData.twoDays150Miles) ? formData.twoDays150Miles.moversDay1 : ''}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                </select>
              </label>



              <label className={styles.inputContainer} style={{ marginLeft: "15px" }}>
                {(isCuFtDepartment) ? 'Round Trip Moving Miles' : 'Miles'}
                <input
                  onWheel={(e) => e.target.blur()}
                  style={{ width: "45px" }}
                  type='number'
                  name={'milesDay1'}
                  value={(formData.twoDays150Miles) ? formData.twoDays150Miles.milesDay1 : ''}
                  placeholder={'ex: 2'}
                  onChange={onValueChange}
                  disabled={disabled}
                />
              </label>

              <label className={styles.commonLabel} style={{ marginLeft: "15px" }}>
                <span>Drive Time</span>
                <select
                  style={{ width: "65px", marginLeft: "3px" }}
                  className={styles.commonInput}
                  name={'driveTimeDay1'}
                  value={(formData.twoDays150Miles) ? formData.twoDays150Miles.driveTimeDay1 : ''}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>0</option>
                  <option>0.05</option>
                  <option>0.10</option>
                  <option>0.16</option>
                  <option>0.25</option>
                  <option>0.33</option>
                  <option>0.5</option>
                  <option>0.75</option>
                  <option>1</option>
                  <option>1.25</option>
                  <option>1.5</option>
                  <option>1.75</option>
                  <option>2</option>
                  <option>2.25</option>
                  <option>2.5</option>
                  <option>2.75</option>
                  <option>3</option>
                  <option>3.25</option>
                  <option>3.5</option>
                  <option>3.75</option>
                  <option>4</option>
                  <option>4.25</option>
                  <option>4.5</option>
                  <option>4.75</option>
                  <option>5</option>
                  <option>5.25</option>
                  <option>5.5</option>
                  <option>5.75</option>
                  <option>6</option>
                  <option>6.25</option>
                  <option>6.5</option>
                  <option>6.75</option>
                  <option>7</option>
                  <option>7.25</option>
                  <option>7.5</option>
                  <option>7.75</option>
                  <option>8</option>
                  <option>8.25</option>
                  <option>8.5</option>
                  <option>8.75</option>
                  <option>9</option>
                  <option>9.25</option>
                  <option>9.5</option>
                  <option>9.75</option>
                  <option>10</option>
                  <option>10.25</option>
                  <option>10.5</option>
                  <option>10.75</option>
                  <option>11</option>
                  <option>11.25</option>
                  <option>11.5</option>
                  <option>11.75</option>
                  <option>12</option>
                </select>
              </label>
            </div>
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <label className={styles.commonLabel}>
                <span>Add Trucks:</span>
                <select
                  className={styles.commonInput1}
                  name={'addTrucksDay1'}
                  value={formData.twoDays150Miles.addTrucksDay1}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option key='0'>0</option>
                  <option key='1'>1</option>
                  <option key='2'>2</option>
                  <option key='3'>3</option>
                  <option key='4'>4</option>
                  <option key='5'>5</option>
                  <option key='6'>6</option>
                  <option key='7'>7</option>
                  <option key='8'>8</option>
                  <option key='9'>9</option>
                  <option key='10'>10</option>
                </select>
              </label>

              <label className={styles.commonLabel} style={{ marginLeft: "15px" }}>
                <span>Remove Trucks:</span>
                <select
                  className={styles.commonInput1}
                  name={'removeTrucksDay1'}
                  value={formData.twoDays150Miles.removeTrucksDay1}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option key='0'>0</option>
                  <option key='1'>1</option>
                  <option key='2'>2</option>
                  <option key='3'>3</option>
                  <option key='4'>4</option>
                  <option key='5'>5</option>
                  <option key='6'>6</option>
                  <option key='7'>7</option>
                  <option key='8'>8</option>
                  <option key='9'>9</option>
                  <option key='10'>10</option>
                </select>
              </label>
            </div>
          </fieldset>


          {(formData.department && Object.keys(formData.department).length && formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length)
            ?
            <>
              <hr className={styles.hLine} />
              <ExtraOptionsValues
                charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                chargeCheckboxValueChange={chargeCheckboxValueChangeDayOne}
                selectedChargesNames={formData.twoDays150Miles.selectedChargesNamesDay1}
                lastTarget={lastTarget}
                chargeQuantityChange={chargeQuantityChangeDayOne}
                disabled={disabled}
              />
            </>
            : ''
          }
        </div>
      </div>


      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '630px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 2 (Parking→Unloading)</span>
            <img src={clockIcon} onClick={() => setShowInputsSecond(!showInputsSecond)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputsSecond && (
            <fieldset className={styles.groupInput} style={{ marginLeft: '20px' }}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeSecondDay'}
                  value={formData.commonValues.addTimeSecondDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeSecondDay'}
                  value={formData.commonValues.removeTimeSecondDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          <fieldset className={styles.groupInput} >


            {rates.length === 0 && <p className={styles.warning1} style={{ margin: '0px', marginBottom: "10px" }}>Select Department!</p>}
            {rates.length > 0 &&
              <div >

                <label style={{ marginBottom: "10px" }}>
                  Rate:
                  <select
                    className={styles.select}
                    value={(formData.twoDays150Miles) ? formData.twoDays150Miles.secondDayRate : 'none'}
                    onChange={(evt) => {
                      resetLastTarget();
                      formChange('twoDays150Miles.secondDayRate', 'set', evt.target.value);
                    }}
                    disabled={isCuFtDepartment}
                    disabled={disabled}
                  >
                    <option value={''}>None</option>
                    {
                      rates.map((rate, index) => {
                        return (
                          <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                        );
                      })
                    }
                  </select>
                </label>

                {isCuFtDepartment &&
                  <label style={{ marginLeft: '10px' }}>Type of place
                    <select value={formData.commonValues.additionalServicesPlaceType}
                      className={styles.commonInput}
                      onChange={evt => formChange(`commonValues.additionalServicesPlaceType`, 'set', evt.target.value)}
                      disabled={disabled}
                    >
                      <option>None</option>
                      {formData.department.additionalServices.placeTypes.length && formData.department.additionalServices.placeTypes.map((placeType, pcftIndex) => {
                        return <option>{placeType.placeTypeName}</option>
                      })}
                    </select>
                  </label>
                }

                {(formData.commonValues.procedures.length > 0 && formData.commonValues.procedures[0] && formData.commonValues.procedures[0].procedureType !== "None" && formData.commonValues.procedures[0].procedureType !== "") ?
                  <label className={styles.proceduresOnDay1} style={{ marginLeft: '20px' }}>
                    Procedures on Day 2
                    <input
                      type='checkbox'
                      name={'proceduresOnDay2'}
                      value={(formData.twoDays150Miles) ? formData.twoDays150Miles.proceduresOnDay2 : false}
                      checked={(formData.twoDays150Miles) ? formData.twoDays150Miles.proceduresOnDay2 : false}
                      onChange={checkboxValueChange}
                      disabled={disabled}
                    />
                  </label>
                  : ""}
              </div>
            }

            {formData.commonValues?.shuttle && <>
              <label className={styles.shuttleOnDayOne} >
                Shuttle on Day 2
                <input
                  type='checkbox'
                  name={'shuttleOnDay2'}
                  value={(formData.twoDays150Miles) ? formData.twoDays150Miles.shuttleOnDay2 : false}
                  checked={(formData.twoDays150Miles) ? formData.twoDays150Miles.shuttleOnDay2 : false}
                  onChange={checkboxValueChange}
                  disabled={disabled}
                />
              </label>
              {(formData.department.extraOptions?.shuttle.isCountable && formData.commonValues?.shuttle) && formData.twoDays150Miles.shuttleOnDay2 &&
                <label className={styles.inputContainer}>
                  Qty:
                  <input
                    type='number'
                    name={'quantityDay2'}
                    value={(formData.twoDays150Miles.quantityDay2)}
                    placeholder={'ex: 2'}
                    onChange={onValueChange}
                    onWheel={(e) => e.target.blur()}
                    disabled={disabled}
                  />
                </label>
              }</>
            }



            <div className={styles.groupInput}>
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <label className={styles.commonLabel}>
                  <span>Movers:</span>
                  <select
                    className={styles.commonInput}
                    name={'moversDay2'}
                    value={(formData.twoDays150Miles) ? formData.twoDays150Miles.moversDay2 : ''}
                    onChange={onValueChange}
                    disabled={disabled}
                  >
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                  </select>
                </label>

                <label className={styles.inputContainer} style={{ marginLeft: "20px" }}>
                  {(isCuFtDepartment) ? 'Round Trip Moving Miles' : 'Miles'}
                  <input
                    onWheel={(e) => e.target.blur()}
                    style={{ width: "45px" }}
                    type='number'
                    name={'milesDay2'}
                    value={(formData.twoDays150Miles) ? formData.twoDays150Miles.milesDay2 : ''}
                    placeholder={'ex: 2'}
                    onChange={onValueChange}
                    disabled={disabled}
                  />
                </label>

                <label className={styles.commonLabel} style={{ marginLeft: "15px" }}>
                  <span>Drive Time</span>
                  <select
                    style={{ width: "65px", marginLeft: "3px" }}
                    className={styles.commonInput}
                    name={'driveTimeDay2'}
                    value={(formData.twoDays150Miles) ? formData.twoDays150Miles.driveTimeDay2 : ''}
                    onChange={onValueChange}
                    disabled={disabled}
                  >
                    <option>0</option>
                    <option>0.05</option>
                    <option>0.10</option>
                    <option>0.16</option>
                    <option>0.25</option>
                    <option>0.33</option>
                    <option>0.5</option>
                    <option>0.75</option>
                    <option>1</option>
                    <option>1.25</option>
                    <option>1.5</option>
                    <option>1.75</option>
                    <option>2</option>
                    <option>2.25</option>
                    <option>2.5</option>
                    <option>2.75</option>
                    <option>3</option>
                    <option>3.25</option>
                    <option>3.5</option>
                    <option>3.75</option>
                    <option>4</option>
                    <option>4.25</option>
                    <option>4.5</option>
                    <option>4.75</option>
                    <option>5</option>
                    <option>5.25</option>
                    <option>5.5</option>
                    <option>5.75</option>
                    <option>6</option>
                    <option>6.25</option>
                    <option>6.5</option>
                    <option>6.75</option>
                    <option>7</option>
                    <option>7.25</option>
                    <option>7.5</option>
                    <option>7.75</option>
                    <option>8</option>
                    <option>8.25</option>
                    <option>8.5</option>
                    <option>8.75</option>
                    <option>9</option>
                    <option>9.25</option>
                    <option>9.5</option>
                    <option>9.75</option>
                    <option>10</option>
                    <option>10.25</option>
                    <option>10.5</option>
                    <option>10.75</option>
                    <option>11</option>
                    <option>11.25</option>
                    <option>11.5</option>
                    <option>11.75</option>
                    <option>12</option>
                  </select>
                </label>
              </div>


              <div style={{ display: 'flex', marginTop: '10px' }}>
                <label className={styles.commonLabel} >
                  <span>Add Trucks:</span>
                  <select
                    className={styles.commonInput1}
                    name={'addTrucksDay2'}
                    value={formData.twoDays150Miles.addTrucksDay2}
                    onChange={onValueChange}
                    disabled={disabled}
                  >
                    <option key='0'>0</option>
                    <option key='1'>1</option>
                    <option key='2'>2</option>
                    <option key='3'>3</option>
                    <option key='4'>4</option>
                    <option key='5'>5</option>
                    <option key='6'>6</option>
                    <option key='7'>7</option>
                    <option key='8'>8</option>
                    <option key='9'>9</option>
                    <option key='10'>10</option>
                  </select>
                </label>

                <label className={styles.commonLabel} style={{ marginLeft: "15px" }}>
                  <span>Remove Trucks:</span>
                  <select
                    className={styles.commonInput1}
                    name={'removeTrucksDay2'}
                    value={formData.twoDays150Miles.removeTrucksDay2}
                    onChange={onValueChange}
                    disabled={disabled}
                  >
                    <option key='0'>0</option>
                    <option key='1'>1</option>
                    <option key='2'>2</option>
                    <option key='3'>3</option>
                    <option key='4'>4</option>
                    <option key='5'>5</option>
                    <option key='6'>6</option>
                    <option key='7'>7</option>
                    <option key='8'>8</option>
                    <option key='9'>9</option>
                    <option key='10'>10</option>
                  </select>
                </label>
              </div>
            </div>

          </fieldset>

          {(formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length) ?
            <div>
              <hr className={styles.hLine} />
              <ExtraOptionsValues
                charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                chargeCheckboxValueChange={chargeCheckboxValueChangeDayTwo}
                selectedChargesNames={formData.twoDays150Miles.selectedChargesNamesDay2}
                lastTarget={lastTarget}
                chargeQuantityChange={chargeQuantityChangeDayTwo}
                disabled={disabled}
              />

              <hr className={styles.hLine} />
            </div>
            :
            <hr className={styles.hLine} />}


          <div>
            {(formData.department && formData.department?.type === 'cuft') &&
              <AdditionalServicesValues
                additionalServices={(formData.department && formData.department.additionalServices) ? formData.department.additionalServices : []}
                selectedAdditionalServices={formData.commonValues.selectedAdditionalServices}
                additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
                additionalServicesPlaceType={formData.commonValues.additionalServicesPlaceType}
                formChange={formChange}
                disabled={disabled}
              />
            }
          </div>
          <hr className={styles.hLine} />
          <MiscChechMarksValues
            miscCheckMarks={(formData.department && formData.department.miscCheckMarks && formData.department.miscCheckMarks.miscCheckMarks.length) ? formData.department.miscCheckMarks.miscCheckMarks : []}
            selectedMiscCheckMarks={formData.selectedMiscCheckMarks}
            checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
            disabled={disabled}
          />
        </div>
      </div>

      {formData.commonValues.unpacking === 'Separate Day' &&
        <div style={{ marginTop: '10px', border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '630px' }}>
          <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>UNPACKING ON SEPARATE DAY</span>
              <img src={clockIcon} onClick={() => setShowInputsUnpacking(!showInputsUnpacking)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
            </div>
            {showInputsUnpacking && (
              <fieldset className={styles.groupInput} style={{ marginLeft: '20px' }}>
                <div className={styles.decorateContainer}>
                  <AdjustTime
                    name={'addTimeUnp'}
                    value={formData.commonValues.addTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Add time:'}
                    disabled={disabled}
                  />
                  <AdjustTime
                    name={'removeTimeUnp'}
                    value={formData.commonValues.removeTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Remove time:'}
                    disabled={disabled}
                  />
                </div>
              </fieldset>
            )}
          </div>

          <div style={{ padding: '5px', }}>
            {rates.length === 0 && <p className={styles.warning1} style={{ margin: '0', marginTop: '10px' }}>Select Department!</p>}
            {rates.length > 0 &&
              <label style={{ display: 'block' }}>
                Rate:
                <select
                  className={styles.select}
                  value={formData.commonValues.unpackingRate}
                  onChange={(evt) => {
                    resetLastTarget();
                    formChange('commonValues.unpackingRate', 'set', evt.target.value);
                  }}
                  disabled={isCuFtDepartment || disabled}
                >
                  <option value={''} key='none'>None</option>
                  {
                    rates.map((rate, index) => {
                      return (
                        <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                      );
                    })
                  }
                </select>
              </label>
            }
            <label className={styles.checkLabel} style={{ justifyContent: "flex-start" }} >
              <span>Movers to Unpack:</span>
              <select
                className={styles.commonInput}
                name={'moversToUnPackSeparate'}
                value={(formData.commonValues.moversToUnPackSeparate) ? formData.commonValues.moversToUnPackSeparate : ''}
                onChange={(evt) => {
                  resetLastTarget();
                  formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                }
                }
                disabled={disabled}
              >
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
            </label>






            {formData.commonValues?.shuttle && <div style={{ display: 'flex' }} className={styles.groupInput}>
              <label className={styles.shuttleOnDayOne}>
                Shuttle on Unpacking Day
                <input
                  type='checkbox'
                  name={'shuttleOnUnpacking'}
                  value={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                  checked={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                  onChange={sepCheckboxValueChange}
                  disabled={disabled}
                />
              </label>
              {formData.commonValues.shuttleOnUnpacking &&
                <label className={styles.inputContainer} >
                  Qty:
                  <input
                    type='number'
                    name={'quantityUnpacking'}
                    value={formData.commonValues.quantityUnpacking}
                    placeholder={'ex: 2'}
                    onChange={(evt) => {
                      resetLastTarget();
                      formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                    }}
                    onWheel={(e) => e.target.blur()}
                    disabled={disabled}
                  />
                </label>
              }
            </div>
            }

            {formData.department && formData.department.truckFee && formData.department.truckFee.calcMethod === "different" &&
              <fieldset className={styles.groupInput} style={{ margin: '0px' }}>
                <label className={styles.inputContainer1} style={{ width: "400px", justifyContent: "flex-start" }}>
                  Truck fee:
                  <input
                    onWheel={(e) => e.target.blur()}
                    type='number'
                    name={'truckFeeSeparate'}
                    value={(formData._id) ? (formData.twoDays150Miles) ? formData.twoDays150Miles.truckFee : '' : getTruckFeeMin(formData.department.truckFee.diffTruckReservations)}
                    placeholder={'ex: 40'}
                    onChange={onValueChange}
                    disabled={disabled}
                  />
                </label>
              </fieldset>
            }
          </div>
        </div>
      }

    </section>
  );
};
// Third party libraries
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Prompt } from 'react-router-dom';
import { nanoid } from 'nanoid';
import * as immutable from 'object-path-immutable';
import { isEqual } from 'lodash';
import { connectSocket, disconnectSocket } from '../../context/socket';
// Components
import { BtnSubmit } from '../btn-submit/Btn-submit';

import { FormContainer } from '../form-container/Form-container';
import { LongDistance } from '../long-distance/Long-distance';

// Local State Functions
import { getUserId, getCompanyId } from '../../clientStore/authSlice/auth-slice';
import {
  getLongDistanceSavingStatus,
  setLongDistanceSavingStatus,
  setLongDistanceLoadingStatus,
} from '../../clientStore/longdistanceSlice/longdistance-slice';
import {
  saveLongDistance,
  updateLongDistance,
} from '../../clientStore/longdistanceSlice/longdistance-async-thunk';
import {
  getLongDistances,
  setLongDistancesLoadingStatus,
} from '../../clientStore/longdistancesSlice/longdistances-slice';
// DataModel
import { longdistanceDataModel } from '../../models/longdistance';
// Utils
import { fetchedData } from '../../util/utils';
//Styles
import styles from './LongDistanceForm.module.css';
import { isLongDistanceFormValid } from '../../util/formValidations';
import { getCompany, getCompanyLoadingStatus } from '../../clientStore/companySlice/company-slice';
import { loadCompany } from '../../clientStore/companySlice/company-async-thunk';

export const LongDistanceForm = ({ longdistanceData, formType = 'new', longdistanceId = undefined }) => {

  const [lastTarget, setLastTarget] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(getUserId);
  const companyId = useSelector(getCompanyId);
  const savedStatus = useSelector(getLongDistanceSavingStatus);
  const longdistances = useSelector(getLongDistances);
  var longdistance = longdistanceData ? longdistanceData : longdistanceDataModel;
  const companyLoadingStatus = useSelector(getCompanyLoadingStatus);
  const company = useSelector(getCompany);
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    if (companyLoadingStatus === 'idle') {
      dispatch(loadCompany(companyId));
    }
  }, [dispatch, companyId, companyLoadingStatus]);

  if (company &&
    Object.keys(company).length &&
    company.longDistanceDirectionType &&
    company.longDistanceCalcMethod
  ) {
    longdistance.directionType = company.longDistanceDirectionType;
    longdistance.calcMethod = company.longDistanceCalcMethod;
  }

  const [formValue, setFormValue] = useState({
    ...longdistance,
    authorId: userId,
    version: longdistance.version + 1,
    companyId,
  });

  const unChangedFormValue = {
    ...longdistance,
    authorId: userId,
    version: longdistance.version + 1,
    companyId,
  }

  const renderLongDistancesOptions = () => {
    if (longdistances.length > 0) {
      return (
        longdistances.map((longdistance) => {
          return (
            <option value={longdistance._id} key={nanoid(longdistances.length)}>
              {longdistance.directionName}
            </option>
          );
        })
      );
    }
    return ('');
  };

  const onChangeSameAsSelect = async (evt) => {
    const copiedLongDistance = await fetchedData('longdistance/getLongDistance', 'POST', { id: evt.target.value });
    if (copiedLongDistance.status === 200) {
      setFormValue({
        ...copiedLongDistance.data,
        _id: longdistance._id ? longdistance._id : undefined,
        authorId: userId,
        version: longdistance.version + 1,
        companyId,
        directionName: longdistance.directionName,
      });
    }
  }

  const formSubmit = (evt) => {
    evt.preventDefault();
    setIsFormDirty(false)
    if (isLongDistanceFormValid(formValue)) {
      switch (formType) {
        case 'new':
          dispatch(saveLongDistance(formValue));
          break;
        case 'edit':
          dispatch(updateLongDistance(formValue, longdistanceId));
          break;
        default:
          throw new Error('Invalid form type');
      }

      setFormValue({
        ...longdistanceDataModel,
        authorId: userId,
        companyId,
      });
    }
    else {
      alert("Please fill all fields")
    }
  };

  useEffect(() => {
    if (savedStatus === 'successed') {
      dispatch(setLongDistanceSavingStatus({ status: 'idle' }));
      dispatch(setLongDistanceLoadingStatus({ status: 'idle' }));
      dispatch(setLongDistancesLoadingStatus({ status: 'idle' }));
      setIsFormDirty(false)
      history.replace('/longdistances');
    }
  }, [savedStatus, history, dispatch]);

  const onFormValueChange = (path, method, value) => {
    setFormValue(immutable[method](formValue, path, value));
  };

  const clearLastTarget = () => {
    if (lastTarget) {
      setLastTarget(undefined);
    }
  }

  useEffect(() => {
    setIsFormDirty((savedStatus !== 'successed' && savedStatus !== 'loading') && !isEqual(formValue, unChangedFormValue))
  }, [formValue, savedStatus]);
  
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isFormDirty) {
        e.preventDefault();
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
        disconnectSocket()
        connectSocket()
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  return (
    <FormContainer>
      <Prompt
        when={isFormDirty}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <form className={styles.form}>

        <LongDistance
          distance={formValue}
          changeDistanceValue={onFormValueChange}
          setFormValue={setFormValue}
          longdistance={longdistance}
          longdistances={longdistances}
          userId={userId}
          companyId={companyId}
          onChangeSameAsSelect={onChangeSameAsSelect}
          renderLongDistancesOptions={renderLongDistancesOptions}
          clearLastTarget={clearLastTarget}
          formSubmit={formSubmit}
        />

      </form>
    </FormContainer>
  );
};

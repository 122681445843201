// Third party libraries
import PropTypes from 'prop-types';
// Styles
import styles from './MinHoursLabel.module.css';

export const MinHoursLabel = (props) => {
  const {
    inputId,
    name,
    title,
    placeholder,
    value,
    changeValue,
    valueType,
    isFocused,
    isLong,
    inputType,
    cursorPosition,
    pattern,
    style = {}
  } = props;

  return (
    <section
      className={styles.inputContainer}
      htmlFor={inputId}
      style={style}
    >

      <p className={styles.inputName}>{title}</p><label>
        {valueType &&
          <span className={styles.inputValueType}>{valueType}</span>
        }
        <input
          id={inputId}
          className={isLong ? styles.inputLongPadding : styles.input}
          type={(inputType) ? inputType : 'text'}
          placeholder={placeholder}
          name={name}
          value={value}
          pattern={pattern}
          onChange={(e) => (pattern) ?
            e.target.validity.valid ? changeValue(e) : (e.target.value === "") ? changeValue(e) : ""
            : changeValue(e)}
          autoFocus={isFocused}
          onFocus={e => {
            if (cursorPosition) {
              e.target.selectionEnd = cursorPosition;
              e.target.selectionStart = cursorPosition;
            }
          }}
          onWheel={(e) => e.target.blur()}
          required
        />

      </label>
    </section>
  );
};

// Variable type settings
MinHoursLabel.propTypes = {
  inputId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  changeValue: PropTypes.func.isRequired,
  valueType: PropTypes.string,
  isFocused: PropTypes.bool,
  isLong: PropTypes.bool,
  inputType: PropTypes.string,
  cursorPosition: PropTypes.number,
  pattern: PropTypes.string,
};

// Third party libraries
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom';
import * as immutable from 'object-path-immutable';
// Components
import { AddBtn } from '../add-btn/Add-btn';
import { Loading } from '../loading/Loading';
import { OutDataMessage } from '../outdata-message/OutData-message';
import { SettingsContainer } from '../settings-container/Settings-container';
import { SettingsTitle } from '../settings-title/Settings-title';
import { UsersList } from '../users-list/Users-list';
import { Search } from '../search/Search';
// Functions for work with local state
import { getCompanyId } from '../../clientStore/authSlice/auth-slice';
import {
  getUsers,
  getProcessUsersLoading,
} from '../../clientStore/usersSlice/user-slice';
import { loadUsers } from '../../clientStore/usersSlice/users-async-thunk';
import { useState } from 'react';
import { setUsersLoadingProcess } from '../../clientStore/usersSlice/user-slice';


export const UsersMain = () => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const companyId = useSelector(getCompanyId);
  const users = useSelector(getUsers);
  const loadingProcess = useSelector(getProcessUsersLoading);
  const [filters, setFilters] = useState(
    {
      'search': {
        'field': '',
        'searchText': '',
      },
      'sort': {
        'field': 'createdAt',
        'order': -1
      },
      'filter': {
        'field': ''
      }
    }
  );
  const [isFilterChanged, setFilterChanged] = useState(false);

  useEffect(() => {
    if (loadingProcess === 'idle' || isFilterChanged) {
      dispatch(loadUsers({ 'companyId': companyId, 'filters': filters }));
       try {
        setFilterChanged(false)
      }
      catch (err) {
        console.log(err)
      };
    }
    /*   return function cleanup() {dispatch(setUsersLoadingProcess({ status: 'idle' }))}; */
  }, [dispatch, companyId, filters, loadingProcess, isFilterChanged]);

  const onFilterChange = (path, method, value) => {
    setFilters(immutable[method](filters, path, value));
     try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      };
  };

  return (
    <SettingsContainer>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <AddBtn
          name={'user'}
          linkUrl={`${url}/add`}
        />
        <Search setFilters={onFilterChange} searchName="UserName" />
      </div>
      <SettingsTitle titleName={'users'} />
      {loadingProcess === 'loading' && <Loading />}
      {loadingProcess === 'successed' && users.length > 0 && <UsersList users={users} setFilters={onFilterChange} filters={filters} />}
      {loadingProcess === 'successed' && users.length === 0 && <OutDataMessage dataName={'Users'} />}
    </SettingsContainer>
  );
};
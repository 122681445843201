// Third party libraries
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import * as immutable from 'object-path-immutable';
import { useHistory, Prompt } from 'react-router-dom';
import { isEqual } from 'lodash';
import { connectSocket, disconnectSocket } from '../../context/socket';
// Components
import { BtnSubmit } from '../btn-submit/Btn-submit';
import { ExtraOptions } from '../extra-options/Extra-options';
import { MiscCheckMarks } from '../misc-checkmarks/Misc-checkmarks';
import { AdditionalServices } from '../additional-services/Additional-services';

import { FormContainer } from '../form-container/Form-container';
import { LongDistance } from '../long-distance/Long-distance';
import { Packing } from '../packing/Packing';
import { DepartmentRates } from '../department-rates/Department-rates';
import { Truck } from '../truck/Truck';
import { DoubleDrive } from '../double-drive/DoubleDrive';
// Local State Functions
import { getUserId, getCompanyId } from '../../clientStore/authSlice/auth-slice';
import {
  getDepartmentSavingStatus,
  setDepartmentSavingStatus,
  setDepartmentLoadingStatus,
  setDepartmentRemovingStatus
} from '../../clientStore/departmentSlice/department-slice';
import {
  saveDepartment,
  updateDepartment,
} from '../../clientStore/departmentSlice/department-async-thunk';
import {
  getDepartments,
  setDepartmentsLoadingStatus,
} from '../../clientStore/departmentsSlice/departments-slice';
// DataModel
import { departmentDataModel } from '../../models/department';
// Utils
import { fetchedData, removeByIndex } from '../../util/utils';
//Styles
import styles from './CuFtDepartmentForm.module.css';
import { isDepartmentFormValid } from '../../util/formValidations';
import { getCompany, getCompanyLoadingStatus } from '../../clientStore/companySlice/company-slice';
import { loadCompany } from '../../clientStore/companySlice/company-async-thunk';
import { CuFtDepartmentRates } from '../cuft-department-rates/CuFt-department-rates';
import { CrateLabel } from '../crate-label/Crate-label';
import { Icon } from '../Icon';
import { IconNames } from '../../util/const';
import { ChargePerMile } from '../charge-per-mile/ChargePerMile';
import { DepartmentTitle } from '../department-title/Department-title';
import { PackingCuFt } from '../packing-cuft/Packing-CuFt';

export const CuFtDepartmentForm = ({ departmentData, formType = 'new', departmentId = undefined }) => {

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [lastTarget, setLastTarget] = useState();
  const [cursorPosition, setCursorPosition] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(getUserId);
  const companyId = useSelector(getCompanyId);
  const savedStatus = useSelector(getDepartmentSavingStatus);
  const departments = useSelector(getDepartments);

  var department = departmentData ? departmentData : { ...departmentDataModel, type: 'cuft' };
  const companyLoadingStatus = useSelector(getCompanyLoadingStatus);
  const company = useSelector(getCompany);
  const [companyCharges, setCompanyCharges] = useState();
  const [companyProcedures, setCompanyProcedures] = useState();
  const [companyMiscCheckMarks, setCompanyMiscCheckMarks] = useState();
  const [companyAdditionalServices, setCompanyAdditionalServices] = useState({ placeTypes: [{ placeTypeName: '' }], services: [] });



  useEffect(() => {
    setCompanyCharges(company.extraOptCharges);
  }, [company, setCompanyCharges]);

  useEffect(() => {
    setCompanyProcedures(company.additProcedures);
  }, [company, setCompanyProcedures]);

  useEffect(() => {
    setCompanyMiscCheckMarks(company.miscCheckMarks);
  }, [company, setCompanyMiscCheckMarks]);

  useEffect(() => {
    if (company.additionalServices && Object.keys(company.additionalServices).length) {
      setCompanyAdditionalServices(company.additionalServices);
    }
  }, [company, setCompanyAdditionalServices]);

  useEffect(() => {
    if (formType === 'new') {
      setFormValue({ ...formValue, additionalServices: JSON.parse(JSON.stringify(companyAdditionalServices)) });
    }
  }, [companyAdditionalServices]);



  if (company &&
    Object.keys(company).length &&
    company.cuftDepartmentCalcMethod
  ) {
    department.rates.calcMethod = company.cuftDepartmentCalcMethod;
  }

  let newCharges = []
  if (companyCharges) {
    if (!department.extraOptions.charges.length) {
      companyCharges.map((chargeId, chIdIndex) => {
        const newCharge = {
          [`id${chIdIndex}`]: 'id' + chIdIndex,
          [`chargeName${chIdIndex}`]: chargeId[`id${chIdIndex}`],
          [`amount${chIdIndex}`]: '',
          [`period${chIdIndex}`]: 'hourly',
          [`perTruck${chIdIndex}`]: false,
          [`isCountable${chIdIndex}`]: false,
          [`uId${chIdIndex}`]: chargeId[`uId${chIdIndex}`]
        }
        newCharges.push((newCharge))
      })
    }
    else {
      companyCharges.map((chargeId, chIdIndex) => {
        if (department.extraOptions.charges[chIdIndex]) {
          const newCharge = {
            [`id${chIdIndex}`]: 'id' + chIdIndex,
            [`chargeName${chIdIndex}`]: department.extraOptions.charges[chIdIndex][`chargeName${chIdIndex}`] ? department.extraOptions.charges[chIdIndex][`chargeName${chIdIndex}`] : chargeId[`id${chIdIndex}`],
            [`amount${chIdIndex}`]: department.extraOptions.charges[chIdIndex][`amount${chIdIndex}`],
            [`period${chIdIndex}`]: department.extraOptions.charges[chIdIndex][`period${chIdIndex}`],
            [`perTruck${chIdIndex}`]: department.extraOptions.charges[chIdIndex][`perTruck${chIdIndex}`],
            [`isCountable${chIdIndex}`]: department.extraOptions.charges[chIdIndex][`isCountable${chIdIndex}`],
            [`uId${chIdIndex}`]: chargeId[`uId${chIdIndex}`]
          }
          newCharges.push((newCharge))
        }
      })
    }
    department.extraOptions.charges = newCharges
  }

  let newProcedures = []
  if (companyProcedures) {
    if (!department.packing.cratings.length) {
      companyProcedures.map((chargeId, chIdIndex) => {
        const newCharge = {
          [`id${chIdIndex}`]: 'id' + chIdIndex,
          [`crateName${chIdIndex}`]: chargeId[`id${chIdIndex}`],
          [`crateCost${chIdIndex}`]: '',
          [`timeToExecuteCrate${chIdIndex}`]: '',
          [`uId${chIdIndex}`]: chargeId[`uId${chIdIndex}`]
        }
        newProcedures.push((newCharge))
      })
    }
    else {
      companyProcedures.map((chargeId, chIdIndex) => {
        if (department.packing.cratings[chIdIndex]) {
          const newCharge = {
            [`id${chIdIndex}`]: 'id' + chIdIndex,
            [`crateName${chIdIndex}`]: department.packing.cratings[chIdIndex][`crateName${chIdIndex}`] ? department.packing.cratings[chIdIndex][`crateName${chIdIndex}`] : chargeId[`id${chIdIndex}`],
            [`crateCost${chIdIndex}`]: department.packing.cratings[chIdIndex][`crateCost${chIdIndex}`],
            [`timeToExecuteCrate${chIdIndex}`]: department.packing.cratings[chIdIndex][`timeToExecuteCrate${chIdIndex}`],
            [`uId${chIdIndex}`]: chargeId[`uId${chIdIndex}`],
          }
          newProcedures.push((newCharge))
        }
      })
    }
    department.packing.cratings = [...newProcedures]
  }

  let newMiscCheckMarks = [];
  if (companyMiscCheckMarks) {
    if (!department.miscCheckMarks.miscCheckMarks.length) {
      companyMiscCheckMarks.map((checkMarkId, chMIdIndex) => {
        const newCheckMark = {
          [`id${chMIdIndex}`]: 'id' + chMIdIndex,
          [`checkMarkName${chMIdIndex}`]: checkMarkId[`id${chMIdIndex}`],
          [`uId${chMIdIndex}`]: checkMarkId[`uId${chMIdIndex}`],
        }
        newMiscCheckMarks.push((newCheckMark))
      })
    }
    else {
      companyMiscCheckMarks.map((checkMarkId, chMIdIndex) => {
        if (department.miscCheckMarks.miscCheckMarks[chMIdIndex]) {
          const newCheckMark = {
            [`id${chMIdIndex}`]: 'id' + chMIdIndex,
            [`checkMarkName${chMIdIndex}`]: department.miscCheckMarks.miscCheckMarks[chMIdIndex][`checkMarkName${chMIdIndex}`] ? department.miscCheckMarks.miscCheckMarks[chMIdIndex][`checkMarkName${chMIdIndex}`] : checkMarkId[`id${chMIdIndex}`],
            [`uId${chMIdIndex}`]: checkMarkId[`uId${chMIdIndex}`],
          }
          newMiscCheckMarks.push((newCheckMark))
        }
      })
    }
  }
  department.miscCheckMarks.miscCheckMarks = [...newMiscCheckMarks]

  useEffect(() => {
    if (companyLoadingStatus === 'idle') {
      dispatch(loadCompany(companyId));
    }
  }, [dispatch, companyId, companyLoadingStatus]);

  const [formValue, setFormValue] = useState({
    ...department,
    authorId: userId,
    version: department.version + 1,
    companyId,
  });

  const unChangedFormValue = {
    ...department,
    authorId: userId,
    version: department.version + 1,
    companyId,
  }

  const renderDepartmentsOptions = (departments) => {
    if (departments.length > 0) {
      return (
        departments && departments.map((department) => {
          return (
            <option value={department._id} key={nanoid(departments.length)}>
              {department.departmentName}
            </option>
          );
        })
      );
    }
    return ('');
  };

  const onChangeSameAsSelect = async (evt) => {
    const copiedDepartment = await fetchedData('department/getDepartment', 'POST', { id: evt.target.value });
    if (copiedDepartment.status === 200) {
      setFormValue({
        ...copiedDepartment.data,
        _id: department._id ? department._id : undefined,
        createdAt: new Date(),
        authorId: userId,
        version: department.version + 1,
        companyId,
        departmentName: formValue.departmentName,
      });
    }
  }

  const formSubmit = (evt) => {
    evt.preventDefault();
    setIsFormDirty(false)
    /* if (isDepartmentFormValid(formValue)) { */
    switch (formType) {
      case 'new':
        //if department with the name exists in company inform user with message: Department with name ${departmentName} already exists
        dispatch(saveDepartment(formValue));
        break;
      case 'edit':
        dispatch(updateDepartment(formValue, departmentId));
        break;
      default:
        throw new Error('Invalid form type');
    }

    setFormValue({
      ...departmentDataModel,
      authorId: userId,
      companyId,
    });
    /*  }
     else {
       alert("Please fill all required fields")
     } */
  };

  useEffect(() => {
    if (savedStatus === 'successed') {
      dispatch(setDepartmentSavingStatus({ status: 'idle' }));
      dispatch(setDepartmentLoadingStatus({ status: 'idle' }));
      dispatch(setDepartmentsLoadingStatus({ status: 'idle' }));
      dispatch(setDepartmentRemovingStatus({ status: 'idle' }));
      //history.replace('/departments');
      setIsFormDirty(false)
      window.location.reload();
    }
  }, [savedStatus, history, dispatch]);

  const onFormValueChange = (path, method, value) => {
    setFormValue(immutable[method](formValue, path, value));
  };

  const clearLastTarget = () => {
    if (lastTarget) {
      setLastTarget(undefined);
    }
  }

  const clearCursorPosition = () => {
    if (cursorPosition) {
      setCursorPosition(undefined);
    }
  }

  useEffect(() => {
    setIsFormDirty((savedStatus !== 'successed' && savedStatus !== 'loading') && !isEqual(formValue, unChangedFormValue))
  }, [formValue, savedStatus]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isFormDirty) {
        e.preventDefault();
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
        disconnectSocket()
        connectSocket()
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  const removePricePerCuFt = (index) => {
    let newPricesPerCuFt = removeByIndex([...formValue.pricesPerCuFt], index)
    onFormValueChange(`pricesPerCuFt`, 'set', newPricesPerCuFt);
  }

  const addPricePerCuFt = (index) => {
    const newPricePerCuFt = {
      [`cubicFtRange${index}`]: '',
      [`operator${index}`]: '===',// '===', '<', '>' '<=', '>='
      [`pricePerCuFt${index}`]: '',
    }
    onFormValueChange(`pricesPerCuFt`, 'push', newPricePerCuFt)
  }




  return (
    <FormContainer>
      <Prompt
        when={isFormDirty}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <form className={styles.form}>
        <fieldset className={styles.formHeader}>
          <legend className="visually-hidden">Common settings for department</legend>
          <div className={styles.fieldContainer}>
            <label htmlFor='departmentName' className={styles.fieldName}>Department name</label>
            <input
              id='departmentName'
              className={styles.departmentField}
              type='text'
              placeholder='City or Town where you have a branch'
              name='departmentName'
              value={formValue.departmentName}
              onChange={(evt) => onFormValueChange(evt.target.name, 'set', evt.target.value)}
              onFocus={() => { clearLastTarget(); clearCursorPosition() }}
              required
            />
          </div>
          <div>
            <label htmlFor='sameAs' className={styles.fieldName}>Same as</label>
            <select
              id='sameAs'
              className={styles.departmentField}
              onFocus={() => { clearLastTarget(); clearCursorPosition() }}
              onChange={onChangeSameAsSelect}
            >
              <option>None</option>
              {renderDepartmentsOptions(departments)}
            </select>
          </div>
        </fieldset>

        <section className={styles.allPage}>
          <CuFtDepartmentRates
            rates={formValue.rates}
            minimumHours={formValue.minimumHours}
            minimumCuFt={formValue.minimumCuFt}
            setDepartmentFormValue={onFormValueChange}
            lastTarget={lastTarget}
            cursorPosition={cursorPosition}
            setLastTarget={setLastTarget}
            setCursorPosition={setCursorPosition}
          />


          <Truck
            truckFee={formValue.truckFee}
            changeTruckValue={onFormValueChange}
            clearLastTarget={clearLastTarget}
          />


          <ChargePerMile
            chargePerMile={formValue.doubleDrive}
            changeChargePerMileValue={onFormValueChange}
            clearLastTarget={clearLastTarget}
          />

          <AdditionalServices
            additionalServices={formValue.additionalServices}
            changeAdditionalServices={onFormValueChange}
            clearLastTarget={clearLastTarget}
            lastTarget={lastTarget}
            setLastTarget={setLastTarget}
            cursorPosition={cursorPosition}
            setCursorPosition={setCursorPosition}
          />

          <ExtraOptions
            extraOptions={formValue.extraOptions}
            changeExtraOptions={onFormValueChange}
            clearLastTarget={clearLastTarget}
            companyCharges={companyCharges}
            setCompanyCharges={setCompanyCharges}
          />
          <MiscCheckMarks
            miscCheckMarks={formValue.miscCheckMarks}
            changeMiscCheckMarks={onFormValueChange}
            clearLastTarget={clearLastTarget}
            companyMiscCheckMarks={companyMiscCheckMarks}
            setCompanyMiscCheckMarks={setCompanyMiscCheckMarks}
          />

          <PackingCuFt
            packing={formValue.packing}
            packingCharge={formValue.packingCharge}
            unPackingCharge={formValue.unPackingCharge}
            clearLastTarget={clearLastTarget}
            lastTarget={lastTarget}
            setLastTarget={setLastTarget}
            cursorPosition={cursorPosition}
            setCursorPosition={setCursorPosition}
            setCompanyProcedures={setCompanyProcedures}
            companyProcedures={companyProcedures}
            changePackingValue={onFormValueChange}
          />

          <BtnSubmit
            isActive={true}
            action={formSubmit}
            name={'Save'}
          />
        </section>
      </form>
    </FormContainer>
  );
};

// Third party libraries
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
// Components
import { Icon } from '../Icon';
import { useLocation } from 'react-router-dom';
// Styles
import styles from './SecondaryMenu.module.css';

export const SecondaryMenu = ({ menuItems }) => {
    const items = []
    const location = useLocation();
    const { type } = location.state || 'hourly';

    for (let key in menuItems) {

      let parameters = {};

      switch (key) {
        case 'cuftDepSettings':
          parameters = {
            type: 'cuft',
            // Add other parameters for 'cuftDepSettings'
          };
          break;
        case 'hourlyDepSettings':
          parameters = {
            type: 'hourly',
            // Add other parameters for 'hourlyDepSettings'
          };
          break;
        case 'departments':
          parameters = {
            type: type,
            // Add other parameters for 'departments'
          };
          break;
        case 'templates':
          parameters = {
            type: type,
            // Add other parameters for 'departments'
          };
          break;
        // Add more cases as needed

        default:
          break;
      }
      items.push(
        <li className={styles.item} key={nanoid(10)}>
          <Link
            className={styles.btn}
            to={{
              pathname: `/${key.toLowerCase()}`,
              state: parameters
            }}
          >
            <div className={styles.iconContainer}>
              <Icon name={menuItems[key]} />
            </div>
            {menuItems[key]}
          </Link>
        </li>
      );
    };
    return (
      <div className={styles.container}>
        <ul className={styles.list}>
          {items.map((it) => it)}
        </ul>
      </div>
    );
  };
// Components
import { Table } from '../table/Table';
import { CompanyTableBody } from '../company-table-body/Company-table-body';
import { TableHeader } from '../table-header/Table-header';

export const CompanyList = ({ companies, filters, setFilters }) => {
  const headerTitles = {
    companyName: 'Company Name',
    secondColumn: "",
    disabled: 'Disabled',
    createdAt: 'Created Date',
    updatedAt: 'Updated Date',
  };

  return (
    <Table>
      <TableHeader headerTitles={headerTitles} filters={filters} setFilters={setFilters} />
      <CompanyTableBody tableData={companies} />
    </Table>
  );
};
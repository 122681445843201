import { createSlice } from '@reduxjs/toolkit';
import { loadCompanies, getCountOfCompanies } from './companies-async-thunk';

const initialState = {
  status: 'idle',
  companies: [],
  count: 0,
  errors: null,
};

const companyListSlice = createSlice({
  name: 'companyList',
  initialState,
  reducers: {
    setCompaniesLoadingStatus(state, action) {
      const { status } = action.payload;
      state.status = status;
    },
  },
  extraReducers: {
    // getCompanies extra reducer
    [loadCompanies.pending]: (state, action) => {
      state.status = 'loading';
    },
    [loadCompanies.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [loadCompanies.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.companies = action.payload.data;
    },

    // getCountOfCompanies extra reducer
    [getCountOfCompanies.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getCountOfCompanies.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [getCountOfCompanies.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.count = action.payload.data;
    },
  },
});

export const { setCompaniesLoadingStatus } = companyListSlice.actions;

export default companyListSlice.reducer;

export const getProcessCompaniesLoading = (state) => state.companyList.status;
export const getCompanies = (state) => state.companyList.companies;
export const getCompaniesCount = (state) => state.companyList.count;

// Third party libraries
import React from 'react';
import { nanoid } from 'nanoid';
// Components
import { CalculationCommonLabel } from '../calculation-common-label/Calculation-common-label';
// Util
import { parseNameRemoveIndex } from '../../util/utils';
// Styles
import styles from './CalculationExtraStop.module.css';

export const CalculationExtraStop = ({ extraStop, groupInputChange, lastTarget, index, calculationType }) => {
  const extraKey = Object.keys(extraStop);
  return (
    <fieldset className={styles.container}>
      <legend className={styles.title}>Extra stop #{index + 1}</legend>
      <label className={styles.commonLabel}>
        <span>Base Loading Hours</span>
        <select
          className={styles.commonInput}
          name={`baseLoadingHours${index}`}
          value={extraStop[`baseLoadingHours${index}`]}
          onChange={(evt) => groupInputChange(evt, index)}
          autoFocus={lastTarget === `baseLoadingHours${index}`}
        >
          <option>0</option>
          <option>0.5</option>
          <option>1</option>
          <option>1.5</option>
          <option>2</option>
          <option>2.5</option>
          <option>3</option>
          <option>3.5</option>
          <option>4</option>
          <option>4.5</option>
          <option>5</option>
          <option>5.5</option>
          <option>6</option>
          <option>6.5</option>
          <option>7</option>
          <option>7.5</option>
          <option>8</option>
          <option>8.5</option>
          <option>9</option>
          <option>9.5</option>
          <option>10</option>
          <option>10.5</option>
        </select>
      </label>

      <label className={styles.commonLabel}>
        <span>Base Unloading Hours</span>
        <select
          className={styles.commonInput}
          name={`baseUnLoadingHours${index}`}
          value={extraStop[`baseUnLoadingHours${index}`]}
          onChange={(evt) => groupInputChange(evt, index)}
          autoFocus={lastTarget === `baseUnLoadingHours${index}`}
        >
          <option>0</option>
          <option>0.5</option>
          <option>1</option>
          <option>1.5</option>
          <option>2</option>
          <option>2.5</option>
          <option>3</option>
          <option>3.5</option>
          <option>4</option>
          <option>4.5</option>
          <option>5</option>
          <option>5.5</option>
          <option>6</option>
          <option>6.5</option>
          <option>7</option>
          <option>7.5</option>
          <option>8</option>
          <option>8.5</option>
          <option>9</option>
          <option>9.5</option>
          <option>10</option>
          <option>10.5</option>
        </select>
      </label>
      <div className={styles.commonInputList}>
        {
          extraKey.map((key) => {

            if (key === `driveTime${index}`) {
              if (calculationType === 'oneDayPacking' || calculationType === 'twoDaysPacking' || calculationType === 'twoDays150Miles') {
                return (
                  <label className={styles.commonLabel}>
                    <span >Drive time</span>
                    <select
                      className={styles.commonInput}
                      name={key}
                      value={extraStop[key]}
                      onChange={(evt) => groupInputChange(evt, index)}
                      autoFocus={lastTarget === key}
                    >
                      <option>0</option>
                      <option>0.05</option>
                      <option>0.10</option>
                      <option>0.16</option>
                      <option>0.25</option>
                      <option>0.33</option>
                      <option>0.5</option>
                      <option>0.75</option>
                      <option>1</option>
                      <option>1.25</option>
                      <option>1.5</option>
                      <option>1.75</option>
                      <option>2</option>
                      <option>2.25</option>
                      <option>2.5</option>
                      <option>2.75</option>
                      <option>3</option>
                      <option>3.25</option>
                      <option>3.5</option>
                      <option>3.75</option>
                      <option>4</option>
                      <option>4.25</option>
                      <option>4.5</option>
                      <option>4.75</option>
                      <option>5</option>
                      <option>5.25</option>
                      <option>5.5</option>
                      <option>5.75</option>
                      <option>6</option>
                      <option>6.25</option>
                      <option>6.5</option>
                      <option>6.75</option>
                      <option>7</option>
                      <option>7.25</option>
                      <option>7.5</option>
                      <option>7.75</option>
                      <option>8</option>
                      <option>8.25</option>
                      <option>8.5</option>
                      <option>8.75</option>
                      <option>9</option>
                      <option>9.25</option>
                      <option>9.5</option>
                      <option>9.75</option>
                      <option>10</option>
                      <option>10.25</option>
                      <option>10.5</option>
                      <option>10.75</option>
                      <option>11</option>
                      <option>11.25</option>
                      <option>11.5</option>
                      <option>11.75</option>
                      <option>12</option>
                    </select>
                  </label>
                );
              } else { return '' }
            }

            /*  if (key === `fragileBoxes${index}`) {
               return ()
             } */

            if ((key !== `baseUnLoadingHours${index}`) && (key !== `baseLoadingHours${index}`)) {
              return (
                <CalculationCommonLabel
                  key={nanoid(extraKey.length)}
                  title={parseNameRemoveIndex(key)}
                  placeholder={'ex: 3'}
                  value={extraStop[key]}
                  inputName={key}
                  changeValue={(evt) => groupInputChange(evt, index)}
                  isFocused={lastTarget === key}
                  titleStyle={(key === `fragileBoxes${index}`) ? { color: 'red' } : {}}
                  isMilesFin={!(calculationType === 'oneDayPacking'
                    || calculationType === 'twoDaysPacking'
                    || calculationType === 'twoDays150Miles')}
                  index={index}
                />
              );
            }
            else {
              return "";
            }
          })
          /*<hr color="#30ad64"></hr>*/
        }
      </div>
    </fieldset>
  );
}

import React from 'react';
import styles from './CustomPopup.module.css';

function CustomPopup({ isOpen, info, errorMessage, hideDialog }) {

    const handleClose = () => {
        if (hideDialog) {
            hideDialog(false);
        }
    };

    return (
        <div>
            {isOpen && (
                <div className={styles.custom_dialog}>
                    <span className={styles.custom_dialog_close} onClick={handleClose}>
                        &times;
                    </span>
                    {info && <p>{info}</p>}
                    {errorMessage && <p className={styles.error_message}>{errorMessage}</p>}
                </div>
            )}
        </div>
    );
}

export default CustomPopup;
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const saveLongDistance = createAsyncThunk(
  'longdistance/save',
  async (newLongDistanceData) => await fetchedData('longdistance/longdistance', 'POST', { ...newLongDistanceData })
);

export const updateLongDistance = createAsyncThunk(
  'longdistance/update',
  async (updateLongDistance) => await fetchedData('longdistance/update', 'POST', updateLongDistance)
);

export const loadLongDistance = createAsyncThunk(
  'longdistance/getLongDistance',
  async (id) => await fetchedData('longdistance/getLongDistance', 'POST', { id })
);

export const removeLongDistance = createAsyncThunk(
  'longdistance/remove',
  async (id) => await fetchedData('longdistance/remove', 'POST', { id })
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const loadCalculations = createAsyncThunk(
  'calculationList/getCalculations',
  async (params) => await fetchedData('calculation/calculations', 'POST', { params })
);

export const loadGroupCalculations = createAsyncThunk(
  'calculationList/getGroupCalculations',
  async (params) => await fetchedData('calculation/groupCalculations', 'POST', { params })
);

export const removeCalculations = createAsyncThunk(
  'calculationList/removeCalculations',
  async (params) => await fetchedData('calculation/removeCalculations', 'DELETE', { params })
);



export const getCountOfCalculations = createAsyncThunk(
  'calculationList/getCount',
  async () => await fetchedData('calculation/count', 'GET')
);

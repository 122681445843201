// Third party libraries
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import * as immutable from 'object-path-immutable';
// Components
import { AddBtn } from '../add-btn/Add-btn';
import { LongDistanceList } from '../longdistance-list/LongDistance-list';
import { Loading } from '../loading/Loading';
import { OutDataMessage } from '../outdata-message/OutData-message';
import { SettingsContainer } from '../settings-container/Settings-container';
import { SettingsTitle } from '../settings-title/Settings-title';
// State functions
import {
  getProcessLongDistancesLoading,
  getLongDistances,
} from '../../clientStore/longdistancesSlice/longdistances-slice';
import { loadLongDistances } from '../../clientStore/longdistancesSlice/longdistances-async-thunk';
import {
  getLongDistanceLoadingStatus,
  getLongDistanceSavingStatus,
  setLongDistanceLoadingStatus,
  setLongDistanceSavingStatus,
} from '../../clientStore/longdistanceSlice/longdistance-slice';
import { useState } from 'react';
import { StatusToggleBtn } from '../status-toggle-btn/Status-toggle-btn';



export const LongDistancesMain = () => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const longdistances = useSelector(getLongDistances);
  const loadingProcess = useSelector(getProcessLongDistancesLoading);
  const longdistanceIsLoading = useSelector(getLongDistanceLoadingStatus);
  const longdistanceIsSaving = useSelector(getLongDistanceSavingStatus);
  const [filters, setFilters] = useState(
    {
      'sort': {
        'field': 'createdAt',
        'order': -1
      },
      'filter': {}
    }
  );
  const [isFilterChanged, setFilterChanged] = useState(false);

  useEffect(() => {
    if (loadingProcess === 'idle' || isFilterChanged) {
      dispatch(loadLongDistances({ 'filters': filters }));
       try {
        setFilterChanged(false)
      }
      catch (err) {
        console.log(err)
      };
    }
    if (longdistanceIsLoading !== 'idle') {
      dispatch(setLongDistanceLoadingStatus({ status: 'idle' }));
    }
    if (longdistanceIsSaving !== 'idle') {
      dispatch(setLongDistanceSavingStatus({ status: 'idle' }));
    }
  }, [dispatch, loadingProcess, longdistanceIsLoading, longdistanceIsSaving, filters, isFilterChanged]);

  const onFilterChange = (path, method, value) => {
    setFilters(immutable[method](filters, path, value));
     try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      };
  };



  return (
    <SettingsContainer>
      <div style={{ display: "flex" }}>
        <AddBtn
          name={'longdistance'}
          linkUrl={`${url}/add`}
          btnType={'link'}
        />
        <StatusToggleBtn statusField='isLongDistanceDisabled' />
      </div>
      <SettingsTitle titleName={'long distance directions'} />
      {loadingProcess === 'loading' && <Loading />}
      {loadingProcess === 'successed' && longdistances.length > 0 && <LongDistanceList longdistances={longdistances} setFilters={onFilterChange} filters={filters} />}
      {loadingProcess === 'successed' && longdistances.length === 0 && <OutDataMessage dataName={'LongDistances'} />}
    </SettingsContainer>
  );
};
// Third party libraries
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// Components
import { Icon } from '../Icon';
import { IconNames } from '../../util/const';
import { useDispatch, useSelector } from 'react-redux';
// Functions for work with store
import {
  getEditableCompany,
  getEditableCompanyLoadingStatus,
} from '../../clientStore/companySlice/company-slice';
import { getCompany } from '../../clientStore/companySlice/company-slice';
import { getCompanyId } from '../../clientStore/authSlice/auth-slice';
import { updateCompany } from '../../clientStore/companySlice/company-async-thunk';
import { loadCompany } from '../../clientStore/companySlice/company-async-thunk';
import { loadEditableCompany } from '../../clientStore/companySlice/company-async-thunk';
import {
  setEditableCompanyLoadingStatus,
} from '../../clientStore/companySlice/company-slice';
// Styles
import styles from './StatusToggleBtn.module.css';

export const StatusToggleBtn = (props) => {
  const { statusField } = props;
  const company = useSelector(getCompany);
  const companyId = useSelector(getCompanyId);
  const dispatch = useDispatch();


  const changeStatus = (evt) => {
    evt.preventDefault();
    dispatch(updateCompany({ ...company, [statusField]: !company[statusField] }, companyId));
    window.location.reload();
  };

  useEffect(() => {
    dispatch(loadCompany(companyId));
  }, [dispatch, companyId]);

  return (
    <label onClick={changeStatus} style={{ marginLeft: '10px', marginTop: '3px' }}>
      Disable
      <input type="checkbox" checked={company[statusField]} />
    </label>
  );
};
import React, { useState, useContext, useCallback, useEffect } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'

import { getCurrentRatePercent, getTotalTimeRange } from '../../util/utils';
// Styles
import styles from "./CalculationSeparateDayLongView.module.css";

export const CalculationSeparateDayLongView = ({
  commonValues,
  anyCalculationType,
  unpDepartment,
  isOOS = false,
  Trucks_Unpacking_Separate_Day,
  Movers_Unpacking_Separate_Day,
  Unpacking_Separate_Day_Lower_hours,
  Unpacking_Separate_Day_Higher_hours,
  Rate_cash_Unpacking_Separate_Day,
  Rate_card_Unpacking_Separate_Day,
  Total_Card_Unpacking_Separate_Day_Long_and_OOS,
  Truck_fee_Unpacking_Separate_Day
}) => {


  return (
    <ul className={styles.paramsSection}>
      {(commonValues.unpacking === 'Same Day') && isOOS && (!unpDepartment || !unpDepartment._id || !commonValues.unpackingRate)
        ? <h1 style={{ color: 'red' }}>Please Fill Out Unpacking Fields</h1> : ''}
      {commonValues.unpacking === 'Separate Day' && <>
        {(unpDepartment && unpDepartment._id && commonValues.unpackingRate) ? <>
          <h2 className={styles.title}>Unpacking on separate day {commonValues.partialUnPacking && "PARTIAL"}</h2>
          <div className={styles.selectedInfo}>
            {unpDepartment && <p className={styles.departmentName}>{unpDepartment.departmentName}</p>}
            {commonValues.unpackingRate && <p className={styles.rateName}>{commonValues.unpackingRate}</p>}
          </div>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Movers_Unpacking_Separate_Day}
            </p>
          </li>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Rate:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>

              {
                (unpDepartment.rates && unpDepartment.rates.calcMethod === 'deduct') ? "$" + Rate_cash_Unpacking_Separate_Day + "h /"
                  + "$" + Rate_card_Unpacking_Separate_Day
                  + ((Truck_fee_Unpacking_Separate_Day !== 0) ? "+ $" + Truck_fee_Unpacking_Separate_Day.toString() + " truck(s) fee" : "").toString()
                  :
                  "$" + (Rate_card_Unpacking_Separate_Day).toString() + "/h" +
                  ((Truck_fee_Unpacking_Separate_Day !== 0) ? "+ $" + Truck_fee_Unpacking_Separate_Day.toString() + " truck(s) fee" : "").toString()
              }
            </p>
          </li>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Trucks_Unpacking_Separate_Day}
              {(commonValues.shuttleOnUnpacking) && "+ SHUTTLE " + ((unpDepartment.extraOptions.shuttle.isCountable) ? `(x${commonValues.quantityUnpacking})` : '')}
            </p>
          </li>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total time range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {getTotalTimeRange((unpDepartment) ? unpDepartment.minimumHours : 0, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours)}
            </p>
          </li>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>FLAT RATE:</p>
            <div className={styles.roundedOrNot}>
              <span className={styles.paramsDecor}></span>
            </div>
            <p className={styles.paramsValue}>$
              {Total_Card_Unpacking_Separate_Day_Long_and_OOS}
            </p>
          </li>
        </>
          : <h1 style={{ color: 'red' }}>Please Fill Out Unpacking Fields</h1>}
      </>}
    </ul>
  );
};

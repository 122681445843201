// Third party libraries
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
// Components
import { ErrorMessage } from '../error-msg/Error-msg';
// State functions
import { getCompany } from '../../clientStore/companySlice/company-slice';
import { getCompanies } from '../../clientStore/companiesSlice/companies-slice'
import { loadCompanies } from '../../clientStore/companiesSlice/companies-async-thunk';
import {
  loadCompany,
} from '../../clientStore/companySlice/company-async-thunk';
import { getCompanyId, getUserRole, getUserId } from '../../clientStore/authSlice/auth-slice';
import { setUsersLoadingProcess } from '../../clientStore/usersSlice/user-slice';
// Util functions
import { onChangeFormValue, fetchedData } from '../../util/utils';
import { HttpCode, Role } from '../../util/const';
// Styles
import styles from './UpdateUserForm.module.css';
import DeleteCalculationsButton from '../delete-calculations-button/Delete-calculations-button';


export const UpdateUserForm = ({ login = '', role = 'moderator', id = '', allowedCreateUser = false, disabled = false, companyId = '' }) => {
  const [isFetchError, setFetchError] = useState({
    isError: false,
    message: '',
  });
  const [isPasswordChange, setPasswordChange] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUserRole = useSelector(getUserRole);
  const currentUserId = useSelector(getUserId);
  const companies = useSelector(getCompanies);
  const isSameUser = currentUserId === id
  const isCompanyUser = currentUserRole === 'companyuser'

  useEffect(() => {
    dispatch(loadCompanies());
    dispatch(loadCompany(companyId));
  }, [dispatch]);

  const companyName = useSelector(getCompany).companyName;

  const [user, setUserForm] = useState({
    id,
    login,
    password: '',
    role,
    allowedCreateUser,
    disabled,
    companyId
  });

  const [changedData, setChangedData] = useState({});

  const onUpdateUserBtnClick = async (evt, userData) => {
    evt.preventDefault();
    if (!isFetchError.isError) {
      const updatedUser = await fetchedData('user/user', 'PATCH', { id, ...userData });
      switch (updatedUser.status) {
        case HttpCode.FAILED:
          setFetchError({
            isError: true,
            message: updatedUser.data.status,
          });
          break;
        case HttpCode.OK:
          setUserForm({
            login: '',
            password: '',
            role: 'user',
            companyId
          });
          dispatch(setUsersLoadingProcess({ status: 'idle' }));
          history.replace('/users');
          break;
        default:
          setFetchError({
            isError: true,
            message: 'Not good thing happenes. Sorry',
          });
          break;
      }
    }
  };

  const onUserDataUpdate = (evt) => {
    onChangeFormValue(evt, user, setUserForm);
    setChangedData({
      ...changedData,
      [evt.target.name]: evt.target.value,
    });
  }

  const onDeleteUserBtnClick = async (evt, userId) => {
    evt.preventDefault();
    const deletedUser = await fetchedData('user/user', 'DELETE', { userId });
    if (deletedUser.status === HttpCode.OK) {
      dispatch(setUsersLoadingProcess({ status: 'idle' }));
      history.replace('/users');
    }
  };

  const renderNotDisabledCompaniesOptions = (companies) => {
    if (companies.length > 0) {
      return (
        companies.map((company) => {
          return (
            <option value={company._id} key={nanoid(companies.length)}>
              {company.companyName}
            </option>
          );
        })
      );
    }
    return ('');
  };

  return (
    <form className={styles.form}>
      <h2 className={styles.title}>User update page</h2>
      <label className='visually-hidden' htmlFor='user_name'>
        User name
      </label>
      <input
        type='text'
        id='user_name'
        className={user.loginValidate === 'error' ? styles.errorField : styles.field}
        name='login'
        value={user.login}
        placeholder={'User login'}
        onChange={(evt) => onUserDataUpdate(evt)}
        required
      />
      {!isSameUser &&
        <label className={styles.userDisabledInput}>
          <input type='checkbox' checked={user.disabled} name="disabled" onChange={(evt) => onUserDataUpdate({ target: { value: evt.target.checked, name: evt.target.name } })} /> Disabled
        </label>
      }
      {
        !isPasswordChange &&
        <button
          className={styles.changePasswordBtn}
          onClick={() => setPasswordChange(true)}
        >
          Change Password
        </button>
      }
      {
        isPasswordChange &&
        <div>
          <label className='visually-hidden' htmlFor='user_password'>
            User password
          </label>
          <input
            type='password'
            id='user_password'
            className={user.passwordValidate === 'error' ? styles.errorField : styles.field}
            name='password'
            value={user.password}
            placeholder={'Password'}
            onChange={(evt) => onUserDataUpdate(evt)}
            required
          />
        </div>
      }
      {(currentUserRole === Role.SUPERUSER) ?
        <label className="label">
          <span className={styles.labelTxt}>Choose company</span>
          <select
            className={styles.select}
            value={user.companyId}
            name='companyId'
            required
            onChange={(evt) => onUserDataUpdate(evt)}
          >
            {renderNotDisabledCompaniesOptions(companies)}
          </select>
        </label>
        :
        <label htmlFor="company_input" className={styles.label}>
          <span className={styles.labelTxt}>Company</span>
          <input
            id='company_input'
            className={styles.select}
            type='text'
            name='company'
            defaultValue={companyName}
            disabled
            required
          />
        </label>
      }
      {!isSameUser &&
        <label className="label">
          <span className={styles.labelTxt}>Choose user role</span>
          <select
            className={styles.select}
            value={user.role}
            name='role'
            required
            onChange={(evt) => onUserDataUpdate(evt)}
          >
            {currentUserRole === Role.SUPERUSER && <option>{Role.COMPANYUSER}</option>}
            {((currentUserRole === Role.SUPERUSER) || (currentUserRole === Role.COMPANYUSER)) && <option>{Role.ADMIN}</option>}
            <option>{Role.MODERATOR}</option>
          </select>
        </label>
      }
      {(currentUserRole === Role.SUPERUSER && user.role === Role.ADMIN) &&
        <div>
          <label>
            <input type='checkbox'
              checked={user.allowedCreateUser}
              name='allowedCreateUser'
              onChange={(evt) => onUserDataUpdate({ target: { value: evt.target.checked, name: evt.target.name } })}
            />
            Allow create second level user
          </label>
        </div>
      }
      {isFetchError.isError && <ErrorMessage errMessage={isFetchError.message} />}
      <button
        className={styles.btnSubmit}
        onClick={(evt) => onUpdateUserBtnClick(evt, changedData)}
      >
        Update
      </button>
      {!isSameUser &&
        <button
          className={styles.btnSubmit}
          onClick={(evt) => window.confirm("Are you sure to delete this user?") && onDeleteUserBtnClick(evt, id)}
        >
          Delete
        </button>
      }
      {isCompanyUser && <DeleteCalculationsButton companyId={companyId} />}
    </form>
  );
};
// Third party libraries
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
// Local State Functions

// Styles
import styles from './ActionTableBody.module.css';


export const ActionTableBody = ({ tableData }) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {

  }, [history, dispatch]);

  return (
    <ul className={styles.list}>
      {tableData.map((data) => {
        const currentdate = new Date(data.createdAt);
        return (
          <li key={data._id} className={styles.link}>
            <span className={styles.txt}>From ${data.totalCardFrom} to ${data.totalCardTo}</span>
            <span className={styles.txt} style={{ marginLeft: '-100px' }}>{data.userId.login}</span>
            <span className={styles.txt}>
              {
                (currentdate.getMonth() + 1).toString().padStart(2, '0') + "/"
                + currentdate.getDate().toString().padStart(2, '0') + "/"
                + currentdate.getFullYear() + " "
                + currentdate.getHours().toString().padStart(2, '0') + ":"
                + currentdate.getMinutes().toString().padStart(2, '0') + ":"
                + currentdate.getSeconds().toString().padStart(2, '0')
              }
            </span>
          </li>

        );
      })}
    </ul>
  );
};
//Third party libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Components
import { LongDistanceForm } from '../longdistance-form/LongDistance-form';
import { Loading } from '../loading/Loading';
// Functions for work with store
import {
  getLongDistance,
  getLongDistanceLoadingStatus,
} from '../../clientStore/longdistanceSlice/longdistance-slice';
import { loadLongDistance } from '../../clientStore/longdistanceSlice/longdistance-async-thunk';

export const LongDistanceEdit = () => {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(getLongDistanceLoadingStatus);
  const longdistance = JSON.parse(JSON.stringify(useSelector(getLongDistance)));
  const params = useParams();

  const id = params.longdistanceId;

  useEffect(() => {
    if (loadingStatus === 'idle') {
      dispatch(loadLongDistance(id));
    }
  }, [ dispatch, id, loadingStatus ]);

  return(
    <React.Fragment>
      {loadingStatus !== 'successed' && <Loading />}
      {loadingStatus === 'successed' && <LongDistanceForm longdistanceData={ longdistance } formType={'edit'} longdistanceId={id} />}
    </React.Fragment>
  );
}
//Third party libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Components
import { CompanyForm } from '../company-form/Company-form';
import { Loading } from '../loading/Loading';
// Functions for work with store
import {
  getEditableCompany,
  getEditableCompanyLoadingStatus,
} from '../../clientStore/companySlice/company-slice';
import { loadEditableCompany } from '../../clientStore/companySlice/company-async-thunk';
import {
  setEditableCompanyLoadingStatus,
} from '../../clientStore/companySlice/company-slice';

export const CompanyEdit = () => {
  const dispatch = useDispatch();
  let loadingStatus = useSelector(getEditableCompanyLoadingStatus);
  const company = useSelector(getEditableCompany);
  const params = useParams();

  const id = params.companyId;

  useEffect(() => {
    if (loadingStatus === 'idle') {
      dispatch(loadEditableCompany(id));
    }
    return () => {
      dispatch(setEditableCompanyLoadingStatus({status:'idle'}))
    }
  }, [dispatch, id]);

  return (
    <React.Fragment>
      {loadingStatus !== 'successed' && <Loading />}
      {loadingStatus === 'successed' && <CompanyForm companyData={company} formType={'edit'} companyId={id} />}
    </React.Fragment>
  );
}
// Third party libraries
import PropTypes from 'prop-types';
// Styles
import styles from './LongDistanceLabel.module.css';

export const LongDistanceLabel = (props) => {
  const {
    inputId,
    name,
    title,
    placeholder,
    value,
    changeValue,
    valueType,
    isFocused,
    isLong,
    inputType,
  } = props;

  return (
    <label
      className={styles.inputContainer}
      htmlFor={inputId}
    >
      <p className={styles.inputName}>{title}</p>
      <div style={{ position: "relative" }}>
        {valueType &&
          <span className={styles.inputValueType}>{valueType}</span>
        }
        <input
          id={inputId}
          className={styles.input}
          type={(inputType) ? inputType : 'text'}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={changeValue}
          onWheel={(e) => e.target.blur()}
          /*autoFocus={isFocused} */
          required
        />
      </div>
    </label>
  );
};

// Variable type settings
LongDistanceLabel.propTypes = {
  inputId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  changeValue: PropTypes.func.isRequired,
  valueType: PropTypes.string,
  isFocused: PropTypes.bool,
  isLong: PropTypes.bool,
  inputType: PropTypes.string,
};

//Third party libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Components
import { OutOfStateForm } from '../outofstate-form/OutOfState-form';
import { Loading } from '../loading/Loading';
// Functions for work with store
import {
  getOutOfState,
  getOutOfStateLoadingStatus,
} from '../../clientStore/outofstateSlice/outofstate-slice';
import { loadOutOfState } from '../../clientStore/outofstateSlice/outofstate-async-thunk';

export const OutOfStateEdit = () => {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(getOutOfStateLoadingStatus);
  const outofstate = JSON.parse(JSON.stringify(useSelector(getOutOfState)));
  const params = useParams();

  const id = params.outofstateId;

  useEffect(() => {
    if (loadingStatus === 'idle') {
      dispatch(loadOutOfState(id));
    }
  }, [dispatch, id, loadingStatus]);

  return (
    <React.Fragment>
      {loadingStatus !== 'successed' && <Loading />}
      {loadingStatus === 'successed' && <OutOfStateForm outofstateData={outofstate} formType={'edit'} outofstateId={id} />}
    </React.Fragment>
  );
}
// Components
import { Icon } from '../Icon';
// Util functions
import { IconNames } from '../../util/const';
// Styles
import styles from './Search.module.css';
import { useState } from 'react';

export const Search = ({ searchName, setFilters }) => {
  const [searchText, setSearchText] = useState();

  return (
    <div className={styles.container}>
      <label>
        <span className='visually-hidden'>Input {searchName} </span>
        <input
          className={styles.input}
          name='searchText'
          id='search'
          placeholder={searchName}
          onChange={(evt) => setSearchText(evt.target.value)}
          onKeyDown={evt => { (evt.code === "Enter" || evt.code === 'NumpadEnter') && setFilters("search", 'set', { 'field': 'login', 'searchText': searchText }) }}
        />
      </label>
      <button className={styles.submitBtn}
        onClick={() => {
          setFilters("search", 'set', { 'field': 'login', 'searchText': searchText });
        }}>
        <Icon name={IconNames.SEARCH} />
      </button>
    </div>
  );
};
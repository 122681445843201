import { createSlice } from '@reduxjs/toolkit';
import {
  loadCompany,
  saveCompany,
  updateCompany,
  removeCompany,
  loadEditableCompany,
} from './company-async-thunk';


const initialState = {
  savingStatus: 'idle',
  loadingStatus: 'idle',
  editableCompanyLoadingStatus: 'idle',
  company: {},
  countOfCompanies: 0,
  errors: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanySavingStatus(state, action) {
      const { status } = action.payload;
      state.savingStatus = status;
    },
    setCompanyLoadingStatus(state, action) {
      const { status } = action.payload;
      state.loadingStatus = status;
    },
    setEditableCompanyLoadingStatus(state, action) {
      const { status } = action.payload;
      state.editableCompanyLoadingStatus = status;
    },
    setCompanyRemovingStatus(state, action) {
      const { status } = action.payload;
      state.removingStatus = status;
    },
  },
  extraReducers: {
    // saveCompany extra reducer
    [saveCompany.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [saveCompany.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [saveCompany.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.company = action.payload.data;
    },

    // updateDepartmen extra reducer
    [updateCompany.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [updateCompany.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [updateCompany.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.company = action.payload.data;
    },

    // loadCompany extra reducer
    [loadCompany.pending]: (state, action) => {
      state.loadingStatus = 'loading';
    },
    [loadCompany.rejected]: (state, action) => {
      state.loadingStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadCompany.fulfilled]: (state, action) => {
      state.loadingStatus = 'successed';
      state.company = action.payload.data;
    },

    // loadEditableCompany extra reducer
    [loadEditableCompany.pending]: (state, action) => {
      state.editableCompanyLoadingStatus = 'loading';
    },
    [loadEditableCompany.rejected]: (state, action) => {
      state.editableCompanyLoadingStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadEditableCompany.fulfilled]: (state, action) => {
      state.editableCompanyLoadingStatus = 'successed';
      state.editableCompany = action.payload.data;
    },

    //removeCompany extra reducer
    [removeCompany.pending]: (state, action) => {
      state.removingStatus = 'loading';
    },
    [removeCompany.rejected]: (state, action) => {
      state.removingStatus = 'failed';
      state.errors = action.error.message;
    },
    [removeCompany.fulfilled]: (state, action) => {
      state.removingStatus = 'successed';
      state.company = action.payload.data;
    },
  },
});

export const { setCompanySavingStatus, setCompanyLoadingStatus, setCompanyRemovingStatus, setEditableCompanyLoadingStatus } = companySlice.actions;

export default companySlice.reducer;

export const getCompanySavingStatus = (state) => state.company.savingStatus;

export const getCompanyLoadingStatus = (state) => state.company.loadingStatus;

export const getEditableCompanyLoadingStatus = (state) => state.company.editableCompanyLoadingStatus;

export const getCompanyRemovingStatus = (state) => state.company.removingStatus;

export const getCompany = (state) => state.company.company;//state.company.company;

export const getEditableCompany = (state) => state.company.editableCompany;
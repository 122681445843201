import React from 'react';
import styles from './TagRemovePopup.module.css';

function TagRemovePopup({ isOpen, info, calculationNames, handleRemoveTag, hideDialog }) {

    const handleClose = () => {
        if (hideDialog) {
            hideDialog(false);
        }
    };

    return (
        <div>
            {isOpen && (
                <div className={styles.custom_dialog}>
                    <span className={styles.custom_dialog_close} onClick={handleClose}>
                        &times;
                    </span>
                    <p>{info}</p>
                    {calculationNames.length > 1 ? (
                        <p>This tag also exists in the following templates: {calculationNames.join(', ')}.</p>
                    ) : (
                        <p>Are you sure you want to remove this tag?</p>
                    )}
                    <button onClick={() => handleRemoveTag(false)} className={styles.removeButton}>Delete only from current template</button>
                    {calculationNames.length > 1 &&
                        <button onClick={() => handleRemoveTag('removeFromEverywhere')} className={styles.removeButton}>Delete from everywhere</button>
                    }
                </div>
            )}
        </div>
    );
}

export default TagRemovePopup;
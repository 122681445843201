import { createSlice } from '@reduxjs/toolkit';
import { loadUsers,loadUsersAll, saveUser } from './users-async-thunk';

const initialState = {
  status: 'idle',
  statusAll: 'idle',
  userList: [],
  userListAll: [],
  errors: null
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersLoadingProcess(state, action) {
      const { status } = action.payload;
      state.status = status;
    },
  },
  extraReducers: {
    // getUsers extra reducer
    [loadUsers.pending]: (state, action) => {
      state.status = 'loading';
    },
    [loadUsers.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [loadUsers.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.userList = action.payload.data;
    },
    // getUsersAll extra reducer
    [loadUsersAll.pending]: (state, action) => {
      state.statusAll = 'loading';
    },
    [loadUsersAll.rejected]: (state, action) => {
      state.statusAll = 'failed';
      state.errors = action.error.message;
    },
    [loadUsersAll.fulfilled]: (state, action) => {
      state.statusAll = 'successed';
      state.userListAll = action.payload.data;
    },
    // saveUser extra reducer
    [saveUser.pending]: (state, action) => {
      state.status = 'loading';
    },
    [saveUser.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [saveUser.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.errors = action.payload;
    },
  },
});

export const { setUsersLoadingProcess } = usersSlice.actions;

export default usersSlice.reducer;

export const getProcessUsersLoading = (state) => state.users.status;
export const getProcessUsersAllLoading = (state) => state.users.statusAll;
export const getUsersError = (state) => state.users.errors;
export const getUsers = (state) => state.users.userList;
export const getUsersAll = (state) => state.users.userListAll;

import { createSlice } from '@reduxjs/toolkit';
import { loadTemplates, getCountOfTemplates } from './templates-async-thunk';

const initialState = {
  status: 'idle',
  templates: [],
  count: 0,
  errors: null,
};

const templateListSlice = createSlice({
  name: 'templateList',
  initialState,
  reducers: {
    setTemplatesLoadingStatus(state, action) {
      const { status } = action.payload;
      state.status = status;
    },
  },
  extraReducers: {
    // getTemplates extra reducer
    [loadTemplates.pending]: (state, action) => {
      state.status = 'loading';
    },
    [loadTemplates.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [loadTemplates.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.templates = action.payload.data;
    },

    // getCountOfTemplates extra reducer
    [getCountOfTemplates.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getCountOfTemplates.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [getCountOfTemplates.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.count = action.payload.data;
    },
  },
});

export const { setTemplatesLoadingStatus } = templateListSlice.actions;

export default templateListSlice.reducer;

export const getProcessTemplatesLoading = (state) => state.templateList.status;
export const getTemplates = (state) => state.templateList.templates;
export const getTemplatesCount = (state) => state.templateList.count;

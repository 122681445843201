// Third party libraries
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import * as immutable from 'object-path-immutable';
// Components
import { AddBtn } from '../add-btn/Add-btn';
import { CompanyList } from '../company-list/Company-list';
import { Loading } from '../loading/Loading';
import { OutDataMessage } from '../outdata-message/OutData-message';
import { SettingsContainer } from '../settings-container/Settings-container';
import { SettingsTitle } from '../settings-title/Settings-title';
// State functions
import {
  getProcessCompaniesLoading,
  getCompanies,
} from '../../clientStore/companiesSlice/companies-slice';
import { loadCompanies } from '../../clientStore/companiesSlice/companies-async-thunk';
import {
  getCompanyLoadingStatus,
  getCompanySavingStatus,
  setCompanyLoadingStatus,
  setCompanySavingStatus,
} from '../../clientStore/companySlice/company-slice';
import { useState } from 'react';

export const CompaniesMain = () => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const companies = useSelector(getCompanies);

  const loadingProcess = useSelector(getProcessCompaniesLoading);


  const [filters, setFilters] = useState(
    {
      'sort': {
        'field': 'createdAt',
        'order': -1
      },
      'filter': {}
    }
  );
  const [isFilterChanged, setFilterChanged] = useState(false);


  useEffect(() => {
    if (loadingProcess === 'idle' || isFilterChanged) {
      dispatch(loadCompanies({ 'filters': filters }));
       try {
        setFilterChanged(false)
      }
      catch (err) {
        console.log(err)
      };
    }
  }, [dispatch, loadingProcess, isFilterChanged]);

  const onFilterChange = (path, method, value) => {
    setFilters(immutable[method](filters, path, value));
     try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      };
  };

  return (
    <SettingsContainer>
      <AddBtn
        name={'company'}
        linkUrl={`${url}/add`}
        btnType={'link'}
      />
      <SettingsTitle titleName={'companies'} />
      {loadingProcess === 'loading' && <Loading />}
      {loadingProcess === 'successed' && companies.length > 0 && <CompanyList companies={companies} setFilters={onFilterChange} filters={filters} />}
      {loadingProcess === 'successed' && companies.length === 0 && <OutDataMessage dataName={'Companies'} />}
    </SettingsContainer>
  );
};
export const templateDataModel = {
  templateName: '',
  templateCalcType: '',
  authorId: '',
  type: 'hourly',//hourly, cuft
  companyId: '',
  templateEditorState: {},
  templateCalcObj: {},
  tags: [
    {
      tagName: '',
      elseText: '',
      elseTextType: 'input', //input, result, none, tag
      elseSelectedTag: 'none',
      conditions: [
        {
          operator0: '=',
          selectedItemObj0: { value: '', label: '' },
          selectedItemType0: '',
          selectOptions0: [],
          resultingText0: '',
          conditionAmountValue0: '',
          conditionSelectValue0: '',
          conditionValueType0: 'input',//input, none
          resultTextType0: 'input', //input, result, none, tag
          selectedTag0: 'none',
        }]
    }
  ],

};

// Components
import { PageHeaderBtns } from '../page-header-btns/Page-header-btns';
import { PageTitle } from '../page-title/Page-title';
// Styles
import styles from './PageHeaderWOB.module.css';

export const PageHeaderWOB = ({ titleIcon, title, menuItems }) => {
  return (
    <section className={styles.container}>
      <div className={styles.block} style={{ height: '50px' }}>
        {/*     <PageTitle iconName={titleIcon} titleText={title} />
        <PageHeaderBtns  /> */}
      </div>
    </section>
  );
};
// Third party libraries
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import { useHistory, Prompt } from 'react-router-dom';
import * as immutable from 'object-path-immutable';
import { has, isEqual, set, template } from 'lodash';
// Components
import { CalculationCommonValues } from '../calculation-common-values/Calculation-common-values';
import { CalculationOneDayResult } from '../calculation-one-day-result/Calculation-one-day-result';
import { CalculationOOSOneDayResult } from '../calculation-oos-one-day-result/Calculation-oos-one-day-result';
import { CalculationTabOneDayValues } from '../calculation-tab-one-day-values/Calculation-tab-one-day-values';
import { CalculationTabOOSOneDayValues } from '../calculation-tab-oos-one-day-values/Calculation-tab-oos-one-day-values';
import { CalculationTwoDaysResult } from '../calculation-two-days-result/Calculation-two-days-result';
import { CalculationTabTwoDaysValues } from '../calculation-tab-two-days-values/Calculation-tab-two-days-values';
import { ActionsMain } from '../actions-main/Actions-main';
import { Icon } from '../Icon';
import mailIcon from './../../img/mail.png';
import mailGreenIcon from './../../img/mailGreen.png';


import { CalculationTwoDays150Result } from '../calculation-two-days-150-result/Calculation-two-days-150-result';
import { CalculationTabTwoDays150Values } from '../calculation-tab-two-days-150-values/Calculation-tab-two-days-150-values';
//import { CalculationTabs } from '../calculation-tabs/Calculatuon-tabs'
import { FormContainer } from '../form-container/Form-container';
import { BtnSubmit } from '../btn-submit/Btn-submit';

import { IconNames, Role } from '../../util/const';
// Local State Functions
import {
  getDepartment,
  getDepartmentLoadingStatus,
} from '../../clientStore/departmentSlice/department-slice';

import {
  getUnpDepartment,
} from '../../clientStore/unpDepartmentSlice/unp-department-slice';
import {
  cloneToSubCalculation,
} from '../../clientStore/calculationSlice/calculation-async-thunk';
import { getUnpDepartmentLoadingStatus } from '../../clientStore/unpDepartmentSlice/unp-department-slice';
import { loadDepartment } from '../../clientStore/departmentSlice/department-async-thunk';
import { loadDepartments } from '../../clientStore/departmentsSlice/departments-async-thunk';
import { loadLongDistances } from '../../clientStore/longdistancesSlice/longdistances-async-thunk';
import { loadOutOfStates } from '../../clientStore/outofstatesSlice/outofstates-async-thunk';
import { getDepartments, getProcessDepartmentsLoading } from '../../clientStore/departmentsSlice/departments-slice';
import { getLongDistances, getProcessLongDistancesLoading } from '../../clientStore/longdistancesSlice/longdistances-slice';
import { getOutOfStates, getProcessOutOfStatesLoading } from '../../clientStore/outofstatesSlice/outofstates-slice';
import {
  saveCalculation,
  updateCalculation,
} from '../../clientStore/calculationSlice/calculation-async-thunk';
import { getUserId, getCompanyId, getUserName, getUserRole } from '../../clientStore/authSlice/auth-slice';
import {
  setCalculationsLoadingStatus,
} from '../../clientStore/calculationsSlice/calculations-slice';
import { CalculationTabLongOneDayValues } from '../calculation-tab-long-one-day-values/Calculation-tab-long-one-day-values';
import { CalculationLongOneDayResult } from '../calculation-long-one-day-result/Calculation-long-one-day-result';
import { CalculationTabLongTwoDayValues } from '../calculation-tab-long-two-day-values/Calculation-tab-long-two-day-values';
import { CalculationTabOOSLongTwoDayValues } from '../calculation-tab-oos-long-two-day-values/Calculation-tab-oos-long-two-day-values';
import { CalculationLongTwoDayResult } from '../calculation-long-two-day-result/Calculation-long-two-day-result';
import { CalculationOOSLongTwoDayResult } from '../calculation-oos-long-two-day-result/Calculation-oos-long-two-day-result';

import {
  getCalculationSavingStatus,
  setCalculationSavingStatus,
  setCalculationLoadingStatus,
  setCalculationRemovingStatus,
  getCalculation,
  getCalculationSavingError
} from '../../clientStore/calculationSlice/calculation-slice';
import { getCompany } from '../../clientStore/companySlice/company-slice';
import { loadCompany } from '../../clientStore/companySlice/company-async-thunk';
// Data model
import { calculationDataModel } from '../../models/calculation.js';

// Util
import { fetchedData, hasRole } from '../../util/utils';
// Styles
import styles from './CalculationForm.module.css';
import { getLongDistance, getLongDistanceLoadingStatus } from '../../clientStore/longdistanceSlice/longdistance-slice';
import { getOutOfState, getOutOfStateLoadingStatus } from '../../clientStore/outofstateSlice/outofstate-slice';
import { loadLongDistance } from '../../clientStore/longdistanceSlice/longdistance-async-thunk';
import { loadOutOfState } from '../../clientStore/outofstateSlice/outofstate-async-thunk';
import { isCalculationFormValid } from '../../util/formValidations';
import { TemplatePreviewPopup } from '../template-preview-popup/Template-preview-popup';
import { getTemplate, getTemplateLoadingStatus } from '../../clientStore/templateSlice/template-slice';
import { loadTemplateByType } from '../../clientStore/templateSlice/template-async-thunk';
import { convertFromRaw, EditorState } from 'draft-js';
import { loadUnpDepartment } from '../../clientStore/unpDepartmentSlice/unp-department-async-thunk';
import { connectSocket, disconnectSocket } from '../../context/socket';
import { getProcessTemplatesLoading, getTemplates } from '../../clientStore/templatesSlice/templates-slice';
import { loadTemplates } from '../../clientStore/templatesSlice/templates-async-thunk';
import { BtnCloneToCalcGroup } from '../btn-clone-to-calc-group/Btn-clone-to-calc-group.jsx';
import { ChildCalcsMain } from '../child-calcs-main/Child-calcs-main.jsx';
import { GroupCalculationsTitle } from '../group-calculations-title/Group-calculations-title.jsx';
import { TableHeader } from '../table-header/Table-header.jsx';
import { BtnMainCalc } from '../btn-main-calc/Btn-main-calc.jsx';
import InventoryContainer from '../inventory-container/InventoryContainer.jsx';
import { getFurniture, getFurnitureLoadingStatus } from '../../clientStore/furnitureSlice/furniture-slice.js';
import { getRoomsAreas, getRoomsAreasLoadingStatus } from '../../clientStore/roomsAreasSlice/roomsAreas-slice.js';
import { loadFurniture } from '../../clientStore/furnitureSlice/furniture-async-thunk.js';
import { loadRoomsAreas } from '../../clientStore/roomsAreasSlice/roomsAreas-async-thunk.js';
import CustomTags from '../custom-tags/CustomTags.jsx';



export const CalculationForm = ({ calculationData, formType = 'new', calculationId = undefined }) => {
  const [updateableSave, setUpdateableSave] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [oldTemplateWasUsed, setOldTemplateWasUsed] = useState(false)
  const [currentLoadedTemplate, setCurrentLoadedTemplate] = useState();
  const [lastTarget, setLastTarget] = useState(undefined);
  const [showSideBar, setShowSideBar] = useState(false);
  const [showCustomTags, setShowCustomTags] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const [popUpShown, setShown] = useState(false)
  const [previewText, setPreviewText] = useState('');
  const [optionsPreviewText, setOptionsPreviewText] = useState('');
  const dispatch = useDispatch();
  const loadingDepartmentsStatus = useSelector(getProcessDepartmentsLoading);
  const loadingLongDistancesStatus = useSelector(getProcessLongDistancesLoading);
  const loadingOutOfStatesStatus = useSelector(getProcessOutOfStatesLoading);
  const departments = useSelector(getDepartments);
  const department = useSelector(getDepartment);
  const unpDepartment = useSelector(getUnpDepartment);
  const departmentLoadingStatus = useSelector(getDepartmentLoadingStatus);
  const unpDepartmentLoadingStatus = useSelector(getUnpDepartmentLoadingStatus);
  const longdistances = useSelector(getLongDistances);
  const outofstates = useSelector(getOutOfStates);
  const templates = useSelector(getTemplates);
  const savedStatus = useSelector(getCalculationSavingStatus);
  const savingError = useSelector(getCalculationSavingError);
  const savedCalculation = useSelector(getCalculation);
  const userId = useSelector(getUserId);
  const companyId = useSelector(getCompanyId);
  const history = useHistory();
  const [rates, setRates] = useState([]);
  const [unpRates, setUnpRates] = useState([]);
  const [factors, setFactors] = useState([]);
  const calculation = calculationData ? calculationData : calculationDataModel;
  const [cursorPosition, setCursorPosition] = useState();
  const company = useSelector(getCompany)
  const longDistanceLoadingStatus = useSelector(getLongDistanceLoadingStatus);
  const longDistance = useSelector(getLongDistance);
  const oosLoadingStatus = useSelector(getOutOfStateLoadingStatus);
  const outOfState = useSelector(getOutOfState);
  const templateLoadingStatus = useSelector(getTemplateLoadingStatus);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const templatesLoadingProcess = useSelector(getProcessTemplatesLoading);
  const [validationError, setValidationError] = useState({});
  const [showGenerateEmail, setShowGenerateEmail] = useState(false);
  const currentUserRole = useSelector(getUserRole);
  const headerTitles = {
    calculationNumber: 'N of calculation',
    departmentId: 'Department',
    thirdColumn: "",
    userId: 'User',
    createdAt: 'Created Date',
  };
  const [isAfterAutoSave, setIsAfterAutoSave] = useState(false);
  const userName = useSelector(getUserName);

  useEffect(() => {
    if (templatesLoadingProcess === 'idle') {
      dispatch(loadTemplates());
    }
  }, [dispatch, templatesLoadingProcess])

  let sameDepartmentSettings = true;
  let sameUnpDepartmentSettings = true;
  let sameLongDirectionSettings = true;
  let sameOOSDirectionSettings = true;
  if (calculationData && calculationData.department) {
    sameDepartmentSettings = isEqual(department, calculationData.department)
  }
  if (calculationData && calculationData.unpDepartment) {
    sameUnpDepartmentSettings = isEqual(unpDepartment, calculationData.unpDepartment)
  }
  if (calculationData && calculationData.longDistance) {
    sameLongDirectionSettings = isEqual(longDistance, calculationData.longDistance)
  }
  if (calculationData && calculationData.outOfState) {
    sameOOSDirectionSettings = isEqual(outOfState, calculationData.outOfState)
  }

  const [calculationForm, setCalculationFormValue] = useState({
    ...calculation,
    ...(formType === "new" ? { userId: userId, historyUserId: userId } : { historyUserId: userId }),
    companyId: companyId,
    version: calculation.version + 1,
  });

  const unChangedCalculationForm = {
    ...calculation,
    ...(formType === "new" ? { userId: userId, historyUserId: userId } : { historyUserId: userId }),
    companyId: companyId,
    version: calculation.version + 1,
  }

  const [tags, setTags] = useState([])
  const [tagsValuesDefault, setTagsValuesDefault] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (calculationData) {
      if (calculationData.department && departmentLoadingStatus === 'idle') {
        dispatch(loadDepartment(calculationData.department._id));
      }
      if (calculationData.unpDepartment && unpDepartmentLoadingStatus === 'idle') {
        dispatch(loadUnpDepartment(calculationData.unpDepartment._id));
      }
      if (calculationData.longDistance && longDistanceLoadingStatus === 'idle') {
        dispatch(loadLongDistance(calculationData.longDistance._id));
      }
      if (calculationData.outOfState && oosLoadingStatus === 'idle') {
        dispatch(loadOutOfState(calculationData.outOfState._id));
      }
    }
    if (loadingDepartmentsStatus === 'idle') {
      dispatch(loadDepartments());
    }
    if (loadingLongDistancesStatus === 'idle') {
      dispatch(loadLongDistances());
    }
    if (loadingOutOfStatesStatus === 'idle') {
      dispatch(loadOutOfStates());
    }

    if (savedStatus === 'successed' && updateableSave) {
      dispatch(setCalculationSavingStatus({ status: 'idle' }));
      dispatch(setCalculationLoadingStatus({ status: 'idle' }));
      dispatch(setCalculationsLoadingStatus({ status: 'idle' }));
      dispatch(setCalculationRemovingStatus({ status: 'idle' }));
      if (savedCalculation._id) {
        history.replace('/calculations/' + savedCalculation._id);
      }
      else if (savedCalculation.message) {
        alert(savedCalculation.message)
      }
      else {
        alert('Unknown error. Please inform developer.')
      }
      setIsFormDirty(false)

      /*  history.go(0); */
    }

    if (savedStatus === 'failed') {
      setIsFirstTime(true)
      alert(savingError);
    }
  }, [dispatch, history, calculationData, departmentLoadingStatus, savedStatus, loadingDepartmentsStatus, loadingLongDistancesStatus, loadingOutOfStatesStatus]);

  useEffect(() => {
    dispatch(loadCompany(companyId));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (templateLoadingStatus === 'idle' && calculationData) {
      dispatch(loadTemplateByType({ calculationType: calculationData.calculationType, companyId: companyId }));
    }
  }, [dispatch, companyId]);

  const onFormValueChange = (path, method, value) => {
    setCalculationFormValue(immutable[method](calculationForm, path, value));
    setShowCustomTags(false);
  };

  const onCustomTagsChange = (path, method, value) => {
    setCalculationFormValue(immutable[method](calculationForm, path, value));
  };

  useEffect(() => {
    setIsFormDirty((savedStatus !== 'successed' && savedStatus !== 'loading') && !isEqual(calculationForm, unChangedCalculationForm))
  }, [calculationForm, savedStatus]);



  useEffect(() => {
    try {
      for (let index = 0; index < templates.length; index++) {
        const template = templates[index];
        if (template.type) {
          if (template.templateCalcType === calculationForm.calculationType) {
            setTags(template.tags)
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(template.templateEditorState))))
            setCurrentLoadedTemplate(template)
            break;
          }
        } else {
          if (template.templateCalcType === calculationForm.calculationType && template.type == calculationForm.department?.type) {
            setTags(template.tags)
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(template.templateEditorState))))
            setCurrentLoadedTemplate(template)
            break;
          }
        }
      }
    } catch (err) { console.log(err) }
  }, [calculationForm.calculationType, calculationForm.department, templates]);

  useEffect(() => {
    if (calculationForm.template && calculationForm.template._id && currentLoadedTemplate) {
      if (calculationForm.template._id !== currentLoadedTemplate._id) {
        setCalculationFormValue(immutable.set(calculationForm, 'template', currentLoadedTemplate));
      }
      if (!isEqual(currentLoadedTemplate, calculationForm.template) && currentLoadedTemplate._id === calculationForm.template._id) {
        setOldTemplateWasUsed(true)
        setTags(calculationForm.template.tags)
        setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(calculationForm.template.templateEditorState))))
      }
    } else {
      try {
        if (!calculationForm.template || !calculationForm.template._id) {
          setCalculationFormValue(immutable.set(calculationForm, 'template', currentLoadedTemplate));
        }
      } catch (err) { console.log(err) }
    }
  }, [currentLoadedTemplate, calculationForm.template])

  const formSubmit = async (evt, isAutoSave) => {
    if (evt) evt.preventDefault();
    setIsFormDirty(false)
    if (await isCalculationFormValid(calculationForm) && !validationError[calculationForm.calculationType]) {
      switch (formType) {
        case 'new':
          dispatch(saveCalculation({ ...calculationForm, isAutoSave, isFirstTime }));
          break;
        case 'edit':
          dispatch(updateCalculation(calculationForm, calculationId));
          break;
        default:
          throw new Error('Invalid form type');
      }
    }
    else {

    }
  };

  const applyNewRates = (evt) => {
    evt.preventDefault();
    try {
      if (!sameDepartmentSettings) {
        fetchedData('department/getDepartment', 'POST', { id: calculationData.department._id }).then((loadedDepartment) => {
          if (loadedDepartment.status === 200) {
            dispatch(updateCalculation({ ...calculationForm, department: loadedDepartment.data }, calculationId));
          }
        });
      }
      if (!sameUnpDepartmentSettings) {
        fetchedData('department/getDepartment', 'POST', { id: calculationData.unpDepartment._id }).then((loadedDepartment) => {
          if (loadedDepartment.status === 200) {
            dispatch(updateCalculation({ ...calculationForm, unpDepartment: loadedDepartment.data }, calculationId));
          }
        });
      }
      if (!sameLongDirectionSettings) {
        fetchedData('longdistance/getLongDistance', 'POST', { id: calculationData.longDistance._id }).then((loadedLongDistance) => {
          if (loadedLongDistance.status === 200) {
            dispatch(updateCalculation({ ...calculationForm, longDistance: loadedLongDistance.data }, calculationId));
          }
        });
      }
      if (!sameOOSDirectionSettings) {
        fetchedData('outofstate/getOutOfState', 'POST', { id: calculationData.outOfState._id }).then((loadedoutOfState) => {
          if (loadedoutOfState.status === 200) {
            dispatch(updateCalculation({ ...calculationForm, outOfState: loadedoutOfState.data }, calculationId));
          }
        });
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const applyNewTemplate = (evt) => {
    evt.preventDefault();
    dispatch(updateCalculation({ ...calculationForm, template: currentLoadedTemplate }, calculationId));
  }

  const onChangeDepartmentSelect = async (evt) => {
    if (evt.target.value === 'None') {
      setCalculationFormValue(immutable.set(calculationForm, 'oneDayPacking.rate', 'none'));
      setCalculationFormValue(immutable.set(calculationForm, 'twoDaysPacking.firstDayRate', 'none'));
      setCalculationFormValue(immutable.set(calculationForm, 'twoDaysPacking.secondDayRate', 'none'));
      setRates([]);
      onFormValueChange('department', 'del');
      setCalculationFormValue({ ...calculationForm, department: undefined });
      return;
    }
    const selectedDepartment = await fetchedData('department/getDepartment', 'POST', { id: evt.target.value });
    if (selectedDepartment.status === 200) {
      setCalculationFormValue(immutable.set(calculationForm, 'department', { ...selectedDepartment.data }));
      setRates(selectedDepartment.data.rates.ratesList);
    }
  }

  const onChangeUnpDepartmentSelect = async (evt) => {
    if (evt.target.value === 'None') {
      onFormValueChange('unpDepartment', 'del');
      setCalculationFormValue({ ...calculationForm, unpDepartment: undefined });
      return;
    }
    const selectedDepartment = await fetchedData('department/getDepartment', 'POST', { id: evt.target.value });
    if (selectedDepartment.status === 200) {
      setCalculationFormValue(immutable.set(calculationForm, 'unpDepartment', { ...selectedDepartment.data }));
      setUnpRates(selectedDepartment.data.rates.ratesList);
    }
  }

  const onChangeLongDistanceSelect = async (evt) => {
    if (evt.target.value === 'None') {

      onFormValueChange('longDistance', 'del');
      setCalculationFormValue({ ...calculationForm, longDistance: undefined });
      return;
    }
    const selectedLongDistance = await fetchedData('longdistance/getLongDistance', 'POST', { id: evt.target.value });
    if (selectedLongDistance.status === 200) {
      setCalculationFormValue(immutable.set(calculationForm, 'longDistance', { ...selectedLongDistance.data }));
    }
  }

  const onChangeOutOfStateSelect = async (evt) => {
    if (evt.target.value === 'None') {
      setFactors([]);
      onFormValueChange('outOfState', 'del');
      setCalculationFormValue({ ...calculationForm, outOfState: undefined });
      return;
    }
    const selectedOutOfState = await fetchedData('outofstate/getOutOfState', 'POST', { id: evt.target.value });
    if (selectedOutOfState.status === 200) {
      setCalculationFormValue(immutable.set(calculationForm, 'outOfState', { ...selectedOutOfState.data }));
      setFactors(selectedOutOfState.data.parameters)
    }
  }

  const resetLastTarget = () => {
    if (lastTarget) {
      setLastTarget(undefined);
    }
  }



  const clearCursorPosition = () => {
    if (cursorPosition) {
      setCursorPosition(undefined);
    }
  }

  const renderProceduresOptions = () => {
    let proceduresOptions = [];
    if (calculationForm.department) {
      proceduresOptions = calculationForm.department.packing.cratings.map((crating, index) => {
        return <option key={nanoid(6)}>{crating[`crateName${index}`]}</option>
      })
    }
    return proceduresOptions;
  }

  const renderLongDistancesOptions = () => {
    let longDistancesOptions = [];
    longDistancesOptions = longdistances.map((longdistance) => {
      return (
        <option key={nanoid(longdistances.length)} value={longdistance._id}>{longdistance.directionName}</option>
      );
    })
    return longDistancesOptions;
  }

  const renderOOSOptions = () => {
    let outOfStatesOptions = [];
    outOfStatesOptions = outofstates.map((outofstates) => {
      return (
        <option key={nanoid(outofstates.length)} value={outofstates._id}>{outofstates.directionName}</option>
      );
    })
    return outOfStatesOptions;
  }

  const sepCheckboxValueChange = (evt) => {
    resetLastTarget();

    switch (evt.target.value) {
      case 'true':
        return onFormValueChange(`commonValues.${evt.target.name}`, 'set', false);
      case 'false':
        return onFormValueChange(`commonValues.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };

  const checkMarkCheckboxValueChange = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedCheckMarksNames = [...calculationForm.selectedMiscCheckMarks];
        var index = calculationForm.selectedMiscCheckMarks.indexOf(evt.target.name);
        if (index !== -1) {
          newSelectedCheckMarksNames.splice(index, 1);
          return onFormValueChange(`selectedMiscCheckMarks`, 'set', newSelectedCheckMarksNames);
        }
        break;
      case 'false':
        return onFormValueChange(`selectedMiscCheckMarks`, 'push', evt.target.name);
      default:
        break;
    }
  };

  const additionalServicesCheckboxValueChange = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedAdditionalServices = [...calculationForm.commonValues.selectedAdditionalServices];
        var index = calculationForm.commonValues.selectedAdditionalServices.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedAdditionalServices.splice(index, 1);
          return onFormValueChange(`commonValues.selectedAdditionalServices`, 'set', newSelectedAdditionalServices);
        }
        break;
      case 'false':
        return onFormValueChange(`commonValues.selectedAdditionalServices`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };

  const handleSideBar = (evt) => {
    evt.preventDefault();
    if (evt.target.tagName != 'BUTTON') {
      setShowSideBar(!showSideBar);
    }
  }

  const handleCustomTags = (evt) => {
    evt.preventDefault();
    if (evt.target.tagName != 'BUTTON') {
      setShowCustomTags(!showCustomTags);
    }
  }

  const handleInventory = (evt) => {
    evt.preventDefault();
    if (evt.target.tagName != 'BUTTON') {
      setShowInventory(!showInventory);
    }
  }

  const handlePreview = (evt) => {
    evt.preventDefault();
    setShown(!popUpShown)
  }

  const setCalculationTypeOptions = () => {
    let calculationTypeOptions = [];
    let isCuFtDepartmentSelected = false

    calculationTypeOptions = [
      <option value='oneDayPacking'>1 Day - Packing / No packing</option>,
      <option value='twoDaysPacking'>2 days - Packing</option>,
      <option value='twoDays150Miles'>2 days - 150 miles</option>,
    ];


    if (calculationForm.department) {
      if (calculationForm.department.type && calculationForm.department.type === 'cuft') {
        isCuFtDepartmentSelected = true;
      }
    }
    if (!company.isLongDistanceDisabled && !isCuFtDepartmentSelected) {
      calculationTypeOptions.push(<option value='longOneDayPacking'>LONG - 1 day - Packing / No packing</option>,
        <option value='longTwoDaysPacking'>LONG - 2 Days - Packing</option>)
    }

    if (!company.isOOSDisabled && !isCuFtDepartmentSelected) {
      calculationTypeOptions.push(<option value='oos1day'>OOS - 1 day - Packing/No packing</option>,
        <option value='oos2days'>OOS - 2 Days - Packing</option>)
    }

    return calculationTypeOptions;
  }

  const handleSideBarKeyPress = (event) => {
    if (event.key === 'Enter') {

    }
  }

  const checkboxValueChange = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        return onFormValueChange(`commonValues.${evt.target.name}`, 'set', false);
      case 'false':
        return onFormValueChange(`commonValues.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };


  const isCuFtDepartment = calculationForm.department?.type === 'cuft';


  const applyInventory = (evt, totalValues) => {
    evt.preventDefault();


    const confirmMessage = "This action will delete all current extra stops in the calculation and override using values from inventory, including cuft and boxes in the calculation. Are you sure you want to proceed?";

    // Show confirmation dialog
    const isConfirmed = window.confirm(confirmMessage);

    if (isConfirmed) {
      // User confirmed, proceed with the action
      const newCalculationForm = JSON.parse(JSON.stringify(calculationForm));
      if (totalValues) {
        if (totalValues.cuftPerStop && totalValues.cuftPerStop.length) {
          if (newCalculationForm.commonValues.extraStops.length) {
            newCalculationForm.commonValues.extraStops = [];
          }
          totalValues.cuftPerStop.forEach((cuft, index) => {
            if (index === 0) {
              newCalculationForm.commonValues.cubicFt = cuft.totalFurniture + cuft.totalWB * 10;
              newCalculationForm.commonValues.smallBoxes = cuft.totalSmall
              newCalculationForm.commonValues.mediumBoxes = cuft.totalMedium
              newCalculationForm.commonValues.fragileBoxes = cuft.totalFragile
            } else {
              const newExtraStop = {
                [`baseUnLoadingHours${index - 1}`]: 0,
                [`baseLoadingHours${index - 1}`]: 0,
              }

              newExtraStop[`cubicFt${index - 1}`] = cuft.totalFurniture + cuft.totalWB * 10;
              if (!isCuFtDepartment) {
                newExtraStop[`miles${index - 1}`] = '';
              }
              newExtraStop[`driveTime${index - 1}`] = '';
              newExtraStop[`smallBoxes${index - 1}`] = cuft.totalSmall;
              newExtraStop[`mediumBoxes${index - 1}`] = cuft.totalMedium;
              newExtraStop[`fragileBoxes${index - 1}`] = cuft.totalFragile;
              newCalculationForm.commonValues.extraStops.push(newExtraStop);
            }
          });
        }
      }
      newCalculationForm.commonValues.packing = newCalculationForm.inventory.packing;
      setCalculationFormValue({ ...newCalculationForm });
      // Add your logic here to apply inventory values
    } else {
      // User did not confirm, do nothing
      console.log('Action cancelled by user');
    }

  }

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isFormDirty) {
        e.preventDefault();
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
        disconnectSocket()
        connectSocket()
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  const furnitureLoadingStatus = useSelector(getFurnitureLoadingStatus);
  const roomAreasLoadingStatus = useSelector(getRoomsAreasLoadingStatus);

  const furniture = useSelector(getFurniture);
  const roomsAreas = useSelector(getRoomsAreas);

  useEffect(() => {
    if (roomAreasLoadingStatus === 'idle') {
      if (company.roomsAreasId) {
        dispatch(loadRoomsAreas(company.roomsAreasId));
      }
    }
  }, [dispatch, company.roomsAreasId, roomAreasLoadingStatus]);


  useEffect(() => {
    if (furnitureLoadingStatus === 'idle') {
      if (company.furnitureId) {
        dispatch(loadFurniture(company.furnitureId));
      }
    }
  }, [dispatch, company.furnitureId, furnitureLoadingStatus]);

  const [isUsernameAdded, setIsUsernameAdded] = useState(false);
  const handleNoteChange = (e) => {
    const { name, value } = e.target;

    // If Enter is pressed and the username hasn't been added yet
    if (e.key === 'Enter' && !isUsernameAdded) {
      const newValue = value + `\n@${userName}: `;
      onFormValueChange(name, 'set', newValue);
      setIsUsernameAdded(true); // Set that the username has been added
      e.preventDefault(); // Prevent the default behavior of the Enter key
    } else if (!isUsernameAdded && value === '') {
      // If the textarea is empty and the user starts typing, add the username
      const newValue = `@${userName}: `;
      onFormValueChange(name, 'set', newValue);
      setIsUsernameAdded(true);
    } else {
      onFormValueChange(name, 'set', value);
    }
    resetLastTarget();
  };


  return (
    <FormContainer>
      <Prompt
        when={isFormDirty}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <form className={[styles.form]}>

        <div style={{ zIndex: '2' }}>
          {/* todo uncomment */}
          {/*   <button className={styles.inventoryButton} onClick={handleInventory} onKeyPress={handleInventory}
            disabled={!calculationForm.calculationNumber}
            title={!calculationForm.calculationNumber ? "Calculation number is required to manage inventory" : "Click to manage inventory"}
          >
            <Icon name={IconNames.INVENTORYCOLORED} />
          </button> */}
          <div className={(showInventory) ? styles.showInventory : styles.hideInventory}>
            <button className={styles.inventoryButton} onClick={handleInventory} onKeyPress={handleInventory}
              disabled={!calculationForm.calculationNumber}
              title={!calculationForm.calculationNumber ? "Calculation number is required to manage inventory" : "Click to manage inventory"}
              style={{ left: '20px' }}
            >
              <Icon name={IconNames.INVENTORYCOLORED} />
            </button>
            <InventoryContainer
              formSubmit={formSubmit}
              applyInventory={applyInventory}
              savedStatus={savedStatus}
              setUpdateableSave={setUpdateableSave}
              calculation={calculationForm}
              onFormValueChange={onFormValueChange}
              furniture={furniture}
              roomsAreas={roomsAreas}
              formType={formType}
              setIsAfterAutoSave={setIsAfterAutoSave}
              setIsFirstTime={setIsFirstTime}
              resetLastTarget={resetLastTarget}
              currentUserRole={currentUserRole}
            />
          </div>

          <button className={`${styles.sideBarButton} ${calculationForm.calculationNote ? styles.buttonWithCircle : ''}`} onClick={handleSideBar} onKeyPress={handleSideBarKeyPress}>
            <Icon name={IconNames.NOTE} />
          </button>
          <div className={(showSideBar) ? styles.showSideBar : styles.hideSideBar}>
            <button className={`${styles.sideBarButton} ${calculationForm.calculationNote ? styles.buttonWithCircle : ''}`} onClick={handleSideBar} onKeyPress={handleSideBarKeyPress}>
              <Icon name={IconNames.NOTE} />
            </button>
            <div className={styles.sidebarContainer}>
              <label className={styles.sLabel}> Note
                <textarea
                  style={{ padding: '10px', fontFamily: 'Arial', color: '#707070' }}
                  className={styles.tarea}
                  name='calculationNote'
                  value={calculationForm.calculationNote}
                  onChange={(e) => onFormValueChange(e.target.name, 'set', e.target.value)}
                  onKeyDown={handleNoteChange}
                  rows="30"
                  isFocused={lastTarget === 'calculationNote'}
                ></textarea>
              </label>
              {(hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR])) ?
                <BtnSubmit
                  isActive={true}
                  action={formSubmit}
                  name={'Save'}
                /> : ''}
            </div>
          </div>

        </div>


        <div className={(showCustomTags) ? styles.showCustomTags : styles.hideCustomTags} >
          {/* todo uncomment */}
          {/*   <button className={styles.customTagsButton} onClick={handleCustomTags} >
            <Icon name={IconNames.CUSTOMTAG} />
          </button> */}
          <CustomTags
            tags={tags}
            tagsValuesDefault={tagsValuesDefault}
            customTagsValues={calculationForm?.customTagsValues ? calculationForm.customTagsValues[calculationForm.calculationType] : {}}
            calculationType={calculationForm.calculationType}
            onFormValueChange={onCustomTagsChange}
          />
        </div>

        <header className={styles.header}>
          <h2 className='visually-hidden'>Form for calculate</h2>
          <fieldset className={styles.fieldset}>
            {!calculationForm.isSubCalc && formType !== 'new' && hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR]) && <BtnCloneToCalcGroup
              mainCalcId={calculationForm._id}
            />
            }
            {calculationForm.isSubCalc && <BtnMainCalc mainCalcId={calculationForm.mainCalcId} />}

            <label className={styles.label}>
              Calculation number</label>
            <input
              className={styles.input}
              name={'calculationNumber'}
              value={calculationForm.calculationNumber}
              onChange={
                (evt) => {
                  onFormValueChange(evt.target.name, 'set', evt.target.value)
                }
              }
              onFocus={resetLastTarget}
              placeholder={'000000000000'}
              disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
            />
            <label className={styles.label}>
              Calculation type</label>
            <select
              className={styles.select}
              name='calculationType'
              value={calculationForm.calculationType}
              onChange={
                (evt) => {
                  onFormValueChange(evt.target.name, 'set', evt.target.value)
                }
              }
              onFocus={resetLastTarget}
              disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
            >
              {setCalculationTypeOptions()}
            </select>

            <label className={styles.label}> Department</label>

            <select
              className={styles.select}
              value={calculationForm.department !== undefined ? calculationForm.department._id : 'None'}
              onChange={onChangeDepartmentSelect}
              onFocus={resetLastTarget}
              disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
            >
              <option value={undefined} key='none'>None</option>
              {
                departments && departments.length && departments.map((department) => {
                  const isCuFtDepartment = department.type === 'cuft';
                  const isExcludedType = ['longOneDayPacking', 'longTwoDaysPacking', 'oos1day', 'oos2days'].includes(calculationForm.calculationType);

                  if (!(isExcludedType && isCuFtDepartment)) {
                    return (
                      <option key={nanoid(departments.length)} value={department._id}>{department.departmentName}</option>
                    );
                  }

                  return null; // Exclude this option
                })
              }
            </select>

          </fieldset>

          <section className={styles.greedContainer}>

            <CalculationCommonValues
              commonValues={calculationForm.commonValues}
              onFormChange={onFormValueChange}
              lastTarget={lastTarget}
              setLastTarget={setLastTarget}
              resetLastTarget={resetLastTarget}
              clearCursorPosition={clearCursorPosition}
              renderProceduresOptions={renderProceduresOptions}
              department={calculationForm.department}
              calculationType={calculationForm.calculationType}
              setCursorPosition={setCursorPosition}
              cursorPosition={cursorPosition}
              isCuFtDepartment={isCuFtDepartment}
              hasRole={hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
            />

            <>
              {
                {
                  'oneDayPacking': <>
                    <CalculationTabOneDayValues
                      rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
                      formData={calculationForm}
                      formChange={onFormValueChange}
                      lastTarget={lastTarget}
                      setLastTarget={setLastTarget}
                      resetLastTarget={resetLastTarget}
                      sepCheckboxValueChange={sepCheckboxValueChange}
                      validationError={validationError}
                      setValidationError={setValidationError}
                      checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
                      additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
                      setCalculationFormValue={setCalculationFormValue}
                      disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                    />
                    <CalculationOneDayResult
                      sameDepartmentSettings={sameDepartmentSettings}
                      applyNewRates={applyNewRates}
                      editorState={editorState}
                      calculationData={calculationForm}
                      tags={tags}
                      setPreviewText={setPreviewText}
                      setOptionsPreviewText={setOptionsPreviewText}
                      formChange={onFormValueChange}
                      resetLastTarget={resetLastTarget}
                      checkboxValueChange={checkboxValueChange}
                      popUpShown={popUpShown}
                      oldTemplateWasUsed={oldTemplateWasUsed}
                      applyNewTemplate={applyNewTemplate}
                      setShowGenerateEmail={setShowGenerateEmail}
                      furniture={furniture}
                      roomsAreas={roomsAreas}
                      setTagsValuesDefault={setTagsValuesDefault}
                      customTagsValues={calculationForm?.customTagsValues ? calculationForm.customTagsValues[calculationForm.calculationType] : {}}
                      showCustomTags={showCustomTags}
                    />
                  </>,
                  'twoDaysPacking': <>
                    <CalculationTabTwoDaysValues
                      rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
                      formData={calculationForm}
                      formChange={onFormValueChange}
                      lastTarget={lastTarget}
                      setLastTarget={setLastTarget}
                      resetLastTarget={resetLastTarget}
                      sepCheckboxValueChange={sepCheckboxValueChange}
                      validationError={validationError}
                      setValidationError={setValidationError}
                      checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
                      additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
                      setCalculationFormValue={setCalculationFormValue}
                      disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                    />
                    <CalculationTwoDaysResult
                      sameDepartmentSettings={sameDepartmentSettings}
                      applyNewRates={applyNewRates}
                      formChange={onFormValueChange}
                      resetLastTarget={resetLastTarget}
                      checkboxValueChange={checkboxValueChange}
                      editorState={editorState}
                      calculationData={calculationForm}
                      tags={tags}
                      popUpShown={popUpShown}
                      setPreviewText={setPreviewText}
                      setOptionsPreviewText={setOptionsPreviewText}
                      oldTemplateWasUsed={oldTemplateWasUsed}
                      applyNewTemplate={applyNewTemplate}
                      setShowGenerateEmail={setShowGenerateEmail}
                      furniture={furniture}
                      roomsAreas={roomsAreas}
                      setTagsValuesDefault={setTagsValuesDefault}
                      customTagsValues={calculationForm?.customTagsValues ? calculationForm.customTagsValues[calculationForm.calculationType] : {}}
                      showCustomTags={showCustomTags}
                    />
                  </>,
                  'twoDays150Miles': <>
                    <CalculationTabTwoDays150Values
                      rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
                      formData={calculationForm}
                      formChange={onFormValueChange}
                      lastTarget={lastTarget}
                      setLastTarget={setLastTarget}
                      resetLastTarget={resetLastTarget}
                      sepCheckboxValueChange={sepCheckboxValueChange}
                      checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
                      additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
                      setCalculationFormValue={setCalculationFormValue}
                      disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                    />
                    <CalculationTwoDays150Result
                      sameDepartmentSettings={sameDepartmentSettings}
                      applyNewRates={applyNewRates}
                      formChange={onFormValueChange}
                      resetLastTarget={resetLastTarget}
                      checkboxValueChange={checkboxValueChange}
                      editorState={editorState}
                      calculationData={calculationForm}
                      tags={tags}
                      popUpShown={popUpShown}
                      setPreviewText={setPreviewText}
                      setOptionsPreviewText={setOptionsPreviewText}
                      oldTemplateWasUsed={oldTemplateWasUsed}
                      applyNewTemplate={applyNewTemplate}
                      setShowGenerateEmail={setShowGenerateEmail}
                      furniture={furniture}
                      roomsAreas={roomsAreas}
                      setTagsValuesDefault={setTagsValuesDefault}
                      customTagsValues={calculationForm?.customTagsValues ? calculationForm.customTagsValues[calculationForm.calculationType] : {}}
                      showCustomTags={showCustomTags}
                    />
                  </>,
                  'longOneDayPacking': <>
                    <CalculationTabLongOneDayValues
                      renderLongDistancesOptions={renderLongDistancesOptions}
                      rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
                      formData={calculationForm}
                      formChange={onFormValueChange}
                      lastTarget={lastTarget}
                      setLastTarget={setLastTarget}
                      resetLastTarget={resetLastTarget}
                      onChangeLongDistanceSelect={onChangeLongDistanceSelect}
                      sepCheckboxValueChange={sepCheckboxValueChange}
                      departments={departments}
                      unpRates={(unpRates.length > 0) ? unpRates : (calculationForm.unpDepartment && calculationForm.unpDepartment.rates) ? calculationForm.unpDepartment.rates.ratesList : []}
                      unpDepartment={calculationForm.unpDepartment}
                      onChangeUnpDepartmentSelect={onChangeUnpDepartmentSelect}
                      checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
                      disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                    />
                    <CalculationLongOneDayResult
                      sameDepartmentSettings={sameDepartmentSettings}
                      applyNewRates={applyNewRates}
                      sameLongDirectionSettings={sameLongDirectionSettings}
                      sameUnpDepartmentSettings={sameUnpDepartmentSettings}
                      formChange={onFormValueChange}
                      lastTarget={lastTarget}
                      setLastTarget={setLastTarget}
                      resetLastTarget={resetLastTarget}
                      checkboxValueChange={checkboxValueChange}
                      editorState={editorState}
                      calculationData={calculationForm}
                      tags={tags}
                      popUpShown={popUpShown}
                      setPreviewText={setPreviewText}
                      setOptionsPreviewText={setOptionsPreviewText}
                      oldTemplateWasUsed={oldTemplateWasUsed}
                      applyNewTemplate={applyNewTemplate}
                      setShowGenerateEmail={setShowGenerateEmail}
                      furniture={furniture}
                      roomsAreas={roomsAreas}
                      setTagsValuesDefault={setTagsValuesDefault}
                      customTagsValues={calculationForm?.customTagsValues ? calculationForm.customTagsValues[calculationForm.calculationType] : {}}
                      showCustomTags={showCustomTags}
                      disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR])}
                    />
                  </>,
                  'longTwoDaysPacking': <>
                    <CalculationTabLongTwoDayValues
                      renderLongDistancesOptions={renderLongDistancesOptions}
                      rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
                      formData={calculationForm}
                      formChange={onFormValueChange}
                      lastTarget={lastTarget}
                      setLastTarget={setLastTarget}
                      resetLastTarget={resetLastTarget}
                      onChangeLongDistanceSelect={onChangeLongDistanceSelect}
                      sepCheckboxValueChange={sepCheckboxValueChange}
                      departments={departments}
                      unpRates={(unpRates.length > 0) ? unpRates : (calculationForm.unpDepartment && calculationForm.unpDepartment.rates) ? calculationForm.unpDepartment.rates.ratesList : []}
                      unpDepartment={calculationForm.unpDepartment}
                      onChangeUnpDepartmentSelect={onChangeUnpDepartmentSelect}
                      checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
                      disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                    />
                    <CalculationLongTwoDayResult
                      calculationData={calculationForm}
                      sameDepartmentSettings={sameDepartmentSettings}
                      applyNewRates={applyNewRates}
                      sameLongDirectionSettings={sameLongDirectionSettings}
                      sameUnpDepartmentSettings={sameUnpDepartmentSettings}
                      formChange={onFormValueChange}
                      lastTarget={lastTarget}
                      setLastTarget={setLastTarget}
                      resetLastTarget={resetLastTarget}
                      checkboxValueChange={checkboxValueChange}
                      editorState={editorState}
                      tags={tags}
                      popUpShown={popUpShown}
                      setPreviewText={setPreviewText}
                      setOptionsPreviewText={setOptionsPreviewText}
                      oldTemplateWasUsed={oldTemplateWasUsed}
                      applyNewTemplate={applyNewTemplate}
                      setShowGenerateEmail={setShowGenerateEmail}
                      furniture={furniture}
                      roomsAreas={roomsAreas}
                      setTagsValuesDefault={setTagsValuesDefault}
                      customTagsValues={calculationForm?.customTagsValues ? calculationForm.customTagsValues[calculationForm.calculationType] : {}}
                      showCustomTags={showCustomTags}
                    />

                  </>,
                  'oos1day': <>
                    <CalculationTabOOSOneDayValues
                      renderOOSOptions={renderOOSOptions}
                      factors={factors.length > 0 ? factors : (calculationForm.outOfState && calculationForm.outOfState.parameters) ? calculationForm.outOfState.parameters : []}
                      rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
                      formData={calculationForm}
                      formChange={onFormValueChange}
                      lastTarget={lastTarget}
                      setLastTarget={setLastTarget}
                      resetLastTarget={resetLastTarget}
                      onChangeOutOfStateSelect={onChangeOutOfStateSelect}
                      sepCheckboxValueChange={sepCheckboxValueChange}
                      departments={departments}
                      unpRates={(unpRates.length > 0) ? unpRates : (calculationForm.unpDepartment && calculationForm.unpDepartment.rates) ? calculationForm.unpDepartment.rates.ratesList : []}
                      unpDepartment={calculationForm.unpDepartment}
                      onChangeUnpDepartmentSelect={onChangeUnpDepartmentSelect}
                      checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
                      disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                    />
                    <CalculationOOSOneDayResult
                      calculationData={calculationForm}
                      sameDepartmentSettings={sameDepartmentSettings}
                      applyNewRates={applyNewRates}
                      sameOOSDirectionSettings={sameOOSDirectionSettings}
                      sameUnpDepartmentSettings={sameUnpDepartmentSettings}
                      formChange={onFormValueChange}
                      resetLastTarget={resetLastTarget}
                      checkboxValueChange={checkboxValueChange}
                      editorState={editorState}
                      tags={tags}
                      popUpShown={popUpShown}
                      setPreviewText={setPreviewText}
                      setOptionsPreviewText={setOptionsPreviewText}
                      oldTemplateWasUsed={oldTemplateWasUsed}
                      applyNewTemplate={applyNewTemplate}
                      setShowGenerateEmail={setShowGenerateEmail}
                      furniture={furniture}
                      roomsAreas={roomsAreas}
                      setTagsValuesDefault={setTagsValuesDefault}
                      customTagsValues={calculationForm?.customTagsValues ? calculationForm.customTagsValues[calculationForm.calculationType] : {}}
                      showCustomTags={showCustomTags}
                    />
                  </>,
                  'oos2days': <>
                    <CalculationTabOOSLongTwoDayValues
                      renderOOSOptions={renderOOSOptions}
                      factors={factors.length > 0 ? factors : (calculationForm.outOfState && calculationForm.outOfState.parameters) ? calculationForm.outOfState.parameters : []}
                      rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
                      formData={calculationForm}
                      formChange={onFormValueChange}
                      lastTarget={lastTarget}
                      setLastTarget={setLastTarget}
                      resetLastTarget={resetLastTarget}
                      onChangeOutOfStateSelect={onChangeOutOfStateSelect}
                      sepCheckboxValueChange={sepCheckboxValueChange}
                      departments={departments}
                      unpRates={(unpRates.length > 0) ? unpRates : (calculationForm.unpDepartment && calculationForm.unpDepartment.rates) ? calculationForm.unpDepartment.rates.ratesList : []}
                      unpDepartment={calculationForm.unpDepartment}
                      onChangeUnpDepartmentSelect={onChangeUnpDepartmentSelect}
                      checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
                      disabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR, Role.VIEWERPLUS])}
                    />
                    <CalculationOOSLongTwoDayResult
                      calculationData={calculationForm}
                      sameDepartmentSettings={sameDepartmentSettings}
                      applyNewRates={applyNewRates}
                      sameOOSDirectionSettings={sameOOSDirectionSettings}
                      sameUnpDepartmentSettings={sameUnpDepartmentSettings}
                      formChange={onFormValueChange}
                      resetLastTarget={resetLastTarget}
                      checkboxValueChange={checkboxValueChange}
                      editorState={editorState}
                      tags={tags}
                      popUpShown={popUpShown}
                      setPreviewText={setPreviewText}
                      setOptionsPreviewText={setOptionsPreviewText}
                      oldTemplateWasUsed={oldTemplateWasUsed}
                      applyNewTemplate={applyNewTemplate}
                      setShowGenerateEmail={setShowGenerateEmail}
                      furniture={furniture}
                      roomsAreas={roomsAreas}
                      setTagsValuesDefault={setTagsValuesDefault}
                      customTagsValues={calculationForm?.customTagsValues ? calculationForm.customTagsValues[calculationForm.calculationType] : {}}
                      showCustomTags={showCustomTags}
                    />
                  </>
                }[calculationForm.calculationType]
              }</>
          </section>
        </header>

        <TemplatePreviewPopup
          popUpShown={popUpShown}
          previewText={previewText}
          setShown={setShown}
          optionsPreviewText={optionsPreviewText}
        />


        <div style={{ display: 'flex' }}>
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            {(calculationData && showGenerateEmail) ? <button onClick={handlePreview} className={styles.btn}
              disabled={!currentLoadedTemplate}>
              {!currentLoadedTemplate ? (
                'Loading...'
              ) : (
                <>
                  <img src={mailGreenIcon} alt="Mail" className={styles.default} style={{ marginRight: '5px' }} />
                  <img src={mailIcon} alt="Mail" className={styles.hover} style={{ marginRight: '5px' }} />
                  Generate Email
                </>
              )}
            </button> : ''}
            {(hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR])) ?
              <BtnSubmit
                isActive={true}
                action={(evt) => { setUpdateableSave(true); formSubmit(evt, isAfterAutoSave) }}
                name={'Save'}
              />
              : ''}
          </div>
        </div>

      </form>
      <div className={styles.groupCalcsCont} >
        <GroupCalculationsTitle />
        <TableHeader headerTitles={headerTitles} />
        <div style={{ marginLeft: '-20px' }}>
          <ChildCalcsMain mainCalcId={(calculationData?.isSubCalc) ? calculationData?.mainCalcId : calculationData?._id} isCalculationPage={true} />
        </div>
      </div>
      {(calculationData) ? <ActionsMain calculationId={calculationData._id} /> : ''}
    </FormContainer >
  );
};



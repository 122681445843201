import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const saveCompany = createAsyncThunk(
  'company/save',
  async (newCompanyData) => await fetchedData('company/company', 'POST', { ...newCompanyData })
);

export const updateCompany = createAsyncThunk(
  'company/update',
  async (updateCompany) => await fetchedData('company/update', 'POST', updateCompany)
);

export const loadCompany = createAsyncThunk(
  'company/getCompany',
  async (id) => await fetchedData('company/getCompany', 'POST', { id })
);

export const loadEditableCompany = createAsyncThunk(
  'company/getEditableCompany',
  async (id) =>  await fetchedData('company/getEditableCompany', 'POST', { id })
);

export const removeCompany = createAsyncThunk(
  'company/remove',
  async (id) => await fetchedData('company/remove', 'POST', { id })
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const loadActions = createAsyncThunk(
  'actionList/getActions',
  async (params) => await fetchedData('action/actions', 'POST',{params})
);

export const getCountOfActions = createAsyncThunk(
  'actionList/getCount',
  async () => await fetchedData('action/count', 'GET')
);

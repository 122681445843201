// Third party libraries
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
// Components
import { Icon } from '../Icon';
import { NumberInputEC } from '../number-input-ec/Number-input-ec';
// Utils
import { IconNames } from '../../util/const';
// Styles
import styles from './DepartmentHourlyRates.module.css';

export const DepartmentHourlyRates = (props) => {
  const {
    customRatesList,
    index,
    setDepartmentFormValue,
    setLastTarget,
    lastTarget,
    cursorPosition,
    setCursorPosition,
  } = props;


  const addRate = (evt) => {
    evt.preventDefault();
    if (lastTarget) {
      setLastTarget(undefined)
    }
    let rateIndex = customRatesList.length;
    setDepartmentFormValue(`rates.ratesList.${index}.customRatesList${index}`, 'push',
      { [`customMovers${rateIndex}`]: '', [`customTrucks${rateIndex}`]: '', [`customHourlyRate${rateIndex}`]: '', }
    );
  }

  const removeRate = (evt) => {
    evt.preventDefault();
    if (lastTarget) {
      setLastTarget(undefined)
    }
    setDepartmentFormValue(`rates.ratesList.${index}.customRatesList${index}.${customRatesList.length - 1}`, 'del');
  }

  const setRateValue = (evt, rateIndex) => {
    evt.preventDefault();
    setLastTarget(evt.target.name)
    setCursorPosition(evt.target.selectionStart)
    setDepartmentFormValue(`rates.ratesList.${index}.customRatesList${index}.${rateIndex}.${evt.target.name.split("_")[1]}`, 'set', evt.target.value);
  }

  return (
    <section className={styles.mainSection}>
      {customRatesList && customRatesList.map((customRate, rateIndex) => {
        return (
          <div className={styles.container} key={nanoid(10)}>
            <div className={styles.shuttleContainer}>
              <label
                className={styles.inputContainer}
                htmlFor={`${index}_customMovers_${rateIndex + 1}`}
              >
                <p className={styles.inputName}>Hourly rate for</p>
                <NumberInputEC
                  inputId={`${index}_customMovers_${rateIndex + 1}`}
                  className={styles.input}
                  type='text'
                  placeholder="ex. 2"
                  name={`${index}_customMovers${rateIndex}`}
                  value={customRate[`customMovers${rateIndex}`]}
                  onChange={(evt) => setRateValue(evt, rateIndex)}
                  autoFocus={lastTarget === `${index}_customMovers${rateIndex}`}
                  required
                  disabled={(rateIndex === 0) ? true : false}
                  onWheel={(e) => e.target.blur()}
                  cursorPosition={cursorPosition}
                />
              </label>
              <label
                className={styles.inputContainerSecond}
                htmlFor={`${index}_customTrucks_${rateIndex + 1}`}
              >
                <p className={styles.inputNameSecond}>movers&</p>
                <NumberInputEC
                  inputId={`${index}_customTrucks_${rateIndex + 1}`}
                  className={styles.inputSecond}
                  type='number'
                  onWheel={(e) => e.target.blur()}
                  placeholder={"ex. 1"}
                  name={`${index}_customTrucks${rateIndex}`}
                  value={customRate[`customTrucks${rateIndex}`]}
                  onChange={(evt) => setRateValue(evt, rateIndex)}
                  autoFocus={lastTarget === `${index}_customTrucks${rateIndex}`}
                  required
                  disabled={(rateIndex === 0) ? true : false}
                  cursorPosition={cursorPosition}
                />
              </label>
              <label
                className={styles.inputContainerThird}
                htmlFor={`${index}_customHourlyRate_${rateIndex + 1}`}
              >
                <p className={styles.inputNameThird}>trucks:</p>
                <span className={styles.inputValueTypeThird}>$</span>
                <NumberInputEC
                  inputId={`${index}_customHourlyRate_${rateIndex + 1}`}
                  className={styles.inputThird}
                  type='number'
                  onWheel={(e) => e.target.blur()}
                  placeholder={"ex. 99"}
                  name={`${index}_customHourlyRate${rateIndex}`}
                  value={customRate[`customHourlyRate${rateIndex}`]}
                  onChange={(evt) => setRateValue(evt, rateIndex)}
                  autoFocus={lastTarget === `${index}_customHourlyRate${rateIndex}`}
                  required
                  cursorPosition={cursorPosition}
                />
              </label>

            </div>
          </div>
        )
      })}
      <div className={styles.buttonContainer}>
        <button
          className={styles.extraBtn}
          onClick={addRate}
        >
          Add hourly rate combination
          <Icon name={IconNames.PLUS} />
        </button>
        <button
          className={styles.removeExtraBtn}
          onClick={removeRate}
        >
          Remove hourly rate combination
          <Icon name={IconNames.MINUS} />
        </button>
      </div>
    </section>
  );
}

DepartmentHourlyRates.propTypes = {
  customRatesList: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  setDepartmentFormValue: PropTypes.func.isRequired,
  setLastTarget: PropTypes.func.isRequired,
  lastTarget: PropTypes.string,
  setCursorPosition: PropTypes.func,
}

// Third party libraries
import PropTypes from 'prop-types';
// Styles
import styles from './CrateLabel.module.css';

export const CrateLabel = (props) => {
  const {
    inputId,
    name,
    title,
    placeholder,
    value,
    changeValue,
    valueType,
    isFocused,
    pattern,
    isLong,
    inputType,
    cursorPosition,
    valueTypeStyle = {}
  } = props;
  return (
    <label
      className={styles.inputContainer}
      htmlFor={inputId}
    >
      <p className={styles.inputName}>{title}</p>
      {valueType &&
        <span className={(valueType === "hrs") ? [styles.inputValueTypeHrs, valueTypeStyle] : [styles.inputValueType, valueTypeStyle]} >{valueType}</span>
      }
      <input
        id={inputId}
        className={styles.input}
        type={(inputType) ? inputType : 'text'}
        placeholder={placeholder}
        name={name}
        value={value}
        pattern={pattern}
        onChange={(e) => (pattern) ?
          e.target.validity.valid ? changeValue(e) : (e.target.value === "") ? changeValue(e) : ""
          : changeValue(e)
        }
        autoFocus={isFocused}
        onFocus={e => {
          if (cursorPosition) {
            e.target.selectionEnd = cursorPosition;
            e.target.selectionStart = cursorPosition;
          }
        }}
        required
      />
    </label>
  );
};

// Variable type settings
CrateLabel.propTypes = {
  inputId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  changeValue: PropTypes.func.isRequired,
  valueType: PropTypes.string,
  pattern: PropTypes.string,
  isFocused: PropTypes.bool,
  isLong: PropTypes.bool,
  inputType: PropTypes.string,
  cursorPosition: PropTypes.number,
};

import React, { useState, useContext, useCallback, useEffect } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'
// Calc util functions

// Styles
import styles from "./CalculationOOSOneDayParams.module.css";
//Components
import { CalculationSeparateDayLongView } from '../calculation-separate-day-long-view/Calculation-separate-day-long-view';
import { generateUnpackingLongText, getChargesResText, getCheckMarkLabelById, getHigherNumberOfHours, getTagsValues, getTotalTimeRange, replaceOptionsTagsByValues, replaceTagsByValues } from '../../util/utils';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import CopyIcon from '../results-copy-icon/Results-copy-icon';
import { ExtraOptionsResults } from '../extra-options-results/Extra-options-results';
import { setTotalFragileBoxes } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { useDispatch } from 'react-redux';
import TruckloadsView from '../truckloads/TruckloadsView';

export const CalculationOOSOneDayParams = ({
  commonValues,
  oos1day,
  department,
  outOfState,
  unpDepartment,
  selectedMiscCheckMarks,
  checkboxValueChange,
  editorState,
  tags,
  setPreviewText, setOptionsPreviewText,
  popUpShown,
  calculationData,
  setShowGenerateEmail, furniture, roomsAreas, setTagsValuesDefault, customTagsValues, showCustomTags
}) => {
  const dispatch = useDispatch();
  const [Total_cubes, setTotal_cubes] = useState(0);
  const [Total_small_boxes, setTotal_small_boxes] = useState(0);
  const [Total_medium_boxes, setTotal_medium_boxes] = useState(0);
  const [Total_fragile_boxes, setTotal_fragile_boxes] = useState(0);
  const [Total_box_cubes, setTotal_box_cubes] = useState(0);
  const [Truckloads, setTruckloads] = useState(0);
  const [Stop_CN_Truckload, setStop_CN_Truckload] = useState(0);
  const [Stop_1_Truckload, setStop_1_Truckload] = useState(0);
  const [Packing_kit_Show, setPacking_kit_Show] = useState(0);
  const [Packing_kit_total_amount_with_taxes, setPacking_kit_total_amount_with_taxes] = useState(0);
  const [Charge_per_mile_amount, setCharge_per_mile_amount] = useState(0);
  const [Movers_OOSOne_Day_Loading, setMovers_OOSOne_Day_Loading] = useState(0);
  const [Rate_card_OOSOne_Day, setRate_card_OOSOne_Day] = useState(0);
  const [Total_Amount_Packing_OOSOne_Day, setTotal_Amount_Packing_OOSOne_Day] = useState(0);
  const [Packing_time_without_loading_OOSOne_Day, setPacking_time_without_loading_OOSOne_Day] = useState(0);
  const [Total_Hours_OOSOne_Day_Unloading, setTotal_Hours_OOSOne_Day_Unloading] = useState(0);
  const [Trucks_OOSOne_Day, setTrucks_OOSOne_Day] = useState(0);
  const [Total_Hours_OOSOne_Day_Loading_Lower_number, setTotal_Hours_OOSOne_Day_Loading_Lower_number] = useState(0);
  const [Drivetime_OOSOne_Day, setDrivetime_OOSOne_Day] = useState(0);
  const [Movers_OOSOne_Day_Unloading, setMovers_OOSOne_Day_Unloading] = useState(0);
  const [Total_Hours_OOSOne_Day_Unloading_Lower_number, setTotal_Hours_OOSOne_Day_Unloading_Lower_number] = useState(0);
  const [Total_Card_OOSOne_Day, setTotal_Card_OOSOne_Day] = useState(0);
  const [Total_Cash_OOSOne_Day, setTotal_Cash_OOSOne_Day] = useState(0);
  const [Total_Hours_OOSOne_Day_Loading, setTotal_Hours_OOSOne_Day_Loading] = useState(0);
  const [Rate_card_Unpacking_Same_Day_OOS, setRate_card_Unpacking_Same_Day_OOS] = useState(0);
  const [Unpacking_hours_same_day_OOSOne_Day, setUnpacking_hours_same_day_OOSOne_Day] = useState(0);
  const [Total_Card_Unpacking_Same_Day_OOS, setTotal_Card_Unpacking_Same_Day_OOS] = useState(0);
  const [Trucks_Unpacking_Separate_Day, setTrucks_Unpacking_Separate_Day] = useState(0)
  const [Movers_Unpacking_Separate_Day, setMovers_Unpacking_Separate_Day] = useState(0)
  const [Unpacking_Separate_Day_Lower_hours, setUnpacking_Separate_Day_Lower_hours] = useState(0)
  const [Unpacking_Separate_Day_Higher_hours, setUnpacking_Separate_Day_Higher_hours] = useState(0)
  const [Rate_cash_Unpacking_Separate_Day, setRate_cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_card_Unpacking_Separate_Day, setRate_card_Unpacking_Separate_Day] = useState(0)
  const [Total_Card_Unpacking_Separate_Day_Long_and_OOS, setTotal_Card_Unpacking_Separate_Day_Long_and_OOS] = useState(0)
  const [Truck_fee_Unpacking_Separate_Day, setTruck_fee_Unpacking_Separate_Day] = useState(0)
  const [Combined_mileage, setCombined_mileage] = useState(0)
  const [Time_to_pack, setTime_to_pack] = useState(0);
  const [Total_cubes_Within_Building, setTotal_cubes_Within_Building] = useState(0)
  const [Truckloads_to_move, setTruckloads_to_move] = useState(0)
  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.emit('getFormulasValues', { calculationType: 'oos1day', commonValues: commonValues, oos1day: oos1day, department: department, unpDepartment: unpDepartment, outOfState: outOfState })
    socket.on('Total_cubes', (data) => setTotal_cubes(data));
    socket.on('Total_small_boxes', (data) => setTotal_small_boxes(data));
    socket.on('Total_medium_boxes', (data) => setTotal_medium_boxes(data));
    socket.on('Total_fragile_boxes', (data) => { setTotal_fragile_boxes(data); dispatch(setTotalFragileBoxes(data)); });
    socket.on('Total_box_cubes', (data) => setTotal_box_cubes(data));
    socket.on('Truckloads', (data) => setTruckloads(data));
    socket.on('Stop_CN_Truckload', (data) => setStop_CN_Truckload(data));
    socket.on('Stop_1_Truckload', (data) => setStop_1_Truckload(data));
    socket.on('Packing_kit_Show', (data) => setPacking_kit_Show(data));
    socket.on('Packing_kit_total_amount_with_taxes', (data) => setPacking_kit_total_amount_with_taxes(data));
    socket.on('Charge_per_mile_amount', (data) => setCharge_per_mile_amount(data));
    socket.on('Movers_OOSOne_Day_Loading', (data) => setMovers_OOSOne_Day_Loading(data));
    socket.on('Rate_card_OOSOne_Day', (data) => setRate_card_OOSOne_Day(data));
    socket.on('Total_Amount_Packing_OOSOne_Day', (data) => setTotal_Amount_Packing_OOSOne_Day(data));
    socket.on('Packing_time_without_loading_OOSOne_Day', (data) => setPacking_time_without_loading_OOSOne_Day(data));
    socket.on('Total_Hours_OOSOne_Day_Unloading', (data) => setTotal_Hours_OOSOne_Day_Unloading(data));
    socket.on('Trucks_OOSOne_Day', (data) => setTrucks_OOSOne_Day(data));
    socket.on('Total_Hours_OOSOne_Day_Loading_Lower_number', (data) => setTotal_Hours_OOSOne_Day_Loading_Lower_number(data));
    socket.on('Drivetime_OOSOne_Day', (data) => setDrivetime_OOSOne_Day(data));
    socket.on('Movers_OOSOne_Day_Unloading', (data) => setMovers_OOSOne_Day_Unloading(data));
    socket.on('Total_Hours_OOSOne_Day_Unloading_Lower_number', (data) => setTotal_Hours_OOSOne_Day_Unloading_Lower_number(data));
    socket.on('Total_Card_OOSOne_Day', (data) => setTotal_Card_OOSOne_Day(data));
    socket.on('Total_Cash_OOSOne_Day', (data) => setTotal_Cash_OOSOne_Day(data));
    socket.on('Total_Hours_OOSOne_Day_Loading', (data) => setTotal_Hours_OOSOne_Day_Loading(data));
    socket.on('Total_cubes_Within_Building', (data) => setTotal_cubes_Within_Building(data));
    socket.on('Truckloads_to_move', (data) => setTruckloads_to_move(data));

    socket.on('Rate_card_Unpacking_Same_Day_OOS', (data) => setRate_card_Unpacking_Same_Day_OOS(data));
    socket.on('Unpacking_hours_same_day_OOSOne_Day', (data) => setUnpacking_hours_same_day_OOSOne_Day(data));
    socket.on('Total_Card_Unpacking_Same_Day_OOS', (data) => setTotal_Card_Unpacking_Same_Day_OOS(data));
    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });
    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data))
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data))
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data))
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data))
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data))
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data))
    socket.on('Total_Card_Unpacking_Separate_Day_Long_and_OOS', (data) => setTotal_Card_Unpacking_Separate_Day_Long_and_OOS(data))
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data))
    socket.on('Time_to_pack', (data) => setTime_to_pack(data));
  }, [commonValues, oos1day, department, outOfState])

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  useEffect(() => {
    if (editorState) {
      let templateText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      let tagValues = getTagsValues({
        calculation: calculationData, tags,
        calculatedParams: {
          Total_cubes: Total_cubes,
          Total_small_boxes: Total_small_boxes,
          Total_medium_boxes: Total_medium_boxes,
          Total_fragile_boxes: Total_fragile_boxes,
          Total_box_cubes: Total_box_cubes,
          Truckloads: Truckloads,
          Truckloads_to_move: Truckloads_to_move,
          Total_cubes_Within_Building: Total_cubes_Within_Building,
          Stop_CN_Truckload: Stop_CN_Truckload,
          Stop_1_Truckload: Stop_1_Truckload,
          Packing_kit_Show: Packing_kit_Show,
          Packing_kit_total_amount_with_taxes: Packing_kit_total_amount_with_taxes,
          Charge_per_mile_amount: Charge_per_mile_amount,
          Movers_OOSOne_Day_Loading: Movers_OOSOne_Day_Loading,
          Rate_card_OOSOne_Day: Rate_card_OOSOne_Day,
          Total_Amount_Packing_OOSOne_Day: Total_Amount_Packing_OOSOne_Day,
          Packing_time_without_loading_OOSOne_Day: Packing_time_without_loading_OOSOne_Day,
          Total_Hours_OOSOne_Day_Unloading: Total_Hours_OOSOne_Day_Unloading,
          Trucks_OOSOne_Day: Trucks_OOSOne_Day,
          Total_Hours_OOSOne_Day_Loading_Lower_number: Total_Hours_OOSOne_Day_Loading_Lower_number,
          Drivetime_OOSOne_Day: Drivetime_OOSOne_Day,
          Movers_OOSOne_Day_Unloading: Movers_OOSOne_Day_Unloading,
          Total_Hours_OOSOne_Day_Unloading_Lower_number: Total_Hours_OOSOne_Day_Unloading_Lower_number,
          Total_Card_OOSOne_Day: Total_Card_OOSOne_Day,
          Total_Cash_OOSOne_Day: Total_Cash_OOSOne_Day,
          Total_Hours_OOSOne_Day_Loading: Total_Hours_OOSOne_Day_Loading,
          Rate_card_Unpacking_Same_Day_OOS: Rate_card_Unpacking_Same_Day_OOS,
          Unpacking_hours_same_day_OOSOne_Day: Unpacking_hours_same_day_OOSOne_Day,
          Total_Card_Unpacking_Same_Day_OOS: Total_Card_Unpacking_Same_Day_OOS,
          Trucks_Unpacking_Separate_Day: Trucks_Unpacking_Separate_Day,
          Movers_Unpacking_Separate_Day: Movers_Unpacking_Separate_Day,
          Unpacking_Separate_Day_Lower_hours: Unpacking_Separate_Day_Lower_hours,
          Unpacking_Separate_Day_Higher_hours: Unpacking_Separate_Day_Higher_hours,
          Rate_cash_Unpacking_Separate_Day: Rate_cash_Unpacking_Separate_Day,
          Rate_card_Unpacking_Separate_Day: Rate_card_Unpacking_Separate_Day,
          Total_Card_Unpacking_Separate_Day_Long_and_OOS: Total_Card_Unpacking_Separate_Day_Long_and_OOS,
          Truck_fee_Unpacking_Separate_Day: Truck_fee_Unpacking_Separate_Day,
          Combined_mileage: Combined_mileage,
        }, furniture, roomsAreas
      })
      const tagValuesCopy = JSON.parse(JSON.stringify(tagValues));
      if (typeof setTagsValuesDefault === 'function') {
        setTagsValuesDefault(prevState => ({
          ...prevState,
          [calculationData.calculationType]: tagValuesCopy
        }));
      }
      if (customTagsValues && Object.keys(customTagsValues).length > 0) {
        for (const key in customTagsValues) {
          tagValues[`|{${key}}`] = customTagsValues[key];
        }
      }
      setPreviewText(replaceTagsByValues(templateText, tagValues))
      setOptionsPreviewText(replaceOptionsTagsByValues(templateText, tagValues))
    }
  }, [popUpShown,/*  calculationData, */ showCustomTags]);

  const handleResCopy = (evt) => {
    evt.preventDefault();

    let chargesText = getChargesResText(department, oos1day.selectedChargesNamesDay1, Trucks_OOSOne_Day);



    let chargesTextDay2 = getChargesResText(department, oos1day.selectedChargesNamesDay2, Trucks_OOSOne_Day);

    department.extraOptions.charges.forEach((charge, chargeIndex) => {
      let showCharge = false;
      showCharge = oos1day.selectedChargesNamesDay2.includes(charge[`chargeName${chargeIndex}`]);
      if (showCharge && (charge[`chargeName${chargeIndex}`] !== '' || charge[`amount${chargeIndex}`] !== '')) {
        chargesTextDay2 += '• ' + charge[`chargeName${chargeIndex}`] + ': $' + charge[`amount${chargeIndex}`] + (charge[`perTruck${chargeIndex}`] ? ' (per truck)' : '') + '\n';
      }
    });

    let proceduresText = '';
    let procedureAdded = false;
    commonValues.procedures.forEach((procedure, pkIndex) => {
      if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
        if (!procedureAdded) {
          proceduresText += 'Procedures:\n';
          procedureAdded = true;
        }
        proceduresText += '• ' + procedure.procedureType + ' x ' + procedure.numberOfProcedures + ':';
        if (Object.keys(department).length !== 0) {
          department.packing.cratings.forEach((crating, index) => {
            if (procedure.procedureType === crating[`crateName${index}`]) {
              proceduresText += ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
            }
          });
        }
        proceduresText += '\n';
      }
    });


    let unpackingText = generateUnpackingLongText(commonValues, oos1day, unpDepartment, Trucks_Unpacking_Separate_Day, Movers_Unpacking_Separate_Day, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours, Rate_cash_Unpacking_Separate_Day, Rate_card_Unpacking_Separate_Day, Total_Card_Unpacking_Separate_Day_Long_and_OOS, Truck_fee_Unpacking_Separate_Day);



    const invoiceText = `Total Cubic ft:${parseFloat(Total_cubes).toFixed(2)} cu ft.
${(Total_cubes_Within_Building > 0) ? `Cubic ft within building: ${parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
Cubic ft to move: ${parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.` : ''}        
Truckloads: ${Truckloads}
${(Truckloads > 1 && (Math.floor(Number(Truckloads))) - Number(Truckloads) < 0) ? Math.floor(Truckloads) + ' truck(s) + ' + Math.floor(Number((Number(Truckloads) - Math.floor(Truckloads)).toFixed(2) * 100)) + "%" : ''}

Total miles: ${parseFloat(Combined_mileage).toFixed(1)}
${(commonValues.extraStops.filter((extraStop, index) =>
      extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
    ).length > 0) ? `There will be ${commonValues.extraStops.filter((extraStop, index) =>
      extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
    ).length} additional stops` : ''}

DAY 1 - LOADING

Movers: ${Movers_OOSOne_Day_Loading}

Trucks: ${Trucks_OOSOne_Day}${(commonValues.shuttle && oos1day.shuttleOnDay1) ? "+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${oos1day.quantityDay1})` : '') : ''}

Total Time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Total_Hours_OOSOne_Day_Loading_Lower_number, Total_Hours_OOSOne_Day_Loading)}

${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && oos1day.selectedChargesNamesDay1.length > 0) ? `
Extra options:` : ''}
${chargesText}

${((commonValues.procedures.length > 0) && (commonValues.procedures[0].procedureType !== "None" && commonValues.procedures[0].procedureType !== "")) ? 'Procedures:' : ''}
${proceduresText}

${commonValues.liftGate ? ' | LIFTGATE' : ''}
${commonValues.hardFloorCovers ? ' | HARDWOOD FLOOR COVERS' : ''}
${commonValues.filmFloorCovers ? ' | CARPET FLOOR COVERS' : ''}
${selectedMiscCheckMarks.map(checkMark => ` | ${getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks).toUpperCase()}`).join('')}

${(commonValues.packing !== "No") ? `Packing Kit: ${'$' + Packing_kit_Show} {commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]
${(commonValues.unpacking === 'Same Day') ? 'UNPACKING' : ''}
`: ""}

${(commonValues.packing !== "No") ? `
Packing Supplies On The Go:
• Small box: $${(Object.keys(department).length !== 0) ? department.packing.supplies.smallBox : ''}
• Medium box: $${(Object.keys(department).length !== 0) ? department.packing.supplies.mediumBox : ''}
• Large box: $${(Object.keys(department).length !== 0) ? department.packing.supplies.largeBox : ''}
• Roll of packing paper: $${(Object.keys(department).length !== 0) ? department.packing.supplies.packingPapper : ''}
• Roll of bubble wrap: $${(Object.keys(department).length !== 0) ? department.packing.supplies.bubbleWrap : ''}
`: ''}

DRIVING

Arrival Time: ${(!Drivetime_OOSOne_Day || Drivetime_OOSOne_Day === 0) ? "Not specified" :
        `${Drivetime_OOSOne_Day.toString()} days`
      }

DAY 2 - UNLOADING

Movers: ${Movers_OOSOne_Day_Unloading}
Trucks: ${Trucks_OOSOne_Day}${(commonValues.shuttle && oos1day.shuttleOnDay2) ? "+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${oos1day.quantityDay2})` : '') : ''}
Total Time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Total_Hours_OOSOne_Day_Unloading_Lower_number, Total_Hours_OOSOne_Day_Unloading)}

${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && oos1day.selectedChargesNamesDay2.length > 0) ? `
Extra options:` : ''}
${chargesTextDay2}

${(oos1day.additionalAmount) ? `Additional amount:${oos1day.additionalAmount}` : ''}

FLAT RATE: $${(outOfState.calcMethod !== 'deduct')
        ?
        Total_Card_OOSOne_Day
        :
        (Total_Cash_OOSOne_Day
          + ' cash / $').toString() +
        (Total_Card_OOSOne_Day + " card").toString()
      }
${(outOfState.calcMethod !== 'deduct') ? `FLAT RATE with ${outOfState.cashPaymentDiscount}% cash discount: $${Total_Cash_OOSOne_Day}
`
        : ""}

${unpackingText}`;




    // Create a textarea element to hold the text
    const textArea = document.createElement("textarea");
    textArea.value = invoiceText.replace(/\n{2,}/g, '\n\n') + '\n';;

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textArea);

  }
  if (typeof setShowGenerateEmail === 'function') {
    if (department && Object.keys(department).length > 0 && oos1day.firstDayRate && oos1day.factor && Trucks_OOSOne_Day > 0
      && outOfState && Object.keys(outOfState).length > 0 && Movers_OOSOne_Day_Loading > 0 && Movers_OOSOne_Day_Unloading > 0) {
      setShowGenerateEmail(true)
    } else {
      setShowGenerateEmail(false)
    }
  }

  return (
    <ul className={styles.paramsSection}>
      <button {...getToggleProps()} className={styles.btnSubmit}>
        <i className={isExpanded ? styles.up : styles.down}></i>
      </button>
      <section {...getCollapseProps()}>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Small boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_small_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Medium boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_medium_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Fragile boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_fragile_boxes}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Boxes cubic ft:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_box_cubes.toFixed(2) + " cu ft."}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Time to pack for 1 mover:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Time_to_pack).toFixed(2) + " h"}
          </p>
        </li>
        <li className={styles.paramsItem} >
          <p className={styles.paramsName}>Truckloads from Stop 1:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_1_Truckload).toFixed(3)}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>
            Truckloads from Additional stops:
          </p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_CN_Truckload).toFixed(3)}
          </p>
        </li>




        {(Charge_per_mile_amount !== 0) ?
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Mileage charge:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {'$' + Charge_per_mile_amount}
            </p>
          </li>
          : ""}




        <h4>DAY - 1 (Packing & Loading)</h4>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Loading hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {/* {Loading_hours_OOSOne_Day_Precount} */}
            {/* {Loading_hours_OOSOne_Day} */}
            {parseFloat(Total_Hours_OOSOne_Day_Loading).toFixed(3)}{' h'}
          </p>
        </li>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Packing Rate:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>$
            {Rate_card_OOSOne_Day}/h
          </p>
        </li>

        {/* hide rate, Packing Time,Total for Packing:
based on Packing(if no)

 */}
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Packing Time:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Packing_time_without_loading_OOSOne_Day).toFixed(3)}{' h'}
          </p>
        </li  >

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Total for Packing:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>$
            {Total_Amount_Packing_OOSOne_Day}
          </p>
        </li>

        <h4>DAY - 2 (Unloading)</h4>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Unloading hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Total_Hours_OOSOne_Day_Unloading).toFixed(3)}{' h'}
          </p>
        </li>

        {(commonValues.unpacking === 'Same Day') ?
          <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Unpacking Rate:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>$
                {Rate_card_Unpacking_Same_Day_OOS}/h
              </p>
            </li>

            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Unpacking Time:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {Math.ceil(Unpacking_hours_same_day_OOSOne_Day)}{' h'}
              </p>
            </li>

            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Total for Unpacking:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>$
                {Total_Card_Unpacking_Same_Day_OOS}
              </p>
            </li>
          </>
          : ''
        }

      </section>
      {(department && Object.keys(department).length > 0 && oos1day.firstDayRate && oos1day.factor && Trucks_OOSOne_Day > 0
        && outOfState && Object.keys(outOfState).length > 0 && Movers_OOSOne_Day_Loading > 0 && Movers_OOSOne_Day_Unloading > 0) ?
        <div>
          <h2 className={styles.titleCenter}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Results  <CopyIcon onClick={handleResCopy} style={{ marginLeft: '5px' }} /></div> {(Total_box_cubes > 0 && commonValues.packing === 'No') ?
            <>
              <span style={{ color: 'red' }}> - No Packing
              </span>
              <label className={styles.commonLabeling} style={{ marginLeft: '5px' }}>
                Confirm
                <input
                  className={styles.mycheckbox}
                  type='checkbox'
                  name={'isNoPackingConfirmed'}
                  checked={commonValues.isNoPackingConfirmed}
                  value={commonValues.isNoPackingConfirmed}
                  onChange={checkboxValueChange}
                />
              </label>
            </> : ''}</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Cubic ft:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Total_cubes.toFixed(2)} cu ft.
            </p>
          </li>
          {(Total_cubes_Within_Building > 0) ? <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft within building:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft to move:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.
              </p>
            </li>
          </> : ''}
          <TruckloadsView Truckloads={Truckloads} Total_cubes_Within_Building={Total_cubes_Within_Building} Truckloads_to_move={Truckloads_to_move} />
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total miles:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>{parseFloat(Combined_mileage).toFixed(1)}</p>
          </li>
          {
            (commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ?
              <li className={styles.paramsItem}><div>
                There will be {commonValues.extraStops.filter((extraStop, index) =>
                  extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
                ).length} additional stops</div>
              </li>
              : ''
          }
          <h2 className={styles.titleCenter}>Day 1 - Loading</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>{Movers_OOSOne_Day_Loading}</p>
          </li>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Trucks_OOSOne_Day}
              {(commonValues.shuttle && oos1day.shuttleOnDay1) && ("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${oos1day.quantityDay1})` : ''))}
            </p>
          </li>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {getTotalTimeRange((department) ? department.minimumHours : 0, Total_Hours_OOSOne_Day_Loading_Lower_number, Total_Hours_OOSOne_Day_Loading)}</p>
          </li>

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={oos1day.selectedChargesNamesDay1}
            trucksCount={Trucks_OOSOne_Day}
          />

          {((commonValues.procedures.length > 0 && commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) && (commonValues.procedures[0].procedureType !== "None" || commonValues.procedures[0].procedureType !== "")) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Procedures:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>

              </p>
              <ul>
                {
                  commonValues.procedures.map((procedure) => {
                    if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
                      return <li>{procedure.procedureType + " x " + procedure.numberOfProcedures}:{
                        (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                          if (procedure.procedureType === crating[`crateName${index}`]) {
                            return ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
                          }
                          else {
                            return ''
                          }
                        })
                      }</li>
                    }
                    else { return '' }
                  })
                }
              </ul>
            </li>
            : ''}

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.liftGate && ' | LIFTGATE'}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.hardFloorCovers && " | HARDWOOD FLOOR COVERS"}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.filmFloorCovers && " | CARPET FLOOR COVERS "}</span>
            {selectedMiscCheckMarks.map(checkMark => <span style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
              {' '} | {getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks)}
            </span>)}
          </div>

          {(commonValues.packing !== "No") ?

            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Packing Kit:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {'$' + Packing_kit_Show} {commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]
                {(commonValues.unpacking === 'Same Day') ? 'UNPACKING' : ''}
              </p>
            </li>
            : ""}
          {(commonValues.packing !== "No") ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Packing Supplies On The Go:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
              </p>
              <ul>
                <li>
                  Small box: ${(Object.keys(department).length !== 0) && department.packing.supplies.smallBox}
                </li>
                <li>
                  Medium box: ${(Object.keys(department).length !== 0) && department.packing.supplies.mediumBox}
                </li>
                <li>
                  Large box: ${(Object.keys(department).length !== 0) && department.packing.supplies.largeBox}
                </li>
                <li>
                  Roll of packing paper: ${(Object.keys(department).length !== 0) && department.packing.supplies.packingPapper}
                </li>
                <li>
                  Roll of bubble wrap: ${(Object.keys(department).length !== 0) && department.packing.supplies.bubbleWrap}
                </li>
              </ul>
            </li>
            : ""}

          <h2 className={styles.titleCenter}>Driving</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Arrival Time:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {
                (!Drivetime_OOSOne_Day || Drivetime_OOSOne_Day === 0) ? "Not specified" :
                  Drivetime_OOSOne_Day.toString() + ' days'
              }</p>
          </li>

          <h2 className={styles.titleCenter}>Day 2 - Unloading</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>{Movers_OOSOne_Day_Unloading}</p>
          </li>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>{Trucks_OOSOne_Day}
              {(commonValues.shuttle && oos1day.shuttleOnDay2) && ("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${oos1day.quantityDay2})` : ''))}
            </p>
          </li>


          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {getTotalTimeRange((department) ? department.minimumHours : 0, Total_Hours_OOSOne_Day_Unloading_Lower_number, Total_Hours_OOSOne_Day_Unloading)}</p>
          </li>

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={oos1day.selectedChargesNamesDay2}
            trucksCount={Trucks_OOSOne_Day}
          />

          {(oos1day.additionalAmount) ? (
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Additional amount:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                ${oos1day.additionalAmount}
              </p>
            </li>) : ''
          }

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>FLAT RATE:</p>
            <div className={styles.roundedOrNot}>

              <span className={styles.paramsDecor}></span>
            </div>
            <p className={styles.paramsValue}>$
              {(outOfState.calcMethod !== 'deduct')
                ?

                Total_Card_OOSOne_Day
                :

                (Total_Cash_OOSOne_Day
                  + ' cash / $').toString() +
                (Total_Card_OOSOne_Day + " card").toString()
              }

            </p>
          </li>


          {(outOfState.calcMethod !== 'deduct') ?
            <div style={{ paddingLeft: '30px' }}>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>FLAT RATE with {outOfState.cashPaymentDiscount}% cash discount:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>$
                  {Total_Cash_OOSOne_Day}
                </p>
              </li>
            </div>
            : ""}
          <CalculationSeparateDayLongView commonValues={commonValues} anyCalculationType={oos1day} unpDepartment={unpDepartment} isOOS={true}
            Trucks_Unpacking_Separate_Day={Trucks_Unpacking_Separate_Day}
            Movers_Unpacking_Separate_Day={Movers_Unpacking_Separate_Day}
            Unpacking_Separate_Day_Lower_hours={Unpacking_Separate_Day_Lower_hours}
            Unpacking_Separate_Day_Higher_hours={Unpacking_Separate_Day_Higher_hours}
            Rate_cash_Unpacking_Separate_Day={Rate_cash_Unpacking_Separate_Day}
            Rate_card_Unpacking_Separate_Day={Rate_card_Unpacking_Separate_Day}
            Total_Card_Unpacking_Separate_Day_Long_and_OOS={Total_Card_Unpacking_Separate_Day_Long_and_OOS}
            Truck_fee_Unpacking_Separate_Day={Truck_fee_Unpacking_Separate_Day}
          />
        </div>
        : ""}
    </ul >
  );
};

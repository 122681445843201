// Components
import { Table } from '../table/Table';
import { DepartmentTableBody } from '../department-table-body/Department-table-body';
import { TableHeader } from '../table-header/Table-header';

export const DepartmentList = ({ departments, filters, setFilters }) => {
  const headerTitles = {
    departmentName: 'Department Name',
    authorId: 'Author',
    thirdColumn: "",
    createdAt: 'Created Date',
    updatedAt: 'Updated Date',
  };

  return (
    <Table>
      <TableHeader headerTitles={headerTitles} filters={filters} setFilters={setFilters} />
      <DepartmentTableBody tableData={departments} />
    </Table>
  );
};
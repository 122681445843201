import { createSlice } from '@reduxjs/toolkit';
import { loadOutOfStates, getCountOfOutOfStates } from './outofstates-async-thunk';

const initialState = {
  status: 'idle',
  outofstates: [],
  count: 0,
  errors: null,
};

const outofstateListSlice = createSlice({
  name: 'outofstateList',
  initialState,
  reducers: {
    setOutOfStatesLoadingStatus (state, action) {
      const { status } = action.payload;
      state.status = status;
    },
  },
  extraReducers: {
    // getOutOfStates extra reducer
    [loadOutOfStates.pending]: (state, action) => {
      state.status = 'loading';
    },
    [loadOutOfStates.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [loadOutOfStates.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.outofstates = action.payload.data;
    },

    // getCountOfOutOfStates extra reducer
    [getCountOfOutOfStates.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getCountOfOutOfStates.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [getCountOfOutOfStates.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.count = action.payload.data;
    },
  },
});

export const { setOutOfStatesLoadingStatus } = outofstateListSlice.actions;

export default outofstateListSlice.reducer;

export const getProcessOutOfStatesLoading = (state) => state.outofstateList.status;
export const getOutOfStates = (state) => state.outofstateList.outofstates;
export const getOutOfStatesCount = (state) => state.outofstateList.count;

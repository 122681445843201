// Third party libraries
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import * as immutable from 'object-path-immutable';
// Components
import { AddBtn } from '../add-btn/Add-btn';
import { OutOfStateList } from '../outofstate-list/OutOfState-list';
import { Loading } from '../loading/Loading';
import { OutDataMessage } from '../outdata-message/OutData-message';
import { SettingsContainer } from '../settings-container/Settings-container';
import { SettingsTitle } from '../settings-title/Settings-title';
// State functions
import {
  getProcessOutOfStatesLoading,
  getOutOfStates,
} from '../../clientStore/outofstatesSlice/outofstates-slice';
import { loadOutOfStates } from '../../clientStore/outofstatesSlice/outofstates-async-thunk';
import {
  getOutOfStateLoadingStatus,
  getOutOfStateSavingStatus,
  setOutOfStateLoadingStatus,
  setOutOfStateSavingStatus,
} from '../../clientStore/outofstateSlice/outofstate-slice';
import { useState } from 'react';
import { StatusToggleBtn } from '../status-toggle-btn/Status-toggle-btn';

export const OutOfStatesMain = () => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const outofstates = useSelector(getOutOfStates);
  const loadingProcess = useSelector(getProcessOutOfStatesLoading);
  const outofstateIsLoading = useSelector(getOutOfStateLoadingStatus);
  const outofstateIsSaving = useSelector(getOutOfStateSavingStatus);
  const [filters, setFilters] = useState(
    {
      'sort': {
        'field': 'createdAt',
        'order': -1
      },
      'filter': {}
    }
  );
  const [isFilterChanged, setFilterChanged] = useState(false);

  useEffect(() => {
    if (loadingProcess === 'idle' || isFilterChanged) {
      dispatch(loadOutOfStates({ 'filters': filters }));
       try {
        setFilterChanged(false)
      }
      catch (err) {
        console.log(err)
      };
    }
    if (outofstateIsLoading !== 'idle') {
      dispatch(setOutOfStateLoadingStatus({ status: 'idle' }));
    }
    if (outofstateIsSaving !== 'idle') {
      dispatch(setOutOfStateSavingStatus({ status: 'idle' }));
    }
  }, [dispatch, loadingProcess, outofstateIsLoading, outofstateIsSaving, filters, isFilterChanged]);

  const onFilterChange = (path, method, value) => {
    setFilters(immutable[method](filters, path, value));
     try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      };
  };

  return (
    <SettingsContainer>
      <div style={{ display: "flex" }}>
        <AddBtn
          name={'outofstate'}
          linkUrl={`${url}/add`}
          btnType={'link'}
        />
        <StatusToggleBtn statusField='isOOSDisabled' />
      </div>
      <SettingsTitle titleName={'out of state directions'} />
      {loadingProcess === 'loading' && <Loading />}
      {loadingProcess === 'successed' && outofstates.length > 0 && <OutOfStateList outofstates={outofstates} setFilters={onFilterChange} filters={filters} />}
      {loadingProcess === 'successed' && outofstates.length === 0 && <OutDataMessage dataName={'OutOfStates'} />}
    </SettingsContainer>
  );
};
// Components
import { Icon } from '../Icon';
// Third party libraries
import { nanoid } from 'nanoid';
import { Link, useLocation } from 'react-router-dom';
// Styles
import styles from './PageHeaderBtns.module.css';

export const PageHeaderBtns = ({ menuItems }) => {

  const location = useLocation();

  const items = []

  //(location.pathname.includes(key.toLowerCase()
  for (let key in menuItems) {
    items.push(
      <li className={styles.item} key={nanoid(10)}>
        <Link
          className={((location.pathname === `/${key.toLowerCase()}`) ? false : true) ? styles.btn : styles.activeBtn}
          to={`/${key.toLowerCase()}`}
        >
          <div className={styles.iconContainer}>
            <Icon name={menuItems[key]} />
          </div>
          {menuItems[key]}
        </Link>
      </li>
    );
  };

  items.push(
    <li className={styles.item} key={nanoid(10)}>
      <Link
        className={((location.pathname === `/`) ? false : true) ? styles.btn : styles.activeBtn}
        to={`/`}
      >
        <div className={styles.iconContainer}>
          <Icon name='Home' />
        </div>
        Home
      </Link>
    </li>
  )
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {items.map((it) => it)}
      </ul>
    </div>
  );
};
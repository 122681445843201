// Third party libraries
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Link } from 'react-router-dom';
import { getCompany, getCompanyLoadingStatus } from '../../clientStore/companySlice/company-slice';

import { getCompanyId, getUserName } from '../../clientStore/authSlice/auth-slice';
// Components
import { LogoutBtn } from '../logout-btn/Logout-btn';
// Styles
import styles from './SiteMenu.module.css';
import { useEffect } from 'react';
import { loadCompany } from '../../clientStore/companySlice/company-async-thunk';

export const SiteMenu = (props) => {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(getCompanyLoadingStatus);
  const { isMobileOpen, onBtnClick, isLoginBtnShowed } = props;
  const mobileMenuClass = isMobileOpen ? styles.containerOn : styles.container;
  const userName = useSelector(getUserName);
  const id = useSelector(getCompanyId);
  const companyName = useSelector(getCompany).companyName;


  useEffect(() => {
    if (loadingStatus === 'idle' && id) {
      dispatch(loadCompany(id));
    }
  }, [dispatch, id, loadingStatus]);

  try {
    return (
      <div className={styles.container}>
        <ul className={styles.list}>

          {userName && <li className={styles.item}><a href='/' className={styles.user}>{userName + '-' + companyName}</a></li>}
     
          {
            isLoginBtnShowed &&
            <li className={styles.item}>
              <Link to='/login' className={styles.link}>Login</Link>
            </li>
          }
          {
            !isLoginBtnShowed &&
            <li className={styles.item}>
              <LogoutBtn />
            </li>
          }
        </ul>
      {/*   <button
          className={styles.btnClose}
          onClick={() => onBtnClick(false)}
        ></button> */}
      </div>
    );
  }
  catch (err) {

  }

};
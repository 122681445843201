// Third party libraries
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
// Local State Functions
import {
  removeTemplate,
} from '../../clientStore/templateSlice/template-async-thunk';
import {
  getTemplateRemovingStatus,
  setTemplateSavingStatus,
  setTemplateLoadingStatus,
  setTemplateRemovingStatus,
} from '../../clientStore/templateSlice/template-slice';
// Styles
import styles from './TemplateTableBody.module.css';
import deleteIcon from './../../img/delete.png';


export const TemplateTableBody = ({ tableData }) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const removedStatus = useSelector(getTemplateRemovingStatus);

  useEffect(() => {
    if (removedStatus === 'successed') {
      dispatch(setTemplateSavingStatus({ status: 'idle' }));
      dispatch(setTemplateLoadingStatus({ status: 'idle' }));
      dispatch(setTemplateRemovingStatus({ status: 'idle' }));
      history.go(0)
    }
  }, [removedStatus, history, dispatch]);

  return (
    <ul className={styles.list}>
      {tableData.map((data) => {
        return (
          <li className={styles.listItem} key={data._id} className={styles.link}>
            <Link to={`${url}/${data._id}`} className={styles.txt}><span >{data.templateName}</span></Link>
            <Link to={`${url}/${data._id}`} className={styles.txt}> <span className={styles.txt}>{data.authorId.login}</span></Link>
           
            <Link to={`${url}/${data._id}`} className={styles.txt}> <span className={styles.txt}>{new Date(data.createdAt).toDateString()}</span></Link>
            <Link to={`${url}/${data._id}`} className={styles.txt}> <span className={styles.txt}>{new Date(data.updatedAt).toDateString()}</span></Link>
          </li>
        );
      })}
    </ul>
  );
};
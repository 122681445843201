import { Icon } from '../Icon';
// Utils
import { IconNames } from '../../util/const';
import { DepartmentDoubleInput } from '../department-double-input/Department-double-input';

// Third party libraries
import PropTypes from 'prop-types';
// Styles
import styles from './DepartmentDoubleWOTInput.module.css';

export const DepartmentDoubleWOTInput = (props) => {
  const {
    values,
    changeValues,
    index,
    removeCondition,
  } = props;

  const changeFirstFee = (evt) => {
    changeValues(evt, 0)
  }

  if (index === 0) {
    return (
      <DepartmentDoubleInput
        values={values}
        inputNameStr={'Truck reservation fee'}
        changeValues={changeFirstFee}
      />)
  }
  else {
    return (
      <div className={styles.inputContainer}>

        <button
          className={styles.removeExtraBtn}
          onClick={(evt) => removeCondition(evt, index)}
        >
          Delete
          <Icon name={IconNames.MINUS} />
        </button> <span className={styles.inputValueType}>$</span>
        <input
          id='destinationFee'
          className={styles.doubleInput}
          style={{ width:"90px"}}
          type='number'
          placeholder='ex. 1.2'
          name='fee'
          value={values.fee}
          onChange={(evt) => changeValues(evt, index)}
          onWheel={(e) => e.target.blur()}
          required
        />
        <span>/</span>
        <span className={styles.inputValueTypeSecond}>if over</span>
        <input
          id='destinationFee'
          className={styles.doubleInputSecond}
          style={{ width:"70px", paddingLeft:"10px"}}
          type='number'
          placeholder='ex. 140'
          name='miles'
          value={values.miles}
          onChange={(evt) => changeValues(evt, index)}
          onWheel={(e) => e.target.blur()}
          required
        />
        <span className={styles.eofmiles}>miles</span>
      </div>);
  }
};

DepartmentDoubleWOTInput.propTypes = {
  values: PropTypes.object.isRequired,
  changeValues: PropTypes.func.isRequired,
  removeCondition: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

// Third party libraries
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
// Local State Functions
import { removeOutOfState } from '../../clientStore/outofstateSlice/outofstate-async-thunk';
import {
  getOutOfStateRemovingStatus,
  setOutOfStateSavingStatus,
  setOutOfStateLoadingStatus,
  setOutOfStateRemovingStatus,
} from '../../clientStore/outofstateSlice/outofstate-slice';
// Styles
import styles from './OutOfStateTableBody.module.css';
import deleteIcon from './../../img/delete.png';

export const OutOfStateTableBody = ({ tableData }) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const removedStatus = useSelector(getOutOfStateRemovingStatus);

  useEffect(() => {
    if (removedStatus === 'successed') {
      dispatch(setOutOfStateSavingStatus({ status: 'idle' }));
      dispatch(setOutOfStateLoadingStatus({ status: 'idle' }));
      dispatch(setOutOfStateRemovingStatus({ status: 'idle' }));
      history.go(0)
    }
  }, [removedStatus, history, dispatch]);

  return (
    <ul className={styles.list}>
      {tableData.map((data) => {
        return (
          <li className={styles.listItem} key={data._id} className={styles.link}>
            <Link to={`${url}/${data._id}`} className={styles.txt}><span>{data.directionName}</span></Link>
            <Link to={`${url}/${data._id}`} className={styles.txt}><span>{data.authorId && data.authorId.login}</span></Link>
            <span className={styles.txt} onClick={() => dispatch(removeOutOfState(data._id))}><img src={deleteIcon} alt="Delete icon" className={styles.icon} /></span>
            <Link to={`${url}/${data._id}`} className={styles.txt}><span>{new Date(data.createdAt).toDateString()}</span></Link>
            <Link to={`${url}/${data._id}`} className={styles.txt}><span >{new Date(data.updatedAt).toDateString()}</span></Link>
          </li>
        );
      })}
    </ul>
  );
};
// Third party libraries
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import * as immutable from 'object-path-immutable';
// Components
import { AddBtn } from '../add-btn/Add-btn';
import { TemplateList } from '../template-list/Template-list';
import { Loading } from '../loading/Loading';
import { OutDataMessage } from '../outdata-message/OutData-message';
import { SettingsContainer } from '../settings-container/Settings-container';
import { SettingsTitle } from '../settings-title/Settings-title';
// State functions
import {
  getProcessTemplatesLoading,
  getTemplates,
} from '../../clientStore/templatesSlice/templates-slice';
import { loadTemplates } from '../../clientStore/templatesSlice/templates-async-thunk';
import {
  getTemplateLoadingStatus,
  getTemplateSavingStatus,
  setTemplateLoadingStatus,
  setTemplateSavingStatus,
} from '../../clientStore/templateSlice/template-slice';
import { useState } from 'react';


export const TemplatesMain = ({ type }) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const templates = useSelector(getTemplates);
  const loadingProcess = useSelector(getProcessTemplatesLoading);
  const templateIsLoading = useSelector(getTemplateLoadingStatus);
  const templateIsSaving = useSelector(getTemplateSavingStatus);
  const [filters, setFilters] = useState(
    {
      'sort': {
        'field': 'createdAt',
        'order': -1
      },
      'filter': {
        'field': 'type',
        'value': type,
      }
    }
  );
  const [isFilterChanged, setFilterChanged] = useState(false);

  useEffect(() => {
    if (loadingProcess === 'idle' || isFilterChanged) {
      dispatch(loadTemplates({ 'filters': filters }));
       try {
        setFilterChanged(false)
      }
      catch (err) {
        console.log(err)
      };
    }
    if (templateIsLoading !== 'idle') {
      dispatch(setTemplateLoadingStatus({ status: 'idle' }));
    }
    if (templateIsSaving !== 'idle') {
      dispatch(setTemplateSavingStatus({ status: 'idle' }));
    }
  }, [dispatch, loadingProcess, templateIsLoading, templateIsSaving, filters, isFilterChanged]);

  const onFilterChange = (path, method, value) => {
    setFilters(immutable[method](filters, path, value));
     try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      };
  };

  return (
    <SettingsContainer>
      {/*  <AddBtn
        name={'template'}
        linkUrl={`${url}/add`}
        btnType={'link'}
      /> */}
      <SettingsTitle titleName={'templates'} />
      {loadingProcess === 'loading' && <Loading />}
      {loadingProcess === 'successed' && templates.length > 0 && <TemplateList templates={templates} setFilters={onFilterChange} filters={filters} />}
      {loadingProcess === 'successed' && templates.length === 0 && <OutDataMessage dataName={'Templates'} />}
    </SettingsContainer>
  );
};
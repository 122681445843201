
import { useHistory } from 'react-router-dom';

import styles from './BtnMainCalc.module.css';


export const BtnMainCalc = ({ mainCalcId }) => {
  const history = useHistory();

  const handleClick = (evt) => {
    history.replace('/calculations/' + mainCalcId);
  };

  return (
    <button onClick={handleClick} className={styles.btn}>MAIN CALCULATION</button>
  );
}
// Third party libraries
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import * as immutable from 'object-path-immutable';
// Components
import { AddBtn } from '../add-btn/Add-btn';
import { DepartmentList } from '../department-list/Department-list';
import { Loading } from '../loading/Loading';
import { OutDataMessage } from '../outdata-message/OutData-message';
import { SettingsContainer } from '../settings-container/Settings-container';
import { SettingsTitle } from '../settings-title/Settings-title';
// State functions
import {
  getProcessDepartmentsLoading,
  getDepartments,
} from '../../clientStore/departmentsSlice/departments-slice';
import { loadDepartments } from '../../clientStore/departmentsSlice/departments-async-thunk';
import {
  getDepartmentLoadingStatus,
  getDepartmentSavingStatus,
  setDepartmentLoadingStatus,
  setDepartmentSavingStatus,
} from '../../clientStore/departmentSlice/department-slice';
import { useState } from 'react';
import { setCompanyLoadingStatus } from '../../clientStore/companySlice/company-slice';


export const DepartmentsMain = ({ type }) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const departments = useSelector(getDepartments);
  const loadingProcess = useSelector(getProcessDepartmentsLoading);
  const departmentIsLoading = useSelector(getDepartmentLoadingStatus);
  const departmentIsSaving = useSelector(getDepartmentSavingStatus);
  const [filters, setFilters] = useState(
    {
      'sort': {
        'field': 'createdAt',
        'order': -1
      },
      'filter': {
        'field': 'type',
        'value': type,
      }
    }
  );
  const [isFilterChanged, setFilterChanged] = useState(false);


  useEffect(() => {
    dispatch(setCompanyLoadingStatus({ status: 'idle' }));
    if (loadingProcess === 'idle' || isFilterChanged) {
      dispatch(loadDepartments({ 'filters': filters }));
      try {
        setFilterChanged(false)
      }
      catch (err) {
        console.log(err)
      };
    }
    if (departmentIsLoading !== 'idle') {
      dispatch(setDepartmentLoadingStatus({ status: 'idle' }));
    }
    if (departmentIsSaving !== 'idle') {
      dispatch(setDepartmentSavingStatus({ status: 'idle' }));
    }
  }, [dispatch, loadingProcess, departmentIsLoading, departmentIsSaving, filters, isFilterChanged]);

  const onFilterChange = (path, method, value) => {
    setFilters(immutable[method](filters, path, value));
    try {
      setFilterChanged(true)
    }
    catch (err) {
      console.log(err)
    };
  };

  const titleName = type === 'hourly' ? 'Hourly departments' : 'Cu Ft departments';


  return (
    <SettingsContainer>
      <AddBtn
        name={'department'}
        linkUrl={`${url}/add`}
        btnType={'link'}
        type={type}
      />
      <SettingsTitle titleName={titleName} />
      {loadingProcess === 'loading' && <Loading />}
      {loadingProcess === 'successed' && departments.length > 0 && <DepartmentList departments={departments} setFilters={onFilterChange} filters={filters} />}
      {loadingProcess === 'successed' && departments.length === 0 && <OutDataMessage dataName={'Departments'} />}
    </SettingsContainer>
  );
};
// Third party libraries
import PropTypes from 'prop-types';
// Components
import { DepartmentDoubleInput } from '../department-double-input/Department-double-input';
import { DepartmentDoubleWOTInput } from '../department-double-wot-input/Department-double-wot-input';
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentTitle } from '../department-title/Department-title';
import { DepartmentRadiobutons } from '../department-radiobutons/Department-radiobutons';
import { Icon } from '../Icon';
// Utils
import { IconNames } from '../../util/const';
// Styles
import styles from './Truck.module.css';

export const Truck = (props) => {
  const {
    truckFee,
    changeTruckValue,
    clearLastTarget,
  } = props;

  const setTruckFee = (evt) => {
    changeTruckValue(`truckFee.${evt.target.name}`, 'set', evt.target.value);
  }

  const setTruckFeeDestination = (evt) => {
    changeTruckValue(`truckFee.truckDestionation.${evt.target.name}`, 'set', evt.target.value);
  };


  const changeRoundToFull = (value) => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        throw new Error('invalid data');
    }
  };

  const setdiffTruckReservations = (evt, index) => {
    changeTruckValue(`truckFee.diffTruckReservations.${index}.${evt.target.name}`, 'set', evt.target.value);
  }

  const addCondition = (evt) => {
    evt.preventDefault();
    changeTruckValue(`truckFee.diffTruckReservations`, 'push', { fee: '', miles: '' });
  }

  const removeCondition = (evt, index) => {
    evt.preventDefault();
    changeTruckValue(`truckFee.diffTruckReservations.${truckFee.diffTruckReservations.splice(index, 1)}`, 'del');
  }

  return (
    <section
      onFocus={clearLastTarget}
    >
      <DepartmentTitle title={'Truck fee'} />
      <div className={styles.inputContainer}>
        <label
          htmlFor='calculationMethod'
          className={styles.fieldName}
        >
          Calculation method
        </label>
        <select
          id='calculationMethod'
          className={styles.feeTypeSelect}
          name='calcMethod'
          onChange={(evt) => setTruckFee(evt)}
          value={truckFee.calcMethod}
        >
          <option
            value=''
          >None</option>
          <option
            value='simple'
          >
            Miles X Factor
          </option>
          <option
            value='different'
          >
            Range of miles
          </option>
        </select>
      </div>

      {(truckFee.calcMethod === "simple") &&
        <div className={styles.container}>
          <DepartmentLabel
            inputId={'truckReservation'}
            name={'truckReservation'}
            valueType={'$'}
            placeholder={'ex. 39'}
            value={truckFee.truckReservation}
            title={'Truck reservation fee'}
            changeValue={(evt) => setTruckFee(evt)}
            inputType='number'
            required
          />
          <hr className={styles.shutLine} />
          <DepartmentDoubleInput
            values={truckFee.truckDestionation}
            changeValues={setTruckFeeDestination}
            inputNameStr={'Destination fee multiplier'}
            required
          />
          <hr className={styles.shutLine} />
          <DepartmentRadiobutons
            title='Display truck fee and destination fee combined'
            name='tfdfCombined'
            isChecked={truckFee.tfdfCombined}
            onChangeValue={(evt) => changeTruckValue(`truckFee.tfdfCombined`, 'set', changeRoundToFull(evt.target.value))}
            firstValue='Yes'
            secondValue='No'
          />
        </div>
      }

      {(truckFee.calcMethod === "different") &&
        <div className={styles.contan}>
          {truckFee.diffTruckReservations.map((diffTruckReservation, index) => {
            return <DepartmentDoubleWOTInput values={diffTruckReservation} changeValues={setdiffTruckReservations} removeCondition={removeCondition} index={index} key={index} />
          })}

          <div className={styles.addParent}>
            <button
              className={styles.extraBtn}
              onClick={addCondition}
            >
              Add condition
              <Icon name={IconNames.PLUS} />
            </button>
          </div>
        </div>
      }

    </section>
  );
};

Truck.propTypes = {
  truckFee: PropTypes.object.isRequired,
  changeTruckValue: PropTypes.func.isRequired,
}

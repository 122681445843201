// Third party libraries
import PropTypes from 'prop-types';
// Styles
import styles from './DepartmentDoubleInput.module.css';

export const DepartmentDoubleInput = (props) => {
  const {
    values,
    changeValues,
    inputNameStr,
  } = props;


  return (<label
    className={styles.inputContainer}
    htmlFor='destinationFee'
  >
    <p className={styles.inputName}>{inputNameStr}</p>
   {/*  <span className={styles.inputValueType}>$</span> */}
   <div>
    <input
      id='destinationFee'
      className={styles.doubleInput}
      type='number'
      placeholder='ex. 1.2'
      name='fee'
      value={values.fee}
      onChange={(evt) => changeValues(evt)}
      onWheel={(e) => e.target.blur()}
      required
    />
    <span>/</span>
    <span className={styles.inputValueTypeSecond}>if over</span>
    <input
      id='destinationFee'
      className={styles.doubleInputSecond}
      type='number'
      placeholder='ex. 140'
      name='miles'
      value={values.miles}
      onChange={(evt) => changeValues(evt)}
      onWheel={(e) => e.target.blur()}
      required
    />
    <span className={styles.eofmiles}>miles</span>
    </div>
  </label>);
};

DepartmentDoubleInput.propTypes = {
  values: PropTypes.object.isRequired,
  changeValues: PropTypes.func.isRequired,
};

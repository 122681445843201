import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const loadUsers = createAsyncThunk(
  'users/getUsers',
  async (params) => await fetchedData('user/users', 'POST', { params })
);

export const loadUsersAll = createAsyncThunk(
  'users/getUsersAll',
  async (params) => await fetchedData('user/usersAll', 'POST', { params })
);

export const saveUser = createAsyncThunk(
  'users/saveUser',
  async (newUserData) => await fetchedData('user/registration', 'POST', { ...newUserData })
);

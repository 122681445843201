// Third party libraries
import PropTypes from 'prop-types';
// Components
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentTitle } from '../department-title/Department-title';
import { DepartmentRadiobuttons } from '../department-radiobuttons/Department-radiobuttons';
// Styles
import styles from './DoubleDrive.module.css';

export const DoubleDrive = (props) => {
  const {
    doubleDrive,
    changeDoubleDriveValue,
    clearLastTarget,
  } = props;
  const onIsActiveChange = (evt) => {
    //setRatesValue({ target: { value: evt.target.checked, name: evt.target.name } }, index)
    
  }
  return (
    <section
      onFocus={clearLastTarget}
    >

      <DepartmentTitle title={'Double drive time & charge per mile'} />
      <div className={styles.flexdiv}>
       
        <label
          className={styles.inputContainer}
          htmlFor='ddmiles'
        >
          <span className={styles.inputValueTypeSecond}>Apply double drive if over</span>
          <input
            id='ddmiles'
            className={styles.doubleInputSecond}
            type='number'
            onWheel={(e) => e.target.blur()}
            placeholder='50'
            name='miles'
            value={doubleDrive.miles}
            onChange={(evt) => changeDoubleDriveValue(`doubleDrive.miles`, 'set', evt.target.value)}
          />
          <span className={styles.eofmiles}>miles</span>
        </label>
        <DepartmentRadiobuttons
          name='applyDoubleDrive'
          isChecked={doubleDrive.apply}
          onChangeValue={(evt) => changeDoubleDriveValue(`doubleDrive.apply`, 'set', !doubleDrive.apply)}
          firstValue='Yes'
          secondValue='No'
        />
      </div>
      <div className={styles.chargesPerMileContainer}>
        <input type='checkbox'
          checked={doubleDrive.isActiveChargePerMile}
          name='isActiveChargePerMile'
          onChange={(evt) => changeDoubleDriveValue(`doubleDrive.isActiveChargePerMile`, 'set', evt.target.checked)}
          defaultValue={false}
        /> Charge per mile
        <input
          id='ddmiles'
          className={styles.chargesPerMileInput}
          type='number'
          onWheel={(e) => e.target.blur()}
          placeholder='ex. 2'
          name='chargePerMile'
          value={doubleDrive.chargePerMile}
          onChange={(evt) => changeDoubleDriveValue(`doubleDrive.chargePerMile`, 'set', evt.target.value)}
          disabled={!doubleDrive.isActiveChargePerMile}
        />
      </div>
    </section>
  );
};

DoubleDrive.propTypes = {
  doubleDrive: PropTypes.object.isRequired,
  changeDoubleDriveValue: PropTypes.func.isRequired,
  clearLastTarget: PropTypes.func.isRequired,
}

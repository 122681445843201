//Third party libraries
import React, {useState } from 'react';

import styles from './TemplatePreviewPopup.module.css';
import copyIcon from './../../img/copy.png';


export const TemplatePreviewPopup = ({ popUpShown, previewText, setShown, optionsPreviewText }) => {

  const [copied, setCopied] = useState(false);
  const [optionsCopied, setOptionsCopied] = useState(false);


  const handleCopy = (evt) => {
    evt.preventDefault();
    //const textToCopy = document.getElementById('message-content-to-copy');
    const textToCopy = document.querySelector('#message-content-to-copy p:first-child');

    const range = document.createRange();
    range.selectNode(textToCopy);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    console.log('textToCopy', textToCopy)
    document.execCommand('copy');
    // Deselect the text and set the copied state to true
    window.getSelection().removeAllRanges();
    setCopied(true);
    // Reset the copied state after a short delay (optional)
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  const handleOptionsCopy = (evt) => {
    evt.preventDefault();
    const textToCopy = document.getElementById('message-content-options-to-copy');
    const range = document.createRange();
    range.selectNode(textToCopy);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    console.log('textToCopy', textToCopy)
    document.execCommand('copy');
    // Deselect the text and set the copied state to true
    window.getSelection().removeAllRanges();
    setOptionsCopied(true);
    // Reset the copied state after a short delay (optional)
    setTimeout(() => {
      setOptionsCopied(false);
    }, 2000);
  }



  return (
    (popUpShown) ?
      <div className={styles.popupMain}>
        <div className={styles.popup} >
          <button type="button" className={styles.close} aria-label="Close" onClick={() => setShown(!popUpShown)}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className={styles.content}>
            <div className={styles.title}>Message Preview</div>
            <div className={styles.inputWrapper}>
              <input type="email" placeholder="To" />
              <div className={styles.to}>
                <span>Cc</span>
                <span>Bcc</span>

              </div>
            </div>

            <div className={styles.secondInputWrapper}>
              <input type="text" placeholder="Subject" />
              <button className={styles.btn} onClick={handleOptionsCopy} style={{ marginRight: "20px" }}>
                <img src={copyIcon} alt="Copy icon" className={styles.icon} style={{ marginRight: "5px" }} />
                {optionsCopied ? 'Copied!' : 'Copy Option to clipboard'}
              </button>
              <button className={styles.btn} onClick={handleCopy}>
                <img src={copyIcon} alt="Copy icon" className={styles.icon} style={{ marginRight: "5px" }} />
                {copied ? 'Copied!' : 'Copy to clipboard'}
              </button>
            </div>
            <div className={styles.message} dangerouslySetInnerHTML={{ __html: previewText }} id="message-content-to-copy"></div>
            <div className={styles.messageHidden} dangerouslySetInnerHTML={{ __html: optionsPreviewText }} id="message-content-options-to-copy"></div>
          </div>
        </div>
      </div>
      : ''
  );
}
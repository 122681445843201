import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const loadLongDistances = createAsyncThunk(
  'longdistanceList/getLongDistances',
  async (params) => await fetchedData('longdistance/longdistances', 'POST', { params })
);

export const getCountOfLongDistances = createAsyncThunk(
  'longdistanceList/getCount',
  async () => await fetchedData('longdistance/count', 'GET')
);

import { components } from 'react-select'

export const CustomOption = (props) => {
  const { setHighLiteItem, value } = props
  const searchItem = props.options.find(option => option.value === value);
  let color = 'black'
  if (searchItem) {
    color = searchItem.color
  }


  const handleFocus = (item) => {
    setHighLiteItem(item)
  }
  return (
    <components.Option {...props} >
      <div onMouseEnter={() => handleFocus(props.value)} onMouseOut={() => handleFocus('')} style={{ color: color }}>
        {props.children}
      </div>
    </components.Option>
  );
};
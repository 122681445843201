// Third party libraries
import { nanoid } from 'nanoid';
// Components
import { Icon } from '../Icon';
// Utils
import { IconNames } from '../../util/const';
// Styles
import styles from './ActionsTableHeader.module.css';

export const ActionsTableHeader = ({ headerTitles, setFilters, filters }) => {
  const titles = Object.values(headerTitles);
  const keys = Object.keys(headerTitles)
  return (
    <header className={styles.header}>
      <ul className={styles.titles}>
        {titles.map((it, index) => {
          return (
            <li
              className={styles.titleItem}
              key={nanoid(titles.length)}
            >
              {it}
              {(it !== "") && <button
                className={(filters.sort.field === keys[index] && filters.sort.order === 1) ? styles.sortBtnAsc : styles.sortBtn}
                onClick={() => {
                  setFilters("sort", 'set', { 'field': keys[index], 'order': (filters.sort.order === -1) ? 1 : -1 });
                }}
              >
                <Icon name={IconNames.TRIANGLE} />
              </button>
              }
            </li>
          );
        })}
      </ul>
    </header >
  );
};

import React from 'react';
import './Pagination.css';

const Pagination = ({ currentPage, totalPages, onPageChange, perPage, onPerPageChange, setFilterChanged }) => {
    const pages = [];
    // Calculate the page numbers to display
    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }

    const handlePrevClick = () => {
         try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      };
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextClick = () => {
         try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      };
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePageClick = (pageNum) => {
         try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      };
        onPageChange(pageNum);
    };

    const handlePerPageChange = (e) => {
         try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      };
        onPerPageChange(e.target.value);
    };

    const renderPageButtons = () => {
        const firstPage = 1;
        const lastPage = totalPages;

        // Calculate the range of page numbers to display
        const pageRange = 3;
        const startRange = Math.max(firstPage, currentPage - pageRange);
        const endRange = Math.min(lastPage, currentPage + pageRange);

        const pageButtons = [];

        for (let i = startRange; i <= endRange; i++) {
            pageButtons.push(
                <a
                    key={i}
                    className={i === currentPage ? 'page active' : 'page'}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </a>
            );
        }

        if (startRange > firstPage) {
            pageButtons.unshift(
                <span key="dots-start" className="dots">...</span>
            );
            pageButtons.unshift(
                <a
                    key={firstPage}
                    className={currentPage === firstPage ? 'page active' : 'page'}
                    onClick={() => handlePageClick(firstPage)}
                >
                    {firstPage}
                </a>
            );
        }

        if (endRange < lastPage) {
            pageButtons.push(
                <span key="dots-end" className="dots">
                    ...
                </span>
            );
            pageButtons.push(
                <a
                    key={lastPage}
                    className={currentPage === lastPage ? 'page active' : 'page'}
                    onClick={() => handlePageClick(lastPage)}
                >
                    {lastPage}
                </a>
            );
        }

        return pageButtons;
    };

    return (
        <div className="pagination">
            <div className="per-page">
                <span>Per Page:</span>
                <select value={perPage} onChange={handlePerPageChange}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                </select>
            </div>
            <div className="pages">
                <button className={currentPage === 1 ? 'prev disabled' : 'prev'} onClick={handlePrevClick} disabled={currentPage === 1}></button>
                {renderPageButtons()}
                <button className={currentPage === totalPages ? 'next disabled' : 'next'} onClick={handleNextClick} disabled={currentPage === totalPages}></button>
            </div>
        </div>
    );
};

export default Pagination;
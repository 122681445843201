import React, { useState, useContext, useCallback, useEffect } from 'react';
import { performComparison } from '../../util/utils';
// Styles
import styles from "./AdditionalServicesEstimateInfo.module.css";

export const AdditionalServicesEstimateInfo = ({
  commonValues,
  department,
  totalCubes,
  scrollRefs,
  highLightItem
}) => {
  const isCuFtDepartment = department?.type === 'cuft';
  return ((isCuFtDepartment) ?
    <li className={styles.paramsItem}>
      <p className={styles.paramsName}>Additional Services not included in the estimate:</p>
      <span className={styles.paramsDecor}></span>
      <p className={styles.paramsValue}>
      </p>
      <ul>
        {department && department.additionalServices && department.additionalServices.services.map((service, index) => {
          if (service.serviceType === 'cuft') {
            for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
              let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
              if (performComparison(pricePerCuFt[`operator${pcftIndex}`], Number(totalCubes), Number(pricePerCuFt[`cubicFtRange${pcftIndex}`]))) {
                return <li key={index + pcftIndex + 'm'}><span>{service.serviceName}</span>: <span>${pricePerCuFt[`pricePerCuFt${pcftIndex}`]}</span></li>;
              }
            }
          } else if (service.serviceType === 'factor') {
            for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
              let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
              if (performComparison(pricePerCuFt[`operator${pcftIndex}`], commonValues.additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
                return <li key={index + pcftIndex + 'e'}><span>{service.serviceName}</span>: <span>${pricePerCuFt[`pricePerCuFt${pcftIndex}`]}</span></li>;
              }
            }
          }
        })}
        {department.packingCharge && <>
          <li>Full packing labor and supplies such as boxes, bubble wrap: ${department.packingCharge.fullPacking} per cubic foot</li>
          <li>Partial packing: ${department.packingCharge.partialPacking} per cubic foot</li>
          <li>Kitchen packing: ${department.packingCharge.kitchenPacking} per cubic foot</li>
          <li>${department.packing.supplies.perOnePackedBox} per box if you'd only like to have us pack up a few things</li>
          <li>Heavy item over 300lbs: ${department.extraOptions.heavyItem}</li>
        </>}
      </ul>
    </li> : '');
};

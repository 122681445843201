// Third party libraries
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as immutable from 'object-path-immutable';
// Components
import { ActionList } from '../action-list/Action-list';
import { Loading } from '../loading/Loading';
import { OutDataMessage } from '../outdata-message/OutData-message';
// State functions
import {
  getProcessGroupCalculationsLoading,
  getCalculations,
  setGroupCalculationsLoadingStatus,
  getGroupCalculations,
} from '../../clientStore/calculationsSlice/calculations-slice';
import { loadActions } from '../../clientStore/actionsSlice/actions-async-thunk';
import { useState } from 'react';
import { ActionsContainer } from '../actions-container/Actions-container';
import { ActionsTitle } from '../actions-title/Actions-title';
import { setDepartmentLoadingStatus } from '../../clientStore/departmentSlice/department-slice';
import { setUnpDepartmentLoadingStatus } from '../../clientStore/unpDepartmentSlice/unp-department-slice';
import { setLongDistanceLoadingStatus } from '../../clientStore/longdistanceSlice/longdistance-slice';
import { setOutOfStateLoadingStatus } from '../../clientStore/outofstateSlice/outofstate-slice';
import { loadCalculations, loadGroupCalculations } from '../../clientStore/calculationsSlice/calculations-async-thunk';
import { CalculationList } from '../calculation-list/Calculation-list';
import { SettingsTitle } from '../settings-title/Settings-title';
import { GroupCalculationsTitle } from '../group-calculations-title/Group-calculations-title';
import { SettingsContainer } from '../settings-container/Settings-container';
import { SubCalcsContainer } from '../subcalcs-container/Subcalcs-container';


export const ChildCalcsMain = (props) => {
  const { mainCalcId, isExpanded, isCalculationPage } = props;

  const dispatch = useDispatch();
  const subCalculations = useSelector(getGroupCalculations(mainCalcId));
  const loadingProcess = useSelector(getProcessGroupCalculationsLoading);


  const [filters, setFilters] = useState(
    {
      'filter': {
        'includeMain': isCalculationPage
      }
    }
  );

  const [isFilterChanged, setFilterChanged] = useState(false);

  useEffect(() => {
    if (loadingProcess === 'idle' || isFilterChanged) {
      dispatch(loadGroupCalculations({ mainCalcId, 'filters': filters }));
      try {
        setFilterChanged(false)
      }
      catch (err) {
        console.log(err)
      };
    }
    return () => {
      dispatch(setGroupCalculationsLoadingStatus({ status: 'idle' }));
    }
  }, [dispatch, mainCalcId]);

  const onFilterChange = (path, method, value) => {
    setFilters(immutable[method](filters, path, value));
    try {
      setFilterChanged(true)
    }
    catch (err) {
      console.log(err)
    };
  };

  return (
    <SubCalcsContainer>
      {/*       {loadingProcess === 'loading' && <Loading />} */}
      {(loadingProcess === 'successed' || isExpanded) && subCalculations && subCalculations.length > 0 && <CalculationList calculations={subCalculations} setFilters={onFilterChange} filters={filters} isChildList={true} isCalculationPage={isCalculationPage} />}
      {/* {loadingProcess === 'successed' && subCalculations && subCalculations.length === 0 && <OutDataMessage dataName={'Group Calculations'} />}
   */}
    </SubCalcsContainer>
  );
};
import { createSlice } from '@reduxjs/toolkit';
import {
  loadLongDistance,
  saveLongDistance,
  updateLongDistance,
  removeLongDistance,
} from './longdistance-async-thunk';


const initialState = {
  savingStatus: 'idle',
  loadingStatus: 'idle',
  longdistance: {},
  countOfLongDistances: 0,
  errors: null,
};

const longdistanceSlice = createSlice({
  name: 'longdistance',
  initialState,
  reducers: {
    setLongDistanceSavingStatus (state, action) {
      const { status } = action.payload;
      state.savingStatus = status;
    },
    setLongDistanceLoadingStatus (state, action) {
      const { status } = action.payload;
      state.loadingStatus = status;
    },
    setLongDistanceRemovingStatus(state, action) {
      const { status } = action.payload;
      state.removingStatus = status;
    },
  },
  extraReducers: {
    // saveLongDistance extra reducer
    [saveLongDistance.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [saveLongDistance.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [saveLongDistance.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.longdistance = action.payload.data;
    },

    // updateDepartmen extra reducer
    [updateLongDistance.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [updateLongDistance.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [updateLongDistance.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.longdistance = action.payload.data;
    },

    // loadLongDistance extra reducer
    [loadLongDistance.pending]: (state, action) => {
      state.loadingStatus = 'loading';
    },
    [loadLongDistance.rejected]: (state, action) => {
      state.loadingStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadLongDistance.fulfilled]: (state, action) => {
      state.loadingStatus = 'successed';
      state.longdistance = action.payload.data;
    },

    //removeLongDistance extra reducer
    [removeLongDistance.pending]: (state, action) => {
      state.removingStatus = 'loading';
    },
    [removeLongDistance.rejected]: (state, action) => {
      state.removingStatus = 'failed';
      state.errors = action.error.message;
    },
    [removeLongDistance.fulfilled]: (state, action) => {
      state.removingStatus = 'successed';
      state.longdistance = action.payload.data;
    },
  },
});

export const { setLongDistanceSavingStatus, setLongDistanceLoadingStatus, setLongDistanceRemovingStatus } = longdistanceSlice.actions;

export default longdistanceSlice.reducer;

export const getLongDistanceSavingStatus = (state) => state.longdistance.savingStatus;

export const getLongDistanceLoadingStatus = (state) => state.longdistance.loadingStatus;

export const getLongDistanceRemovingStatus = (state) => state.longdistance.removingStatus;

export const getLongDistance = (state) => state.longdistance.longdistance;

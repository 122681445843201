// Third party libraries
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as immutable from 'object-path-immutable';
// Components
import { ActionList } from '../action-list/Action-list';
import { Loading } from '../loading/Loading';
import { OutDataMessage } from '../outdata-message/OutData-message';
// State functions
import {
  getProcessActionsLoading,
  getActions,
  setActionsLoadingStatus,
} from '../../clientStore/actionsSlice/actions-slice';
import { loadActions } from '../../clientStore/actionsSlice/actions-async-thunk';
import { useState } from 'react';
import { ActionsContainer } from '../actions-container/Actions-container';
import { ActionsTitle } from '../actions-title/Actions-title';
import { setDepartmentLoadingStatus } from '../../clientStore/departmentSlice/department-slice';
import { setUnpDepartmentLoadingStatus } from '../../clientStore/unpDepartmentSlice/unp-department-slice';
import { setLongDistanceLoadingStatus } from '../../clientStore/longdistanceSlice/longdistance-slice';
import { setOutOfStateLoadingStatus } from '../../clientStore/outofstateSlice/outofstate-slice';


export const ActionsMain = (props) => {
  const dispatch = useDispatch();
  const actions = useSelector(getActions);
  const loadingProcess = useSelector(getProcessActionsLoading);

  const { calculationId } = props;
  const [filters, setFilters] = useState(
    {
      'sort': {
        'field': 'createdAt',
        'order': -1
      },
      'filter': {}
    }
  );
  const [isFilterChanged, setFilterChanged] = useState(false);

  useEffect(() => {
    if (loadingProcess === 'idle' || isFilterChanged) {
      dispatch(loadActions({ 'filters': filters, 'calculationId': calculationId }));
       try {
        setFilterChanged(false)
      }
      catch (err) {
        console.log(err)
      };
    }
    return () => {
      dispatch(setActionsLoadingStatus({ status: 'idle' }));
      dispatch(setDepartmentLoadingStatus({ status: 'idle' }));
      dispatch(setUnpDepartmentLoadingStatus({ status: 'idle' }));
      dispatch(setLongDistanceLoadingStatus({ status: 'idle' }));
      dispatch(setOutOfStateLoadingStatus({ status: 'idle' }));
    }
  }, [dispatch, calculationId]);

  const onFilterChange = (path, method, value) => {
    setFilters(immutable[method](filters, path, value));
     try {
        setFilterChanged(true)
      }
      catch (err) {
        console.log(err)
      };
  };

  return (

    <ActionsContainer>
      <ActionsTitle />
      {loadingProcess === 'loading' && <Loading />}
      {loadingProcess === 'successed' && actions.length > 0 && <ActionList actions={actions} setFilters={onFilterChange} filters={filters} />}
      {loadingProcess === 'successed' && actions.length === 0 && <OutDataMessage dataName={'Actions'} />}
    </ActionsContainer>
  );
};
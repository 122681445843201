import { createSlice } from '@reduxjs/toolkit';
import { loadCalculations, getCountOfCalculations, removeCalculations, loadGroupCalculations } from './calculations-async-thunk';

const initialState = {
  status: 'idle',
  groupStatus: 'idle',
  removingStatus: 'idle',
  groupStatus: 'idle',
  calculations: [],
  removedCalculations: [],
  groupCalculations: [],
  count: 0,
  errors: null,
};

const calculationListSlice = createSlice({
  name: 'calculationList',
  initialState,
  reducers: {
    setCalculationsLoadingStatus(state, action) {
      const { status } = action.payload;
      state.status = status;
    },
    setGroupCalculationsLoadingStatus(state, action) {
      const { status } = action.payload;
      state.groupStatus = status;
    },
  },
  extraReducers: {
    // getCalculations extra reducer
    [loadCalculations.pending]: (state, action) => {
      state.status = 'loading';
    },
    [loadCalculations.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [loadCalculations.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.calculations = action.payload.data;
    },

    // getCalculations extra reducer
    [loadGroupCalculations.pending]: (state, action) => {
      state.groupStatus = 'loading';
    },
    [loadGroupCalculations.rejected]: (state, action) => {
      state.groupStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadGroupCalculations.fulfilled]: (state, action) => {
      state.groupStatus = 'successed';
      state.groupCalculations = { ...state.groupCalculations, ...action.payload.data };
    },

    // removeCalculations extra reducer
    [removeCalculations.pending]: (state, action) => {
      state.removingStatus = 'loading';
    },
    [removeCalculations.rejected]: (state, action) => {
      state.removingStatus = 'failed';
      state.errors = action.error.message;
    },
    [removeCalculations.fulfilled]: (state, action) => {
      state.removingStatus = 'successed';
      state.removedCalculations = action.payload.data;
    },

    // getCountOfCalculations extra reducer
    [getCountOfCalculations.pending]: (state, action) => {
      state.status = 'loading';
    },
    [getCountOfCalculations.rejected]: (state, action) => {
      state.status = 'failed';
      state.errors = action.error.message;
    },
    [getCountOfCalculations.fulfilled]: (state, action) => {
      state.status = 'successed';
      state.count = action.payload.data;
    },
  },
});

export const { setCalculationsLoadingStatus, setGroupCalculationsLoadingStatus } = calculationListSlice.actions;

export default calculationListSlice.reducer;

export const getProcessCalculationsLoading = (state) => state.calculationList.status;
export const getProcessGroupCalculationsLoading = (state) => state.calculationList.groupStatus;
export const getCalculations = (state) => state.calculationList.calculations;
export const getGroupCalculations = (mainCalcId) => (state) => { return state.calculationList.groupCalculations[mainCalcId] };
export const removeCompanyCalculations = (state) => state.calculationList.removedCalculations;
export const getProcessCalculationsRemoving = (state) => state.calculationList.removingStatus;
export const getCalculationsCount = (state) => state.calculationList.count;

// Third party libraries
import PropTypes from 'prop-types';
// Styles
import styles from './RateTypeRadiobuttons.module.css';

export const RateTypeRadiobuttons = (props) => {
  const { name, isChecked, onChangeValue, firstValue, secondValue } = props;
  return (
    <label className={styles.inputContainer}>
    
      <p className={styles.radiobtnContainer}>
        <input
          type='radio'
          value={true}
          name={name}
          checked={isChecked}
          onChange={onChangeValue}
        />
        {firstValue}
        <input
          className={styles.secondInput}
          type='radio'
          value={false}
          name={name}
          checked={!isChecked}
          onChange={onChangeValue}
        />
        {secondValue}
      </p>
    </label>
  );
};

RateTypeRadiobuttons.propTypes = {
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  firstValue: PropTypes.string.isRequired,
  secondValue: PropTypes.string.isRequired,
}
